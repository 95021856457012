import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import FooterRgsa from "../rgsaLogin/FooterRgsa";
import TitleHeader from "./TitleHeaderRgsa";
import Resources from "./Resources";
import Translate from "../../components/Shared/Translate";
import { NavLink, useNavigate } from "react-router-dom";
import { variables } from "../../components/Shared/Variables.js";
import {
  updateChild,
  updateParent,
  updategrandChild,
  updateggChild,
  updateExtended,
} from "../../features/breadcrumb/breadcrumbSlice";
import {
  updateFilterVal,
  updateNodeVal,
  updateFilterRetained,
  updateIsBlockCourseIndexAPi,
  updateAccordianNo,
  updateIsContentNodeVal,
} from "../../features/CourseIndex/courseIndexSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
// import { useErrorBoundary } from "react-error-boundary";

import dateFormat from "dateformat";

import { TimerFormat } from "../../components/generalFunctions/timeFormat.js";
import Cookies from "universal-cookie";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import useScrollPosition from "../../components/Shared/ScrollTracker.js";
import { apiCallingForCourseStarted } from "../../components/generalFunctions/apiCalling.js";
import CertificateRgsa from "./certificateRgsa";
import Chatbot from "./Chatbot";

export default function IndexRgsa() {
  const nodeValue = useSelector((state) => state.courseindex.nodeData);
  const { moduleId } = useParams();

  // console.log("nodeValue : ", nodeValue);

  // const { showBoundary } = useErrorBoundary();
  const dispatch = useDispatch();
  var coursedata = JSON.parse(localStorage.getItem("coursedata"));
  const [courseLevel, setcourseLevel] = useState(coursedata?.courseLevel);
const [assesment,setAssessment] = useState([])
  const [isLoader, setIsLoader] = useState(false);
  const [batchID, setBatchID] = useState("8574");
  const [section, setSection] = useState(localStorage.getItem("section") ? localStorage.getItem("section") : "course");
  const [sessions, setSessions] = useState([]);

  const userId = localStorage.getItem("login-userId")
  const courseData = JSON.parse(localStorage.getItem("coursedata"));


  const nextPrevData = async () => {
    const nodeValFromLocalStorage = await JSON.parse(localStorage.getItem("nodeVal") || "[]");
    const allTopics = nodeValFromLocalStorage.filter((it) => {
      return it.deliverymodename != ""
    })
    // // console.log("allTopics : ",allTopics);
    let nodeIds = "";
    const nodeIdsArr = allTopics.map((i) => {
      return i.nodeId
    })
    nodeIds = nodeIdsArr.join(",");
    let bodyForNextPrev = {
      "userId": userId,
      "batchId": courseData.batchId,
      "nodeids": nodeIds
    }
    // console.log("body for next prev : ", bodyForNextPrev);
    let resAfterFetchingNextPrev = await axios.post(variables.API_URL + `api/GetUserSessionv1`, bodyForNextPrev);
    // console.log("resAfterFetchingNextPrev : ", resAfterFetchingNextPrev);
    if (resAfterFetchingNextPrev?.data?.isSuccess) {
      localStorage.setItem("nextPrevData", JSON.stringify(resAfterFetchingNextPrev));
    }
    setIsLoader(false);
  }

  const getcourseIndex = async () => {
    var userId = await localStorage.getItem("login-userId");
    setBatchID(coursedata.batchId);
    var coursedataInApi = await JSON.parse(
      localStorage.getItem("coursedata")
    );
    // alert(coursedataInApi?.courseLevel);
    if (nodeValue.length > 0) {
      // console.log("inside nodeValue.length > 0 : ", nodeValue)
      // alert("re arranging value in co after nodeval updated");
      // setNodeData(nodeValue);
      // setData(nodeValue);
      let tempdataList = nodeValue.filter(
        (node) => node.nodeClassification === "Session" && node.parentNodeId == moduleId && node.deliverymodename != "Assessment"
      );
      var assessmentList = nodeValue.filter(
        (node) => node.deliverymodename === "Assessment" && node.parentNodeId == moduleId
      );
      setAssessment(assessmentList)
      console.log(JSON.stringify(assessmentList))
      // console.log("tempdataList : ", tempdataList);
      setSessions(tempdataList)
    } else {
      setIsLoader(true);
      fetch(variables.API_URL + "api/RGSACourseIndex", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + localStorage.getItem("JWTToken")

        },
        body: JSON.stringify({
          courseNodeId: coursedata.courseNodeId,
          userId: userId,
          batchId: coursedata.batchId,
        }),
      })
        .then((response) => response.json())
        .then(async (data) => {
          // console.log("course api result : ", data.dataList);
          if (data?.isSuccess) {
            // setNodeData(data.dataList);

            // setVcNotifications([1])
            // setData(data.dataList);
            let tempdataList = data.dataList.filter(
              (node) => node.nodeClassification === "Session"  && node.deliverymodename != "Assessment"
            );
            var assessmentList = data.dataList.filter(
              (node) => node.deliverymodename === "Assessment"
            );
            console.log(JSON.stringify(data?.dataList))
            setSessions(tempdataList)
            setAssessment(tempdataList)

            dispatch(updateNodeVal(data.dataList));
            localStorage.setItem("nodeVal", JSON.stringify(data?.dataList));
            nextPrevData();
          } else {
            setIsLoader(false);
            throw new Error("Data is not successful");
          }
        })
        .catch((error) => {
          // console.log("error : ", error);
          setIsLoader(false);
        })
    }
  };
  console.log({ assesment});

  const SessionRedirectionLink = (
    deliveryMode,
    nodeName,
    nodeID,
    batchID,
    examMode, completionStatus
  ) => {
    // alert("nodeID: "+nodeID);
    // // console.log("nodeName: "+ nodeName+", nodeID: "+nodeID+", batchID: "+batchID+", deliverMode: "+deliveryMode);
    // // console.log("completionStatus in content accordian : ", completionStatus)
    var link = "";
    switch (deliveryMode) {
      case 1: {
        if (completionStatus == "Completed") {
          // // console.log("link to complete")
          link = `/VirtualSession/2`;
          break;
        } else {
          // // console.log("normal vc link")
          link = `/VirtualSession/${nodeID}`;
          break;
        }
      }
      case 2: {
        link = `/TopicDetailsRgsa/${nodeName}/${nodeID}/${batchID}/${deliveryMode}/${examMode}`;
        // localStorage.setItem("sessionnodename", nodeName);
        // localStorage.setItem("sessionnodeid", nodeID);
        // localStorage.setItem("sessionbatchid", batchID);
        break;
      }
      case 3: {
        link = `/VirtualSession`;
        break;
      }

      // this case below for assesment
      case 4: {
        link = `/TopicDetailsRgsa/${nodeName}/${nodeID}/${batchID}/${deliveryMode}/${examMode}`;
        break;
      }
      case 5: {
        link = `/VirtualSession`;
        break;
      }
      case 6: {
        link = `/Feedback`;
        break;
      }
    }

    /*  if(deliveryMode == 2) {
    
    }
    else {
      link = `/courseIndex/vc/${nodeName}`;
    } */

    //var link = deliveryMode == 2 ? `/CourseIndex/TopicDetails/${nodeName}/${nodeID}/${batchID}` : ``;
    return link;
  };

  const navigate = useNavigate()
  const goToTopicPage = (subnode) => {
    navigate(
      SessionRedirectionLink(
        subnode.deliveryMode,
        subnode.nodeName,
        subnode.nodeId,
        "8574",
        subnode.examMode,
        subnode.completionStatus,
      )
    );
  }

  useEffect(() => {
    getcourseIndex();

  }, [])


  // const getcourseIndex = async () => {
  //   setcourseLevel(coursedata.courseLevel);
  //   setcourseNodeId(coursedata.courseNodeId);
  //   setBatchID(coursedata.batchId);
  //   var userId = localStorage.getItem("login-userId");
  //   var coursedataInApi = await JSON.parse(localStorage.getItem("coursedata"));
  //   // alert(coursedataInApi?.courseLevel);
  //   if (nodeValue.length > 0) {
  //     // console.log("inside nodeValue.length > 0 : ", nodeValue)
  //     // alert("re arranging value in co after nodeval updated");
  //     setNodeData(nodeValue);
  //     // setData(nodeValue);
  //     if (coursedataInApi?.courseLevel == 3) {
  //       // console.log("inside course level 3 : ", courseLevel);
  //       if (filterVal != "") {
  //         const sublevel = nodeValue.filter((subnode) => subnode.deliveryMode == filterVal);
  //         // console.log("sublevel after filter in api resp : ", sublevel);
  //         setDataList(sublevel);
  //       } else {
  //         let tempdataList = nodeValue.filter(
  //           (node) => node.nodeClassification === "Session"
  //         );
  //         // console.log("tempdataList : ", tempdataList);
  //         setDataList(tempdataList);
  //       }
  //     }
  //   } else {
  //     // console.log("calling api/CourseIndex")
  //     setIsLoader(true);
  //     fetch(variables.API_URL + "api/CourseIndex", {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         CourseNodeId: coursedata.courseNodeId,
  //         UserId: userId,
  //         BatchId: coursedata.batchId,
  //       }),
  //     })
  //       .then((response) => response.json())
  //       .then(async (data) => {
  //         // console.log("course api result : ", data.dataList)
  //         if (data?.isSuccess) {
  //           setNodeData(data.dataList);

  //           // setVcNotifications([1])
  //           // setData(data.dataList);
  //           if (coursedataInApi?.courseLevel == 3) {
  //             if (filterVal != "") {
  //               const sublevel = data.dataList.filter((subnode) => subnode.deliveryMode == filterVal);
  //               // // console.log("sublevel after filter in api resp : ", sublevel);
  //               setDataList(sublevel);
  //             } else {
  //               let tempdataList = data.dataList.filter(
  //                 (node) => node.nodeClassification === "Session"
  //               );
  //               // // console.log("tempdataList : ", tempdataList);
  //               setDataList(tempdataList);
  //             }
  //           }
  //           setIsLoader(false);
  //           dispatch(
  //             updateNodeVal(
  //               data.dataList
  //             )
  //           );
  //           localStorage.setItem("nodeVal", JSON.stringify(data?.dataList));
  //           dispatch(updateFilterRetained(false));
  //           nextPrevData();
  //           var isAnyTopicCompleted = data.dataList.filter((it) => {
  //             return it.completionStatus == "Completed" && it.nodeClassification == "Session"
  //           })
  //           if (isAnyTopicCompleted.length > 0) {
  //             // alert("at least topic is complete")
  //             apiCallingForCourseStarted(userId, coursedata.batchId);
  //           }
  //         } else {
  //           setIsLoader(false);
  //           throw new Error('Data is not successful');
  //         }

  //       }).catch((error) => {
  //         showBoundary(error);

  //         // // console.log("error in api/CourseIndex : ", error);
  //         setIsLoader(false);
  //       })
  //   }
  // };

  const sectionHandler = (sectionType) => {
    // console.log("sectionHandler calling : ", sectionType);
    localStorage.setItem("section", sectionType);
    setSection(sectionType);
  }

  const courseCompleted  = true;

  return (
    <>
      <TitleHeader url='/RgsaMyCourses' title={coursedata?.nodeName}></TitleHeader>
      <Chatbot></Chatbot>
      <div class="rgsa-Session-wrapper pb-5">
        <div class="bg-overlay pt-6em">
          <img
            src={coursedata.nodethumbnailurl != "" ? `${variables.AWSPath}${variables.AWSFolder}${coursedata.nodethumbnailurl}` : "../../Assets/Linqskills_Icons/course-img2.svg"}
            class="overlay-img"
          ></img>
          <div class="bg-white rgsa-border-radius">
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoader}

            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <div class="header-tabs-rgsa d-flex justify-content-center pt-2 ">
              <div onClick={() => sectionHandler("course")} class={section == "course" ? "border-bottom-rgsaActive text-center border-width" : "border-bottom-rgsaDefault text-center border-width"}>
                <img src={section == "course" ? "../../Assets/Linqskills_Icons/VideoContentActive-rgsa.png" : "../../Assets/Linqskills_Icons/VideoContentDefault-rgsa.png"} class={section == "course" ? " " : ""}></img>
                {/* <img src="../../Assets/Linqskills_Icons/content-rgsa-24-Active.svg" class="pe-2 mt-3p"></img> */}

                <h4 class="heading-three-rgsa"><Translate textKey="courseindex009" /></h4>
              </div>

              <div onClick={() => sectionHandler("certificate")} class={section == "certificate" ? "border-bottom-rgsaActive text-center border-width" : "border-bottom-rgsaDefault text-center border-width"}>
                <img src={section == "certificate" ? "../../Assets/Linqskills_Icons/Resources-Active-rgsa.svg" : "../../Assets/Linqskills_Icons/Resources-Default-rgsa.svg"} class={section == "certificate" ? "" : ""}></img>
                {/* <img src="../../Assets/Linqskills_Icons/certificate-rgsa-24-Active.svg" class="pe-2"></img>   */}
                <h4 class="heading-three-rgsa"><Translate textKey="courseindex010" /></h4>
              </div>
              <div onClick={() => courseCompleted && sectionHandler("assessment")}  class={section == "assessment" ? "border-bottom-rgsaActive text-center border-width" : "border-bottom-rgsaDefault text-center border-width"}>
                <img src={(section == "assessment" && courseCompleted) ? "../../Assets/Linqskills_Icons/assessment-purple.svg" : "../../Assets/Linqskills_Icons/Assessment-Default.svg"} class={section == "assessment" ? "" : ""}></img>
                {/* <img src="../../Assets/Linqskills_Icons/certificate-rgsa-24-Active.svg" class="pe-2"></img>   */}
                <h4 class="heading-three-rgsa"><Translate textKey="આકારણી" /></h4>
              </div>
            </div>
            <div class="container-fluid pt-3 pe-3 ps-3 ">
              <div class="conatiner profile-rgsa ">
                {/* <div class="certificate-img text-center">
          <img src="../../Assets/Linqskills_Icons/Certificate-illustration.svg" class="w-auto"></img>      
          <h4 class="heading-two-rgsa"><Translate textKey="certificate001" /></h4>

          </div> */}

                {
                  section == "course" && sessions.length > 0 ?
                    <>
                      {sessions.map((subnode) => {
                        return (
                          <div class="details-gtime justify-content-between mb-2em">
                            <div class="me-2 col-12 rgsa-greybox" onClick={() => goToTopicPage(subnode)}>
                              <img
                                src={`${variables.AWSPath}${variables.AWSFolder}${subnode.nodethumbnailurl}`}
                                class="w-100 box-image"
                              ></img>
                            </div>
                            <div class="d-flex mt-3">
                              <div class="col pe-3">
                                <span class="color-blue">
                                  {subnode.nodeName}
                                </span>
                              </div>
                              {subnode.completionStatus === "Open" ||
                                subnode.completionStatus === "Locked" ? (
                                <div class="inline-grid" onClick={() => goToTopicPage(subnode)} >
                                  <img
                                    src="../../Assets/Linqskills_icons/rgsa-rightbtn.svg"
                                    class="float-end ms-2"
                                  ></img>
                                  <p class="heading-two-rgsa mt-2">{subnode.duration} min</p>
                                </div>
                              ) :
                                <div class="inline-grid" onClick={() => goToTopicPage(subnode)}>
                                  <img
                                    src="../../Assets/Linqskills_Icons/Status-completed-rgsa.svg"
                                    class="ms-2"
                                  ></img>
                                  <p class="heading-two-rgsa mt-2">{subnode.duration} min</p>
                                </div>
                              }
                            </div>
                          </div>
                        );
                      })}
                    </> :
                    section == "certificate" ?
                      <>
                        <Resources></Resources>
                        {/* <CertificateRgsa></CertificateRgsa> */}
                      </> : section == "assessment" ?
                      <>
                     
                        <div> {assesment.map((subnode) => {
                        return (
                          <div class="details-gtime justify-content-between mb-2em">
                            {console.log({assesment})}
                            <div class="me-2 col-12 rgsa-greybox" onClick={() => goToTopicPage(subnode)}>
                              <img
                                src={`${variables.AWSPath}${variables.AWSFolder}${subnode.nodethumbnailurl}`}
                                class="w-100 box-image"
                              ></img>
                            </div>
                            <div class="d-flex mt-3">
                              <div class="col pe-3">
                                <span class="color-blue">
                                  {subnode.nodeName}
                                </span>
                              </div>
                              {subnode.completionStatus === "Open" ||
                                subnode.completionStatus === "Locked" ? (
                                <div class="inline-grid" onClick={() => goToTopicPage(subnode)} >
                                  <img
                                    src="../../Assets/Linqskills_icons/rgsa-rightbtn.svg"
                                    class="float-end ms-2"
                                  ></img>
                                  <p class="heading-two-rgsa mt-2">{subnode.duration} min</p>
                                </div>
                              ) :
                                <div class="inline-grid" onClick={() => goToTopicPage(subnode)}>
                                  <img
                                    src="../../Assets/Linqskills_Icons/Status-completed-rgsa.svg"
                                    class="ms-2"
                                  ></img>
                                  <p class="heading-two-rgsa mt-2">{subnode.duration} min</p>
                                </div>
                              }
                            </div>
                          </div>
                        );
                      })}</div>
                        {/* <CertificateRgsa></CertificateRgsa> */}
                      </> : ""
                }



                {/*  <div class="details-gtime d-flex mb-4">
            <div class="me-2">
              <img
                src="../../Assets/Linqskills_icons/content-gujrathi.svg"
                class=""
              ></img>
            </div>
            <div>
              <span class="">
                પંચાયત વિકાસ યોજનાઓ માટે વિષયલક્ષી અભિગમોના પરિચયની સાથે 29
                વિષયોને આવરી લેતા PRIs નીકામગીરી
              </span>
            </div>
            <div class="">
              <img
                src="../../Assets/Linqskills_icons/arrowright-24-Purple.svg"
                class="float-end"
              ></img>
            </div>
          </div>

          <div class="details-gtime d-flex mb-4">
            <div class="me-2">
              <img
                src="../../Assets/Linqskills_icons/content-gujrathi.svg"
                class=""
              ></img>
            </div>
            <div>
              <span class="">
                પંચાયત વિકાસ યોજનાઓ માટે વિષયલક્ષી અભિગમોના પરિચયની સાથે 29
                વિષયોને આવરી લેતા PRIs નીકામગીરી
              </span>
            </div>
            <div class="">
              <img
                src="../../Assets/Linqskills_icons/arrowright-24-Purple.svg"
                class="float-end"
              ></img>
            </div>
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterRgsa> </FooterRgsa>
    </>
  );
}

{
  /* <div className="container d-flex login-footer mobilefooter">
                                   
<div className="col-6 col-sm-12 ps-3 poweredby">
    <span>f <span className="span-bold">
      sss</span></span>
    <span className="ps-3 footertext">
    sdsd <span className="span-bold">
        dsdsss</span></span>
</div>
<div className="col-6 text-right pe-3 footerdetails display-desktop">
    <span className="ps-3">
    sdsd</span>
</div>

</div> */
}
