import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import rootReducer from "./features/rootReducer";


const persistConfig = {
  key: 'root',
  storage,
  // Optionally, you can specify which parts of the state to persist using the "whitelist" or "blacklist" option
   whitelist: ['breadcrumb','courseindex','project','navbar'], // Only persist the 'auth' slice
  // blacklist: ['cart'], // Persist all slices except 'cart'
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
