import React, { useState, useEffect } from "react";
import { Redirect, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Cookies from 'universal-cookie';
import FooterRgsa from "./FooterRgsa";
import axios from "axios";
import Translate from "../../components/Shared/Translate";
import { variables } from "../../components/Shared/Variables";
import TextField from '../../components/Shared/TextField';
import { useDispatch, useSelector } from "react-redux";
import { updateIndex, updateIsUserLoggedIn, updateNodeVal, callingDispose, updateVideo } from "../../features/CourseIndex/courseIndexSlice";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LanguagePopupRgsa from "./languagePopupRgsa";
import { Select } from "@mui/material";
import Form from 'react-bootstrap/Form';
import { blue } from "@mui/material/colors";
import { data } from './data';
import { LevelRole } from './LevelRole';


export default function LoginRgsa() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpCorrect, setIsOtpCorrect] = useState(true);
  const [ipAddress, setIPAddress] = useState('')
  const [formErrors, setFormErrors] = useState({});
  const isProject = useSelector(state => state.project.isProject);
  const [openLanguage, setopenLanguage] = useState(false);
  const [totalLanguages, setTotalLanguages] = useState([]);
  const [langId, setLangId] = useState(2);
  var Lan = cookies.get('language');
  // console.log("cookies.get('language') in Rgsa login page : ", cookies.get('language'));
  Lan = Lan != undefined ? Lan.substring(0, 2) : isProject.defaultLanguageID == 1 ? "EN" : isProject.defaultLanguageID == 2 ? "HI" : isProject.defaultLanguageID == 7 ? "GU" : "EN";
  const [profile, setProfile] = useState({
    name: ""
  });
  const [inputType, setInputType] = useState("number");
  const otpFromStorage = localStorage.getItem("login-otp");
  const [isLoader, setIsLoader] = useState(false);
  var errorCount = 0;

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error =>  console.log(error))
      cookies.set("language","Gujarati");
      localStorage.setItem("prefferedLanguage", "Gujarati")
  }, []);
  
  const handleInput = (e) => {
    const inputValue = e.target.value;
    // Regular expression to allow only numbers
    const regex = /^[0-9\b]+$/;

    if (inputValue === "" || regex.test(inputValue)) {
      setNumber(inputValue);
    }
    // console.log("value:" + number);
  };

  function closeLanguage() {
    setopenLanguage(false)
  }
  

  const validateStep3 = (name) => {
    const errors = {};
    var nameReg = /^[a-zA-Z ]+$/;
    var totalError = 0;
    if (!name) {
      errors.fullname = "registration056";
      totalError++;
    }
    if (name.length < 3) {
      errors.fullname = "registration056";
      totalError++;
    } else {
      const firstThreeLetters = name && name.slice(0, 3);
      // console.log("firstThreeLetters : ", firstThreeLetters);
      const areFirstThreeLettersNotSpaces =
        firstThreeLetters && !firstThreeLetters.includes(" ");
      // // console.log("areFirstThreeLettersNotSpaces : ",areFirstThreeLettersNotSpaces);
      if (!areFirstThreeLettersNotSpaces) {
        // alert("inside areFirstThreeLettersNotSpaces")
        errors.fullname = "registration056";
        totalError++;
      } else {
        const firstLetter = name && name.slice(0, 1);
        let regexForSpecialCharacter = /^[a-zA-Z0-9]+$/;
        const areFirstThreeLettersAlphanumeric =
          firstLetter && regexForSpecialCharacter.test(firstLetter);
        // // console.log("areFirstLetterAlphanumeric : ", areFirstThreeLettersAlphanumeric);
        if (!areFirstThreeLettersAlphanumeric) {
          // alert("inside areFirstThreeLettersAlphanumeric")
          errors.fullname = "registration056";
          totalError++;
        } else {
          const allowedSpecialCharacters = /^[a-zA-Z !`-]+$/;
          const isValidName =
            name && allowedSpecialCharacters.test(name);
           console.log("isValidName : ", isValidName)
          if (!isValidName) {
            // alert("inside isValidName")
            errors.fullname = "registration056";
            totalError++;
          } else {
            if (!nameReg.test(name)) {
              // alert("inside here")
              errors.fullname = "registration056";
              name = "";
              // totalError++;
            }
          }
        }
      }
    }

    errorCount = totalError;

    return errors;
  };

  const handleOtp = (e) => {
    const inputValue = e.target.value;
    // Regular expression to allow only numbers
    const regex = /^[0-9\b]+$/;

    if (inputValue === "" || regex.test(inputValue)) {
      setOtp(inputValue);
      setIsOtpCorrect(true)
    }
    // console.log("OTP:" + otp);
  };
  const hanldeProfile = (e) => {
    // const inputValue = e.target.value;
    const { name, value } = e.target;
    setFormErrors({})

    // console.log("profile val : ", value);
    if (value == "") {
      setProfile({ ...profile, [name]: value })
    } else {

      if (value.length > 2) {
        const nameRegex1 = /^[a-zA-Z -]+$/;
        setProfile({ ...profile, [name]: value })

        if (nameRegex1.test(value)) {
        }
      } else {

        const nameRegex2 = /^[a-zA-Z]+$/;
        setProfile({ ...profile, [name]: value })

        if (nameRegex2.test(value)) {

        }
      }
    }

  };

  const backBtn = () => {
    setInputType("number")
    setOtp("");
    setIsOtpCorrect(true);
  }
  const sendOtp = async (e) => {
    setIsLoader(true)
    e.preventDefault();
    // console.log("otp calling");
    const sendOtpValue = await axios.post(variables.API_URL + 'api/LoginRGSA', {
      "phoneno": number
    })
    // console.log("result:", sendOtpValue.data.isSuccess, sendOtpValue?.data?.dataList[0]?.userId != null)
    if (sendOtpValue.data.isSuccess && sendOtpValue?.data?.dataList[0]?.userId != null) {
      localStorage.setItem("login-userId", sendOtpValue?.data?.dataList[0]?.userId);
      localStorage.setItem("login-userName", sendOtpValue?.data?.dataList[0]?.userName);
      localStorage.setItem("login-firstName", sendOtpValue?.data?.dataList[0]?.firstName);
      localStorage.setItem("login-otp", sendOtpValue?.data?.dataList[0]?.otp);
      setInputType("otp");
      setIsLoader(false)
    } else {
      setIsLoader(false)
    }
  };

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  function generateString() {
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < 16; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  const logEveryoneOut = async () => {
    //// console.log("logged everyionev out")
    try {
      let url = variables.API_URL + 'api/LogEveryoneOut'
      const data = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({

          userId: localStorage.getItem('login-userId')

        })
      })

      const res = await data.json();


      // console.log(res, "logged everyionev out")
    } catch (error) {
      // console.log(error)
    }

  }

  const createSession = async () => {
    // console.log("session created")
    try {
      let url = variables.API_URL + 'api/InserUserLoginDetails'
      const sessionToken = generateString();
      /*  const SIGNING_KEY = 'lInQsKiLlS';
      const sessionToken = generateSessionToken(SIGNING_KEY); 
      // console.log(sessionToken,"session")   */

      const data = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({

          userId: localStorage.getItem('login-userId'),
          sessionID: sessionToken,
          ipAddress: ipAddress,
          deviceInfo: isMobile ? "Mobile" : "Desktop"

        })
      })

      const res = await data.json();
      if (res.isSuccess) {
        cookies.set('sessionId', sessionToken)
      }
      // console.log(res, "insert details")

    } catch (error) {
      // console.log(error)
    }

  }


  const verifyOtp = async (e) => {
    e.preventDefault();
    // console.log("verify otp calling : ", langId);
    const firstNameFromStorage = localStorage.getItem("login-firstName");

    try {
      const response = await axios.post(variables.API_URL + 'api/VerifyRGSAOtp', {
        userId: userId,
        userPreferredLanguageId: langId,
        otp: otp,
      });
      if (response.data.isSuccess) {

        localStorage.setItem("JWTToken", response.data.jwtToken)
        dispatch(updateIndex('CO'));
        dispatch(updateNodeVal([]));
        dispatch(callingDispose(false));
        dispatch(updateVideo(false));
        dispatch(updateIsUserLoggedIn(true));

        logEveryoneOut().then(() => {
          createSession().then(() => {
            // setauthenticated(loggedInUser);
            if (firstNameFromStorage == "") {
              setInputType("profile");
            } else {
              // console.log("naviagte to my course page");
              navigate("/RgsaMyCourses")
            }
          })
        })

      }
      else {
        // console.log("wrong otp entered");
        setIsOtpCorrect(false);
      }
    } catch (error) {
      // Handle error
      console.error('Error updating RGS profile:', error);
    }

    /*  if (otpFromStorage == otp) {
       // console.log("otp verified done");
       logEveryoneOut().then(() => {
         createSession().then(() => {
           // setauthenticated(loggedInUser);
         })
       })
       if (firstNameFromStorage == "") {
         setInputType("profile");
       } else {
         // console.log("naviagte to my course page");
         navigate("/RgsaMyCourses")
       }
 
     } else {
       // console.log("wrong otp entered");
       setIsOtpCorrect(false);
     } */
    /*  const sendOtpValue = axios.post(variables.API_URL+'/sendOtp',{
      "number": number
    })
    // console.log("result:"+sendOtpValue) */
  };
  const userId = localStorage.getItem("login-userId")

  const updateRGSProfile = async () => {
    try {
      const response = await axios.post(variables.API_URL + 'api/UpdateRGSAprofile', {
        userId: parseInt(userId),
        userName: profile.name,
        firstName: profile.name,
        lastName: '',
        email: '',
      });
      return response.data;
    } catch (error) {
      // Handle error
      console.error('Error updating RGS profile:', error);
    }
  };

  const profileSubmit = async (e) => {
    e.preventDefault();
     console.log("calling profileSubmit:", profile.name, profile.name.length);
    localStorage.setItem("login-firstName", profile.name);
    //debugger;
    setFormErrors(validateStep3(profile.name))
    if(errorCount==0){

    
    try {

      await updateRGSProfile();
      navigate("/RgsaMyCourses");
    } catch (error) {
      // Handle error if needed
      console.error("Error submitting profile:", error);
    }}
  };

//State, Block, Village
  const [selectedState, setSelectedState] = useState('');
  const [selectedBlock, setSelectedBlock] = useState('');
  
  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedBlock(''); // Reset block when state changes
  };
  
  const handleBlockChange = (event) => {
    setSelectedBlock(event.target.value);
  };

  const states = Object.keys(data.states);
  const blocks = selectedState ? data.states[selectedState] : [];
  const villages = selectedBlock ? data.blocks[selectedBlock] : [];

  //Level & Role
  const [selectedLevel, setSelectedLevel] = useState('');

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
  };

  const levels = LevelRole.levels;
  const roles = selectedLevel
    ? LevelRole.roles.filter(role => LevelRole.levels.find(level => level.id === parseInt(selectedLevel)).roles.includes(role.id))
    : LevelRole.roles;




  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}

      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {openLanguage ?
        <LanguagePopupRgsa loginLangPopUp={true} setLangId={setLangId} closeEvent={closeLanguage} show={true} okEvent={closeLanguage} availableLanguages={totalLanguages} headerKey="Login014" hideDefaultCheck="true" />
        : ""}
      <div class="rgsa-wrapper">
        <div className="rgsa-backgroundcolor container-fluid pe-3 ps-3">
          <div className="container">
            <div className="col-md-12 pt-3">
              <img src="../../../assets/media/logos/linqskills.png" class="invisible"></img>
              <div className="language-select rgsa-lan float-start text-uppercase" onClick={() => setopenLanguage(true)}>
                <img src="../../Assets/Linqskills_Icons/Language_16.svg" className="pe-1 mb-1px"></img><span class="align-self-center cursor-pointer">{Lan}</span>
                {/*<select className="select-lang" value={language} onChange={handleChange} >*/}
                {/*    <option value="en" key="en">EN</option>*/}

                {/*    {languageOptions.map((language) => (*/}
                {/*        <option value={language.value} key={language.value}>{language.label}</option>*/}
                {/*    ))}*/}
                {/*</select>*/}

              </div>

            </div>



            <div className="logo-rgsa text-center">
              <img
                src="../../Assets/media/logos/rgsa-Logo.svg"
                className="text-center"
              ></img>

            </div>

            <div class="form-box">
              <h4 class="heading-one-rgsa"> <Translate textKey="Login022" /></h4>

              {inputType === "number" && (
                <form className="rgsa-form">
                  <div className="text-new mb-3">
                    {/* <input
                  type="text"
                  className="m-0 form-control"
                  id=""
                  onChange={handleInput}
                  value={number}
                  maxLength="10"
                  placeholder="ફોન નંબર દાખલ કરો"
                ></input> */}

                    <TextField type="text"
                      onchange={handleInput}
                      value={number}
                      classNm="m-0 form-control" label="ફોન નંબર દાખલ કરો"
                      infoKey=""
                      textKey="registration004"
                      maxlength="10"
                      isMandate={false}
                      placeholderkey="Login017"
                    />
                  </div>
                  <div className="col-md-12 d-flex">
                    <div class="d-flex col-4 rgsa-progress">
                      <img src="../../Assets/Linqskills_icons/Progress-Default.svg" class="me-2 w-15"></img>
                      <img src="../../Assets/Linqskills_icons/Progress-Default.svg" class="me-2 w-15"></img>
                      <img src="../../Assets/Linqskills_icons/Progress-Active.svg" class="w-24"></img>
                    </div>

                    <div class="col-8">
                      <button
                        disabled={number.length != 10 ? true : false}
                        onClick={sendOtp}
                        className={
                          number.length != 10
                            ? "rgs-disabledbtn rgs-primarybtn"
                            : "rgs-primarybtn"
                        }
                      >
                        <Translate textKey="userprofile021" />
                        <img src={number.length > 9 ? "../../Assets/Linqskills_icons/arrowright-rgsa-active.svg" : "../../Assets/Linqskills_icons/rgsa-rightarrow-disabled.svg"} class="float-end"></img>
                      </button>
                    </div>

                  </div>
                </form>
              )}
              {/* otp form */}

              {inputType === "otp" && (
                <form className="rgsa-form">
                  <div class={!isOtpCorrect ? "error-msg mb-3" : "mb-3"} >
                    {/* <input
                  type="email"
                  className="m-0 form-control"
                  id=""
                  onChange={handleOtp}
                  value={otp}
                  maxLength="4"
                  placeholder="ફોન નંબર દાખલ કરો"
                ></input> */}

                    <TextField type="text"
                      onchange={handleOtp} name="username"
                      value={otp}
                      classNm="m-0 form-control" label="Otp"
                      infoKey=""
                      textKey="Login019"
                      maxlength="4"
                      isMandate={false}
                      placeholderkey="Login020"
                    />
                  </div>
                  <div>
                    {
                      otpFromStorage != null && otpFromStorage != "" && otp.length < 4 ?
                        <p class="heading-two-rgsa mb-0 mt-2 ms-2">{otpFromStorage}</p> : ""
                    }
                    {
                      isOtpCorrect ? "" :
                        <div class="d-flex mb-3">
                          <div>
                            <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                          </div>
                          <div class="pt-2">
                            <p class="login-error h7 mb-0 align-self-center">
                              ખોટો ઓટીપી કોડ. કૃપા કરીને એસએમએસ દ્વારા મોકલેલ કોડ દાખલ કરો</p>
                          </div>
                        </div>
                    }
                  </div>
                  <div className="col-md-12 d-flex">
                    <div class="d-flex col-4 rgsa-progress">
                      <img src="../../Assets/Linqskills_icons/Progress-Default.svg" class="me-2 w-15"></img>
                      <img src="../../Assets/Linqskills_icons/Progress-Active.svg" class="me-2 w-24"></img>
                      <img src="../../Assets/Linqskills_icons/Progress-Selected.svg" class="w-15"></img>
                    </div>
                    <div class="col-8">
                      <button
                        disabled={otp.length != 4 ? true : false}
                        onClick={verifyOtp}
                        className={
                          otp.length != 4
                            ? "rgs-disabledbtn rgs-primarybtn"
                            : "rgs-primarybtn"
                        }
                      >
                        <Translate textKey="Login007" />               <img src="../../Assets/Linqskills_icons/arrowright-rgsa-active.svg" class="float-end"></img>

                      </button>

                    </div>
                  </div>
                  <div class="col-12 d-block">
                    <button
                      onClick={backBtn}
                      className="mt-2 rgs-secondarybtn"
                    >
                      <img src="../../Assets/Linqskills_icons/rgsa-leftarrow-secondary.svg" class="float-start"></img>
                      <Translate textKey="Login021" />
                    </button>
                  </div>
                </form>
              )}
              {/* profile form */}
              {inputType === "profile" && (
                <form className="rgsa-form">
                  <div className=" mb-3">
                    {/* <input
                  type="email"
                  className="m-0 form-control"
                  id=""
                  onChange={hanldeProfile}
                  name="name"
                  value={profile.name}
                  placeholder="તમારું નામ દાખલ કરો"
                ></input> */}
                    <TextField type="text"
                      onchange={hanldeProfile} name="name"
                      value={profile.name}
                      classNm="m-0 form-control" label="તમારું નામ"
                      infoKey=""
                      textKey="registration025"
                      isMandate={false}
                      errorKey={formErrors.fullname}
                      placeholderkey="registration026"
                    />
                  </div>

                  <div class="txt-box mb-3 d-flex">
                            <select value={selectedState} onChange={handleStateChange} className="border-0 form-control form-select input-bg select-bg">
                            <option value=""><Translate textKey="Login025" /></option>
                            {states.map(state => (
                              <option key={state} value={state}>{state}</option>
                            ))}
                          </select>
                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer mt-1"></img>
                  </div>
                  <div class="txt-box mb-3 d-flex">
                      <select value={selectedBlock} onChange={handleBlockChange} className="border-0 form-control form-select input-bg select-bg">
                        <option value=""><Translate textKey="Login026" /></option>
                        {blocks.map(block => (
                          <option key={block} value={block}>{block}</option>
                        ))}
                      </select>
                      <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer mt-1"></img>
                    </div>
                      <div class="txt-box mb-3 d-flex">
                        <select className="border-0 form-control form-select input-bg select-bg">
                          <option value=""><Translate textKey="Login027" /></option>
                          {villages.map(village => (
                            <option key={village} value={village}>{village}</option>
                          ))}
                        </select>
                        <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer mt-1"></img>
                      </div>
    

                  <div class="txt-box mb-3 d-flex">

                    <select value={selectedLevel} onChange={handleLevelChange} className="border-0 form-control form-select input-bg select-bg">
                      <option value=""><Translate textKey="Login023" /></option>
                      {levels.map(level => (
                        <option key={level.id} value={level.id}>{level.name}</option>
                      ))}
                    </select>
                    <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer mt-1 "></img>
                  </div>
                  <div class="txt-box mb-3 d-flex">
                      <select className="border-0 form-control form-select input-bg select-bg">
                        <option value=""><Translate textKey="Login024" /></option>
                        {roles.map(role => (
                          <option key={role.id} value={role.id}>{role.name}</option>
                        ))}
                      </select>
                    <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer mt-1 "></img>
                  </div>

                  <div className="col-md-12 d-flex mt-3">
                    <div class="d-flex col-4 rgsa-progress">
                      <img src="../../Assets/Linqskills_icons/Progress-Active.svg" class="me-2 w-24"></img>
                      <img src="../../Assets/Linqskills_icons/Progress-Selected.svg" class="me-2 w-15"></img>
                      <img src="../../Assets/Linqskills_icons/Progress-Selected.svg" class="w-15"></img>
                    </div>
                    <div class="col-8">
                      <button
                        onClick={profileSubmit}
                        disabled={profile.name.length < 3 ? true : false}
                        className={profile.name.length < 3 ? "rgs-disabledbtn rgs-primarybtn" : "rgs-primarybtn"}
                      >
                        <Translate textKey="registration009" />
                        <img src="../../Assets/Linqskills_icons/arrowright-rgsa-active.svg" class="float-end"></img>
                      </button>
                    </div>

                  </div>
                </form>
              )}
            </div>
            <div class="text-center">
              <img src="../../Assets/media/logos/rgsa-panchayat.svg" ></img>

            </div>

          </div>
        </div>
        <FooterRgsa> </FooterRgsa>
      </div>
    </>
  );
}



{/* <Translate textKey="postregform022" /> */ }
{/* <Translate textKey="postregform022" /> */ }



{/* <div className="container d-flex login-footer mobilefooter">
                                   
<div className="col-6 col-sm-12 ps-3 poweredby">
    <span>f <span className="span-bold">
      sss</span></span>
    <span className="ps-3 footertext">
    sdsd <span className="span-bold">
        dsdsss</span></span>
</div>
<div className="col-6 text-right pe-3 footerdetails display-desktop">
    <span className="ps-3">
    sdsd</span>
</div>

</div> */}