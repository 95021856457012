import Primarybtn from "../Shared/Primarybtn";
import Secondarybtn from "../Shared/Secondarybtn";
import TextField from '../Shared/TextField'

export default function ProgressSteps(props) {

    // console.log("props in ProgressSteps : ",props)
    return (
        <>
            <div id="progressbarContainer">
                <div id="emptyProgressbar"></div>
                <div id="completedProgress">
                </div>
                <ul className="p-0">
        {/* Step 1: Completed if Step2Active or Step3Active, Active otherwise */}
        <li id="stepLabel1" className={(props.Step2Active || props.Step3Active) ? "completedStep" : "activeStep"}>
            <img 
                src={(props.Step2Active || props.Step3Active) 
                    ? "../../Assets/media/icons/Active/Step-Completed.svg" 
                    : "../../Assets/media/icons/Active/step01-active.png"} 
                className="w-36p" 
                alt="Step 1"
            />
        </li>

        {/* Step 2: Completed if Step3Active, Active if Step2Active */}
        <li id="stepLabel2" className={props.Step3Active ? "completedStep" : (props.Step2Active ? "activeStep" : "inactiveStep")}>
            <img 
                src={props.Step3Active 
                    ? "../../Assets/media/icons/Active/Step-Completed.svg" 
                    : (props.Step2Active 
                        ? "../../Assets/media/icons/Active/step02-active.png" 
                        : "../../Assets/media/icons/Active/step02-active.png")} 
                className="w-36p" 
                alt="Step 2"
            />
        </li>

        {/* Step 3: Active if Step3Active, Inactive otherwise */}
        <li id="stepLabel3" className={props.Step3Active ? "activeStep" : "inactiveStep"}>
            <img 
                src={props.Step3Active 
                    ? "../../Assets/media/icons/Active/step03-active.png" 
                    : "../../Assets/media/icons/Active/step03-active.png"} 
                className="w-36p" 
                alt="Step 3"
            />
        </li>
    </ul>{/*  <ul className="p-0"> */}
              {/*   {
                        props.Step1Active ?
                        <li id="stepLable2" class="activeStep"><img src="../../Assets/media/icons/Active/Step-Completed.svg" class="w-36p"></img></li> :  <li id="stepLable1" class="activeStep"><img src="../../Assets/media/icons/Active/step01-active.png"></img></li>
                    }
                    {
                        props.Step2Active ?
                        <li id="stepLable2" class="activeStep"><img src="../../Assets/media/icons/Active/Step-Completed.svg" class="w-36p"></img></li> :  <li id="stepLable1" class="activeStep"><img src="../../Assets/media/icons/Active/step02-active.png"></img></li>
                    }
                     {
                        props.Step3Active ?
                        <li id="stepLable2" class="activeStep"><img src="../../Assets/media/icons/Active/Step-Completed.svg" class="w-36p"></img></li> :  <li id="stepLable1" class="activeStep"><img src="../../Assets/media/icons/Active/step03-active.png"></img></li>
                    }
                     */}
                   {/*  {
                        props.Step2Active &&
                        <li id="stepLable2" class="activeStep"><img src="../../Assets/media/icons/Active/step02-active.png" class="w-36p"></img></li>
                    }
                    {/* <img src="../../Assets/media/icons/Active/Step-Completed.svg" class="w-36p"></img> */}
                  {/*  {
                        !props.Step2Active &&
                        <li id="stepLable2" class=""><img src="../../Assets/media/icons/Inactive/ic_36_step02_inactive.svg"></img></li>
                    }
                    {
                        props.Step3Active &&
                        <li id="stepLable3" class="activeStep"><img src="../../Assets/media/icons/Active/step03-active.png"></img></li>
                    } */}
                    {/* {
                        !props.Step3Active &&
                        <li id="stepLable3" class=""><img src="../../Assets/media/icons/Inactive/ic_36_step03_inactive.svg"></img></li>
                    } */}
                    {/* {
                        props.Step4Active &&
                        <li id="stepLable4" class="activeStep"><img src="../../Assets/media/icons/Active/step04-active.png"></img></li>
                    }
                    {
                        !props.Step4Active &&
                        <li id="stepLable4" class="M1"><img src="../../Assets/media/icons/Inactive/ic_36_step04_inactive.svg"></img></li>
                    } */}
               {/*  </ul> */}
            </div>
        </>
    )

}