import React, { useEffect, useState, useRef } from "react";
import HamburgerIcons from "./HamburgerIcons";
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateggChild, updateCurrentNodeIdAt } from "../../features/breadcrumb/breadcrumbSlice";
import { callingDispose, updateVideoIndex } from '../../features/CourseIndex/courseIndexSlice'


export default function TopicsList(props) {

    // // console.log("props in topic lists : ", props);
    const [language, setLanguage] = useState(useSelector(state => state.language.language))
    const isNextPrevUpdated = useSelector(state => state.courseindex.isNextPrevUpdated);

    const meetingData = localStorage.getItem("meeting");
    const guidedLearningStatus = localStorage.getItem("guidedLearningStatus");
    // // console.log("meetingData : ", JSON.parse(meetingData));
    const [toggle, setToggle] = useState(false);
    // // console.log("props.topicsListData in tipoc list : ",props.topicsListData)
    var indexNo = props.sessionType === "meeting" ? "m" : props.sessionType === "selfStudy" ? props.index : props.index;
    let temp = props.topicsListData.findIndex(topics => topics.topicStatus == "Open");

    // console.log("indexNo && temp  : ", indexNo, temp);

    const [listTopicStatusSession, setListTopicStatusSession] = useState([]);


    // console.log("props.Index : ", props.index);

    const shouldLog = useRef(true);
    const cookies = new Cookies();
    const dispatch = useDispatch();

    const handleTopicClick = async (status, nodeID, ind, vcTitleHeading, topicName, topics) => {
        // // console.log(`inside topiclist handleTopicClick : ${nodeID} :: ind == ${ind}  :: vcTitleHeading == ${vcTitleHeading} :: topicName == ${topicName} :: topics == ${JSON.stringify(topics)} ;;;`)
        let resAfterFetchingNextPrev = await JSON.parse(localStorage.getItem("nextPrevData"));
        if (status == "Open" || status == "Completed") {
            dispatch(updateVideoIndex("-1"));
            if (nodeID === "m") {
                props.setIndex("m")
                dispatch(updateggChild("Meeting Details"))
                props.onTopicClick(nodeID)
                props.sessionType === "meeting" && props.setVcTitleHeading("Meeting Details");
                props.hideHamburger();
            } else {
                if (ind == props.index) {
                    // alert("click on same tab");
                    // console.log("click on same tab")
                } else {
                    if (guidedLearningStatus == "yes") {
                        if (ind > 0) {
                            for (let a = 0; a < resAfterFetchingNextPrev?.data.userDataList.length; a++) {
                                if (resAfterFetchingNextPrev?.data.userDataList[a].nodeID == props.topicsListData[ind].nodeID && resAfterFetchingNextPrev?.data.userDataList[a-1].topicStatus == "Completed") {
                                    dispatch(callingDispose(true));
                                    props.setIndex(ind)
                                    localStorage.setItem("topicDetails", JSON.stringify(topics))
                                    dispatch(updateggChild(topicName))
                                    props.setCurrentTopicContentData(topics);
                                    props.sessionType === "meeting" && props.setVcTitleHeading(vcTitleHeading);
                                    var listData = props.topicsListData;
                                    var data = listData.filter((topics) => topics.nodeID == nodeID);
                                    if ((data[0].topicStatus != "Locked") && (data[0].sessionDeliveryMode == 2 || data[0].sessionDeliveryMode == 1)) {
                                        props.onTopicClick(data, true);
                                    }
                                    props.hideHamburger();
                                } else {
                                    // console.log("current topic is not completed yet");
                                }
                            }
                        } else if (props.topicsListData[ind].topicStatus == "Completed") {
                            // alert("click on previous topics")
                            dispatch(callingDispose(true));
                            props.setIndex(ind)
                            localStorage.setItem("topicDetails", JSON.stringify(topics))
                            dispatch(updateggChild(topicName))
                            props.setCurrentTopicContentData(topics);
                            props.sessionType === "meeting" && props.setVcTitleHeading(vcTitleHeading);
                            var listData = props.topicsListData;
                            var data = listData.filter((topics) => topics.nodeID == nodeID);
                            if ((data[0].topicStatus != "Locked") && (data[0].sessionDeliveryMode == 2 || data[0].sessionDeliveryMode == 1)) {
                                props.onTopicClick(data, true);
                            }
                            props.hideHamburger();
                        }
                    } else {
                        dispatch(callingDispose(true));
                        props.setIndex(ind)
                        localStorage.setItem("topicDetails", JSON.stringify(topics))
                        dispatch(updateggChild(topicName))
                        props.sessionType === "meeting" && props.setVcTitleHeading(vcTitleHeading);
                        var listData = props.topicsListData;
                        var data = listData.filter((topics) => topics.nodeID == nodeID);
                        if ((data[0].topicStatus != "Locked") && (data[0].sessionDeliveryMode == 2 || data[0].sessionDeliveryMode == 1)) {
                            props.onTopicClick(data, true);
                        }
                        props.hideHamburger();
                    }
                }
            }

            if (props.isTopicChanged) {
                props.setIsTopicChanged(!props.isTopicChanged)
            }
        } else {
            // console.log("status is locked");
        }

    }

    // alert("props.addHamburgerCls : " + props.addHamburgerCls);



    useEffect(() => {
        // // console.log(language+"hello world")
        // // console.log("props.topicsListData: ",props.topicsListData,props.index);
        if (props.topicsListData.length > 0) {
            if (props.index == "m") {
                // const topicName = (props.topicsListData[0][`nodeName_${localStorage.getItem("prefferedLanguage")}`] || props.topicsListData[0][`nodeName_${localStorage.getItem("prefferedLanguage")}`] || props.topicsListData[0].nodeName )

                // dispatch(updateggChild(topicName));
                // dispatch(updateCurrentNodeIdAt(props.topicsListData[0].nodeID));
            } else {
                const topicName = (props.topicsListData[props.index][`nodeName_${localStorage.getItem("prefferedLanguage")}`] || props.topicsListData[props.index][`nodeName_${localStorage.getItem("prefferedLanguage")}`] || props.topicsListData[props.index].nodeName)

                dispatch(updateggChild(topicName));
                dispatch(updateCurrentNodeIdAt(props.topicsListData[props.index].nodeID));
            }
        } else {

        }
    }, [props])
    const tempListOfTopics = JSON.parse(localStorage.getItem("nextPrevData"));
    // console.log("tempListOfTopics : ",tempListOfTopics);

    useEffect(() => {
        // // console.log("props.topicsListData[0][`nodeName_${cookies.get(language)}`] : ",props.topicsListData[0][`nodeName_${cookies.get("language")}`])
        // props.topicsListData.length > 0 && dispatch(updateggChild(props.topicsListData[indexNo === -1 ? 0 : indexNo].nodeName))
        // // console.log("props.setIndex(indexNo === -1 ? 0 : indexNo) : ",indexNo);
        // alert("its working")
        props.setIndex(indexNo === -1 ? 0 : indexNo);
        
        let filterListTopic = tempListOfTopics?.data?.userDataList.filter((it) => {
            return it.sessionID == props.sessionId
        })
        // // console.log("filterListTopic : ", filterListTopic[props.index]);
        console.log("filterListTopic : ",filterListTopic);

        if(filterListTopic == undefined ){
            setListTopicStatusSession([]);
        } else {
            setListTopicStatusSession(filterListTopic);
        }
        setToggle(!toggle);
    }, [props.topicsListData, isNextPrevUpdated])

    // // console.log("props.topicsListData in topicList.js : ",props.topicsListData);
    /*  useEffect(()=>{
         
     },[props]) */

    // console.log("listTopicStatusSession : ",listTopicStatusSession);

    return (
        <>
            {
                listTopicStatusSession.length > 0 ?
                    <>
                        <div class={`col-lg-3 col-sm-12 col-xs-12 courseInside-Lmenu ${props.showHamburgerIcon ? "leftmenu-none" : ""}`}>
                            <div class="card primary-radius">
                                <div class="card-body cardbody-height h-auto p-0">
                                    <div class="left-menu">
                                        <div class="d-flex ">
                                            <div class="pt-1">
                                                <h5 class="m-0 increase-font text-capitalized">{props.sessionName}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div  >
                                        {props.sessionType === "meeting" && <div class={`left-menu border-top topicsListDiv ${props.index === "m" && 'active'} `} onClick={() => handleTopicClick("Open", "m")} >
                                            <div class="d-flex" >
                                                <div class="">
                                                    <img src="../../Assets/Linqskills_Icons/sessionstatus-open.svg" class="pe-1"></img>
                                                </div>
                                                <div class="pt-1">
                                                    <h5 class="m-0 cursor-pointer ">Meeting Details</h5>
                                                </div>
                                            </div>
                                        </div>}

                                        {
                                            props.topicsListData?.map((topics, ind) => {
                                                // // console.log("topics in topic lsit : ",topics)
                                                const topicName = (topics[`nodeName_${localStorage.getItem("prefferedLanguage")}`] || topics[`nodeName_${localStorage.getItem("prefferedLanguage")}`] || topics.nodeName)
                                                // console.log("listTopicStatusSession[ind]?.topicStatus : ", listTopicStatusSession[ind]?.topicStatus)
                                                return (
                                                    <>
                                                        {
                                                            guidedLearningStatus == "yes" ?
                                                                <>
                                                                    <div id={ind} class={`left-menu cursor-pointer courselist-hover border-top topicsListDiv ${((ind === props.index && 'active') || ((props.index === -1) && topics.topicStatus == "Open")) && topics.topicStatus != "Locked" && 'active'}`} onClick={() => handleTopicClick(listTopicStatusSession[ind]?.topicStatus, topics.nodeID, ind, topics.sessionName, topicName, topics)} >
                                                                        <div class="d-flex">
                                                                            <div class="">
                                                                                {listTopicStatusSession[ind]?.topicStatus === "Open" && ind == props.index && <img src="../../Assets/Linqskills_Icons/sessionstatus-open.svg" class="pe-1"></img>}
                                                                                {listTopicStatusSession[ind]?.topicStatus === "Open" && listTopicStatusSession[ind - 1]?.topicStatus != "Completed" && ind > 0 && <img src="../../Assets/Linqskills_Icons/sessionstatus-locked.svg" class="pe-1"></img>}
                                                                                {listTopicStatusSession[ind]?.topicStatus === "Open" && listTopicStatusSession[ind - 1]?.topicStatus == "Completed" && ind > 0 && ind != props.index && <img src="../../Assets/Linqskills_Icons/sessionstatus-open.svg" class="pe-1"></img>}
                                                                                {listTopicStatusSession[ind]?.topicStatus === "Completed" && <img src="../../Assets/Linqskills_Icons/sessionstatus-completed.svg" class="pe-1"></img>}
                                                                                {listTopicStatusSession[ind]?.topicStatus === "Locked" && <img src="../../Assets/Linqskills_Icons/sessionstatus-locked.svg" class="pe-1"></img>}
                                                                                {listTopicStatusSession[ind]?.topicStatus === "Submitted" && <img src="../../Assets/Linqskills_Icons/sessionstatus-submitted.svg" class="pe-1"></img>}
                                                                            </div>
                                                                            <div class="pt-1">
                                                                                <h5 class="m-0 cursor-pointer">{topicName}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </> :
                                                                <>
                                                                    <div id={ind} class={`left-menu cursor-pointer courselist-hover border-top topicsListDiv ${((ind === props.index && 'active') || ((props.index === -1) && topics.topicStatus == "Open")) && topics.topicStatus != "Locked" && 'active'}`} onClick={() => handleTopicClick(listTopicStatusSession[ind]?.topicStatus, topics.nodeID, ind, topics.sessionName, topicName, topics)} >
                                                                        <div class="d-flex">
                                                                            <div class="">
                                                                                {listTopicStatusSession[ind]?.topicStatus === "Open" && <img src="../../Assets/Linqskills_Icons/sessionstatus-open.svg" class="pe-1"></img>}
                                                                                {listTopicStatusSession[ind]?.topicStatus === "Completed" && <img src="../../Assets/Linqskills_Icons/sessionstatus-completed.svg" class="pe-1"></img>}
                                                                                {listTopicStatusSession[ind]?.topicStatus === "Locked" && <img src="../../Assets/Linqskills_Icons/sessionstatus-locked.svg" class="pe-1"></img>}
                                                                                {listTopicStatusSession[ind]?.topicStatus === "Submitted" && <img src="../../Assets/Linqskills_Icons/sessionstatus-submitted.svg" class="pe-1"></img>}
                                                                            </div>
                                                                            <div class="pt-1">
                                                                                <h5 class="m-0 cursor-pointer">{topicName}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : ""
            }
        </>
    )
}