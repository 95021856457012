import React, { useEffect,useState } from "react";
import NavOne from '../../components/NavOne.js';
//import InstructionsContent from '../EligibilityCheck/InstructionsContent';
import Breadcrumb from '../CourseIndex/Breadcrumb';
import Certificate from '../Shared/Certificate';
import { updateChild, updateExtended, updateParent } from "../../features/breadcrumb/breadcrumbSlice";
import { useDispatch,useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { variables } from "../Shared/Variables";

export default function DownloadCertificate() {
    const location = useLocation();
    // const propsData = location.state;
    // // console.log("propsData : ", propsData);
    // const dataForCertificate = useSelector(state=>state.courseindex.dataForCertificate);
    const certificateFrom = localStorage.getItem("certificateFrom");
    var courseName = localStorage.getItem('coursename');
    var certificatePDFFileName = localStorage.getItem('certificatepdffilename');
    var certificateImgFileName = localStorage.getItem('certificateimgfilename');
    const certificateData = JSON.parse(localStorage.getItem("certificateData"));
    const userId = localStorage.getItem('login-userId');
    const [dataCertificate, setDataCertificate] = useState("");
    // // console.log("certificatePDFFileName : ", certificatePDFFileName)
    // // console.log("certificateImgFileName : ", certificateImgFileName)
    // // console.log("certificateData final data : ", certificateData);
    const dispatch = useDispatch();

    const getDataForBatchCertificate = async () => {
        const bodyForCertificate = {
            userId: userId,
            batchId: JSON.stringify(certificateData.batchId)
        }
        // const resFromGetCertificate = await axios.post(variables.API_URL + 'api/GetCertificate', bodyForCertificate)
        // // console.log("resFromGetCertificate : ",resFromGetCertificate);
        // const resFromGetCertificate = {
        //     "isSuccess": true, "returnMessage": "success", "data": [{ "batchId": 4516, "userId": 0, "startDate": null, "endDate": null, "enrollmentDate": null, "isActive": false, "certificate": "Data/Certificate/certificate_151_65762.pdf", "certificateno": null, "downloadDate": null, "isCertificateReleased": false, "certificateGrade": null, "createdOn": "0001-01-01T00:00:00", "id": 0, "markedCompleted": false, "studentAttendancePercentage": null }], "dataList": null, "userDataList": null
        // }

        // if(resFromGetCertificate.isSuccess){
        //     // // console.log("data fetched from getDataForBatchCertificate");
        //     setDataCertificate(resFromGetCertificate.data[0]);
        // }
    }



    useEffect(() => {
        dispatch(updateExtended("false"))
        if(certificateFrom == "ProfileContent.js"){
            dispatch(updateParent("User Profile"));
        } else {
            dispatch(updateParent(courseName))
        }
        dispatch(updateChild('Certificate'))
        getDataForBatchCertificate()
    }, [])

    // console.log("certificateData : ",certificateData);

    return (
        <>
            <NavOne />
            <div class="container-fluid body-wrapper bg-default">
                <div class="container p-0 ">
                    <Breadcrumb redirectLink={certificateFrom == "ProfileContent.js" ? "UserProfile" :"mycourses" } />
                    <div class="pb-4 col-md-7 m-auto download-certificate">
                   
               
                        <Certificate courseName={courseName} certificateData={certificateData} certificatePDFFileName={dataCertificate?.certificate} certificateImgFileName={certificateImgFileName} />
                  
                </div>
            </div>
            </div>
        </>
    )
}