import React, { useState, useEffect,useRef } from "react";
import NavOne from '../NavOne';
import Translate, { getTranslatedValue } from '../Shared/Translate';
import DropdownAdminbtn from '../Admin/DropdownAdminbtn';
import { NavLink, useNavigate } from "react-router-dom";
import axios from 'axios';
import { variables } from './Variables';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from "react-redux";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CustumLoader from "./CustumLoader";
import { HighestQualificationArray, CurrentDesignation, ClassesYouAreCurrentlyTeaching, SubjectsYouAreCurrentlyInstructing, Semester, OngoingPassedArray, CollegeNameArray, DepartmentArray } from "../generalFunctions/profileValues";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';


export default function WelcomeMenu(props) {

  //console.log("props in welcome menu : ", props);
  const myDivRef = useRef(null);

  const navigate = useNavigate();
  const cookies = new Cookies();
  const userId = localStorage.getItem("login-userId")
  let isDevice = useSelector(state => state.courseindex.isDevice);
  const courseData = JSON.parse(localStorage.getItem("coursedata"));
  // console.log("courseData : ", courseData);
  const initialValues = {
    fullname: courseData?.firstName ? courseData?.firstName : "",
    email: "",
    GenderPreference: "",
    LanguagePreference: courseData?.preferredLanguageId ? courseData?.preferredLanguageId : "",
    CurrentState: "",
    CurrentDistrict: "",
    city: "",
    pincode : "",
    CurrentWorkingStatus: "",
    CurrentJobPosition: "",
    SelectTheCountry: "",
    usediCode: "",
    state: "",
    district: "",
    schoolName: "",
    highestQualification: "",
    currentDesignation: "",
    collegeState: "",
    collegeDistrict: "",
    collegeCity: "",
    collegeName: "",
    department: "",
    semester: "",
    ongoingPassed: "true",
    yearOfPassing: ""
  }

  const [tempArr, setTempArr] = useState([]);
  const [formValues, setFormValues] = useState(initialValues);

  const [formType, setFormType] = useState("email"); //change this
  const [queIds, setQueIds] = useState([]);
  const [toggle,setToggle] = useState(false)
  const [isUsediCodeCheck, setIsUsediCodeCheck] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [valid, setValid] = useState(false);
  const [cityVal, setCityVal] = useState("");
  const [pincode, setPincode] = useState(""); 
const [otp,setOtp] = useState("");
  const endYear = new Date().getFullYear();  // Current year
  const startYear = 2000;  // The earliest year you want to include
const [disabledbtn,setDisabledbtn] = useState(true);
const [verifydisabledbtn,setVerifyDisabledbtn] = useState(true);

  const [selectedYear, setSelectedYear] = useState(endYear);
  const [otpsent,setOptsent] = useState(false)
  const [formErrors,setFormErrors] = useState({});
  const [personalformErrors,setpersonalFormErrors] = useState({});
  const [organisationformErrors,setorganisationFormErrors] = useState({});

   // Generate the list of years from the current year to the start year
  const years = [];
  for (let i = endYear; i >= startYear; i--) {
    years.push({ value: i.toString() });
  }

  // Handle the year selection change
  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const scrollToDiv = () => {
    if (myDivRef.current) {
      myDivRef.current.scrollIntoView({ behavior: 'auto' });
    } else {
      console.warn('myDivRef is null');
    }
  };

  const formHandler = async (type) => {
   // console.log("working in formHandler : ");
   const errors =  checkDisableForm1()
   if(errors==0){
    handleType(type);
    
  }
  else {
    console.log({ errors});
  }
  
  }
  const taketoCoursePage = ()=>{
    const errors =  checkDisableForm1()
    if(errors==0){
goToCoursePage();      
    }
    else {
      console.log({ errors});
    }
  }

  const handleOrganisationForm = async () => {
    const errors =  checkDisableForm2()
    if(errors==0){
      goToCoursePage();
    }

  }
  const handleType = (type)=>{
    setFormType(type);
    if(type=="organisation"){
      scrollToDiv();
    }}
  const [languageList, setLanguageList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [stateCollegeList, setStateCollegeList] = useState([]);
  const [districtCollegeList, setDistrictCollegeList] = useState([]);
  const [cityCollegeList, setCityCollegeList] = useState([]);
  const [collegeList, setCollegeList] = useState([]);
  const [residingStates, setResidingStates] = useState([]);
  const [residingDistrict, setResidingDistrict] = useState([]);
  const [isUsediCodeVerified, setIsUsediCodeVerified] = useState("unchecked");
  const [isNameValidText, setIsNameValidText] = useState(true);

  const getAllStates = async () => {
    
    // let UserId = localStorage.getItem("login-userId")
    // // console.log("login-userId : ", UserId);
    const result = await axios.post(variables.API_URL + `api/GetSchoolList`, {
      "StateId": "",
      "DistrictId": ""
    });
    if (result.data.isSuccess && result.data.dataList.length > 0) {
      let resAfterFetchingSates = result.data.dataList;
      // console.log("resAfterFetchingSates : ", resAfterFetchingSates);
      setStateList(resAfterFetchingSates);
    } else {
      // console.log("error while fetching states");
    }
    
  }

  const getResidingData = async (type, id) => {
    if (type == "residingState") {
      const residingStates = await axios.post(variables.API_URL + `api/GetStateAndDistricts`, {});
      if (residingStates.data.isSuccess && residingStates.data.dataList.length > 0) {
        let resAfterFetchingResidingSates = residingStates.data.dataList;
        // console.log("resAfterFetchingResidingSates : ", resAfterFetchingResidingSates);
        setResidingStates(resAfterFetchingResidingSates);
      } else {
        // console.log("error while fetching resAfterFetchingResidingSates");
      }

    } else if (type == "residingDistrict") {
      setIsLoader(true);
      const residingDistrict = await axios.post(variables.API_URL + `api/GetStateAndDistricts`, {
        "stateId": id
      });
      if (residingDistrict.data.isSuccess && residingDistrict.data.dataList.length > 0) {
        let resAfterFetchingResidingDistrict = residingDistrict.data.dataList;
        // console.log("resAfterFetchingResidingDistrict : ", resAfterFetchingResidingDistrict);
        setResidingDistrict(resAfterFetchingResidingDistrict);
        
      } else {
        // console.log("error while fetching resAfterFetchingResidingDistrict");
      }
      setIsLoader(false);
    }

  }

  const getAllCollegesData = async (type, id) => {
    setIsLoader(true);
    if (type == "collegeState") {
      const collegeStateResult = await axios.post(variables.API_URL + `api/GetCollegeList`, {
        "stateId": "",
        "districtId": ""
      });
      //console.log("collegeStateResult : ", collegeStateResult);
      if (collegeStateResult.data.isSuccess && collegeStateResult.data.dataList.length > 0) {
        let resAfterFetchingCollegeSates = collegeStateResult.data.dataList;
       // console.log("resAfterFetchingCollegeSates : ", resAfterFetchingCollegeSates);
        setStateCollegeList(resAfterFetchingCollegeSates);
      } else {
        // console.log("error while fetching resAfterFetchingCollegeSates");
      }
      setIsLoader(false);
    } else if (type == "collegeDistrict") {
      const collegeDistrictResult = await axios.post(variables.API_URL + `api/GetCollegeList`, {
        "stateId": id,
        "districtId": ""
      });
     // console.log("collegeDistrictResult : ", collegeDistrictResult);
      if (collegeDistrictResult.data.isSuccess && collegeDistrictResult.data.dataList.length > 0) {
        let resAfterFetchingCollegeDistrict = collegeDistrictResult.data.dataList;
       // console.log("resAfterFetchingCollegeDistrict : ", resAfterFetchingCollegeDistrict);
        setDistrictCollegeList(resAfterFetchingCollegeDistrict)
      } else {
        // console.log("error while fetching resAfterFetchingCollegeDistrict");
      }
      setIsLoader(false);
    } else if (type == "collegeCity") {
      console.log("call city api for college");
      const collegeCityResult = await axios.post(variables.API_URL + `api/GetCollegeList`, {
        "stateId": formValues.collegeState,
        "districtId": id
      });
     // console.log("collegeCityResult : ", collegeCityResult);
      if (collegeCityResult.data.isSuccess && collegeCityResult.data.dataList.length > 0) {
        let resAfterFetchingCollegeCity = collegeCityResult.data.dataList;
       // console.log("resAfterFetchingCollegeCity : ", resAfterFetchingCollegeCity);
        setCityCollegeList(resAfterFetchingCollegeCity)
      } else {
        // console.log("error while fetching resAfterFetchingCollegeCity");
      }
      setIsLoader(false);
    } else if (type == "collegeName") {
     // console.log("filter college for particular cities");
      const collegeResult = await axios.post(variables.API_URL + `api/GetCollegeList`, {
        "stateId": formValues.collegeState,
        "districtId": formValues.collegeDistrict,
        "city": id
      });
     // console.log("collegeResult : ", collegeResult);
      if (collegeResult.data.isSuccess && collegeResult.data.dataList.length > 0) {
        let resAfterFetchingCollege = collegeResult.data.dataList;
      //  console.log("resAfterFetchingCollege : ", resAfterFetchingCollege);
        setCollegeList(resAfterFetchingCollege)
      } else {
        // console.log("error while fetching resAfterFetchingCollege");
      }
      setIsLoader(false);
    }
  }

  const getAllLanguages = async () => {
    // let UserId = localStorage.getItem("login-userId")
    // // console.log("login-userId : ", UserId);
    const queryParams = {
      isForFormRegister: true,
    };
    const result = await axios.get(variables.API_URL + `api/GetLanguages`, {
      params: queryParams,
    });
    if (result.data.isSuccess && result.data.dataList.length > 0) {
      let resAfterFetchingLangugaes = result.data.dataList;
      // console.log("resAfterFetchingLangugaes : ", resAfterFetchingLangugaes);
      setLanguageList(resAfterFetchingLangugaes);
    } else {
      // console.log("error while fetching Languages");
    }
  }

  const checkDataFromCorseData = () => {
    // console.log("checkDataFromCorseData calling : ", courseData)
    if (!props.isProfile) {
      // console.log("courseData.formQuestionNo : ",courseData.formQuestionNo);
      const idsInArray = courseData.formQuestionNo.split(",");
      // console.log("idsInArray : ",idsInArray);
      setQueIds(idsInArray);
      // if(courseData.formQuestionNo)
    }

    if (courseData?.firstName) {
      // console.log("who first")
      setFormValues({ ...formValues, "fullname": courseData.firstName  });
      
    }
    if (courseData?.email) {
      setFormValues({ ...formValues, "email": courseData.email });
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(courseData?.email);
      // console.log("isValidEmail : ", isValidEmail)
      setValid(isValidEmail);
    }
    if (props.isProfile) {
      // console.log("its from profile dont set language id");
    } else {
      if (courseData?.preferredLanguageId == 1) {
        localStorage.setItem("prefferedLanguage", "English")
        cookies.set('language', "English");
      } else if (courseData?.preferredLanguageId == 2) {
        localStorage.setItem("prefferedLanguage", "Hindi")
        cookies.set('language', "Hindi");
      }
    }

  }

 // console.log("queIds : ", queIds)

  useEffect(() => {
    if (props.isProfile) {
      setFormValues({ ...formValues, "district": "" });
      setFormValues({ ...formValues, "state": "" });
      setFormValues({ ...formValues, "schoolName": "" });
      setFormValues({ ...formValues, "usediCode": "" });
      setIsUsediCodeCheck(false);
    }
  }, [props.isShowSettings])

  useEffect(()=>{
    setToggle(!toggle);
  },[formType])

  useEffect(() => {
    // console.log("fetching states in useeffect");
    checkDataFromCorseData();
    getAllStates();
    getResidingData("residingState");
    getAllCollegesData("collegeState");
    getAllLanguages();
    console.log({formErrors})
  }, [])


  const getAllDistricts = async (stateId) => {
    // console.log("calling getAllDistricts : ");
    setIsLoader(true);
    const result = await axios.post(variables.API_URL + `api/GetSchoolList`, {
      "StateId": stateId,
      "DistrictId": ""
    });
    if (result.data.isSuccess && result.data.dataList.length > 0) {
      let resAfterFetchingDistricts = result.data.dataList;
      // console.log("resAfterFetchingDistricts : ", resAfterFetchingDistricts);
      setDistrictList(resAfterFetchingDistricts);
    } else {
      // console.log("error while fetching districts");
    }
    setIsLoader(false);
  }
  const getAllSchools = async (districtId) => {
    setIsLoader(true);
    // console.log("calling getAllSchools : ");
    const result = await axios.post(variables.API_URL + `api/GetSchoolList`, {
      "StateId": formValues.state,
      "DistrictId": districtId
    });
    if (result.data.isSuccess && result.data.dataList.length > 0) {
      let resAfterFetchingSchools = result.data.dataList;
      // console.log("resAfterFetchingSchools : ", resAfterFetchingSchools);
      setSchoolList(resAfterFetchingSchools);
    } else {
      // console.log("error while fetching schools");
    }
    setIsLoader(false);
  }

  const handleFormValues = async (e, ind) => {
    let arr = tempArr;
    // console.log("e.target.value : ", e.target.value);
    // console.log("e.target.name : ", e.target.name);
    // console.log("value of index : ", ind);
    if (e.target.name == "LanguagePreference") {
      // console.log("event.target.options[event.target.selectedIndex].text : ", e.target.options[e.target.selectedIndex].text)
      localStorage.setItem("prefferedLanguage", e.target.options[e.target.selectedIndex].text)
      cookies.set('language', e.target.options[e.target.selectedIndex].text)
    }
    if (e.target.name == "state") {
      getAllDistricts(e.target.value)
    } else if (e.target.name == "district") {
      getAllSchools(e.target.value)
    }

    if (e.target.name == "CurrentState") {
      setFormValues({ ...formValues, city: "" });
      setFormValues({ ...formValues, pincode: "" });
      setCityVal("");
      setPincode("");
      getResidingData("residingDistrict", e.target.value);
    }

    if (e.target.name == "collegeState") {
      getAllCollegesData("collegeDistrict", e.target.value);
    } else if (e.target.name == "collegeDistrict") {
      getAllCollegesData("collegeCity", e.target.value);
    } else if (e.target.name == "collegeCity") {
      getAllCollegesData("collegeName", e.target.value);
    }



    if (e.target.value == "") {
      let index = arr.indexOf(ind);
      if (index !== -1) {
        arr.splice(index, 1);
      }
      // console.log("arr inside : ", arr);
      setTempArr(arr);
    } else {
      if (!arr.includes(ind)) {
        arr.push(ind);
        setTempArr(arr);
      }
    }
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  }
 // console.log("formvalues : ", formValues);
  // console.log("tempArr : ", tempArr);

  const usediCheckBox = (e) => {
    // console.log("usidCheckBox : ", e.target.checked);
    setIsUsediCodeCheck(e.target.checked)
    setFormValues({ ...formValues, ["usediCode"]: "" });
  }
  const usediCodeHandler = (e) => {
    // console.log("usediCodeHandler : ", e.target.value);
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    setFormValues({ ...formValues, ["usediCode"]: numericValue })
    setIsUsediCodeVerified("unchecked");
  }
  const goToCoursePage = async () => {
   // debugger;
    // console.log("calling goToCoursePage : ", formValues);
    formValues.fullname = formValues.fullname.trim();
    setIsLoader(true)
    const body = {
      "UserID": parseInt(userId),
      "projectID": parseInt(courseData.projectId),
      "gradingRuleName": courseData.gradingRuleName,
      "courseID": parseInt(courseData.courseId),
      "Gender": formValues.GenderPreference,
      "LanguageID": parseInt(formValues.LanguagePreference),
      "UDISECode": isUsediCodeCheck ? "" : formValues.usediCode,
      "StateID": isUsediCodeCheck ? parseInt(formValues.state) : 0,
      "DistrictID": isUsediCodeCheck ? parseInt(formValues.district) : 0,
      "SchoolID": isUsediCodeCheck ? parseInt(formValues.schoolName) : 0,
      "schoolCity": "",
      "FullName": formValues.fullname,
      "Email": formValues.email,
      "Type": props.isProfile == true ? "org" : "",
      "residingStateID": parseInt(formValues.CurrentState),
      "residingDistrictID": parseInt(formValues.CurrentDistrict),
      "residingCity": cityVal,
      "residingPincode": pincode,
      "qualification": "",
      "designation": "",
      "collegeStateID": parseInt(formValues.collegeState),
      "collegeDistrictID": parseInt(formValues.collegeDistrict),
      "collegeCity": formValues.collegeCity,
      "collegeID": parseInt(formValues.collegeName),
      "departmentID": parseInt(formValues.department),
      "ongoingPassed": formValues.ongoingPassed,
      "semester": formValues.semester,
      "yearOfPassing": formValues.yearOfPassing.toString(),
      "classesTeaching": "",
      "subjectsTeaching": "",
    }

    console.log("body for submit data : ", body);
    //debugger;
    const result = await axios.post(variables.API_URL + `api/SaveCandidateRegistrationDetails`, body, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("JWTToken")
      },
    });
    // console.log("result after submit api : ", result);
    if (result.data.isSuccess && result.data.errorMsg == "Candidate details submitted successfully") {
      if (props.isProfile) {
        // console.log("Candidate details submitted successfully you can reset the form");
        setFormValues({ ...formValues, "district": "" });
        setFormValues({ ...formValues, "state": "" });
        setFormValues({ ...formValues, "schoolName": "" });
        setFormValues({ ...formValues, "usediCode": "" });
        setIsUsediCodeCheck(false);
        setIsLoader(false)
      } else {
        setIsLoader(false)
        if (courseData.activeStage === 1) {
          navigate('/mycourses')
        } else {
          if (isDevice == "mobile") {
            navigate('/courseIndexMiddlePage')
          } else {
            navigate('/CourseIndex')
          }
        }

      }
    } else {
      setIsLoader(false)
      // console.log("error while submitting details");
    }
  }

  const verifyOTP = async(e)=>{
    try {
        const result = await axios.post(variables.API_URL+'api/VerifyOtp',
            {
                PhoneNumber:"" ,
                Email: formValues.email,
                Otp:otp,
                Userid:0
            }
        )
        const data = result.data
        console.log("otp verification:"+ JSON.stringify(data.isSuccess));
         if(data.isSuccess){
          const { otp, ...rest } = formErrors;
          setFormErrors(rest); 
          setVerifyDisabledbtn(true)
          setOptsent(false)

          handleType("personal")
         }
         else{
          setFormErrors({...formErrors, otp: getTranslatedValue("wrongOtpEntered") })
        }
    } catch (error) {
        console.log(error)
    }

}

function getOTP() {


  fetch(variables.API_URL + 'api/SendOTP', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          MobileNo: formValues.phonenumber,
          Email: formValues.email,
        
      })
  })
      .then(res => res.json())
      .then((result) => {
          if (result.isSuccess == true) {
              

          }
      }, (error) => {
      })


}
function CheckUsername() {


  fetch(variables.API_URL + 'api/CheckUserNameExists', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        UserName: formValues.email,
        UserId: userId,
        
      })
  })
      .then(res => res.json())
      .then((result) => {
          if (result.isSuccess == true) {
            const { email, ...rest } = formErrors;
            setFormErrors(rest); 
            setOptsent(true)
              getOTP();

          }
          else {
            setFormErrors({...formErrors, email: getTranslatedValue("registration049") })
          }
         
            setDisabledbtn(true)
          
      }, (error) => {
      })


}

  const checkDisableForm1 = () => {
    
    const errors ={}
    let errorCount = 0;
    console.log("formValues inisde fun : ", formValues);
    const elements = document.querySelectorAll('.personal');
    // console.log("elements : ", elements)
    let temp = false
    if (formValues.fullname.length < 3) {
      errors.fullname = getTranslatedValue("registration056")
      errorCount++;
    } else {
      let name = formValues.fullname;
      const firstThreeLetters = name && name.slice(0, 3);
      // console.log("firstThreeLetters : ", firstThreeLetters);
      const areFirstThreeLettersNotSpaces = firstThreeLetters && !firstThreeLetters.includes(' ');
      // console.log("areFirstThreeLettersNotSpaces : ", areFirstThreeLettersNotSpaces);
      if (!areFirstThreeLettersNotSpaces) {
        errors.fullname = getTranslatedValue("registration056")
        errorCount++;

      }
      const firstLetter = name && name.slice(0, 1);
      let regexForSpecialCharacter = /^[a-zA-Z0-9]+$/;
      const areFirstThreeLettersAlphanumeric = firstLetter && regexForSpecialCharacter.test(firstLetter);
      // console.log("areFirstLetterAlphanumeric : ", areFirstThreeLettersAlphanumeric);
      if (!areFirstThreeLettersAlphanumeric) {
        errors.fullname = getTranslatedValue("registration056")
        errorCount++;

      }
    }

    if (valid == false) {
      console.log("returning true beacause email is not valid")
      temp = true
    }
   

    elements.forEach(element => {
      // Do something with each element
      // console.log("element.textContent : ", element.value);
      if (element.value == "") {
        const formattedName = element.name
        .replace(/^Current/, '')  // Remove "Current" at the start
        .replace(/Preference$/, '')  // Remove "Preference" at the end
        .replace(/([A-Z])/g, ' $1')  // Insert space before each uppercase letter
        .trim()  // Remove leading/trailing spaces
        .replace(/^./, str => str.toUpperCase());
        errors[element.name]= `${formattedName} is required`
        errorCount++;

        console.log("element.value == empty thats why temp is true");
      }
      if (queIds.includes(12) || element.name == "pincode") {
        if (formValues.pincode?.length != 6) {
          if(!errors.pincode){
            errors.pincode = "Enter valid pincode"
            errorCount++;
          }
         
  
        }
      }
    });
    console.log({ errors});
    setpersonalFormErrors(errors);
    return errorCount

    // if (formValues.GenderPreference != "" && formValues.LanguagePreference != "") {
    //   return false
    // } else {
    //   return true
    // }
  }

  const checkDisableForm2 = () => {
    const errors ={}
    let errorCount = 0;
    // setToggle(!toggle);
    console.log("formValues inisde checkDisableForm2 : ", formValues);
    const elements = document.querySelectorAll('.organisation');
    console.log("elements : ", elements)
    let temp = false
    if (isUsediCodeCheck) {
      if (formValues.state == "" || formValues.district == "" || formValues.schoolName == "") {
        temp = true
        console.log("chamging temp inside isUsediCodeCheck");
        // return true
    }
    } else {
      if (queIds.includes("3")) {
        if (formValues.usediCode.length < 10 || isUsediCodeVerified == "invalid" || isUsediCodeVerified == "unchecked") {
          console.log("chamging temp inside formValues.usediCode.length < 10");
          temp = true
          // return true
        }
      }
    }
    
    elements.forEach(element => {
      // Do something with each element
      // console.log("element.textContent and isUsediCodeCheck : ", element.name, isUsediCodeCheck);
      if (element.value == "") {
        console.log("inside element.value == ");
        if (isUsediCodeCheck /* && element.name == "udise" */) {
          if (formValues.state === "") {
            errors.state = "State is required";  // Add error for state
            errorCount++;  // Increment error count
            temp = true;
        }

        if (formValues.district === "") {
            errors.district = "District is required";  // Add error for district
            errorCount++;  // Increment error count
            temp = true;
        }

        if (formValues.schoolName === "") {
            errors.schoolName = "School name is required";  // Add error for school name
            errorCount++;  // Increment error count
            temp = true;
        } else {

            temp = false
          }
        } else {
          console.log("inside uuuuuuu")
          const formattedName = element.name
          .replace(/^Current/, '')  // Remove "Current" at the start
          .replace(/Preference$/, '')  // Remove "Preference" at the end
          .replace(/([A-Z])/g, ' $1')  // Insert space before each uppercase letter
          .trim()  // Remove leading/trailing spaces
          .replace(/^./, str => str.toUpperCase());
          errors[element.name]= `${formattedName} is required`
        errorCount++;

          temp = true
          // return true
        }
        // console.log("chamging temp inside elements.forEach");
      }
    });
    console.log("final val of temp in checkDisableForm2 : ",temp);
    setorganisationFormErrors(errors);
    return errorCount

  }

  const checkDisableUsediInput = () => {
    // console.log("isUsediCodeCheck : isUsediCodeVerified = ", isUsediCodeCheck + " : " + isUsediCodeVerified)
    if (!isUsediCodeCheck) {
      // console.log("return false")
      return false
      // if(!isUsediCodeVerified){
      // } else {
      //   // console.log("return true")
      //   return true
      // }
    } else {
      // console.log("return true")
      return true
    }
  }

  const verifyUsediCode = async () => {
    // console.log("verifyUsediCode calling :");
    const result = await axios.post(variables.API_URL + `api/CheckUdiseCode`, {
      "UDISECODE": formValues.usediCode,
    })
    // console.log("result after checking udise code : ", result);
    if (result.data.isSuccess && result.data.returnMessage == "Success") {
      setIsUsediCodeVerified("valid");
    } else {
      // console.log("udise code is invalid or something went wrong")
      setIsUsediCodeVerified("invalid")
    }
  }
  // console.log("isUsediCodeVerified isUsediCodeVerified : ", isUsediCodeVerified)

  const nameHandler = (e) => {
    // console.log("nameHandler calling : ", e.target.value)
    setIsNameValidText(true);
    const nameRegex = /^[a-zA-Z !`-]+$/;
    if (e.target.value == "") {
      setFormValues({ ...formValues, ["fullname"]: "" })
    } else {
      if (e.target.value.match(nameRegex)) {
        setFormValues({ ...formValues, ["fullname"]: e.target.value })
      } else {
        setFormValues({ ...formValues, ["fullname"]: formValues.fullname })
      }
    }
  }
  const emailHandler = (e) => {
    // console.log("emailHandler calling : ", e.target.value)
    setDisabledbtn(false)
    setOptsent(false)
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value);
    // console.log("isValidEmail : ", isValidEmail)
    
    setValid(isValidEmail);
    if (e.target.value == "") {
      setFormValues({ ...formValues, ["email"]: "" })
    } else {
      setFormValues({ ...formValues, ["email"]: e.target.value })
    }
  }
  const otpHandler=(e)=>{
    setVerifyDisabledbtn(false)
    
    const otp = e.target.value;
    if(otp.length ==0){
      setVerifyDisabledbtn(true)
    }
    setOtp(otp)

  }
  const cityHandler = (e) => {
    const city = e.target.value;

    // Use regex to remove any numeric characters
    const textValue = city.replace(/[^a-zA-Z\s]/g, '');

    setFormValues({ ...formValues, city: textValue });
    setCityVal(textValue);
  };

  const pincodeHandler = (e) => {
    const pincode = e.target.value;

    // Use regex to allow only digits and ensure the length is up to 6
    const numericValue = pincode.replace(/\D/g, ''); // Remove non-digit characters

    if (numericValue.length <= 6) {
      setFormValues({ ...formValues, pincode: numericValue });
      setPincode(numericValue);
    }
  };

  const yearHandler = (year) => {
    console.log("Selected Year:", year.getFullYear());
    setSelectedYear(year)
    setFormValues({ ...formValues, ["yearOfPassing"]: year.getFullYear() })
  }
  
  const goBack = ()=>{
    navigate("/mycourses")
  }

  return (
    <>
      {
        props.isProfile == true ? "" :
          <>
            <NavOne mobileView={true} ></NavOne>
          </>
      }
      {/*  <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}

      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {isLoader && <CustumLoader message="Loading..." />}

      {/* organisation tab for edit profile */}
      {
        props.isProfile ?
          <>
            <div class="p-3 pt-4 pb-4 border-bottom">
              <h4 class=""><Translate textKey="postregform009" />
              </h4>
              <div class="deatils-drop mb-3">
                <div class="col-md-8 col-sm-12 mt-0">

                  <h6 class="bold-500 mb-3"><Translate textKey="postregform011" />
                  </h6>



                  <div class="col-md-12 ">
                    <div class="d-flex ">
                      <div class="input-group mb-1 d-flex w-75 me-2">
                        <input type="text" name="udise" value={formValues.usediCode} onChange={usediCodeHandler} class="form-control organisation" disabled={checkDisableUsediInput()} placeholder="Enter Code hererere" ></input>

                      </div>
                      <button onClick={verifyUsediCode} disabled={formValues.usediCode.length > 9 ? false : true} class={formValues.usediCode.length > 9 ? "primary-btn" : "disabled-btn"}>{isUsediCodeVerified == "valid" ? "Verified" : isUsediCodeVerified == "unchecked" ? "Verify" : "invalid"}</button>
                    </div>

                    {/* error msg one */}
                    {
                      isUsediCodeVerified == "invalid" ?
                        <>
                          <div class="d-flex">
                            <div>
                              <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                            </div>
                            <div class="pt-2">
                              <p class="login-error h7 mb-0 align-self-center"><Translate textKey="postregform013" /></p>
                            </div>
                          </div>
                        </> : ""
                    }
                    {/* error msg two
                                    <div class="d-flex">
                                    <div>
                                      <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>

                                      </div>
                                      <div class="pt-2">
                                        <p class="login-error h7 mb-0 align-self-center">There was a connection break, please resbmit the code.
                                        // <Translate textKey="postregform014" /> 
                                        </p>
                                        </div>
                                    </div> */}


                    <div class="form-check mt-2">
                      <input class="form-check-input" onChange={usediCheckBox} checked={isUsediCodeCheck} type="checkbox" value="" id="defaultCheck1"></input>
                      <label class="form-check-label" for="defaultCheck1">
                        <Translate textKey="postregform012" />
                      </label>
                    </div>
                  </div>
                </div>

              </div>



              {
                isUsediCodeCheck ?
                  <>
                    <div class="details-box mb-4">
                      <h4 class=""><Translate textKey="postregform015" /></h4>
                      <div class="deatils-drop">
                        <div class="col-md-6 col-sm-12 mt-0">

                          <h6 class="bold-500"><Translate textKey="postregform017" />
                          </h6>


                          <div class="col-md-12">
                            <div class="dropdown-btn cursor-pointer dropdown-options mt-0">
                              <div class="select-dropdown cursor-pointer">
                                <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                  <option value=""> <Translate textKey="postregform016" />
                                    {/* <Translate textKey="postregform016" /> */}
                                  </option>
                                  {stateList.map((item) => {
                                    return (
                                      <>
                                        <option value={item.stateId}>
                                          {item.stateName}</option>
                                      </>
                                    )
                                  })}

                                  {/* <option value="Option 3">Two</option> */}
                                </select>
                              </div>
                            </div>
                          </div>
                          {organisationformErrors.state &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{organisationformErrors.state}</p>
                                        </div>
                                      </div>
                                    </>}
                        </div>
             
                      </div>
                    </div>
                    <div class="details-box mb-4">
                      <h4 class=""><Translate textKey="postregform018" />
                        {/* <Translate textKey="postregform018" /> */}
                      </h4>
                      <div class="deatils-drop">
                        <div class="col-md-6 col-sm-12 mt-0">

                          <h6 class="bold-500"><Translate textKey="postregform020" />
                            {/* <Translate textKey="postregform020" /> */}
                          </h6>

                          <div class="col-md-12">
                            <div class="dropdown-btn cursor-pointer dropdown-options mt-0">
                              <div class="select-dropdown cursor-pointer">
                                <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                <select value={formValues.district} name="district" onChange={(e) => handleFormValues(e, "7")} class="cursor-pointer organisation text-truncate">
                                  <option value=""> <Translate textKey="postregform019" />
                                    {/* <Translate textKey="postregform019" /> */}
                                  </option>
                                  {districtList.map((item) => {
                                    return (
                                      <>
                                        <option value={item.districtId}>
                                          {item.districtName}</option>
                                      </>
                                    )
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {organisationformErrors.district &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{organisationformErrors.district}</p>
                                        </div>
                                      </div>
                                    </>}
                      </div>
                    </div>
                    <div class="details-box mb-4 pb-3">
                      <h4 class=""><Translate textKey="postregform021" />
                        {/* <Translate textKey="postregform021" /> */}
                      </h4>
                      <div class="deatils-drop detailsdrop-padding mb-3">
                        <div class="col-md-6 col-sm-12 mt-0">

                          <h6 class="bold-500"><Translate textKey="postregform023" />
                            {/* <Translate textKey="postregform023" /> */}
                          </h6>

                          <div class="col-md-12">
                            <div class="dropdown-btn cursor-pointer dropdown-options mt-0 mb-2">
                              <div class="select-dropdown cursor-pointer mb-3">
                                <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                <select value={formValues.schoolName} name="schoolName" onChange={(e) => handleFormValues(e, "8")} class="cursor-pointer organisation text-truncate">
                                  <option value=""> <Translate textKey="postregform022" />
                                    {/* <Translate textKey="postregform022" /> */}
                                  </option>
                                  {schoolList.map((item) => {
                                    return (
                                      <>
                                        <option value={item.schoolId}>
                                          {item.schoolName}</option>
                                      </>
                                    )
                                  })}

                                </select>
                                {/* <div class="pb-3">
                                    <h6 class="bold-500 mt-2">If your school name is not available in the provided list, contact our administrative team
                                      <Translate textKey="postregform024" /> 
                                </h6> */}

                              </div>

                              <div class="d-block mt-2 ">
                                <h6 class="bold-500"><Translate textKey="postregform024" />
                                  {/* <Translate textKey="postregform024" />  */}
                                </h6>
                              </div>

                            </div>
                          </div>
                        </div>
                        {organisationformErrors.schoolName &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{organisationformErrors.schoolName}</p>
                                        </div>
                                      </div>
                                    </>}
                      </div>
                    </div>

                  </> : ""
              }

              <div class="col-md-12 pb-3 text-center p-3 pb-4 mt-4 border-top">
                {/* <p class="mb-0"><Translate textKey="postregform028" /></p>
                      <p>
                        <Translate textKey="postregform029" />
                      </p> */}
                <div class="m-auto justify-content-center welcome-btns">
                  {
                    props.isProfile == true ?
                      "" :
                      <button onClick={() => formHandler("personal")} class="secondary-btn me-3 mb-3"><Translate textKey="postregform030" /></button>
                  }
                  <button onClick={handleOrganisationForm}  class="primary-btn">Submit</button>
                </div>
              </div>

            </div>
          </> :
          <>
            <div ref={myDivRef} className={props.isProfile == true ? "content-cards bg-body box-one welcome-menu" : "col-lg-9 col-sm-12 col-xs-12 content-cards bg-body box-one welcome-menu"}>
            <img src="../../Assets/Linqskills_Icons/breadcrumb-back.svg" onClick={goBack} class="cursor-pointer float-left mt-3"></img>
              <div class={props.isProfile == true ? "p-0" : "pt-3"}>
                <div  class="d-flex text-center m-auto justify-content-center welcome-heading pb-3 pt-3">
                {
                    props.isProfile == true ?
                      "" :
                      <div class="me-4 border-bottom">
                        <img  src={formType == "email" ? "../../Assets/Linqskills_Icons/email-24.svg"  : "../../Assets/Linqskills_Icons/email-default.svg"} class="cursor-pointer mt-1 mb-1"></img>
                        <h6 class={formType == "email" ? "active-text cursor-pointer" : "cursor-pointer"}>EMAIL ID
                        </h6>
                      </div>
                  }
                  {
                    props.isProfile == true ?
                      "" :
                      <div class="me-4 border-bottom">
                        <img src={formType == "personal" ? "../../Assets/Linqskills_Icons/b-icons-24_Personal-filled.svg" : "../../Assets/Linqskills_Icons/b-icons-24_Personal-Default.svg"} class="cursor-pointer mb-1"></img>
                        <h6 class={formType == "personal" ? "active-text cursor-pointer mb-1" : " cursor-pointer"}> <Translate textKey="postregform001" />
                        </h6>
                      </div>
                  }
                  
                  {
                    queIds.includes("3") || queIds.includes("17") ?
                      <>
                        <div class="me-3 border-bottom">
                          <img src={formType == "organisation" ? "../../Assets/Linqskills_Icons/b-icons-24_Organisation-filled.svg" : "../../Assets/Linqskills_Icons/b-icons-24_Organisation-Default.svg"} class="cursor-pointer mb-1"></img>

                          {/*for active <img src= class="cursor-pointer"></img> */}
                          <h6 class={formType == "organisation" ? "active-text cursor-pointer" : "cursor-pointer"}><Translate textKey="postregform002" />
                          </h6>
                        </div>
                      </> : ""
                  }
             
                </div>
<div class="mb-3">
  <h5 class="text-center">All fields are mandatory</h5>
</div>
                {
                  formType == "email" ? <>
                      <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3"><Translate textKey="registration006" />
                                    <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                      {/* <Translate textKey="registration006" /> */}
                                    </h5>
                                    <h6 class="bold-500"><Translate textKey="postregform027" />
                                      {/* <Translate textKey="postregform027" /> */}
                                    </h6>

                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12 ">
                                      <div class="email-box">
                                        <div class="input-group mb-1 d-flex col me-2">
                                          <input type="text" value={formValues.email} onChange={emailHandler} class="form-control personal" placeholder={getTranslatedValue("registration007")} placeholderkey="registration007" ></input>
                                     
                                        </div>
                                        <div>
                                        <button class={disabledbtn?"disabled-btn marginT-mobile":"primary-btn marginT-mobile"} onClick={CheckUsername}>Send OTP</button>
                                      </div>
                                    </div>
                                    {formErrors.email &&  <>
                                        <div class="d-flex">
                                          <div>
                                            <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                          </div>
                                          <div class="pt-2">
                                            <p class="login-error h7 mb-0 align-self-center">{formErrors.email}</p>
                                          </div>
                                        </div>
                                      </>}
                                      {otpsent &&  <Translate textKey="userprofile004" />}

                                  </div>
                                    <div class="col-md-12 mt-3">
                                      <div class="email-box">
                                        <div class="input-group mb-1 d-flex col me-2">
                                          <input type="text"  value={otp} onChange={otpHandler} class="form-control personal" placeholder={getTranslatedValue("Login020")} placeholderkey="registration026" ></input>
                                </div>
                                        <div>
                                        <button class={verifydisabledbtn?"disabled-btn marginT-mobile":"primary-btn marginT-mobile"} onClick={verifyOTP} >Verify </button>
                                        </div>
                                      </div>
                                      {formErrors.otp &&  <>
                                        <div class="d-flex">
                                          <div>
                                            <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                          </div>
                                          <div class="pt-2">
                                            <p class="login-error h7 mb-0 align-self-center">{formErrors.otp}</p>
                                          </div>
                                        </div>
                                      </>}
                                    </div>
                                   
                                  </div>
                                </div>
                               
                              </div>
                    <div class="col-md-12 pb-4 text-center">
                            
{/*                               <button disabled={checkDisableForm1()} onClick={() => formHandler("personal")} class={checkDisableForm1() ? "disabled-btn" : "primary-btn"}>Proceed Next</button>
 */}                            </div>
                  </>:
                  formType == "personal" ?
                    <>
                      <div>
                        {
                          queIds.includes("7") ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3"><Translate textKey="registration025" />
                                      {/* <Translate textKey="postregform009" /> */}
                                      <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                    </h5>
                                    <h6 class="bold-500"> <Translate textKey="postregform026" />
                                      {/* <Translate textKey="postregform011" /> */}
                                    </h6>

                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12 ">
                                      <div class="d-flex">
                                        <div class="input-group mb-1 d-flex w-75 me-2">
                                          <input type="text" value={formValues.fullname} name="fullname" onChange={nameHandler} class="form-control personal" placeholderkey="registration026" ></input>
                                        </div>
                                      </div>
                                    </div>
                                    {personalformErrors.fullname &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{personalformErrors.fullname}</p>
                                        </div>
                                      </div>
                                    </>}
                                  </div>
                                  {
                                    isNameValidText == false ?
                                      <>
                                        <div class="d-flex">
                                          <div>
                                            <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                          </div>
                                          <div class="pt-2">
                                            <p class="login-error h7 mb-0 align-self-center"></p>
                                          </div>
                                        </div>
                                      </> : ""
                                  }
                                  
                       
                                  
                                </div>
                              </div>
                            </> : ""
                        }

                        {
                          queIds.includes("1") ?
                            <>
                              <div id='1' class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3"> <Translate textKey="postregform003" />
                                    <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                    </h5>
                                    <h6 class="bold-500"><Translate textKey="postregform005" />
                                    </h6>
                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12">
                                      <div class="dropdown-btn cursor-pointer dropdown-options mt-0">
                                        <div class="select-dropdown cursor-pointer">
                                          <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                          <select value={formValues.GenderPreference} onChange={(e) => handleFormValues(e, "1")}
                                            name="GenderPreference" class="cursor-pointer personal text-truncate">
                                            <option value="" class=""> <Translate textKey="postregform004" /></option>
                                            <option value="M">
                                              Male</option>
                                            <option value="F">Female</option>
                                            <option value="O">Others</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    {personalformErrors.GenderPreference &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{personalformErrors.GenderPreference}</p>
                                        </div>
                                      </div>
                                    </>}
                                  </div>
                           
                                </div>
                              </div>
                            </> : ""
                        }
                        {
                          queIds.includes("2") ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3"><Translate textKey="postregform006" />
                                    <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                    </h5> 
                                    <h6 class="bold-500"><Translate textKey="postregform008" />
                                    </h6>
                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12">
                                      <div class="dropdown-btn cursor-pointer dropdown-options mt-0">
                                        <div class="select-dropdown cursor-pointer">
                                          <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                          <select value={formValues.LanguagePreference} onChange={(e) => handleFormValues(e, "2")}
                                            name="LanguagePreference" class="cursor-pointer personal text-truncate">

                                            <option value=""> <Translate textKey="postregform007" /></option>
                                            {languageList.map((item) => {
                                              return (
                                                <>
                                                  <option value={item.languageId}>
                                                    {item.languageName}</option>
                                                </>
                                              )
                                            })}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    {personalformErrors.LanguagePreference &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{personalformErrors.LanguagePreference}</p>
                                        </div>
                                      </div>
                                    </>}
                                  </div>
                               
                                </div>
                              </div>
                            </> : ""
                        }

                       {/*  {
                          queIds.includes("8") ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3"><Translate textKey="registration006" />
                                    <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                      {/* <Translate textKey="registration006" /> 
                                    </h5>
                                    <h6 class="bold-500"><Translate textKey="postregform027" />
                                      {/* <Translate textKey="postregform027" /> 
                                    </h6>

                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12 ">
                                      <div class="d-flex">
                                        <div class="input-group mb-1 d-flex w-75 me-2">
                                          <input type="text" value={formValues.email} onChange={emailHandler} class="form-control personal" placeholderkey="registration026" ></input>
                                        </div>
                                        <div>
                                        <button class="primary-btn">Send OTP</button>
                                      </div>
                                    </div>
                                  </div>
                                    <div class="col-md-12 mt-3">
                                      <div class="d-flex">
                                        <div class="input-group mb-1 d-flex w-75 me-2">
                                          <input type="text" value={formValues.email} onChange={emailHandler} class="form-control personal" placeholderkey="registration026" ></input>
                                </div>
                                        <div>
                                        <button class="disabled-btn">Verify </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                               
                              </div>
                            </> : ""
                        } */}
                        {queIds.includes("9") ?
                          <>
                            <div class="details-box mb-4 border">
                              <div class="deatils-drop">
                                <div class="col-md-6 lecture-details p-3">
                                  <h5 class="bold-500 mb-3"><Translate textKey="postregform016" />
                                  <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                  </h5>
                                  <h6 class="bold-500"><Translate textKey="postregform032" />
                                  </h6>
                                </div>
                                <div class="col-md-6 p-3">
                                  <div class="col-md-12">
                                    <div class="dropdown-btn cursor-pointer dropdown-options mt-0">
                                      <div class="select-dropdown cursor-pointer">
                                        <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                        <select value={formValues.CurrentState} onChange={(e) => handleFormValues(e, "9")}
                                          name="CurrentState" class="cursor-pointer personal text-truncate">

                                          <option value=""> <Translate textKey="postregform015" /></option>
                                          {residingStates.map((item) => {
                                            return (
                                              <>
                                                <option value={item.stateId}>
                                                  {item.stateName}</option>
                                              </>
                                            )
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  {personalformErrors.CurrentState &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{personalformErrors.CurrentState}</p>
                                        </div>
                                      </div>
                                    </>}
                                </div>
                            
                              </div>
                            </div>
                          </> : ""}
                        {queIds.includes("10") ?
                          <>
                            <div class="details-box mb-4 border">
                              <div class="deatils-drop">
                                <div class="col-md-6 lecture-details p-3">
                                  <h5 class="bold-500 mb-3"><Translate textKey="postregform019" />
                                  <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                  </h5>
                                  <h6 class="bold-500"><Translate textKey="postregform033" />
                                  </h6>
                                </div>
                                <div class="col-md-6 p-3">
                                  <div class="col-md-12">
                                    <div class="dropdown-btn cursor-pointer dropdown-options mt-0">
                                      <div class="select-dropdown cursor-pointer">
                                        <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                        <select value={formValues.CurrentDistrict} onChange={(e) => handleFormValues(e, "9")}
                                          name="CurrentDistrict" class="cursor-pointer personal text-truncate">

                                          <option value=""> <Translate textKey="postregform018" /></option>
                                          {residingDistrict.map((item) => {
                                            return (
                                              <>
                                                <option value={item.districtId}>
                                                  {item.districtName}</option>
                                              </>
                                            )
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  {personalformErrors.CurrentDistrict &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{personalformErrors.CurrentDistrict}</p>
                                        </div>
                                      </div>
                                    </>}
                                </div>
                         
                              </div>
                            </div>
                          </> : ""}
                        {
                          queIds.includes("11") ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3"><Translate textKey="postregform034" />
                                    <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                      {/* <Translate textKey="postregform009" /> */}
                                    </h5>
                                    <h6 class="bold-500"> <Translate textKey="postregform036" />
                                      {/* <Translate textKey="postregform011" /> */}
                                    </h6>

                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12 ">
                                      <div class="d-flex">
                                        <div class="input-group mb-1 d-flex w-75 me-2">
                                          <input type="text" name="city" disabled={formValues.CurrentState == "" ? true : false} value={cityVal} onChange={cityHandler} class="form-control personal" placeholderkey="postregform035" ></input>
                                        </div>
                                      </div>
                                    </div>
                                    {personalformErrors.city &&  <>
                                      <div class="d-flex ss">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{personalformErrors.city}</p>
                                        </div>
                                      </div>
                                    </>}
                                  </div>
                   
                                </div>
                              </div>
                            </> : ""
                        }
                        {
                          queIds.includes("12") ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3"><Translate textKey="postregform037" />
                                    <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                      {/* <Translate textKey="postregform009" /> */}
                                    </h5>
                                    <h6 class="bold-500"> <Translate textKey="postregform039" />
                                      {/* <Translate textKey="postregform011" /> */}
                                    </h6>

                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12 ">
                                      <div class="d-flex">
                                        <div class="input-group mb-1 d-flex w-75 me-2">
                                          <input type="text" name="pincode" disabled={formValues.CurrentState == "" ? true : false} inputMode="numeric" pattern="\d{0,6}" value={pincode} onChange={pincodeHandler} class="form-control personal" placeholderkey="postregform038" ></input>
                                        </div>
                                      </div>
                                    </div>
                                    {personalformErrors.pincode &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{personalformErrors.pincode}</p>
                                        </div>
                                      </div>
                                    </>}
                                  </div>
                             
                                </div>
                              </div>
                            </> : ""
                        }
                        {
                          queIds.includes("12") && <> </> }
                        {/* {
                          queIds.includes(33) ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3">Current Working Status

                                    </h5>
                                    <h6 class="bold-500"><Translate textKey="postregform005" />

                                    </h6>
                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12">
                                      <div class="dropdown-btn cursor-pointer dropdown-options">
                                        <div class="select-dropdown cursor-pointer">
                                          <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                          <select onChange={(e) => handleFormValues(e, "3")}
                                            name="CurrentWorkingStatus" class="cursor-pointer personal text-truncate">
                                            <option value=""> Select your current position</option>
                                            <option value="Option 2">
                                              One</option>
                                            <option value="Option 3">Two</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </> : ""
                        }
                        {
                          queIds.includes(44) ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3">Current Job Position</h5>
                                    <h6 class="bold-500"><Translate textKey="postregform005" /></h6>
                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12">
                                      <div class="dropdown-btn cursor-pointer dropdown-options">
                                        <div class="select-dropdown cursor-pointer">


                                          <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                          <select onChange={(e) => handleFormValues(e, "4")}
                                            name="CurrentJobPosition" class="cursor-pointer text-truncate">
                                            <option value=""> Select your current position</option>
                                            <option value="Option 2">
                                              One</option>
                                            <option value="Option 3">Two</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </> : ""
                        }
                        {
                          queIds.includes(55) ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3">Select the Country</h5>
                                    <h6 class="bold-500">Choose the service provider to establish a connection for the online meeting.</h6>
                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12">
                                      <div class="dropdown-btn cursor-pointer dropdown-options">
                                        <div class="select-dropdown cursor-pointer">
                                          <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                          <select onChange={(e) => handleFormValues(e, "5")}
                                            name="SelectTheCountry" class="cursor-pointer text-truncate">
                                            <option value="" class=""> Select The Country

                                            </option>
                                            <option value=""> INDIA</option>
                                            <option value="Option 2">One</option>
                                            <option value="Option 3">Two</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </> : ""
                        } */}
                        {
                          queIds.includes("3") || queIds.includes("17") ?
                          <> 
                            <div class="col-md-12 pb-4 text-center">
                            {
                            props.isProfile == true ?
                              "" :
                              <button onClick={() => handleType("email")} class="secondary-btn me-3 mb-3"><Translate textKey="postregform030" /></button>
                          }
                              <button  onClick={() => formHandler("organisation")} class="primary-btn">Proceed Next</button>
                            </div></> :
                            <div class="col-md-12 pb-4 text-center">
                              <button onClick={taketoCoursePage} class="primary-btn"><Translate textKey="postregform031" /></button>
                            </div>
                        }

                      </div>
                    </>
                    : formType == "organisation" ?
                      <>
                        <div  class="organisation-tabs">
                          {/* {
                            queIds.includes(13) ?
                              <>
                                <div class="details-box mb-4 border">
                                  <div class="deatils-drop">
                                    <div class="col-md-6 lecture-details p-3">
                                      <h5 class="bold-500 mb-3"><Translate textKey="postregform015" />

                                      </h5>
                                      <h6 class="bold-500"><Translate textKey="postregform017" />
                                      </h6>
                                    </div>
                                    <div class="col-md-6 p-3">
                                      <div class="col-md-12">
                                        <div class="dropdown-btn cursor-pointer dropdown-options">
                                          <div class="select-dropdown cursor-pointer">
                                            <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                            <select value={formValues.state} name="highestQualification" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                              <option value=""> <Translate textKey="postregform016" />
                                              </option>
                                              {HighestQualificationArray.map((item) => {
                                                return (
                                                  <>
                                                    <option value={item.id}>
                                                      {item.value}</option>
                                                  </>
                                                )
                                              })}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </> : ""
                          } */}

                          {/* {
                            queIds.includes(14) ?
                              <>
                                <div class="details-box mb-4 border">
                                  <div class="deatils-drop">
                                    <div class="col-md-6 lecture-details p-3">
                                      <h5 class="bold-500 mb-3"><Translate textKey="postregform015" />

                                      </h5>
                                      <h6 class="bold-500"><Translate textKey="postregform017" />
                                      </h6>
                                    </div>
                                    <div class="col-md-6 p-3">
                                      <div class="col-md-12">
                                        <div class="dropdown-btn cursor-pointer dropdown-options">
                                          <div class="select-dropdown cursor-pointer">
                                            <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                            <select value={formValues.state} name="currentDesignation" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                              <option value=""> <Translate textKey="postregform016" />
                                                
                                              </option>
                                              {CurrentDesignation.map((item) => {
                                                return (
                                                  <>
                                                    <option value={item.id}>
                                                      {item.value}</option>
                                                  </>
                                                )
                                              })}
                                              
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </> : ""
                          } */}

                          {
                            queIds.includes("3") ?
                              <>
                                <div class="details-box mb-4 border">
                                  <div class="deatils-drop">
                                    <div class="col-md-6 lecture-details p-3">
                                      <h5 class="bold-500 mb-3"><Translate textKey="postregform009" />
                                      <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                      </h5>
                                      <h6 class="bold-500"><Translate textKey="postregform011" />
                                      </h6>

                                    </div>
                                    <div class="col-md-6 p-3">
                                      <div class="col-md-12 ">
                                        <div class="d-flex">
                                          <div class="input-group mb-1 d-flex w-75 me-2">
                                            <input type="text" name="udise" value={formValues.usediCode} onChange={usediCodeHandler} class="form-control organisation" disabled={checkDisableUsediInput()} placeholder="Enter Code" ></input>

                                          </div>
                                          <button onClick={verifyUsediCode} disabled={formValues.usediCode.length > 9 ? false : true} class={formValues.usediCode.length > 9 ? "primary-btn" : "disabled-btn"}>{isUsediCodeVerified == "valid" ? "Verified" : isUsediCodeVerified == "unchecked" ? "Verify" : "invalid"}</button>
                                        </div>

                                        {/* error msg one */}
                                        {
                                          isUsediCodeVerified == "invalid" ?
                                            <>
                                              <div class="d-flex">
                                                <div>
                                                  <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                                </div>
                                                <div class="pt-2">
                                                  <p class="login-error h7 mb-0 align-self-center"><Translate textKey="postregform013" /></p>
                                                </div>
                                              </div>
                                            </> : ""
                                        }
                                        {/* error msg two
                                    <div class="d-flex">
                                    <div>
                                      <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>

                                      </div>
                                      <div class="pt-2">
                                        <p class="login-error h7 mb-0 align-self-center">There was a connection break, please resbmit the code.
                                        // <Translate textKey="postregform014" /> 
                                        </p>
                                        </div>
                                    </div> */}


                                        <div class="form-check">
                                          <input class="form-check-input" onChange={usediCheckBox} checked={isUsediCodeCheck} type="checkbox" value="" id="defaultCheck1"></input>
                                          <label class="form-check-label" for="defaultCheck1">
                                            <Translate textKey="postregform012" />
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </> : ""
                          }

                          {
                            isUsediCodeCheck ?
                              <>
                                <div class="details-box mb-4 border">
                                  <div class="deatils-drop">
                                    <div class="col-md-6 lecture-details p-3">
                                      <h5 class="bold-500 mb-3"><Translate textKey="postregform015" />
                                      <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                      </h5>
                                      <h6 class="bold-500"><Translate textKey="postregform017" />
                                      </h6>
                                    </div>
                                    <div class="col-md-6 p-3">
                                      <div class="col-md-12">
                                        <div class="dropdown-btn cursor-pointer dropdown-options">
                                          <div class="select-dropdown cursor-pointer">
                                            <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                            <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                              <option value=""> <Translate textKey="postregform016" />
                                                {/* <Translate textKey="postregform016" /> */}
                                              </option>
                                              {stateList.map((item) => {
                                                return (
                                                  <>
                                                    <option value={item.stateId}>
                                                      {item.stateName}</option>
                                                  </>
                                                )
                                              })}

                                              {/* <option value="Option 3">Two</option> */}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      {organisationformErrors.state &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{organisationformErrors.state}</p>
                                        </div>
                                      </div>
                                    </>}
                                    </div>
                             
                                  </div>
                                </div>
                                <div class="details-box mb-4 border">
                                  <div class="deatils-drop">
                                    <div class="col-md-6 lecture-details p-3">
                                      <h5 class="bold-500 mb-3"><Translate textKey="postregform018" />
                                      <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                        {/* <Translate textKey="postregform018" /> */}
                                      </h5>
                                      <h6 class="bold-500"><Translate textKey="postregform020" />
                                        {/* <Translate textKey="postregform020" /> */}
                                      </h6>
                                    </div>
                                    <div class="col-md-6 p-3">
                                      <div class="col-md-12">
                                        <div class="dropdown-btn cursor-pointer dropdown-options">
                                          <div class="select-dropdown cursor-pointer">
                                            <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                            <select value={formValues.district} name="district" onChange={(e) => handleFormValues(e, "7")} class="cursor-pointer organisation text-truncate">
                                              <option value=""> <Translate textKey="postregform019" />
                                                {/* <Translate textKey="postregform019" /> */}
                                              </option>
                                              {districtList.map((item) => {
                                                return (
                                                  <>
                                                    <option value={item.districtId}>
                                                      {item.districtName}</option>
                                                  </>
                                                )
                                              })}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {organisationformErrors.district &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{organisationformErrors.district}</p>
                                        </div>
                                      </div>
                                    </>}
                                  </div>
                                </div>
                                <div class="details-box mb-4 border">
                                  <div class="deatils-drop detailsdrop-padding">
                                    <div class="col-md-6 lecture-details p-3">
                                      <h5 class="bold-500 mb-3"><Translate textKey="postregform021" />
                                      <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                        {/* <Translate textKey="postregform021" /> */}
                                      </h5>
                                      <h6 class="bold-500"><Translate textKey="postregform023" />
                                        {/* <Translate textKey="postregform023" /> */}
                                      </h6>
                                    </div>
                                    <div class="col-md-6 p-3 pb-5 pt-0">
                                      <div class="col-md-12">
                                        <div class="dropdown-btn cursor-pointer dropdown-options">
                                          <div class="select-dropdown cursor-pointer">
                                            <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                            <select value={formValues.schoolName} name="schoolName" onChange={(e) => handleFormValues(e, "8")} class="cursor-pointer organisation text-truncate">
                                              <option value=""> <Translate textKey="postregform022" />
                                                {/* <Translate textKey="postregform022" /> */}
                                              </option>
                                              {schoolList.map((item) => {
                                                return (
                                                  <>
                                                    <option value={item.schoolId}>
                                                      {item.schoolName}</option>
                                                  </>
                                                )
                                              })}
                                            </select>
                                          </div>
                                          <div class="d-block mt-2">
                                            <h6 class="bold-500"><Translate textKey="postregform024" />
                                              {/* <Translate textKey="postregform024" />  */}
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {organisationformErrors.schoolName &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{organisationformErrors.schoolName}</p>
                                        </div>
                                      </div>
                                    </>}
                                  </div>
                                </div>
                              </> : ""
                          }

                          {
                            queIds.includes("3") ?
                              <>
                                {/* {
                                  queIds.includes(15) ?
                                    <>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3"><Translate textKey="postregform015" />

                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform017" />
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform016" />
                                                      
                                                    </option>
                                                    {ClassesYouAreCurrentlyTeaching.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item.id}>
                                                            {item.value}</option>
                                                        </>
                                                      )
                                                    })}
                                                    
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </> : ""
                                }

                                {
                                  queIds.includes(16) ?
                                    <>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3"><Translate textKey="postregform015" />

                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform017" />
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform016" /></option>
                                                    {SubjectsYouAreCurrentlyInstructing.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item.id}>
                                                            {item.value}</option>
                                                        </>
                                                      )
                                                    })}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </> : ""
                                } */}
                              </> :
                              <>
                                {
                                  queIds.includes("17") ?
                                    <>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3"><Translate textKey="postregform052" />
                                            <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform017" />
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options mt-0">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.collegeState} name="collegeState" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform016" />
                                                    </option>
                                                    {stateCollegeList.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item.stateId}>
                                                            {item.stateName}</option>
                                                        </>
                                                      )
                                                    })}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            {organisationformErrors.collegeState &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{organisationformErrors.collegeState}</p>
                                        </div>
                                      </div>
                                    </>}
                                          </div>
                                    
                                        </div>
                                      </div>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3"><Translate textKey="postregform018" />
                                            <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                              {/* <Translate textKey="postregform018" /> */}
                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform020" />
                                              {/* <Translate textKey="postregform020" /> */}
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options mt-0">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.collegeDistrict} name="collegeDistrict" onChange={(e) => handleFormValues(e, "7")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform019" />

                                                    </option>
                                                    {districtCollegeList.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item.districtId}>
                                                            {item.districtName}</option>
                                                        </>
                                                      )
                                                    })}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            {organisationformErrors.collegeDistrict &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{organisationformErrors.collegeDistrict}</p>
                                        </div>
                                      </div>
                                    </>}
                                          </div>
                                      
                                        </div>
                                      </div>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3"><Translate textKey="postregform053" />
                                            <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                              {/* <Translate textKey="postregform018" /> */}
                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform020" />
                                              {/* <Translate textKey="postregform020" /> */}
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options mt-0">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.collegeCity} name="collegeCity" onChange={(e) => handleFormValues(e, "7")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform053" />

                                                    </option>
                                                    {cityCollegeList.map((item) => {
                                                      console.log("city item of college : ", item)
                                                      return (
                                                        <>
                                                          <option value={item.city}>
                                                            {item.city}</option>
                                                        </>
                                                      )
                                                    })}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            {organisationformErrors.collegeCity &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{organisationformErrors.collegeCity}</p>
                                        </div>
                                      </div>
                                    </>}
                                          </div>
                                     
                                        </div>
                                      </div>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3"> <Translate textKey="postregform054" />
                                            <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform055" />
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options mt-0">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.collegeName} name="collegeName" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform056" />
                                                    </option>
                                                    {collegeList.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item.collegeId}>
                                                            {item.collegeName}</option>
                                                        </>
                                                      )
                                                    })}
                                                  </select>

                                                </div>

                                              </div>
                                              <div class="d-block mt-3">
                                                <h6 class="bold-500"><Translate textKey="postregform057" />
                                                  {/* <Translate textKey="postregform024" />  */}
                                                </h6>
                                              </div>

                                            </div>
                                            {organisationformErrors.collegeName &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{organisationformErrors.collegeName}</p>
                                        </div>
                                      </div>
                                    </>}
                                          </div>
                             
                                        </div>
                                      </div>
                                    </> : ""
                                }
                                {
                                  queIds.includes("18") ?
                                    <>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3">Department
                                            <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform058" />
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options mt-0">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.department} name="department" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform059" />
                                                    </option>
                                                    {DepartmentArray.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item.id}>
                                                            {item.value}</option>
                                                        </>
                                                      )
                                                    })}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            {organisationformErrors.department &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{organisationformErrors.department}</p>
                                        </div>
                                      </div>
                                    </>}
                                          </div>
                              
                                        </div>
                                      </div>
                                    </> : ""
                                }

                                {
                                  queIds.includes("20") ?
                                    <>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3"><Translate textKey="postregform061" /><img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform063" />
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options mt-0">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.ongoingPassed} name="ongoingPassed" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">

                                                    {OngoingPassedArray.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item.id}>
                                                            {item.value}</option>
                                                        </>
                                                      )
                                                    })}
                                                    {/* <option value="Option 3">Two</option> */}
                                                  </select>
                                                </div>
                                              </div>
                                              {organisationformErrors.ongoingPassed &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{organisationformErrors.ongoingPassed}</p>
                                        </div>
                                      </div>
                                    </>}
                                            </div>
                              
                                          </div>
                              
                                        </div>
                                      </div>
                                    </> : ""
                                }



                                {
                                  queIds.includes("19") && formValues.ongoingPassed == "true" ?
                                    <>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3"><Translate textKey="postregform064" />
                                            <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform066" />
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options mt-0">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.semester} name="semester" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform065" />
                                                      {/* <Translate textKey="postregform016" /> */}
                                                    </option>
                                                    {Semester.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item.id}>
                                                            {item.value}</option>
                                                        </>
                                                      )
                                                    })}
                                                    {/* <option value="Option 3">Two</option> */}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            {organisationformErrors.semester &&  <>
                                      <div class="d-flex">
                                        <div>
                                          <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                        </div>
                                        <div class="pt-2">
                                          <p class="login-error h7 mb-0 align-self-center">{organisationformErrors.semester}</p>
                                        </div>
                                      </div>
                                    </>}
                                          </div>
                            
                                        </div>
                                      </div>
                                    </> :
                                    <>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3"> <Translate textKey="postregform070" /><img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform072" />
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options mt-0">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.yearOfPassing} name="yearOfPassing" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform071" />
                                                    </option>
                                                    {years.map((item) => {
                                                      // console.log("years item : ",item);
                                                      return (
                                                        <>
                                                          <option value={item.value}>
                                                            {item.value}</option>
                                                        </>
                                                      )
                                                    })}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                }

                                {/* {
                                  queIds.includes(23) ?
                                    "" : ""
                                } */}
                              </>
                          }

                          <div class="col-md-12 pb-3 text-center p-3 pt-0 pb-4 mt-3">
                            <p class="mb-0"><Translate textKey="postregform028" /></p>
                            <p>
                              <Translate textKey="postregform029" />
                            </p>
                            <div class="m-auto justify-content-center welcome-btns">
                              {
                                props.isProfile == true ?
                                  "" :
                                  <button onClick={() => formHandler("personal")} class="secondary-btn me-3 mb-3"><Translate textKey="postregform030" /></button>
                              }
                              
                              <button onClick={handleOrganisationForm}  class= "primary-btn"><Translate textKey="postregform031" /></button>
                            </div>
                          </div>
                        </div>
                      </>
                      : ""
                }
                {
                  queIds.includes(1) ?
                    <>
                    </> : ""
                }
              </div>
            </div>
          </>
      }
    </>
  )

}



// {
//   queIds.includes(14) ?
//     <>
//       <div class="details-box mb-4 border">
//         <div class="deatils-drop">
//           <div class="col-md-6 lecture-details p-3">
//             <h5 class="bold-500 mb-3"><Translate textKey="postregform015" />

//             </h5>
//             <h6 class="bold-500"><Translate textKey="postregform017" />
//             </h6>
//           </div>
//           <div class="col-md-6 p-3">
//             <div class="col-md-12">
//               <div class="dropdown-btn cursor-pointer dropdown-options">
//                 <div class="select-dropdown cursor-pointer">
//                   <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
//                   <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
//                     <option value=""> <Translate textKey="postregform016" />
//                       {/* <Translate textKey="postregform016" /> */}
//                     </option>
//                     {CurrentDesignation.map((item) => {
//                       return (
//                         <>
//                           <option value={item.id}>
//                             {item.value}</option>
//                         </>
//                       )
//                     })}
//                     {/* <option value="Option 3">Two</option> */}
//                   </select>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </> : ""
// }

// {
//   queIds.includes(88) ?
//     <>
//       <div class="details-box mb-4 border">
//         <div class="deatils-drop">
//           <div class="col-md-6 lecture-details p-3">
//             <h5 class="bold-500 mb-3">Which Subjects do you teach</h5>
//             <h6 class="bold-500"> <Translate textKey="postregform005" />
//             </h6>
//           </div>
//           <div class="col-md-6 p-3">
//             <div class="col-md-12">
//               <div class="dropdown-btn cursor-pointer dropdown-options">
//                 <div class="select-dropdown cursor-pointer">
//                   <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
//                   <select class="cursor-pointer text-truncate">
//                     <option value="Option 1"> Select your current position</option>
//                     <option value="Option 2">
//                       One</option>
//                     <option value="Option 3">Two</option>
//                   </select>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </> : ""
// }