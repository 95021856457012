import React, { useState } from 'react';
import './Chatbox.css';
import './boxchat.css'; 
import Translate from "../../components/Shared/Translate";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleRight, faPaperPlane, faTimes, faVideo, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import ViewTutorialPopUpRgsa from '../CourseIndexRgsa/viewContentPopUpRgsa';

import video1 from "../../Assets/media/2_Panchayat_Vikas_Yojana_1.mp4";
import video2 from "../../Assets/media/2_Panchayat_Vikas_Yojana_2.mp4";

function ChatbotQuery({ isOpen, onClose }) {

  //let pdfUrl = "https://linqskills.s3.ap-south-1.amazonaws.com/UAT/LH/Data/ImageGallery/18921/pdf/RGSA+PDF%201.pdf";

  let pdfUrl = "../Assets/media/pdf/2GPDPGuj.pdf";
  
  let videoUrl1 = video1;
  let videoUrl2 = video2;

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isShowModal, setIsShowModal] = useState(false);
  const [extensionType, setExtensionType] = useState("");
  const [indexOFMedia, setIndexOFMedia] = useState("");
  const loginUserName = localStorage.getItem("login-firstName");

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleSendMessage = () => {
    console.log("newMessage : ", newMessage);

    let newMessages = [...messages];
    newMessages.push({
      "text": newMessage,
      "type": "outgoing"
    });
    newMessages.push({
      "text": "Chatbot003",
      "type": "incomming"
    });
    setMessages(newMessages);
    setNewMessage("");
    
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const openResource = (type,index) => {
    console.log("calling openResource type is : ",type);
    setExtensionType(type);
    setIndexOFMedia(index);
    setIsShowModal(true);
  }
  
  const CloseModalPopup = () => {
    setIsShowModal(false);
  };



  if (!isOpen) return null;

  return (
    <div className="chatbox">
      <div className="chatbox-header">
        <span><Translate textKey="Chatbt004" /></span>
        <p class="m-0" onClick={onClose}>   <img src="../../Assets/Linqskills_Icons/popup-close.svg" class="m-0 cursor-pointer float-end cross-icon2 "></img></p>
      </div>
      {isShowModal && (
          <ViewTutorialPopUpRgsa
            show={isShowModal}
            onHide={CloseModalPopup}
            src={extensionType=="pdf" ? indexOFMedia == 1 ? pdfUrl : "" : indexOFMedia == 1 ? videoUrl1 : videoUrl2}
            extensionType={extensionType}
            showHeading={false}
          />
      )}
      <div className="chatbox-content">
        <div id="chatList">
          <li className="chat incoming">
            <div className="incomingbox"><Translate textKey="Chatbot002" /> <span class="chatbot-text bg-transparent">{loginUserName}</span><br /> <span><Translate textKey="Chatbot001" /></span></div>
          </li>
        </div>

        {
          messages.map((msg,index)=>{
            console.log(`msg at index ${index} : `,msg);
            return(
              <>
              {
                msg.type == "outgoing" ? 
                <div className="chat outgoing d-flex">
                  <div className="outgoingbox">
                   {msg.text}
                  </div>
                </div> : 
                <>
                <div className="resources incomingbox">
                  <p className="mt-2 mb-2"><Translate textKey={msg.text} /></p>
                  <div className="me-2 d-flex">
                    <div className="d-flex">
                      <span onClick={()=> openResource("mp4",1)} className="ms-2 me-2">
                       {/* <FontAwesomeIcon className="fs-4 fw-boldest" icon={faVideo} /> */}
                       <img src="../../Assets/Linqskills_Icons/VideoContentActive-rgsa.png" class=" "></img>
                      </span>
                      <span onClick={()=> openResource("mp4",2)} className="ms-2 me-2">
                       {/* <FontAwesomeIcon className="fs-4 fw-boldest" icon={faVideo} /> */}
                       <img src="../../Assets/Linqskills_Icons/VideoContentActive-rgsa.png" class=" "></img>
                      </span>
                      <span onClick={()=> openResource("pdf",1)} className="ms-2 me-2">
                       {/* <FontAwesomeIcon className="fs-4 fw-boldest" icon={faFilePdf} /> */}
                       <img src="../../Assets/Linqskills_Icons/Resources-Default-rgsa.svg" class=""></img>
                      </span>
                    </div>
                  </div>
                </div>
                </>
              }
              </>
            )
          })
        }
      </div>
      <div className="d-flex m-2 me-3">
        <input
          type="text" className="form-control ms-2"
          value={newMessage}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Type a message..."
        />

        <button className="col btn btn-sm rgs-primarybtn d-flex justify-content-center me-2 ms-1" onClick={handleSendMessage}>
          <p className="align-content-center fs-6 fw-bold">Send</p>
          {/* <FontAwesomeIcon className="ms-1 mt-1" icon={faPaperPlane} /> */}
          <img src="../../Assets/Linqskills_Icons/arrowright-rgsa-active.svg" class="w-auto float-end"></img>        </button>
      </div>
    </div>
  );
}

export default ChatbotQuery;



// my CSS

// .outgoingbox {
//   background: var(--white-bg);
//   border-radius: 10px 0px 10px 10px;
//   padding: 12px 16px;
//   max-width: 75%;
//   color: var(--chatbot-text-color);
//   font-weight: 400;
//   line-height: 20px;
//   font-size: 1rem;
//   box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
// }
// .chatbox .outgoing {
//   margin: 12px 0;
//   justify-content: flex-end;
// }
// .chatbox .outgoing .user-icon {
//   width: 32px;
//   height: 32px;
//   cursor: default;
//   text-align: center;
//   line-height: 32px;
//   align-self: center;
//   margin: 0px 0px 10px 7px;
// }
// .outgoing:after {
//   content: "";
//   position: relative;
//   top: 0px;
//   right: 0px;
//   left: -1px;
//   width: 0px;
//   height: 0px;
//   border: 5px solid #fff;
//   border-left-color: var(--outgoing-msg-bg);
//   border-top-color: var(--outgoing-msg-bg);
//   border-right-color: transparent;
//   border-bottom-color: transparent;
// }




// old code kalidas


// import React, { useState } from 'react';
// import './Chatbox.css';
// import './boxchat.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleRight, faPaperPlane, faTimes, faVideo, faFilePdf  } from '@fortawesome/free-solid-svg-icons';

// function ChatbotQuery({ isOpen, onClose }) { 
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState('');

//   const handleInputChange = (e) => {
//     setNewMessage(e.target.value);
//   };

//   const handleSendMessage = () => {
//     if (newMessage.trim() !== '') {
//       let newMsg = { text: newMessage, type: 'text', resources: [] };

//       if (newMessage.toLowerCase() === 'i have a query') {
//         newMsg = {
//           text: newMessage,
//           type: 'query',
//           description: 'Here are some resources to help with your query:',
//           resources: [
//             { type: 'video', url: 'http://example.com/query-video1-url' },
//             { type: 'video', url: 'http://example.com/query-video2-url' },
//             { type: 'pdf', url: '/TopicDetailsRgsa/s21/18986/undefined/2/pdf' },
//           ],
//         };
//       } else if (newMessage.toUpperCase() === 'GSPT') {
//         newMsg = {
//           text: newMessage,
//           type: 'query',
//           description: 'Here are some resources to help with your query:',
//           resources: [
//             { type: 'video', url: 'http://example.com/gspt-video-url' },
//             { type: 'pdf', url: 'http://example.com/gspt-pdf-url' },
       
//           ],
//         };
//       }

//       setMessages([...messages, newMsg]);
//       setNewMessage('');
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === 'Enter') {
//       handleSendMessage();
//     }
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="chatbox">
//       <div className="chatbox-header">
//         <span>Chatbox</span>
//         <button onClick={onClose}>X</button>
//       </div>
//       <div className="chatbox-content">
//               <div id="chatList">
//                     <li className="chat incoming">
//                       <div className="incomingbox">Hello <span class="chatbot-text bg-transparent">Kumar</span><br /> <span>I'm your personal learning assistant.I'll do my best to help you learn better.</span></div>
//                   </li>
//               </div>
//               {messages.map((msg, index) => (
//             <div key={index} className="message">
//                 <div className="chat outgoing d-flex">
//                     <div className="outgoingbox">
//                           {msg.text}
//                     </div>
//                   </div>
//               {msg.type === 'query' && (
//                 <div className="resources incomingbox">
//                   <p className="mt-2 mb-2">{msg.description}</p>

//                   <div className="me-2 d-flex">
//                   {msg.resources.map((resource, idx) => (
//                     <a
//                       key={idx}
//                       href={resource.url}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                     >
//                       <div className="d-flex">
//                         <span className="ms-2 me-2">
//                           {resource.type === 'video' && (
//                             <FontAwesomeIcon className="fs-4 fw-boldest" icon={faVideo} />
//                           )}
//                       </span>
//                       <span>
//                         {resource.type === 'pdf' && (
//                           <FontAwesomeIcon className="fs-4 fw-boldest" icon={faFilePdf} />
//                         )}
//                       </span>
//                       </div>
//                     </a>
//                   ))}
//                   </div>
//                 </div>
//               )}
//             </div>
//           ))}
//       </div>
//       <div className="d-flex m-2 me-3">
//          <input
//             type="text"  className="form-control ms-2"
//             value={newMessage}
//             onChange={handleInputChange}
//             onKeyPress={handleKeyPress}
//             placeholder="Type a message..."
//           />
        
//         <button className="btn btn-sm btn-primary d-flex justify-content-center me-2 ms-1" onClick={handleSendMessage}> 
//           <span className="align-content-center fs-6 fw-bold">Send</span>
//           <FontAwesomeIcon className="ms-1 mt-1" icon={faPaperPlane} />
//         </button>
//       </div>
//     </div>
//   );
// }

// export default ChatbotQuery;
