import React, { useEffect, useState } from "react";
import LoginForm from "./LoginForm";
import { variables } from "../Shared/Variables";
import TextField from "../Shared/TextField";
import Translate from "../Shared/Translate";
import ActionBtnText from "../Shared/ActionBtnText";
import TestPopup from "../Popups/AlertPopup";

export default function ForgotPassword({ setForgotPassword }) {
  const initialValues = {
    username: "",
    userid: 0,
    password: "",
    firstname: "",
    email: "",
    mobile: "",
    usernametype: "",
    username1: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [successstatus, setStatus] = useState(0);
  const [buttonClass, setbuttonClass] = useState(
    "continue-popup btn disabled-btn w-100"
  );
  const [disabled, setdisabled] = useState(true);
  const [isMultiUser, setisMultiUser] = useState(false);
  const [usernames, setusernames] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [onOkFunction, setOnOkFunction] = useState(null);
  const [onCloseFunction, setOnCloseFunction] = useState(null);
  const [popuptxt, setPopupTxt] = useState("")

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (e.target.value != "") {
      setbuttonClass("continue-popu primary-btn w-100");
      setdisabled(false);
    } else {
      setbuttonClass("continue-popup disabled-btn w-100");
      setdisabled(true);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors({});
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    setShowPopup(true);
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      submit();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "forgotpassword003"; //"Phone number or email Id is required";
    }
    return errors;
  };
  function closeModal() {
    setisMultiUser(false);
  }
  function submit() {
    const errors = {};
    //const obj = encodeURI(formValues.username);
    //fetch(variables.API_URL + 'api/SendPassword?UserName=' + obj)
    fetch(variables.API_URL + "api/SendPassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserName: isMultiUser ? formValues.email : formValues.username,
        Userid: isMultiUser ? formValues.userid : 0,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("result here : ", result);
        if (result.data.retMessage == "Multiple Users") {
          setusernames(result.data.users);
          setisMultiUser(true);
        } else {
          setisMultiUser(false);
          var status =
            result.isSuccess && result.data.retMessage == "Success1"
              ? 1
              : result.isSuccess && result.data.retMessage == "Success2"
                ? 2
                : result.isSuccess && result.data.retMessage == "Success3"
                  ? 3
                  : result.isSuccess && result.data.retMessage == "No user found with the provided details."
                    ? 4
                    : 0;
          var errormsg = "";
          if (result.data.retMessage == "Not Found") {
            var mobReg = /^\d*(?:\.\d{1,2})?$/;
            var emailReg = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
            var errormsg = mobReg.test(formValues.username)
              ? "forgotpassword007"
              : emailReg.test(formValues.username)
                ? "forgotpassword008"
                : "forgotpassword011";
          }
          errors.username = errormsg;
          setFormErrors(errors);
          setStatus(status);
          formValues.username = "";
        }
      });
  }
  function submitMultiUser() {
    fetch(variables.API_URL + "api/SendMultiUserPassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserId: formValues.userid,
        UserName: formValues.username1,
        Password: formValues.password,
        Mobile: formValues.mobile,
        Email: formValues.email,
        FirstName: formValues.firstname,
        UserNameType: formValues.usernametype,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        var status =
          result.isSuccess && result.returnMessage == "Success1"
            ? 1
            : result.isSuccess && result.returnMessage == "Success2"
              ? 2
              : 3;
        setisMultiUser(false);
        //var errormsg = result.returnMessage == "Not Found" ? "forgotpassword011" : "";
        //errors.username = errormsg;
        //setFormErrors(errors);
        setStatus(status);
        formValues.username = "";
      });
  }
  function onChangeValue(event) {
    formValues.password = event.target.getAttribute("data-password");
    formValues.firstname = event.target.getAttribute("data-firstName");
    formValues.email = event.target.getAttribute("data-email");
    formValues.mobile = event.target.getAttribute("data-mobile");
    formValues.usernametype = event.target.getAttribute("data-usernametype");
    formValues.username1 = event.target.getAttribute("data-username");
    formValues.userid = event.target.value;
  }

  const handlePopupOk = () => {
    if (onOkFunction) onOkFunction(); // Execute the dynamic onOk function
    setShowPopup(false); // Hide popup after action
  };

  const handlePopupClose = () => {
    if (onCloseFunction) onCloseFunction(); // Execute the dynamic onClose function
    setShowPopup(false); // Hide popup after action
  };

  return !setForgotPassword ? (
    <LoginForm />
  ) : isMultiUser ? (
    <>
      <div class="row">
        <div class="popup-one p-4 text-lg-left pt-2">
          <img
            src="../../Assets/Linqskills_Icons/popup-close.svg"
            class="float-end cross-icon2 cursor-pointer"
            onClick={closeModal}
          ></img>
          <div class="row pt-3 pb-3">
            <h6 class="mb-3">
              {/*   <img src="../../Assets/Linqskills_Icons/help.svg" class="me-2"></img>*/}
              <img
                src="../../Assets/Linqskills_Icons/select-user.svg"
                class="me-2"
              ></img>
              Select the User/ Login ID
            </h6>

            <p class="h7">
              Choose the Login ID associated with the email or mobile no.
            </p>
          </div>

          {usernames.map((user) => (
            <div
              class="form-check mb-2"
              key={user.userId}
              onChange={onChangeValue}
            >
              <input
                class="form-check-input"
                type="radio"
                name="MultiUserId"
                data-firstname={user.firstName}
                data-password={user.password}
                data-mobile={user.phoneNo}
                data-email={user.email}
                data-usernametype={user.userNameType}
                data-username={user.userName}
                value={user.userId}
                id="rdMultiuserId"
              ></input>
              <label class="form-check-label" for="flexRadioDefault1">
                {user.userName}
              </label>
            </div>
          ))}

          <div class="next-prev-padding d-flex mt-4">
            <button
              type="button"
              onClick={submit}
              class="continue-popup btn primary-btn w-50 m-auto"
            >
              Submit
              {/* <img
                src="../../Assets/Linqskills_Icons/arrow_forwardwhite_ios_24px.svg"
                class="ps-2"
              ></img> */}
            </button>
          </div>
        </div>
      </div>
      <section class="modal overlay"></section>
    </>
  ) : (
    <>
      <div className="row mt-5 heading-text">
        <h3 className="text-center ps-1 pe-1 hin-h3">
          <Translate textKey="forgotpassword001" />
        </h3>
      </div>
      <div id="frmRegisterEGA" className="padding-new">
        <div className="d-block forgot-password mt-3" id="">
          <div id="dvMobileNo" className="mt-1">
            <form
              onSubmit={handleSubmit}
              className="mt-2 col-md-8 col-xl-8 m-auto"
            >
              {
                /* ( !showPopup || successstatus == 0 )&& */
                <TextField
                  type="email"
                  onchange={handleChange}
                  value={formValues.username}
                  name="username"
                  classNm="form-inputOne input-bg"
                  placeholderkey="registration012"
                  textKey="registration006"
                  infoKey="registration012"
                  errorKey={formErrors.username}
                />
              }{" "}
              {
                /*  ( !showPopup || successstatus == 0 ) && */
                <div className="next-prev-padding d-flex ms-2 btnMT">
                  <button
                    type="submit"
                    id="btnSubmit"
                    disabled={disabled}
                    className={buttonClass}
                  >
                    <Translate textKey="forgotpassword005" />
                    <img
                      src="../../Assets/Linqskills_Icons/arrow_forwardgrey.svg"
                      className=""
                    ></img>
                  </button>
                </div>
              }
            </form>
          </div>
          <label style={{ display: "none" }}>
            {successstatus == 1 ? (
              <h5>
                <Translate textKey="forgotpassword010" setvalue={(val) => setPopupTxt(val)} />
              </h5>
            ) : successstatus == 4 ? (
              <h5>
                <Translate textKey="forgotpassword009" setvalue={(val) => setPopupTxt(val)} />
              </h5>
            ) : successstatus == 2 ? (
              <h5>
                <Translate textKey="forgotpassword009" setvalue={(val) => setPopupTxt(val)} />
              </h5>
            ) : (
              <h5>
                <Translate textKey="forgotpassword009" setvalue={(val) => setPopupTxt(val)} />
              </h5>
            )}
          </label>

          {/*    {successstatus > 0 &&
                                <div className="col-md-12">
                                    <div className="shared-details">
                                        <img src="../../Assets/Linqskills_Icons/password-error1.svg" className="error-img1 float-start"></img>
                                        <img src="../../Assets/Linqskills_Icons/password-error2.svg" className="float-end error-img2"></img>
                 
                                       
                                    </div>
                                </div>
                            }


                            <ActionBtnText textKey="forgotpassword006" onClick={() => setForgotPassword(false)} /> */}
        </div>
      </div>

      <div class="popup">
        {" "}
        {showPopup && successstatus > 0 && (
          <TestPopup
            show={showPopup}
            onHide={handlePopupClose}
            onClose={() => setForgotPassword(false)}
            title="Success"
            type="success"
            txt={popuptxt}
            okLabel="BACK TO LOGIN"
          />
        )}
      </div>
    </>
  );
}
