
import TextField from '../Shared/TextField'
import React, { useState, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { variables } from '../Shared/Variables';
import ProgressSteps from "./ProgressSteps";
import LoginForm from "./LoginForm";

import Translate from '../Shared/Translate';
import LanguagePopup from '../Shared/LanguagePopup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Cookies from 'universal-cookie';
import VideoPlayer from "../Shared/VideoPlayer";
import { isMobile } from "react-device-detect";
import RegisterPageError from '../Shared/registerPageError';
import { updateRegisterError } from "../../features/pageError/pageErrorSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateIndex, updateIsUserLoggedIn, updateNodeVal, callingDispose, updateVideo, updateIsDevice, updateIsMobile } from "../../features/CourseIndex/courseIndexSlice";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CustumLoader from '../Shared/CustumLoader';
import TestPopup from '../Popups/AlertPopup';
import axios from 'axios';
export default function Registration(props) {

    const dispatch = useDispatch();
    const initialValues = {
        userid: 0, batchId: 0, languageId: null,
        countryid: 0, phonenumber: "", emailid: "", otp: "", emailotp: "", username: "", fullname: "",
        password: "", confirmpassword: "", stateid: 0, statename: "", cityname: "",
        qualificationid: 0, qualificationname: "", ProjectId: 0, CourseId: 0,
        Step2Active: false, Step3Active: false, Step4Active: false, ContactNo: "", TPLogo: "", TPId: 1
    }

    const cookies = new Cookies();
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isLoader, setIsLoader] = useState(false);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [ipAddress, setIPAddress] = useState('');
    const [qualifications, setQualifications] = useState([]);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const [step4, setStep4] = useState(false);
    const [stepSuccess, setstepSuccess] = useState(false);
    const [loggedIn, setloggedIn] = useState(false);
    const [disabledClass, setdisabledClass] = useState("disabled-btn");
    const [disabled, setdisabled] = useState(true);
    const [showState, setShowState] = useState(false);
    const [stateLabel, setStateLabel] = useState("");
    const [openVideo, setOpenVideo] = useState(false);
    //const [openLanguage, setOpenLanguage] = useState(cookies.get('language')==""?true:false);
    const [openLanguage, setOpenLanguage] = useState(true);
    const [totalLanguages, setTotalLanguages] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [popupType, setPopupType] = useState("prompt");
    const [popupTxt, setPopupTxt] = useState("");
    const [onOkFunction, setOnOkFunction] = useState(null);
    const [onCloseFunction, setOnCloseFunction] = useState(null);
    const [okLabel, setOkLabel] = useState("Ok");
    const [backtoLogin, setBacktoLogin] = useState(false);

    const [userMessage1, setUserMessage1] = useState("");
    const [userMessage2, setUserMessage2] = useState("");
    const [userMessage3, setUserMessage3] = useState("");
    const [ok, setOK] = useState("");
    const [proceed, setProceed] = useState("");
    // const passValidation = {
    //     uperCase: false,
    //     lowerCase: false,
    //     specialChar: false,
    //     numberVal: false,
    //     minLength: false
    // }

    // const [passValidVal, setPassValidVal] = useState(passValidation);
    const [uperCasePass, setUperCasePass] = useState(false);
    const [overAllRegexPass, setOverAllRegexPass] = useState(false);
    const [lowerCasePass, setLowerCasePass] = useState(false);
    const [specialCharPass, setSpecialCharPass] = useState(false);
    const [numberValPass, setNumberValPass] = useState(false);
    const [minLengthPass, setMinLengthPass] = useState(false);

    const [cancel, setCancel] = useState("");
    const [linkBroken, setlinkBroken] = useState(false);

    const [hiddenOTP, sethiddenOTP] = useState("");
    //const [fullname, setfullname] = useState("");
    const [countryname, setCountryName] = useState("");
    var url = window.location.href;
    var registerGUId = url.substring(url.lastIndexOf('/') + 1, url.length);
    const shouldLog = useRef(true);
    const [selectStyle, setSelectStyle] = useState("form-select form-dropdown input-bg select-change-placeholder");
    const [selectStyleEducation, setselectStyleEducation] = useState("form-select form-dropdown input-bg select-change-placeholder");
    var errorCount = 0;

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Define your mobile screen width here
    useEffect(() => {
        const handleWindowSizeChange = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const handlePass = (e) => {
        const { name, value } = e.target;
        let valuee = value.replace(/\s/g, "");

        console.log("e.target.value (no spaces): ", valuee)
        console.log("e.target.value : ", e.target.value);
        setFormErrors({})
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const numberRegex = /[0-9]/;
        const specialCharRegex = /[@!]/;
        

        

        if (uppercaseRegex.test(value)) {
            console.log("inside uppercaseRegex")
            setUperCasePass(true);
        } else if (!uppercaseRegex.test(value)) {
            console.log("inside false uppercaseRegex")
            setUperCasePass(false);
            // setPassValidVal({ ...passValidVal, ["uperCase"]: false });
        }
        if (lowercaseRegex.test(value)) {
            console.log("inside lowercaseRegex")
            setLowerCasePass(true);
            // setPassValidVal({ ...passValidVal, ["lowerCase"]: true });
        } else if (!lowercaseRegex.test(value)) {
            console.log("inside false lowercaseRegex")
            setLowerCasePass(false);
            // setPassValidVal({ ...passValidVal, ["lowerCase"]: false });
        }

        if (numberRegex.test(value)) {
            console.log("inside numberRegex")
            setNumberValPass(true)
            // setPassValidVal({ ...passValidVal, ["numberVal"]: true });
        } else if (!numberRegex.test(value)) {
            console.log("inside false numberRegex")
            setNumberValPass(false)
            // setPassValidVal({ ...passValidVal, ["numberVal"]: false });
        }

        if (specialCharRegex.test(value)) {
            console.log("inside specialCharRegex")
            setSpecialCharPass(true);
            // setPassValidVal({ ...passValidVal, ["specialChar"]: true });
        } else if (!specialCharRegex.test(value)) {
            console.log("inside false specialCharRegex")
            setSpecialCharPass(false);
            // setPassValidVal({ ...passValidVal, ["specialChar"]: false });
        }
        if (value.length > 7) {
            console.log("inside value.length > 7")
            setMinLengthPass(true)
            // setPassValidVal({ ...passValidVal, ["minLength"]: true });
        } else if (value.length < 7) {
            console.log("inside false value.length > 7")
            setMinLengthPass(false)
            // setPassValidVal({ ...passValidVal, ["minLength"]: false });
        }
        
        if (e.target.value != "") {
            setdisabledClass("");
            setdisabled(false)
        }
        else if (e.target.name == "emailid" && formValues.countryid == 102) {
            // console.log("email is not mandotary in this");
        } else {
            setdisabledClass("disabled-btn");
            setdisabled(true)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormErrors({})
        if (name == "password") {
            handlePass(e);
        }
        if (name == "password") {
            const regex = /^\S+$/;

            if (regex.test(value)) {
                console.log("Valid password");
            } else {
                console.log("Password should not contain spaces");
                return
            }
        }

        setFormValues({ ...formValues, [name]: value })
        // console.log("e.target.value : ", e.target.value);
        if (e.target.value != "") {

            setdisabledClass("");
            setdisabled(false)
        }
        else if (e.target.name == "emailid" && formValues.countryid == 102) {
            // console.log("email is not mandotary in this");
        } else {
            setdisabledClass("disabled-btn");
            setdisabled(true)
        }
    };

    const PhonenoKeyUp = (e) => {
        var filter = /^\d*(?:\.\d{1,2})?$/;
        if (!filter.test(e.target.value)) { e.target.value = "" }

    };
    function handleCountryDropdown(event) {
        var index = event.nativeEvent.target.selectedIndex;
        setCountryName(event.nativeEvent.target[index].text);
        // console.log("e,target.value in handleCountryDropdown : ", event.nativeEvent.target[index].value)
        if (event.nativeEvent.target[index].value != 102) {
            setdisabledClass("");
            setdisabled(false)
            formValues.phonenumber = "";
        }
        formValues.countryid = event.nativeEvent.target[index].value;
    }

    function handleEducDropdown(event) {
        var index = event.nativeEvent.target.selectedIndex;
        formValues.qualificationname = event.nativeEvent.target[index].text;

        formValues.qualificationid = event.nativeEvent.target[index].value;
        if (event.nativeEvent.target[index].text == "Select your highest qualification") {

            setselectStyleEducation("form-select form-dropdown input-bg select-change-placeholder")
        }
        else {
            setselectStyleEducation("form-select form-dropdown input-bg select-change-value")

        }
    }
    function handleStateDropdown(event) {
        var index = event.nativeEvent.target.selectedIndex;
        formValues.stateid = event.nativeEvent.target[index].value;
        formValues.statename = event.nativeEvent.target[index].text;
        if (event.nativeEvent.target[index].text == "Select State") {

            setSelectStyle("form-select form-dropdown input-bg select-change-placeholder")
        } else {
            setSelectStyle("form-select form-dropdown input-bg select-change-value")

        }

    }


    useEffect(() => {

        if (shouldLog.current) {
            shouldLog.current = false;
            getCountriesStatesEdu();


        }

    }, [formErrors]);

    useEffect(() => {
        setdisabled(true);
        setdisabledClass("disabled-btn");
    }, [])

    useEffect(() => {
        if (step3) {
            document.getElementById("fname").value = ""
        }
    }, [step3])

    useEffect(() => {
        if (step3) {
            document.getElementById("cPassword").value = ""
        }
    }, [step3])


    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIPAddress(data.ip))
            .catch(error => console.log(error))
    }, []);

    const handleSubmitStep1 = (e) => {
        setdisabled(true);
        setdisabledClass("disabled-btn");
        // e.preventDefault();
        setFormErrors({})
        errorCount = 0;
        setFormErrors(validateStep1(formValues))
        if (countryname != "India") {
            setShowState(false);
        }
        else {
            setShowState(true);
        }

      
        if (errorCount == 0) {
            checkMobileExits();
        }
    };
    const regerateOTP = (e) => {
        getOTP();
    }

    const verifyOTP = async(e)=>{
        try {
            const result = await axios.post(variables.API_URL+'api/VerifyOtp',
                {
                    PhoneNumber: formValues.phonenumber,
                    Email: "",
                    Otp:formValues.otp,
                    Userid:0
                }
            )
            const data = result.data
            console.log("otp verification:"+ JSON.stringify(data.isSuccess));

            return data.isSuccess
        } catch (error) {
            console.log(error)
        }

    }
    const handleSubmitStep2 = async(e) => {
        // e.preventDefault();
        // setFormErrors({})
        //debugger;
        const errorData = await validateStep2(formValues)
        const errors = errorData.errors
        setFormErrors(errors)
        console.log("formValues: " + JSON.stringify(errorData))

        console.log("formValues: " + JSON.stringify(formErrors))
        setdisabled(true);
        setdisabledClass("disabled-btn");

        if (errorData.totalError == 0) {      
                setStep3(true);
                //setfullname("");
                formValues.Step3Active = true;
               setStep2(false);
            
            
        }



    };
    const handleSubmitStep3 = async (e) => {
        // e.preventDefault();
        // setFormErrors({})
        // console.log("at step 2 final one")
        setFormErrors(validateStep3(formValues))
        setdisabledClass("disabled-btn");
        if (errorCount == 0 && isLoader == false) {
            setdisabled(true);
            await setIsLoader(true)
            RegisterUser();
            // setStep4(true);
            // formValues.Step3Active = true;
            // setStep3(false);
        } else {
            setIsLoader(false);
        }

    };
    const handleSubmitStep4 = (e) => {
        // console.log("at step 3rd cont btn")
        // RegisterUser();
    };

    const handlePopupOk = () => {
        if (onOkFunction) onOkFunction(); // Execute the dynamic onOk function
        setShowPopup(false); // Hide popup after action
    };

    const handlePopupClose = () => {
        if (onCloseFunction) onCloseFunction(); // Execute the dynamic onClose function
        setShowPopup(false); // Hide popup after action
    };
    function getOTP() {
        setdisabled(false);
        setdisabledClass("");

        fetch(variables.API_URL + 'api/SendOTP', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                MobileNo: formValues.phonenumber,
                Email: formValues.emailid,
               /*  ContactNo: formValues.ContactNo,
                TPLogo: formValues.TPLogo */
            })
        })
            .then(res => res.json())
            .then((result) => {
                if (result.isSuccess == true) {
                    //sethiddenOTP(result.data.otp);

                    setStep2(true);
                   // setStep3(true);
                    formValues.Step2Active = true;

                }
            }, (error) => {
            })


    }
    function checkMobileExits() {
       
        if (isMobile) {
            localStorage.setItem("isDevice", "mobile");
            dispatch(updateIsDevice('mobile'));
        } else {
            localStorage.setItem("isDevice", "desktop");
            dispatch(updateIsDevice('desktop'));
        }
        fetch(variables.API_URL + 'api/CheckUserExists', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                PhoneNo: formValues.phonenumber == "" ? "" : formValues.phonenumber,
                Email: formValues.emailid == "" ? "" : formValues.emailid,
                CourseId: formValues.CourseId,
                ProjectId: formValues.ProjectId

            })
        })
            .then(response => response.json())
            .then(result => {
                if (result.data.resultId == 2 || result.data.resultId == 3) {
                    setPopupTxt(userMessage2)
                    setOnOkFunction(() => () => submitCourse(result.data.userId)); // Set dynamic function
                    setOnCloseFunction(() => () => clearForm()); // Set dynamic function
                    setShowPopup(true);
                    setPopupType("prompt")
                    setOkLabel("Ok")
                    /*  confirmAlert({
                         message: userMessage2,
                         buttons: [
                             {
                                 label: ok,
                                 onClick: () => submitCourse(result.data.userId),
                                 class: "primary-btn"
 
 
 
                             },
                             {
                                 label: cancel,
                                 onClick: () => clearForm(),
                                 class: "secondary-btn"
 
                             }
                         ]
                     }); */
                }
                else if (result.data.resultId == 4) {
                    setPopupTxt(userMessage3)
                    setOnOkFunction(() => () => getOTP()); // Set dynamic function
                    setOnCloseFunction(() => () => clearForm()); // Set dynamic function
                    setShowPopup(true);
                    setPopupType("prompt")
                    setOkLabel("Ok")
                  /*   confirmAlert({
                        message: userMessage3,
                        buttons: [
                            {
                                label: ok,
                                onClick: () => getOTP(),
                                class: "primary-btn"

                            },
                            {
                                label: cancel,
                                onClick: () => clearForm(),
                                class: "secondary-btn"

                            }
                        ]
                    }) */;
                }
                else if (result.data.resultId == 1) {
                    //confirmAlert({
                    //    customUI: ({}) => {
                    //        return (
                    //            <div className="react-con(firm-alert">
                    //                <div className="react-confirm-alert-body">{userMessage1}
                    //                    <div className="react-confirm-alert-button-group">
                    //                        <button label="No" onClick={clearForm}>OK</button>
                    //                    </div>
                    //                </div>
                    //            </div>
                    //        );
                    // }
                    //});
                    setPopupTxt(userMessage1)
                    setOnOkFunction(() => () => proccedToLogin(formValues.phonenumber)); // Set dynamic function
                    setOnCloseFunction(() => () => clearForm()); // Set dynamic function
                    setShowPopup(true);
                    setPopupType("prompt")
                    setOkLabel("Proceed")

                 /*    confirmAlert({
                        message: userMessage1,
                        buttons: [
                            {
                                label: proceed,
                                onClick: () => proccedToLogin(formValues.phonenumber),
                                class: "primary-btn"

                            },
                            {
                                label: cancel,
                                onClick: () => clearForm(),
                                class: "secondary-btn"

                            }
                        ]
                    }) */;
                }

                else {
                    getOTP();

                }

            });
    }

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    function generateString() {
        let result = ' ';
        const charactersLength = characters.length;
        for (let i = 0; i < 16; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }


    const createSession = async () => {
        // console.log("session created")
        try {
            let url = variables.API_URL + 'api/InserUserLoginDetails'
            const sessionToken = generateString();
            /*  const SIGNING_KEY = 'lInQsKiLlS';
            const sessionToken = generateSessionToken(SIGNING_KEY); 
            // console.log(sessionToken,"session")   */

            const data = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    userId: localStorage.getItem('login-userId'),
                    sessionID: sessionToken,
                    ipAddress: ipAddress,
                    deviceInfo: isMobile ? "Mobile" : "Desktop"

                })
            })

            const res = await data.json();
            if (res.isSuccess) {

                cookies.set('sessionId', sessionToken)
            }
            // console.log(res, "insert details")

        } catch (error) {
            // console.log(error)
        }

    }

    function proccedToLogin(mobileNo) {
        // alert("navigate to login page")
        dispatch(updateIsMobile(mobileNo));
        setBacktoLogin(true);
    }
    function clearForm() {
        // formValues.phonenumber = "";
        // formValues.emailid = "";
        // setFormValues({ ...formValues, "phonenumber": "" })
        // setFormValues({ ...formValues, "emailid": "" })
        setdisabled(true);
    }

    // console.log("formValues : ", formValues)
    async function setUserLogin(userid) {
        localStorage.setItem("login-userId", userid)
        await dispatch(updateIndex('CO'));
        await dispatch(updateNodeVal([]));
        await dispatch(updateIsUserLoggedIn(true));
        await dispatch(callingDispose(false));
        createSession().then(() => {
            setloggedIn(true)

        })
    }
    async function setUserLoginReg() {
        localStorage.setItem("login-userId", formValues.userid)

        await dispatch(updateIndex('CO'));
        await dispatch(updateNodeVal([]));
        await dispatch(callingDispose(false));
        await dispatch(updateIsUserLoggedIn(true));
        createSession().then(() => {
            setloggedIn(true)
        })
    }
    function submitCourse(UserId1) {
        if (formValues.batchId != 0) {
            fetch(variables.API_URL + `api/AddUserToBatch?UserId=${UserId1}&CourseId=${formValues.CourseId}&ProjectId=${formValues.ProjectId}&batchID=${formValues.batchId}`, {
                method: 'POST',
                // headers: {
                //     'Accept': 'application/json',
                //     'Content-Type': 'application/json'
                // },
            })
                .then(res => res.json())
                .then((result) => {
                    // console.log("result in api/AddUserToBatch : ", result);
                    var msg = result.isSuccess == true ? "User enrolled successfully!!" : "Something went wrong..Please try Again.";
                    localStorage.clear();
                    localStorage.setItem("JWTToken", result.jwtToken)
                    localStorage.setItem("courseIdRegisterFor", formValues.CourseId);
                    setPopupTxt(msg)
                    setOnCloseFunction(() => () => result.isSuccess == true ? setUserLogin(UserId1) : clearForm()); // Set dynamic function
                    //setOnCloseFunction(() => () => clearForm()); // Set dynamic function
                    setShowPopup(true);
                    setPopupType("success")
                    setOkLabel("Ok")
                    /*    confirmAlert({
                           message: msg,
                           buttons: [
                               {
                                   class: "primary-btn",
                                   label: 'OK',
                                   onClick: () => result.isSuccess == true ? setUserLogin(UserId1) : clearForm()
                               }
                           ]
                       }); */
                }, (error) => {
                })
        } else {
            fetch(variables.API_URL + 'api/AddUserCourse', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    UserId: UserId1,
                    CourseId: formValues.CourseId,
                    ProjectId: formValues.ProjectId,
                })

            })
                .then(res => res.json())
                .then((result) => {
                    var msg = result.isSuccess == true ? "User enrolled successfully!!" : "Something went wrong..Please try Again.";
                    localStorage.clear();
                    localStorage.setItem("JWTToken", result.jwtToken)
                    setPopupTxt(msg)
                    setOnCloseFunction(() => () => result.isSuccess == true ? setUserLogin(UserId1) : clearForm()); // Set dynamic function
                    //setOnCloseFunction(() => () => clearForm()); // Set dynamic function
                    setShowPopup(true);
                    setPopupType("success")
                    setOkLabel("Ok")
                   /*  confirmAlert({
                        message: msg,
                        buttons: [
                            {
                                class: "primary-btn",
                                label: 'OK',
                                onClick: () => result.isSuccess == true ? setUserLogin(UserId1) : clearForm()
                            }
                        ]
                    }) */;
                }, (error) => {
                })
        }


    }

    // function submitCourse(UserId1) {
    //     fetch(variables.API_URL + 'api/AddUserCourse', {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             UserId: UserId1,
    //             CourseId: formValues.CourseId,
    //             ProjectId: formValues.ProjectId,
    //         })

    //     })
    //         .then(res => res.json())
    //         .then((result) => {
    //             var msg = result.isSuccess == true ? "User enrolled successfully!!" : "Something went wrong..Please try Again.";
    //             localStorage.clear();
    //             localStorage.setItem("JWTToken", result.jwtToken)

    //             confirmAlert({
    //                 message: msg,
    //                 buttons: [
    //                     {
    //                         label: 'OK',
    //                         onClick: () => result.isSuccess == true ? setUserLogin(UserId1) : clearForm()
    //                     }
    //                 ]
    //             });
    //         }, (error) => {
    //         })

    // }
    // console.log("main lang id : ", props.mailLangId);
    function RegisterUser() {

        var obj = JSON.stringify({
            languageID: props.mailLangId,
            mobileNo: formValues.phonenumber,
            email: formValues.emailid,
            fullName: formValues.fullname,
            password: formValues.confirmpassword,
            countryName: countryname,
            countryId: formValues.countryid,
            stateId: countryname == "India" ? formValues.stateid : 0,
            qualificationId: formValues.qualificationid,
            qualificationName: formValues.qualificationname,
            blockName: formValues.cityname,
            districtName: formValues.cityname,
            stateName: countryname == "India" ? formValues.statename : "",
            courseId: formValues.CourseId,
            projectId: formValues.ProjectId,
            otp: hiddenOTP,
            ContactNo: formValues.ContactNo,
            TPLogo: formValues.TPLogo,
            TPId: formValues.TPId,
            batchId: formValues.batchId
        });
        // console.log("register body : ", obj)

        fetch(variables.API_URL + 'api/RegisterUser', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                languageID: props.mailLangId,
                mobileNo: formValues.phonenumber,
                email: formValues.emailid,
                fullName: formValues.fullname,
                password: formValues.confirmpassword,
                countryName: countryname,
                countryId: formValues.countryid,
                stateId: countryname == "India" ? formValues.stateid : 0,
                qualificationId: formValues.qualificationid,
                qualificationName: formValues.qualificationname,
                blockName: formValues.cityname,
                districtName: formValues.cityname,
                stateName: countryname == "India" ? formValues.statename : "",
                courseId: formValues.CourseId,
                projectId: formValues.ProjectId,
                otp: hiddenOTP,
                ContactNo: formValues.ContactNo,
                TPLogo: formValues.TPLogo,
                TPId: formValues.TPId,
                batchId: formValues.batchId
            })

        })
            .then(res => res.json())
            .then((result) => {
                if (result.isSuccess && result.returnMessage == "success") {
                    localStorage.clear();
                    localStorage.setItem("JWTToken", result.jwtToken);
                    formValues.username = result.userName;
                    setStep4(false);
                    setstepSuccess(true);
                    formValues.userid = result.userId;
                    setIsLoader(false)
                    setdisabled(false);
                } else {
                    // console.log("some error occured")
                    alert("some error occured while registering user");
                    setIsLoader(false)
                    setdisabled(false);
                }
            }, (error) => {
                setIsLoader(false)
                setdisabled(false);
            })

    }

    const validateStep1 = (values) => {
        const errors = {}
        var totalError = 0;
        const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phoneReg = /^[6-9]/;


        if (countryname == "India" && !values.phonenumber) {
            errors.phonenumber = "registration005";//"Phone number is required";
            totalError++;
        }

        if (countryname == "India" && values.phonenumber.length < 10) {
            errors.phonenumber = "registration055";
            totalError++;
        }
        if (countryname == "India" && !phoneReg.test(values.phonenumber)) {
            errors.phonenumber = "registration011";
            totalError++;
        }

        if (countryname != "India" && !values.emailid) {
            errors.emailid = "registration007";
            totalError++;
        }
        if ((values.emailid != "" && !emailReg.test(values.emailid))) {
            errors.emailid = "registration012";
            totalError++;
        }

        // console.log("totalError in step 1 : ", totalError);

        errorCount = totalError;
        return errors;
    }

    const validateStep2 = async(values) => {
        const errors = {}
        var totalError = 0;
        const isValidOtp =  await verifyOTP();
        console.log("valid otp: "+JSON.stringify(isValidOtp))
        if(!isValidOtp){
            errors.emailotp = "registration024";
            totalError++;
            console.log("error: "+totalError);

        }
        if (!values.otp && formValues.countryid == "102") {
            errors.otp = "registration019";//"Phone number is required";
            totalError++;
            // console.log("Indside VL2 !values.otp && formValues.countryid == 102")
        }
       else if (!isValidOtp && formValues.countryid == "102") {
            errors.otp = "registration023";
            totalError++;
            // console.log("Indside VL2 hiddenOTP != values.otp")
        } 
        if (!values.emailotp && formValues.countryid != "102") {
            errors.emailotp = "registration022";//"Phone number is required";
            totalError++;
            // console.log("Indside VL2 !values.emailotp")
        }
        else if (hiddenOTP != values.emailotp && formValues.countryid != "102") {
            errors.emailotp = "registration024";
            totalError++;
            // console.log("Indside VL2 hiddenOTP != values.emailotp")
        }

        // console.log("totalError in valdation 2 : ", totalError);
        errorCount = totalError;

        return {errors,totalError};
    }
    const validateStep3 = (values) => {
       values.fullname =  values.fullname.trim();
        const errors = {};
        var passReg = /^(?!.*\s)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!])[A-Za-z\d@!]{8,12}$/;
        var nameReg = /^[a-zA-Z ]+$/;
        var totalError = 0;
        if (!values.fullname) {
            errors.fullname = "registration056";
            totalError++;
        }
        if (values.fullname.length < 3) {
            errors.fullname = "registration056";
            totalError++;
        } else {
            const firstThreeLetters = values.fullname && values.fullname.slice(0, 3);
            // console.log("firstThreeLetters : ", firstThreeLetters);
            const areFirstThreeLettersNotSpaces = firstThreeLetters && !firstThreeLetters.includes(' ');
            // // console.log("areFirstThreeLettersNotSpaces : ",areFirstThreeLettersNotSpaces);
            if (!areFirstThreeLettersNotSpaces) {
                // alert("inside areFirstThreeLettersNotSpaces")
                errors.fullname = "registration056";
                totalError++;
            } else {
                const firstLetter = values.fullname && values.fullname.slice(0, 1);
                let regexForSpecialCharacter = /^[a-zA-Z0-9]+$/;
                const areFirstThreeLettersAlphanumeric = firstLetter && regexForSpecialCharacter.test(firstLetter);
                // // console.log("areFirstLetterAlphanumeric : ", areFirstThreeLettersAlphanumeric);
                if (!areFirstThreeLettersAlphanumeric) {
                    // alert("inside areFirstThreeLettersAlphanumeric")
                    errors.fullname = "registration056";
                    totalError++;
                } else {
                    const allowedSpecialCharacters = /^[a-zA-Z !`-]+$/;
                    const isValidName = values.fullname && allowedSpecialCharacters.test(values.fullname);
                    // console.log("isValidName : ", isValidName)
                    if (!isValidName) {
                        // alert("inside isValidName")
                        errors.fullname = "registration056";
                        totalError++;
                    } else {
                        if (!nameReg.test(values.fullname)) {
                            // alert("inside here")
                            errors.fullname = "registration056";
                            values.fullname = "";
                            // totalError++;
                        }
                    }
                }
            }
        }
        const combinedRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@!]).+$/;
       

        if (!passReg.test(values.password)) {

            if (!combinedRegex.test(values.password)) {
                console.log("inside combinedRegex")
                setOverAllRegexPass(true);
                errors.password = "registration030";
            }
            errors.password = "registration030";
            totalError++;

        }
        if (values.password != values.confirmpassword) {
            errors.confirmpassword = "registration032";
            totalError++;

        }
        if (values.password.length < 8) {
            errors.password = "registration027";
            totalError++;

        }
        if (!values.password) {
            errors.password = "Login005";
            totalError++;

        }
        if (!values.confirmpassword) {
            errors.confirmpassword = "registration029";
            totalError++;

        }

        errorCount = totalError;

        return errors;
    }
    function closeVideo() {
        setOpenVideo(false);
        setOpenLanguage(false);

    }
    function okLanguage() {
        setOpenVideo(true); setOpenLanguage(false)
    }

    function getCountriesStatesEdu() {
        // console.log("registerGUId in : ", registerGUId)
        const encodedValue = encodeURIComponent(registerGUId);
        // alert(variables.API_URL + 'api/GetCountriesStates/' + encodedValue)
        fetch(variables.API_URL + 'api/GetCountriesStates/' + encodedValue,
            {
                method: 'GET'
            }
        )
            .then(response => response.json())
            .then(result => {
                if (result.isSuccess) {
                    // // console.log("resultdata",result.data)
                    if (result.data.errorMessage == "") {
                        formValues.countryid = result.data.countries[0].countryId;
                        setCountryName(result.data.countries[0].countryName);
                        formValues.CourseId = result.data.courseId;
                        formValues.ProjectId = result.data.projectId;
                        formValues.ContactNo = result.data.contactNo;
                        formValues.TPLogo = result.data.tpLogo;
                        formValues.TPId = result.data.tpId;
                        formValues.batchId = result.data.batchId;
                        setShowState(true);
                        setCountries(result.data.countries);
                        setStates(result.data.states);
                        setQualifications(result.data.qualifications);
                    }
                    else {
                        setlinkBroken(true);
                        dispatch(updateRegisterError({ errorMess: result.data.errorMessage, contact: result.data.contactNo }))
                        // alert(result.data.errorMessage)
                    }


                }

            });
    }

    //set language variables
    const setState = (stateLabel) => {
        setStateLabel(stateLabel);
    };
    const [educLabel, setEducLabel] = useState("");
    const setEduc = (educLabel) => {
        setEducLabel(educLabel);
    };
    const getUserMessage1 = (userMessage1) => {
        setUserMessage1(userMessage1);
    };
    const getUserMessage2 = (userMessage2) => {
        setUserMessage2(userMessage2);
    };
    const getUserMessage3 = (userMessage3) => {
        setUserMessage3(userMessage3);
    };
    const getOK = (ok) => {
        setOK(ok);
    };
    const getProceed = (val) => {
        setProceed(val);
    };
    const getCancel = (cancel) => {
        setCancel(cancel);
    };
    if (loggedIn) {
        return <Navigate to="/mycourses" />;
    }
    else if (backtoLogin) {
        return <Navigate to="/" />;
    }
    else if (linkBroken) {

        return <Navigate to="/registerPageError" />;
        // return(
        //     <RegisterPageError></RegisterPageError>
        // )
    }
    else {
        // console.log("countires : ", countries);
        // console.log("formValues : ", formValues);
        console.log("disabled : ", stepSuccess);
        return (

            stepSuccess ?
                <>
                    <div className="row mt-5 heading-text">
                        <h3 className="text-center ps-1 pe-1"><Translate textKey="registration041" /></h3>

                    </div>
                    <div class="col-md-12 text-center pt-3">
                        {
                            formValues.countryid == "102" ?
                                <img src="../../Assets/Linqskills_Icons/check_circle.svg" class=""></img>
                                :
                                <img src="../../Assets/Linqskills_Icons/check_circle.svg" class=""></img>
                        }
                    </div>
                    <div class="row text-center m-auto register-success mb-3">
                        <h5 class="mt-3 mb-1 bold-700"><Translate textKey="registration042" /></h5>
                        <h6 class=""><Translate textKey="registration043" />
                            {/* {
                                formValues.phonenumber != "" && formValues.emailid != "" ?


                                    <Translate textKey="registration051" /> :
                                    formValues.phonenumber != "" ?
                                        // <Translate textKey="registration044" /> 
                                        ""
                                        :
                                        formValues.emailid != "" ?
                                            <Translate textKey="registration050" />
                                            : null
                            } */}
                        </h6>
                        <div class="d-flex align-items-baseline justify-content-center">
                        <h6 class="mt-3"><Translate textKey="registration045" /> </h6>
                        <h5 class="bold-700 ms-2">{formValues.username}</h5>
                        </div>
                        <div class="d-flex align-items-baseline justify-content-center">
                        <h6 class="mt-0"><Translate textKey="registration046" /> </h6>
                        <h5 class="bold-700 ms-2">{formValues.password}</h5>
                        </div>
                       
                    
                        
                        <h6 class="mt-1"><Translate textKey="registration047" /></h6>
                        <div className="next-prev-padding d-flex ms-2 mt-3 mb-5">
                            <button id="btnSubmit" type="button" disabled={disabled} onClick={setUserLoginReg} className={`continue-popup primary-btn w-75 m-auto ${disabled && disabledClass}`}><Translate textKey="registration048" />   <img src="../../Assets/Linqskills_Icons/arrow_forwardwhite.svg" class=""></img></button>
                        </div>
                    </div>
                </>
                :
                <>
                    {showPopup && (
                        <TestPopup
                            show={showPopup}
                            onHide={handlePopupClose}
                            onOk={handlePopupOk}
                            onClose={handlePopupClose}
                            title={popupType == 'prompt' ? "Warning" : "Success"}
                            txt={popupTxt}
                            type={popupType}
                            okLabel={okLabel}
                        />
                    )}

                    {isLoader && <CustumLoader message="coursecontent006" />}

                    <div className="row mt-5 heading-text">
                        <h3 className="text-center ps-1 pe-1"><Translate textKey="registration040" /></h3>
                    </div>
                    <ProgressSteps Step2Active={formValues.Step2Active} Step3Active={formValues.Step3Active} Step4Active={formValues.Step4Active} />
                    <div className="padding-new">
                        {
                            step2 ?
                                <div class="Step2">
                                    <div className="d-block mt-3" id="">
                                        <div id="dvMobileNo" className="mt-1">

                                            <form className="mt-2 col-md-8 col-xl-8 m-auto">
                                                {
                                                    formValues.phonenumber != "" ?
                                                        <>
                                                            <TextField type="text"
                                                                onchange={handleChange} name="otp"
                                                                value={formValues.otp}
                                                                classNm="change-one"
                                                                isMandate={true}
                                                                textKey="registration017"
                                                                errorKey={formErrors.otp}
                                                                infoKey=""
                                                                maxlength="4"
                                                                placeholderkey="registration018"
                                                            />
                                                            {!formErrors.otp &&
                                                                <div class="d-flex form-check p-0">
                                                                    <div class="div">
                                                                        <img src="../../Assets/Linqskills_Icons/info.svg" class=""></img>
                                                                    </div>
                                                                    <div class="div pt-2">
                                                                        <p class="form-check-label h7 info-msg mb-0" for="exampleCheck1"><Translate textKey="registration019" /> </p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </> : ""
                                                }

                                                {
                                                    formValues.emailid != "" ?
                                                        <>
                                                            <TextField type="text"
                                                                onchange={handleChange} name="emailotp"
                                                                value={formValues.emailotp}
                                                                isMandate={true}
                                                                classNm="change-one"
                                                                textKey="registration054"
                                                                errorKey={formErrors.emailotp}
                                                                infoKey=""
                                                                maxlength="4"
                                                                placeholderkey="registration018"
                                                            />
                                                            {!formErrors.emailotp &&
                                                                <div class="d-flex form-check p-0">
                                                                    <div class="div">
                                                                        <img src="../../Assets/Linqskills_Icons/info.svg" class=""></img>
                                                                    </div>
                                                                    <div class="div pt-2">
                                                                        <p class="form-check-label h7 info-msg mb-0" for="exampleCheck1"><Translate textKey="registration022" /> </p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </> : ""
                                                }

                                                <h6 className="text-center ms-3 mt-4 bold-500 text-gradient cursor-pointer" onClick={() => regerateOTP()}>
                                                    <Translate textKey="registration020" />
                                                </h6>

                                                <div className="next-prev-padding d-flex btnMT">
                                                    <button id="btnBack" type="button" onClick={() => setStep2(false)} className="continue-popup btn secondary-btn me-3 w-100">
                                                        <img src="../../Assets/Linqskills_Icons/arrow-backblue.svg" class=""></img>
                                                        <Translate textKey="registration021" />


                                                    </button>
                                                    <button id="btnSubmitStep2" type="button" onClick={handleSubmitStep2} disabled={disabled} className={`continue-popup btn primary-btn w-100 ${disabled === true && disabledClass}`}><Translate textKey="registration009" />
                                                        <img src="../../Assets/Linqskills_Icons/arrow_forwardwhite.svg" class=""></img>
                                                    </button>
                                                </div>


                                                <div class="d-flex textbtn-padding">
                                                    {/*<div class="col">
                                                        <h6 className="text-gradient text-uppercase"><Translate textKey="forgotpassword006" /></h6>
                                                    </div>*/}
                                                    <div class="col">
                                                        <h6 className="text-center bold-500 text-gradient" onClick={() => setOpenVideo(true)}>
                                                            <img src="../../Assets/Linqskills_Icons/Play_purple.svg" class="me-2"></img>
                                                            <Translate textKey="registration010" /></h6>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>

                                    </div>
                                </div>
                                :
                                (
                                    step3 ?
                                        <div class="Step3 pb-5">
                                            <div className="d-block mt-3 pb-3" id="">
                                                <div id="dvMobileNo" className="mt-1">
                                                    <div style={{ display: 'none' }}>
                                                        {formValues.fullname}
                                                        {countryname}
                                                        {formValues.cityname}

                                                    </div>
                                                    <form className="mt-2 col-md-8 col-xl-8 m-auto">

                                                        <TextField type="text"
                                                            id="fname"
                                                            onchange={handleChange} name="fullname"
                                                            value={formValues.fullname}
                                                            classNm="change-one"
                                                            isMandate={true}
                                                            textKey="registration025"
                                                            errorKey={formErrors.fullname}
                                                            infoKey=""
                                                            placeholderkey="registration026"
                                                        />
                                                        <TextField type="text"
                                                            onchange={handleChange} name="password"
                                                            value={formValues.password}
                                                            spaceNotAceepted={true}
                                                            isMandate={true}
                                                            classNm="change-one"
                                                            textKey="Login004"
                                                            errorKey={formErrors.password}
                                                            infoKey=""
                                                            placeholderkey="Login005"
                                                        />
                                                        <div class="pt-3 pb-0">


                                                            <div class="form-check ps-4 ms-2">
                                                                {
                                                                    uperCasePass ? <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked></input> : ""
                                                                }
                                                                <label class="form-check-label h7 info-msg" for="flexCheckChecked">
                                                                    At least one UPPERCASE letter
                                                                </label>
                                                            </div>

                                                            <div class="form-check ps-4 ms-2">
                                                                {
                                                                    lowerCasePass ? <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked></input> : ""
                                                                }
                                                                <label class="form-check-label h7 info-msg" for="flexCheckChecked">
                                                                    At least one lowercase letter
                                                                </label>
                                                            </div>

                                                            <div class="form-check ps-4 ms-2">
                                                                {
                                                                    numberValPass ? <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked></input> : ""
                                                                }
                                                                <label class="form-check-label h7 info-msg" for="flexCheckChecked">
                                                                    At least one number value (1,2,3)


                                                                </label>
                                                            </div>
                                                            <div class="form-check ps-4 ms-2">
                                                                {
                                                                    specialCharPass ? <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked></input> : ""
                                                                }
                                                                <label class="form-check-label h7 info-msg" for="flexCheckChecked">
                                                                    Enter either @ or !

                                                                </label>
                                                            </div>


                                                            <div class="form-check ps-4 ms-2">
                                                                {
                                                                    minLengthPass ? <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked></input> : ""
                                                                }
                                                                <label class="form-check-label h7 info-msg" for="flexCheckChecked">
                                                                    Between 8 – 12 characters
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <TextField type="text"
                                                            id="cPassword"
                                                            onchange={handleChange} name="confirmpassword"
                                                            value={formValues.confirmpassword}
                                                            classNm="change-one"
                                                            isMandate={true}
                                                            textKey="registration028"
                                                            errorKey={formErrors.confirmpassword}
                                                            infoKey=""
                                                            placeholderkey="registration029"
                                                        />

                                                        {/* <div class="d-flex form-check p-0">

                                                            <div class="div">
                                                              
                                                                <img src="../../Assets/Linqskills_Icons/info.svg" class=""></img>
                                                            </div>
                                                            <div class="div pt-2">
                                                              
                                                                <p className="form-check-label h7 info-msg" htmlFor="exampleCheck1">
                                                                    <Translate textKey="registration030" />
                                                                </p>
                                                            </div>
                                                        </div>  */}

                                                        {
                                                            isLoader == false ?
                                                                <>
                                                                    <div className="next-prev-padding d-flex btnMT">
                                                                        <button id="btnSubmitStep3" type="button" disabled={disabled} onClick={handleSubmitStep3} className={`continue-popup primary-btn w-100 ${disabled && disabledClass}`}><Translate textKey="registration009" />
                                                                            <img src="../../Assets/Linqskills_Icons/arrow_forwardwhite.svg" class=""></img>
                                                                        </button>
                                                                    </div>
                                                                </> : ''
                                                        }


                                                        {/*<div class="textbtn-padding">
                                                            <h6 className="text-center m-auto text-gradient text-uppercase cursor-pointer"><Translate textKey="forgotpassword006" /></h6>
                                                        </div>*/}

                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                        :
                                        (
                                            step4 ?
                                                <div class="Step4 step-four">
                                                    <div className="d-block mt-3" id="">
                                                        <div id="dvMobileNo" className="mt-1">
                                                            <form className="mt-2 col-md-8 col-xl-8 m-auto">


                                                                <TextField type="text"
                                                                    onchange={handleChange} name="countryname"
                                                                    value={countryname}
                                                                    isMandate={true}
                                                                    classNm="change-one"
                                                                    textKey="registration003"
                                                                    infoKey=""
                                                                    placeholderkey=""
                                                                    disable={true}


                                                                />

                                                                {showState &&
                                                                    <div className="labelWrap">
                                                                        <label style={{ display: 'none' }}>
                                                                            <Translate textKey="registration034" setvalue={setState} />
                                                                        </label>
                                                                        <label class="dropdown-label"><Translate textKey="registration033" /><img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class=""></img></label>
                                                                        <img src="../../Assets/Linqskills_Icons/arrow_down_ios_24px.svg" class="dropdown-arrow"></img>
                                                                        <select class={selectStyle}
                                                                            onChange={handleStateDropdown}
                                                                            name="stateid"
                                                                            aria-label="Default select example">
                                                                            <option selected>{stateLabel}</option>
                                                                            {states.map((state) => (

                                                                                <option key={state.stateId} value={state.stateId}>{state.stateName}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                }
                                                                <TextField type="text"
                                                                    onchange={handleChange} name="cityname"
                                                                    value={formValues.cityname}
                                                                    classNm="change-one"
                                                                    isMandate={true}
                                                                    textKey="registration035"
                                                                    infoKey=""
                                                                    placeholderkey="registration036"
                                                                />

                                                                <div className="labelWrap">
                                                                    <label style={{ display: 'none' }}>
                                                                        <Translate textKey="registration038" setvalue={setEduc} />
                                                                    </label>
                                                                    
                                                                    <label class="dropdown-label"><Translate textKey="registration037" /><img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class=""></img></label>
                                                                    <img src="../../Assets/Linqskills_Icons/arrow_down_ios_24px.svg" class="dropdown-arrow"></img>
                                                                    <select class={selectStyleEducation} aria-label="Default select example"
                                                                        onChange={handleEducDropdown}
                                                                        name="qualificationid">
                                                                        <option selected>{educLabel}</option>
                                                                        {qualifications.map((qual) => (
                                                                            <option key={qual.qualificationId} value={qual.qualificationId}> {qual.qualificationName}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                {/*<div className="form-check mt-2 ps-2 d-flex">*/}
                                                                {/*    */}{/*<img src="../../Assets/Linqskills_Icons/LoginError.svg" className="ms-2 me-2"></img>*/}
                                                                {/*    */}{/*<span className="login-error check-text">Could not find the mobile number</span>*/}
                                                                {/*    <img src="../../Assets/Linqskills_Icons/help.svg" class="me-2 inst-icon"></img>*/}
                                                                {/*    <h6 className="form-check-label check-text ms-4" htmlFor="exampleCheck1">*/}
                                                                {/*        The password should contain atleast one uppercase, lowercase & number.  </h6>*/}
                                                                {/*</div>*/}
                                                                {/*<div className="remember-me form-check">*/}
                                                                {/*    <input type="checkbox" className="form-check-input mt-0" id="chkRememberMe"></input>*/}
                                                                {/*    <h6 className="form-check-label check-text hin-h6" htmlFor="exampleCheck1"> <Translate textKey="Login006" /></h6>*/}
                                                                {/*</div>*/}
                                                                <div className="next-prev-padding d-flex btnMT">
                                                                    <button id="btnSubmitStep4" onClick={handleSubmitStep4} type="button" disabled={disabled} className={`continue-popup btn primary-btn w-100 ${disabled && disabledClass}`}><Translate textKey="registration009" />
                                                                        <img src="../../Assets/Linqskills_Icons/arrow_forwardwhite.svg" class=""></img>
                                                                    </button>

                                                                </div>


                                                            </form>
                                                        </div>

                                                    </div>
                                                </div>
                                                :
                                                <div className="Step1">
                                                    <div style={{ display: 'none' }}>
                                                        <Translate textKey="registration057" setvalue={getUserMessage1} />
                                                        <Translate textKey="registration014" setvalue={getUserMessage2} />
                                                        <Translate textKey="registration049" setvalue={getUserMessage3} />
                                                        <Translate textKey="registration015" setvalue={getOK} />
                                                        <Translate textKey="registration058" setvalue={getProceed} />
                                                        <Translate textKey="registration016" setvalue={getCancel} />

                                                    </div>
                                                    <div className="d-block mt-3" id="">

                                                        <div id="dvMobileNo" className="mt-1">
                                                            <form className="mt-2 col-md-8 col-xl-8 m-auto">
                                                                <div className="labelWrap">


                                                                    <label className="dropdown-label trn"><Translate textKey="registration003" /><img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" className="ms-1"></img></label>
                                                                    <img src="../../Assets/Linqskills_Icons/arrow_down_ios_24px.svg" className="dropdown-arrow"></img>
                                                                    <select name="countryid" onChange={handleCountryDropdown}
                                                                        className="form-select form-dropdown input-bg"
                                                                        aria-label="Default select example">
                                                                        {countries.map((country, index1) => (
                                                                            // country.countryName == "India" ?
                                                                            //    <option selected key={country.countryId} value={country.countryId} > {country.countryName}</option>
                                                                            //    :
                                                                            <option key={country.countryId} defaultValue={country.countryId} value={country.countryId}> {country.countryName}</option>

                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                {
                                                                    formValues.countryid == 102 ?
                                                                        <>
                                                                            <div class="inputwrapper " data-required="+91">
                                                                                {
                                                                                    formValues.countryid == 102 ?
                                                                                        <TextField type="text"
                                                                                            class="inputDataText"
                                                                                            isMandate={true}
                                                                                            onchange={handleChange} name="phonenumber"
                                                                                            maxlength="10"
                                                                                            value={formValues.phonenumber}
                                                                                            classNm="change-one"
                                                                                            textKey="registration004"
                                                                                            errorKey={formErrors.phonenumber}
                                                                                            infoKey=""

                                                                                            keyup={PhonenoKeyUp}
                                                                                            placeholderkey="registration005"
                                                                                        />

                                                                                        : ""
                                                                                }
                                                                            </div>
                                                                        </> :
                                                                        ""
                                                                }


                                                                {
                                                                    formValues.countryid == 102 ?
                                                                        "" :
                                                                        <>
                                                                            <TextField type="text"
                                                                                onchange={handleChange} name="emailid"
                                                                                value={formValues.emailid}
                                                                                classNm="change-one"
                                                                                textKey="registration006"
                                                                                errorKey={formErrors.emailid}
                                                                                infoKey=""
                                                                                placeholderkey="registration007"
                                                                                isIndia={formValues.countryid}
                                                                            />
                                                                            <div class="d-flex form-check p-0">
                                                                                <div class="div">
                                                                                    <img src="../../Assets/Linqskills_Icons/info.svg" class=""></img>
                                                                                </div>
                                                                                <div class="div pt-2">
                                                                                    <p className="form-check-label h7 info-msg" htmlFor="exampleCheck1">
                                                                                        <Translate textKey="registration008" /></p>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                }


                                                                {/*<div className="form-check mt-2 ps-2 d-flex">*/}
                                                                {/*    <img src="../../Assets/Linqskills_Icons/help.svg" class="me-2 inst-icon"></img>*/}
                                                                {/*    <p className="form-check-label ms-4 h7 info-msg" htmlFor="exampleCheck1">*/}
                                                                {/*        <Translate textKey="registration008" />*/}
                                                                {/*    </p>*/}
                                                                {/*</div>*/}


                                                                <div className="next-prev-padding d-flex btnMT ">
                                                                    <button id="btnSubmitStep1" type="button" onClick={() => handleSubmitStep1()} disabled={disabled} className={`continue-popup w-100 ${disabled ? disabledClass : "primary-btn"}`}><Translate textKey="registration009" />
                                                                        <img src="../../Assets/Linqskills_Icons/arrow_forwardwhite.svg" className=""></img>
                                                                    </button>
                                                                </div>
                                                                <div class="d-flex textbtn-padding">
                                                                    {/*<div class="col">
                                                                        <h6 onClick={() => setBacktoLogin(true)} className="text-gradient text-uppercase m-0 mt-2 cursor-pointer"><Translate textKey="forgotpassword006" />
                                                                        </h6>
                                                                    </div>*/}

                                                                    <div class="col">
                                                                        <h6 className="text-center bold-500 text-gradient mt-2 cursor-pointer" onClick={() => setOpenVideo(true)}>
                                                                            <img src="../../Assets/Linqskills_Icons/Play_purple.svg" class="me-2"></img>
                                                                            <Translate textKey="registration010" /></h6>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                        )
                                )
                        }
                    </div>
                    {openLanguage &&
                        <LanguagePopup
                            //   availableLanguages={totalLanguages} 
                            closeEvent={closeVideo}
                            okEvent={okLanguage}
                            mailLangId={props.mailLangId}
                            setMainLangId={props.setMainLangId}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            forRegVideo="true"
                            headerKey="registration002"
                            hideDefaultCheck="true"
                            dontShowClose="true"
                            loginPage={false} />
                    }
                    {
                        openVideo &&
                        <>
                            <div class="row m-2">
                                <div class="popup-one text-lg-left">
                                    <img src="../../Assets/Linqskills_Icons/popup-close.svg" class="mb-2 float-end close-icon" onClick={closeVideo}></img>
                                    <div class="mb-3">
                                        <h6 class="p-0 m-0">
                                            <Translate textKey="registration001" />
                                        </h6>
                                    </div>
                                    {
                                        cookies.get('language') == "Hindi" ?
                                            <VideoPlayer src="https://linqskills.s3.ap-south-1.amazonaws.com/UAT/LH/Data/ImageGallery/18738/videos/Register_hin.mp4" />
                                            :
                                            <VideoPlayer src="https://linqskills.s3.ap-south-1.amazonaws.com/UAT/LH/Data/ImageGallery/18738/videos/Register_eng.mp4" />

                                    }
                                </div>
                            </div>
                            <section class="modal overlay">
                            </section>
                        </>
                    }
                </>
        )
    }
}
