import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

const AdobePdfViewer = (props) => {
  const src = localStorage.getItem("href");
  const navigate = useNavigate();
  const { name, id, batchId, deliveryMode, examMode } = useParams();
  useEffect(() => {
    // Load Adobe PDF Embed API script
    const script = document.createElement("script");
    script.src = "https://documentcloud.adobe.com/view-sdk/main.js";
    script.async = true;
    script.onload = () => {
      if (window.AdobeDC) {
        // Initialize the AdobeDC View
        const adobeDCView = new window.AdobeDC.View({
       //   clientId: "dc36a4ab1eca4146bb2fb21d92df246c",
           clientId: "d754a5b1c3804162ba0e86e2e02e22e4",
          divId: "pdf-viewer-container",
          parentDivId: "pdf-container",
        });

        // Preview the PDF file
        adobeDCView.previewFile(
          {
            content: {
              location: {
                url: src,
                headers: [
                  {
                    values: [
                      {
                        type: "text",
                        value: "d754a5b1c3804162ba0e86e2e02e22e4",
                        key: "API_KEY",
                        enabled: true,
                      },
                      {
                        type: "text",
                        value: ["example_sdk"],
                        key: "META_SCOPE",
                        enabled: true,
                      },
                      {
                        type: "text",
                        value: "",
                        key: "ACCESS_TOKEN",
                        enabled: false,
                      },
                      {
                        type: "text",
                        value: "",
                        key: "PRIVATE_KEY",
                        enabled: false,
                      },
                      {
                        type: "text",
                        value: "",
                        key: "JWT_TOKEN",
                        enabled: false,
                      },
                      {
                        type: "text",
                        key: "TECHNICAL_ACCOUNT_ID",
                        enabled: true,
                      },
                      {
                        type: "text",
                        value: "ims-na1.adobelogin.com",
                        key: "IMS",
                        enabled: true,
                      },
                      {
                        type: "text",
                        value: "CF341E566620C0270A495CE1@AdobeOrg",
                        key: "IMS_ORG",
                        enabled: true,
                      },
                    ],
                    name: "reactlocolhost",
                  },
                ],
              },
            },
            metaData: {
              fileName: props.fileName || "sample.pdf",
            },
          },
          {
            embedMode: "FULL_WINDOW",
            defaultViewMode: "FIT_WIDTH",
            showDownloadPDF: false,
            showPrintPDF: false,
            showLeftHandPanel: false,
            showAnnotationTools: false,
            showPageControls: true,
            showDocumentLinkAnnotation: false,
            exitPDFViewerType: "CLOSE",
          }
        );
        const icons = document.getElementsByClassName(
          ".HAZavG_spectrum--large"
        );
        console.log({ icons });
      } else {
        console.error("AdobeDC is not defined");
      }
    };
    script.onerror = () =>
      console.error("Failed to load the Adobe PDF Embed API script.");
    document.head.appendChild(script);

    // Cleanup script when component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, [props.url, props.fileName]);

  const goBack = () => {
    navigate(
      `/TopicDetails/${name}/${id}/${batchId}/${deliveryMode}/${examMode}`
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <style>{`
      .header {
        position: absolute;
        top: 0px;
        align-items: center;
        box-sizing: border-box;
        display: flex;
        background-color: white;
        justify-content: space-between;
        height: 47px;
        max-height: 47px;
        outline: 0;
        width: 100%;
        padding: 0 16px;
        border-bottom: 1px solid #eaeaea;
        z-index: 1001; 

      }
.header-title {
        flex-grow: 1; 
        color: white; 
                pointer-events: none;


      }
      .close-btn {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 0;
        width: 52px;
        height: 47px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
        pointer-events: auto;
      }
        .close-icon{
        color: #6e6e6e;
        height: 36px;
    width: 36px;
              fill: #6e6e6e;

        }
      `}</style>

      <div className="header">
        <div className="header-title"></div>

        {/* Uncomment this for button visibility */}
        <button className="close-btn" onClick={goBack}>
          <svg
            viewBox="0 0 36 36"
            focusable="false"
            aria-hidden="true"
            role="img"
            class="close-icon"
          >
            <path d="M26.485 6.686L18 15.172 9.515 6.686a1 1 0 0 0-1.414 0L6.686 8.1a1 1 0 0 0 0 1.414L15.172 18l-8.486 8.485a1 1 0 0 0 0 1.414L8.1 29.314a1 1 0 0 0 1.414 0L18 20.828l8.485 8.485a1 1 0 0 0 1.414 0l1.415-1.413a1 1 0 0 0 0-1.414L20.828 18l8.485-8.485a1 1 0 0 0 0-1.414L27.9 6.686a1 1 0 0 0-1.415 0z"></path>
          </svg>
        </button>
      </div>

      <div id="pdf-container">
        <div
          id="pdf-viewer-container"
          style={{ width: "100%", height: "100vh", overflow: "auto" }}
        ></div>
      </div>
    </div>
  );
};

export default AdobePdfViewer;
