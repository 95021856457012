import React, { useEffect, useState } from "react";
import axios from "axios";
import { variables } from "../Shared/Variables";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CustumLoader from "./CustumLoader";

export default function MyPerformance() {
  const [data, setData] = useState([]);
  const userId = localStorage.getItem("login-userId");
  const coursedata = JSON.parse(localStorage.getItem("coursedata"));
  const [isLoader, setIsLoader] = useState(false);
  const [resultData, setResultData] = useState([]);

  const performanceData = async () => {
    setIsLoader(true);
    const data = await axios.post(
      variables.API_URL + "api/GeCandidateDashboardCounts",
      {
        UserId: parseInt(userId),
        BatchId: coursedata.batchId,
        courseId: coursedata.courseNodeId,
        param: null,
        AddonCourseId: 0,
      }
    );
    setIsLoader(false);
    // console.log("data.data.data : ",data.data.data);
    let resultGraphArr = [];
    for(let i=0; i < 3; i++){
      // console.log(`val of ${i} `);
      if(i == 0){
        let obj1 = {
          'TotalClasses': data.data.data.totalVirtualClass,
          'completed': data.data.data.completedVirtualClass,
        }
        resultGraphArr.push(obj1);
      } else if( i == 1){
        let obj2 = {
          'TotalClasses': data.data.data.totalSelfStudySession,
          'completed': data.data.data.completedSelfStudySession,
        }
        resultGraphArr.push(obj2);
      } else if(i == 2){
        let obj3 = {
          'TotalClasses': data.data.data.totalAssessment,
          'completed': 3,
        }
        resultGraphArr.push(obj3);
      }
    }
    // console.log("resultGraphArr after : ",resultGraphArr);
    setResultData(resultGraphArr);
    setData(data.data.data);
  };

  const openSessions = JSON.parse(localStorage.getItem("openSessions"));

  function calculatePercentage(numerator, denominator) {
    // Check for division by zero
    if (denominator === 0) {
      return 0;
    }

    // Calculate and return the percentage
    const percentage = Math.floor((numerator / denominator) * 100);
    return percentage;
  }
  let totalsessions;

  useEffect(() => {
    performanceData();
    totalsessions =
      data.totalAssessment +
      data.totalAssignment +
      data.totalSelfStudySession +
      data.totalVirtualClass;
  }, []);
  // console.log("data: ", data);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    plugins: {
      title: {
        display: true,
        // text: 'Chart.js Bar Chart - Stacked',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const labels = ['Oline Lectures','Self Study', 'Assessment'];
  //   const datssssa = [
  //     { name: "A", x: 30, y: 70 },,
  //     { name: "B", x: 12, y: 88 },
  //     { name: "C", x: 15, y: 85 },
  //     { name: "D", x: 35, y: 65 },
  //     { name: "E", x: 54, y: 46 },
  //     { name: "F", x: 72, y: 28 },
  //     { name: "G", x: 32, y: 68 }
  // ];
  const graphData = {
    labels,
    datasets: [
      // {
      //   label: 'Online lectures',
      //   // data: {x:40, y: 70},
      //   data: labels.map(() => {
      //     return (7)
      //   }),
      //   backgroundColor: 'rgb(255, 99, 132)',
      // },
      {
        label: 'Completed',
        // data: {x:50, y: 20},
        data: labels.map((item,ind) => {
          return (resultData[ind]?.completed);
        }),
        backgroundColor: 'rgb(75, 192, 192)',
      },
      {
        label: 'Not Completed',
        // data: {x:30, y: 46},
        data: labels.map((item,ind) => {
          return (resultData[ind]?.TotalClasses - resultData[ind]?.completed);
        }),
        backgroundColor: 'rgb(255, 0, 0)',
      },
    ],
  };

  return (
    <>
     {/*  <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}

      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
                     {isLoader &&      <CustumLoader message="Loading..." />}

      {data && (
        <div className="performance-cards mb-4">
          {/* need to add this class in the parent componnent col-lg-9 col-sm-12 col-xs-12 content-cards */}
          <div className="profile-heading primary-radius">
            {/* Please call the session heading component here I have just kept static for design understanding  */}
            <div className=" session-heading bg-body border-bottom">
              <div class="d-flex">
                <div class="pink-box">
                  <img
                    src="../../Assets/Linqskills_Icons/performance-multicolorRed.svg"
                    className=""
                  ></img>
                </div>

                <div class="heading-one">
                  <h5 className="mb-0 text-truncate">My Performance</h5>
                </div>
              </div>
            </div>

            <div class="col-md-12 p-4 performance-section pb-2">
              <div class="row p-cardsSection ps-2 mb-3">
                <div class="col-md-4 performance-card p-3">
                  <div class="d-flex mb-4">
                    <div>
                      <img
                        src="../../Assets/Linqskills_Icons/courses26_multicolorRed.svg"
                        className=""
                      ></img>
                    </div>
                    <div>
                      <h5 class="mb-0 mt-1 ms-2">Attendance</h5>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div>
                      <img
                        src={
                          data.selfAttendance < data.batchAttendance
                            ? `../../Assets/Linqskills_Icons/warning24-red.svg`
                            : "../../Assets/Linqskills_Icons/success24_default.svg"
                        }
                        className="mt-2"
                      ></img>
                    </div>
                    <div>
                      <h4 class="mb-0 mt-1 ms-2">{data.selfAttendance}%</h4>
                    </div>
                  </div>
                  <div class="performance-notification mt-1">
                    <span class="bold-500">Batch Attendance :</span>
                    <span>{data.batchAttendance}%</span>
                  </div>
                </div>
              </div>

              {/* {data.totalSession > 0 && (
                <Bar options={options} data={graphData} />
              )} */}


              <div class="heading-one">
                <h4 className="mb-0 text-truncate bold-500">Session Details</h4>
              </div>
              <div class="row p-cardsSection ps-2 mb-3 mt-2">
                {/*all sessions */}
                {data.totalSession > 0 && (
                  <div class="col-md-4 performance-card p-3">
                    <div class="d-flex mb-4">
                      <div>
                        <img
                          src="../../Assets/Linqskills_Icons/courses26_multicolorGreen.svg"
                          className=""
                        ></img>
                      </div>
                      <div>
                        <h5 class="mb-0 mt-1 ms-2">All Sessions</h5>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div>
                        <img
                          src={
                            openSessions.AllSessions > 0
                              ? `../../Assets/Linqskills_Icons/warning24-red.svg`
                              : "../../Assets/Linqskills_Icons/success24_default.svg"
                          }
                          className="mt-2"
                        ></img>
                      </div>
                      <div>
                        <h4 class="mb-0 mt-1 ms-2">
                          {calculatePercentage(
                            data.completedVirtualClass +
                            data.completedAssessment +
                            data.completedSelfStudySession +
                            data.completedAssignment,
                            data.totalSession
                          )}
                          %
                        </h4>
                      </div>
                    </div>
                    <div class="performance-notification mt-1">
                      <span class="bold-500">
                        Completed :
                        {data.completedVirtualClass +
                          data.completedAssessment +
                          data.completedSelfStudySession +
                          data.completedAssignment}{" "}
                        of {data.totalSession}
                      </span>
                    </div>
                  </div>
                )}
                {/* online lectures */}
                {data.totalVirtualClass > 0 && (
                  <div class="col-md-4 performance-card p-3">
                    <div class="d-flex mb-4">
                      <div>
                        <img
                          src="../../Assets/Linqskills_Icons/virtual-multicolorgreen.svg"
                          className=""
                        ></img>
                      </div>
                      <div>
                        <h5 class="mb-0 mt-1 ms-2">Online lectures</h5>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div>
                        <img
                          src={
                            openSessions.VirtualClass > 0
                              ? `../../Assets/Linqskills_Icons/warning24-red.svg`
                              : "../../Assets/Linqskills_Icons/success24_default.svg"
                          }
                          className="mt-2"
                        ></img>
                      </div>
                      <div>
                        <h4 class="mb-0 mt-1 ms-2 bold-500">
                          {calculatePercentage(
                            data.completedVirtualClass,
                            data.totalVirtualClass /* onlinelectures_count */
                          )}
                          %
                        </h4>
                      </div>
                    </div>
                    <div class="performance-notification mt-1">
                      <span class="bold-500">
                        Attended :{data.completedVirtualClass} of{" "}
                        {data.totalVirtualClass}
                      </span>
                    </div>
                  </div>
                )}
                {/* self study */}
                {data.totalSelfStudySession > 0 && (
                  <div class="col-md-4 performance-card p-3">
                    <div class="d-flex mb-4">
                      <div>
                        <img
                          src="../../Assets/Linqskills_Icons/interactivegallery-multicolor.svg"
                          className=""
                        ></img>
                      </div>
                      <div>
                        <h5 class="mb-0 mt-1 ms-2">Self Study</h5>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div>
                        <img
                          src={
                            openSessions.SelfStudy > 0
                              ? `../../Assets/Linqskills_Icons/warning24-red.svg`
                              : "../../Assets/Linqskills_Icons/success24_default.svg"
                          }
                          className="mt-2"
                        ></img>
                      </div>
                      <div>
                        <h4 class="mb-0 mt-1 ms-2">
                          {calculatePercentage(
                            data.completedSelfStudySession,
                            data.totalSelfStudySession
                          )}
                          %
                        </h4>
                      </div>
                    </div>
                    <div class="performance-notification mt-1">
                      <span class="bold-500">
                        Viewed :{data.completedSelfStudySession} of{" "}
                        {data.totalSelfStudySession}
                      </span>
                    </div>
                  </div>
                )}
                {/* assessment */}
                {data.totalAssessment > 0 && (
                  <div class="col-md-4 performance-card p-3">
                    <div class="d-flex mb-4">
                      <div>
                        <img
                          src="../../Assets/Linqskills_Icons/assessment-multicolorgreen.svg"
                          className=""
                        ></img>
                      </div>
                      <div>
                        <h5 class="mb-0 mt-1 ms-2">Assessment</h5>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div>
                        <img
                          src={
                            openSessions.Assessment > 0
                              ? `../../Assets/Linqskills_Icons/warning24-red.svg`
                              : "../../Assets/Linqskills_Icons/success24_default.svg"
                          }
                          className="mt-2"
                        ></img>
                      </div>
                      <div>
                        <h4 class="mb-0 mt-1 ms-2">
                          {calculatePercentage(
                            data.completedAssessment,
                            data.totalAssessment
                          )}
                          %
                        </h4>
                      </div>
                    </div>
                    <div class="performance-notification mt-1">
                      <span class="bold-500">
                        Submitted :{data.completedAssessment} of{" "}
                        {data.totalAssessment}
                      </span>
                    </div>
                  </div>
                )}
                {/* assignment */}
                {data.totalAssignment > 0 && (
                  <div class="col-md-4 performance-card p-3">
                    <div class="d-flex mb-4">
                      <div>
                        <img
                          src="../../Assets/Linqskills_Icons/assignment-multicolorgreen.svg"
                          className=""
                        ></img>
                      </div>
                      <div>
                        <h5 class="mb-0 mt-1 ms-2">Assignment</h5>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div>
                        <img
                          src={
                            openSessions.Assignment > 0
                              ? `../../Assets/Linqskills_Icons/warning24-red.svg`
                              : "../../Assets/Linqskills_Icons/success24_default.svg"
                          }
                          className="mt-2"
                        ></img>
                      </div>
                      <div>
                        <h4 class="mb-0 mt-1 ms-2">
                          {calculatePercentage(
                            data.completedAssignment,
                            data.totalAssignment
                          )}
                          %
                        </h4>
                      </div>
                    </div>
                    <div class="performance-notification mt-1">
                      <span class="bold-500">
                        Submitted :{data.completedAssignment} of{" "}
                        {data.totalAssignment}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
