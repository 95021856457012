
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Breadcrumb from "../CourseIndex/Breadcrumb";
import TopicContent from "../Shared/TopicContent.js";
import HamburgerIcons from "../Shared/HamburgerIcons.js";
import NavOne from "../NavOne";
//import { Redirect } from "react-router-dom";
//import TrainerMessage from '../../components/CourseIndex/TrainerMessage.js';
//import Translate from '../Shared/Translate';
//import Cookies from 'universal-cookie';
//import PopupOne from '../Shared/PopupOne';
import { variables } from '../Shared/Variables';
import axios from "axios";
import Zoom from '../Shared/Zoom';
import TopicsList from "../Shared/TopicsList";
import {
    updateChild,
    updateParent,
    updategrandChild,
    updateggChild,
    updateExtended
} from "../../features/breadcrumb/breadcrumbSlice";
import { updateNavbar } from '../../features/navbar/navbarSlice';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import CompletedTest from './CompletedTest'
import SessionHeading from "../EligibilityCheck/SessionHeading";
import { TimerFormat } from "../generalFunctions/timeFormat";
import NavigationBtns from "./NavigationBtns";
import { apiCallingForCourseStarted } from "../generalFunctions/apiCalling.js";

//import AssessmentInstructions from './AssessmentInstructions';



export default function VirtualSession() {
    const navigate = useNavigate();
    const { id, batchID, name } = useParams();
    const shouldLog = useRef(true);
    const meetingData = localStorage.getItem("meeting");
    // console.log("meetingData if id = 1 : ", JSON.parse(meetingData));
    const userID = localStorage.getItem("login-userId")

    const nodeName = name;
    const nodeID = id;
    
    const comingFrom = useSelector(state=>state.courseindex.index);
    // // console.log("comingFrom get value redux : ",comingFrom)
    var course = JSON.parse(localStorage.getItem('coursedata'));
    var user = JSON.parse(localStorage.getItem('login-userId'));
    const child = useSelector(state => state.childNode)
    const grandchild = useSelector(state => state.breadcrumb.grandchildNode)
    var timeZone = (variables.TimeZone)


    const [vcCompletd, setVcComplete] = useState(true)
    const [topicContentData, setTopicContentData] = useState([]);
    const [isDisplayTopicContent, setTopicContentDisplay] = useState(false);
    const [updateTopicTimeSpent, setUpdateTopicTimeSpent] = useState(false);
    const [vcTitleHeading, setVcTitleHeading] = useState("Meeting Details");
    let [data, setData] = useState([])
    const [topicData, setTopicData] = useState([])
    const [listOfTopics, setTopicsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [showMainDiv, setShowMainDiv] = useState(true);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [videoRecordingAvailDate, setVideoRecordingAvailDate] = useState("");
    const [meetingStartTime, setMeetingStartTime] = useState("");
    const [index, setIndex] = useState(0);

    function addMinutes(date, minutes) {
        // // console.log("date in addMinutes : ",date);
        date.setMinutes(date.getMinutes() + minutes);
        return date;
    }
    

    useEffect(() => {
        // // console.log("Get the current time outside", JSON.parse(meetingData)[0].sessionStart)
        if (id == 2) {
            // console.log("vc seesion is completed")
        } else if (!isButtonEnabled && id != 2 && JSON.parse(meetingData)[0].sessionStart != null) {
            var regex = new RegExp(':', 'g')
            const meetingDataArr = JSON.parse(meetingData)[0].sessionStart.split("T");
            // // console.log("meetingDataArr outside", meetingDataArr);
            const intervalId = setInterval(() => {
                // // console.log("Get the current time inside set interval")
                function isToday(date) {
                    const today = new Date();
               
                    // 👇️ Today's date
                    // // console.log(today);

                    if (today.toDateString() === date.toDateString()) {
                        return true;
                    }

                    return false;
                }
                // // console.log("isToday(new Date('2023-08-11') : ", isToday(new Date(meetingDataArr[0])));

                if (isToday(new Date(meetingDataArr[0]))) {
                    const convertTime12to24 = (time12h) => {
                        const [time, modifier] = time12h.split(' ');
                        let [hours, minutes] = time.split(':');
                        if (hours === '12') {
                            hours = '00';
                        }
                        if (modifier === 'PM') {
                            hours = parseInt(hours, 10) + 12;
                        }

                        return `${hours}:${minutes}`;
                    }
                    // // console.log("convertTime12to24('meetingDataArr[1]') : ", convertTime12to24(`${meetingDataArr[1]} ${meetingDataArr[2]}`));
                    let meetingTimeIn24hrFormat = convertTime12to24(`${meetingDataArr[1]} ${meetingDataArr[2]}`)
                    let targetTime = new Date(); // Create a new date object to avoid modifying the current time
                    targetTime.setMinutes(targetTime.getMinutes() - 5);
                    targetTime = targetTime.toLocaleString('en-US', { hour12: false })
                    // // console.log("targetTime before : ", targetTime);

                    const targetTimeArr = targetTime.split(" ");
                    // // console.log("targetTimeArr line 103 : meetingDataArr[1] :", meetingDataArr[1], targetTimeArr[1]);

                    function strToMins(t) {
                        var s = t.split(":");
                        return Number(s[0]) * 60 + Number(s[1]);
                    }

                    function minsToStr(t) {
                        return Math.trunc(t / 60) + ':' + ('00' + t % 60).slice(-2);
                    }
                    var result = minsToStr(strToMins(meetingDataArr[1]) - strToMins(targetTimeArr[1]));
                    // // console.log("result", result);
                    if (meetingDataArr[1] < targetTimeArr[1]) {
                        // // console.log("result <= 0:59 in condition");
                        if (result <= "0:59") {
                            // Enable the button if the current time is equal to or after the target time
                            setIsButtonEnabled(true);
                            clearInterval(intervalId);
                        } else {
                            // Disable the button if the current time is before the target time
                            setIsButtonEnabled(false);
                        }
                    } else {
                        if (result <= "0:05") {
                            // Enable the button if the current time is equal to or after the target time
                            setIsButtonEnabled(true);
                            clearInterval(intervalId);
                        } else {
                            // Disable the button if the current time is before the target time
                            setIsButtonEnabled(false);
                        }
                    }
                }
            }, 4000);
        } else {
            // console.log("vc session is not scheduled yet sesssionStart is null");
        }
        // Clean up the interval when the component unmounts or the dependencies change

    }, []);
    // // console.log("isButtonEnabled check: ", isButtonEnabled)
    const getSessionData = async () => {
        // /UserId=' + localStorage.getItem('login-userId') + '&BatchId=' + localStorage.getItem('coursedata').batchId + '&courseId=' + localStorage.getItem('coursedata').courseId + '&param=null&AddonCourseId=0'
        const batchId = await course.batchId;

        // // console.log("batch id in body", batchId)
        fetch(variables.API_URL + 'api/TraineeWebinarDetails', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                UserId: localStorage.getItem('login-userId'),
                BatchId: course.batchId,
                CourseId: course.courseId,
                NodeId: id,
                TimeZone: timeZone,
                param: "",
                AddonCourseId: 0
            })
        }).then(response => response.json())
            .then(res => {
                // console.table(JSON.stringify(res.dataList[0]) + "TraineeWebinar Data");
                // // console.log("record of data in getSessionData", res.dataList);
                if (res.isSuccess) {
                    var record = res.dataList;
                    if (record.length > 0) {
                        data = record;
                        setData(data);
                        // console.log("addMinutes(data[0].sessionStart,5 : ",addMinutes(new Date(data[0].sessionStart),5));
                setMeetingStartTime(addMinutes(new Date(data[0].sessionStart),5));
                        localStorage.setItem("webinardetails", null);
                        localStorage.setItem("webinardetails", JSON.stringify(data));
                    }
                }
            }).catch((error) => {
                // console.log(error)
            })
    }

    const GetTopicsList = (nodeName, nodeID, batchID, isDisplayContent) => {
        var userID = localStorage.getItem("login-userId");
        userID = encodeURIComponent(userID);

        fetch(variables.API_URL + 'api/UserSelfStudy',
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    UserID: userID,
                    NodeID: nodeID,
                    BatchID: batchID,
                }),
            })
            .then(response => {
                if (response.statusText == "Unauthorized") {
                    this.setState({ isUnauthorizedUser: true });
                }
                else
                    return response.json();
            })
            .then(data => {
                // // console.log("data in get GetTopicsList ",data)
                if (data.isSuccess) {
                    setTopicsList(data.dataList);
                    if (isDisplayContent) {
                        var lists = data.dataList;
                        lists.map((l) => {
                            var conEng = l.contentInEnglish;

                            conEng = conEng.indexOf("amazonaws.com") < 0 ? conEng.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conEng;

                            conEng = conEng.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/")

                            var conHin = l.contentInHindi;

                            conHin = conHin.indexOf("amazonaws.com") < 0 ? conHin.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conHin;

                            conHin = conHin.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");



                            var conTam = l.contentInTamil;

                            conTam = conTam.indexOf("amazonaws.com") < 0 ? conTam.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conTam;

                            conTam = conTam.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");



                            var conBen = l.contentInBengali;

                            conBen = conBen.indexOf("amazonaws.com") < 0 ? conBen.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conBen;

                            conBen = conBen.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");

                            var conTel = l.contentInTelugu;

                            conTel = conTel.indexOf("amazonaws.com") < 0 ? conTel.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conTel;

                            conTel = conTel.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");


                            var conOdi = l.contentInOdiya;

                            conOdi = conOdi.indexOf("amazonaws.com") < 0 ? conOdi.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conOdi;

                            conOdi = conOdi.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");


                            const updatedItem = {

                                ...l.contentInEnglish = conEng,

                                ...l.contentInHindi = conHin,

                                ...l.contentInTamil = conTam,

                                ...l.contentInBengali = conBen,

                                ...l.contentInTelugu = conTel,

                                ...l.contentInOdiya = conOdi

                            }
                            return updatedItem;
                        });
                        DisplayTopicContent(lists);
                    }
                } else if (id == 2){
                    // setTopicData(JSON.parse(meetingData))
                }
            });
    }

    const handleTopicStatusUpdate = () => {
        // alert("handleTopicStatusUpdate");
        // // console.log("handleTopicStatusUpdate");
        setUpdateTopicTimeSpent(false);

        GetTopicsList(nodeName, nodeID, batchID, false);
    }

    const fetchTopicsData = () => {
        try {

            fetch(variables.API_URL + 'api/UserSelfStudy',
                {
                    method: 'Post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        UserId: localStorage.getItem('login-userId'),
                        BatchId: course.batchId,
                        NodeId: id,
                    }),
                })
                .then(response => {
                    if (response.statusText == "Unauthorized") {
                        this.setState({ isUnauthorizedUser: true });
                    }
                    else
                        return response.json();
                })
                .then(data => {
                    // console.log("data in get topic list",data)
                    if (data.isSuccess) {
                        setTopicData(data.dataList)
                        localStorage.setItem("topicDetails", null);
                        localStorage.setItem("topicDetails", JSON.stringify(data.dataList));
                    } else if (id == 2){
                        // setTopicData(JSON.parse(meetingData))
                    } 

                }).catch((error) => {
                    // console.log(error)

                })


        }
        catch (e) {
            // console.log(e);
        }
    }

    const changeVcStatus = async () => {

        setVcComplete(true);
    }

    const DisplayTopicContent = (dataList) => {
        // alert("DisplayTopicContent");
        // console.log("dataList in  DisplayTopicContent in virtual session", dataList)
        if (dataList.length > 0) {
            if (dataList.length > 1) {
                var data = dataList.filter((topics) => topics.topicStatus == "Open");
                if (data.length > 0) {
                    // console.log("ppp in vs", data)
                    // console.log("displayTopicContent:", data[0])
                    setTopicContentData(data[0]);
                }
                else {
                    // console.log("aaa in vs")
                    // // console.log("displayTopicContent list:" + dataList[0])
                    setTopicContentData(dataList[0]);
                }
            }
            else {
                // console.log("rrr in vs")
                setTopicContentData(dataList[0]);
            }

            setTopicContentDisplay(true);
        }
    }

    const handleTopicChange = (data, isChange) => {
        if (data === "m") {
            setTopicContentDisplay(false);
        }
        // setUpdateTopicTimeSpent(isChange);

        //// Invoke GetTopicsList() after 2secs, so that the setUpdateTopicTimeSpent is updated first and timespent of the current node gets updated
        //setTimeout(() => {
        else DisplayTopicContent(data);
        //}, 2000);
        CloseHamburger()
    }

    const DisplayHamburger = () => {
        setShowMainDiv(false);
    }

    const HandleHamburgerClick = (data) => {
        setShowMainDiv(data);
    }

    const CloseHamburger = () => {
        setShowMainDiv(true);

    }



    useEffect(() => {  
        dispatch(updateExtended("true"));
        dispatch(updateParent(course.courseName))
        dispatch(updateggChild("Meeting Details"))
        fetchTopicsData();
        setRedirect();
        // console.log("id in use :", id)
        // console.log("setting data for id 1 in data : ", meetingData);
        if (id == 1) {
            // // console.log("setting meeting : ", meetingData);
            if (meetingData == null) {
                navigate("/CourseIndex");
            } else {
                setData(JSON.parse(meetingData));
                // console.log("addMinutes(JSON.parse(meetingData)[0].sessionStart,5 : ",addMinutes(new Date(JSON.parse(meetingData)[0].sessionStart),5));
                setMeetingStartTime(addMinutes(new Date(JSON.parse(meetingData)[0].sessionStart),5));
                localStorage.setItem("webinardetails", meetingData);
            }
        } else if (id == 2) {
            if (JSON.parse(meetingData)[0]?.isVideoRecording == "Yes") {
                const tomorrow = (dt) => {
                    // Creating the date instance
                    let d = new Date(dt);
                    // Adding one date to the present date
                    d.setDate(d.getDate() + 1);
                    let year = d.getFullYear()
                    let month = String(d.getMonth() + 1)
                    let day = String(d.getDate())
                    // Adding leading 0 if the day or month
                    // is one digit value
                    month = month.length == 1 ?
                        month.padStart('2', '0') : month;
                    day = day.length == 1 ?
                        day.padStart('2', '0') : day;
                    // Printing the present date
                    // // console.log(`seeeeeee ${year}-${month}-${day}`);
                    let nextDate = `${year}-${month}-${day}`
                    return nextDate
                }

                const meetingDataArr = JSON.parse(meetingData)[0]?.completedOn.split(" ");
                // console.log("meetingDataArrTomorrow", meetingDataArr);

                const resNextDate = tomorrow(meetingDataArr[0])
                // console.log("resNextDate : ", resNextDate)
                setVideoRecordingAvailDate(resNextDate);
                // tomorrow("2021-02-28")
                // tomorrow("2021-4-30")
            } else {
                // alert("no video record para available");
            }
        } else {
            getSessionData();
            // // console.log("setting content in comming From")
            // setComingFrom("content");
        }
        setLoading(false);
    }, [])

    const setRedirect = () => {

        const endFlag = localStorage.getItem('endSession')
        if (endFlag === 'true') {
            localStorage.setItem("endSession", 'false');
            window.location.reload();
            dispatch(updateNavbar(false));
        }
    }

    useEffect(() => {
        if (shouldLog.current) {
            shouldLog.current = false;
            // console.log("calling get topic list")
            GetTopicsList(nodeName, nodeID, batchID, true);
        }
    }, [updateTopicTimeSpent]);

    // console.log("dataaa[0]", data[0])
    // // console.log("course.courseDuration : ",course)
    // // console.log("nextDate",JSON.parse(meetingData)[0].completedOn.setDate(JSON.parse(meetingData)[0].completedOn.getDate() + 1))
    // // console.log("schdule date : ", moment(data[0]?.sessionStart).format("DD MMMM YYYY"));
    // // console.log("schdule time : ", moment(data[0]?.sessionStart).format("hh:mm A"))
    // // console.log("meetingData[0].duration : ",JSON.parse(meetingData)[0].duration)
    return (
        <>
            {/*   <PopupOne/>*/}
            <NavOne mobileView={true} isDNone={true} />

            <div class="container-fluid body-wrapper bg-default">
                <div class="container p-0 mb-2">
                <Breadcrumb redirectLink="CourseIndex" hamburgerClassSow={showMainDiv} />
                </div>
                <div class="container">
                 
                    <HamburgerIcons showHamburgerIcon={showMainDiv} hamburgerParentCallBack={HandleHamburgerClick} />

                    <div class="course-indexcontent mb-4">
                        <TopicsList sessionType="meeting" setVcTitleHeading={setVcTitleHeading} sessionName={grandchild} mainTitle={course.courseName} subTitle={grandchild} topicsListData={topicData} setTopicData={setTopicData} onTopicClick={handleTopicChange} showHamburger={DisplayHamburger} hideHamburger={CloseHamburger} showHamburgerIcon={showMainDiv} setIndex={setIndex} index={index} />
                        {
                            isDisplayTopicContent ?
                                <TopicContent topicContent={topicContentData} batchId={batchID} onTopicComplete={handleTopicStatusUpdate} isUpdateTopicTimeSpent={updateTopicTimeSpent} showMainDiv={showMainDiv} />
                                :

                                (data?.length > 0) && data[0]?.meetingStatus != "" ?
                                    <div class={`col-lg-9 col-sm-12 col-xs-12 content-cards  ${showMainDiv ? "sas" : "d-none"} `}>
                                        <div class="global-radius">

                                            <SessionHeading name={vcTitleHeading} iconType="vcSession" duration={data[0].duration} startTime={data[0].startTime} meetingStatus={data[0].meetingStatus} completedOn={data[0].completedOn} startDateTime={meetingStartTime} deliveryMode="1"></SessionHeading>
                                            <div class="bg-white bottom-radius meeting-details-text">
                                                <div class="assessment-instructions border-bottom">
                                                   <h6> <span>Meeting <span class="display-desktop">Service</span>  : {data[0].typeNew === 'googlemeet'
                                                        ?
                                                        <span class="b-text">Google Meeting </span>
                                                        :
                                                        <span class="b-text">Zoom Meeting</span>
                                                    }</span>
                                                    </h6>
                                                    <h6>
                                                        <span> 
                                                            <span class="display-desktop">Scheduled</span> Date : <span class="b-text">{moment(data[0].sessionStart).format("DD MMMM YYYY")}  </span>
                                                            </span></h6>
                                                    <h6>
                                                        <span>
                                                        <span class="display-desktop me-1">
                                                            Scheduled  </span>
                                                                <span> Start Time :  </span><span class="b-text">{data[0].startTime}</span>
                                                            </span>
                                                        </h6>
                                                    <h6>Duration :   <span class="b-text">{TimerFormat(data[0].duration)}</span></h6>
                                                    <h6>Attendance :  <span class="b-text">{data[0].attendance == null ? "optional" : "Mandatory"}</span></h6>
                                                    <h6>Video Recording : <span class="b-text">{data[0].isVideoRecording == null ? "No" : "Yes"}</span></h6>
                                                </div>
                                                <div class="row text-center pt-3 pb-3 click-start">
                                                    <h6>Click on the “Join” button to proceed</h6>
                                                    {
                                                        data[0].typeNew == 'customvc'
                                                        ? <a target="_blank" href={data[0].student_join_url}><button disabled={isButtonEnabled ? false : true} class={isButtonEnabled ? "primary-btn m-auto text-uppercase" : "disabled-btn m-auto text-uppercase"}onclick={()=>apiCallingForCourseStarted(userID,batchID)}>Join</button></a> :
                                                        data[0].typeNew !== 'googlemeet'
                                                        ?
                                                        <Zoom isButtonEnabled={isButtonEnabled} props={data[0]}></Zoom>
                                                        :
                                                        <a target="_blank" href={data[0].student_join_url}><button disabled={isButtonEnabled ? false : true} class={isButtonEnabled ? "primary-btn m-auto text-uppercase" : "disabled-btn m-auto text-uppercase"} onclick={()=>apiCallingForCourseStarted(userID,batchID)}>Join</button></a>
                                                    }
                                                </div>
                                                {/* <NavigationBtns></NavigationBtns> */}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    id == 2 ? <div class={`col-lg-9 col-sm-12 col-xs-12 content-cards ${showMainDiv ? "sas" : "d-none"} `}>
                                        <div class="global-radius">
                                            <SessionHeading
                                                name={vcTitleHeading}
                                                duration={JSON.parse(meetingData)[0].duration}
                                                completedOn={JSON.parse(meetingData)[0].completedOn}
                                                meetingStatus="past"
                                                startDateTime={JSON.parse(meetingData)[0].sessionStart} deliveryMode="1"
                                                iconType="vcSession"
                                            >
                                            </SessionHeading>
                                            <CompletedTest recordedVC={JSON.parse(meetingData)[0]?.recordedVC ? JSON.parse(meetingData)[0]?.recordedVC : ""}
                                             dontShowNavBtns={false} topicsListData={topicData} isShow={comingFrom == "CO" ? true : false} Message={JSON.parse(meetingData)[0].isVideoRecording == null ? "The session was completed on" : "The video recording will be made available after"} testCompletedOn={JSON.parse(meetingData)[0].isVideoRecording == null ? JSON.parse(meetingData)[0].completedOn : videoRecordingAvailDate} ImgSrc="../../Assets/Linqskills_Icons/completedImg.svg" />
                                        </div>
                                    </div> :
                                        <div class={`col-lg-9 col-sm-12 col-xs-12 content-cards ${showMainDiv ? "sas" : "d-none"} `}>
                                            <div class="global-radius">
                                                <SessionHeading name={vcTitleHeading} iconType="vcSession" duration={data[0]?.duration} deliveryMode="1"></SessionHeading>
                                                <CompletedTest recordedVC={JSON.parse(meetingData)[0]?.recordedVC} dontShowNavBtns={false} topicsListData={topicData} isShow={comingFrom == "CO" ? true : false} Message="The details will be made available,once the meeting is scheduled." ImgSrc="../../Assets/Linqskills_Icons/vc-notScheduled.svg" />
                                            </div>
                                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )

}





