import React, { useEffect, useState } from "react";

const MasonryLayout = ({ children }) => {
  const [columns, setColumns] = useState([[], [], [], []]); // Default to 4 columns

  // Function to distribute children into columns
  const distributeItems = (childrenArray, numOfColumns) => {
    const cols = Array.from({ length: numOfColumns }, () => []);
    childrenArray.forEach((child, index) => {
      cols[index % numOfColumns].push(child);
    });
    return cols;
  };

  // Effect to adjust columns based on screen size
  useEffect(() => {
    const updateColumns = () => {
      const width = window.innerWidth;
      const childrenArray = React.Children.toArray(children);
      if (width < 600) {
        setColumns(distributeItems(childrenArray, 1)); // 1 column for small screens
      } else if (width < 900) {
        setColumns(distributeItems(childrenArray, 2)); // 2 columns for medium screens
      } else {
        setColumns(distributeItems(childrenArray, 4)); // 4 columns for large screens
      }
    };

    // Initial setup
    updateColumns();

    // Listen for window resizing
    window.addEventListener("resize", updateColumns);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", updateColumns);
  }, [children]);

  // Inline styles for the masonry layout
  const layoutStyle = {
    display: "flex",
    gap: "16px",
  };

  const columnStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    flex: 1,
  };

  return (
    <div style={layoutStyle}>
      {columns.map((column, columnIndex) => (
        <div key={columnIndex} style={columnStyle}>
          {column.map((child, childIndex) => (
            <div key={childIndex}>
              {child} {/* Render the card component here */}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default MasonryLayout;
