

import React, { useEffect, useState } from "react";
import UploadPopup from "./UploadPopup";
import { variables } from '../Shared/Variables';
import SessionHeading from "../EligibilityCheck/SessionHeading";
import axios from "axios";
import TestPopup from "../Popups/TestPopup";


export default function ProfileDetails({ userProfileData, gettingUserProfile, setIshow }) {
  const userId = localStorage.getItem("login-userId")
  const [userProfileImage, setUserProfileImg] = useState("");
  const [fullNameVal, setFullNameVal] = useState(userProfileData?.fullName);
  const [genderVal, setGenderVal] = useState(userProfileData?.gender)
  const [isPopUpShow, setIsPopUpShow] = useState(false);
  const [isBtn, setIsBtn] = useState(true);
  const [isUploadPopup, setIsUploadPopup] = useState(false);
  const [successMess, setSuccessMess] = useState("");
  const [text, setText] = useState("");
  const [titlePrompt, setTitlePrompt] = useState("");
  var prompt = "<h5>SuccessFully Updated</h5>";
  var prompt2 = "<h5>Something went wrong please try again</h5>";
  // // console.log("userProfileData : ", userProfileData);
  const [selectStyle, setSelectStyle] = useState("form-select form-dropdown input-bg select-change-placeholder");

  useEffect(() => {
    setUserProfileImg(userProfileData?.profilePic);
  }, [userProfileData])

  const onCloseIsUploadPopup = (callingFrom) => {
    // // console.log("onCloseIsUploadPopup : ", callingFrom);
    if (callingFrom && callingFrom == "successfull") {
      setIsUploadPopup(false);
      gettingUserProfile()
    } else {
      setIsUploadPopup(false);
    }
  }
  //   const validateStep3 = (values) => {
  //     const errors = {};
  //     var passReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;
  //     var nameReg = /^[a-zA-Z ]+$/;
  //     var totalError = 0;
  //     if (!values.fullname) {
  //         errors.fullname = "registration056";
  //         totalError++;
  //     }
  //     if (values.fullname.length < 3) {
  //         errors.fullname = "registration056";
  //         totalError++;
  //     } else {
  //         const firstThreeLetters = values.fullname && values.fullname.slice(0, 3);
  //         // console.log("firstThreeLetters : ",firstThreeLetters);
  //         const areFirstThreeLettersNotSpaces = firstThreeLetters && !firstThreeLetters.includes(' ');
  //         // // console.log("areFirstThreeLettersNotSpaces : ",areFirstThreeLettersNotSpaces);
  //         if(!areFirstThreeLettersNotSpaces){
  //             // alert("inside areFirstThreeLettersNotSpaces")
  //             errors.fullname = "registration056";
  //             totalError++;
  //         } else {
  //             const firstLetter = values.fullname && values.fullname.slice(0, 1);
  //             let regexForSpecialCharacter = /^[a-zA-Z0-9]+$/;
  //             const areFirstThreeLettersAlphanumeric = firstLetter && regexForSpecialCharacter.test(firstLetter);
  //             // console.log("areFirstLetterAlphanumeric : ",areFirstThreeLettersAlphanumeric);
  //             if(!areFirstThreeLettersAlphanumeric){
  //                 // alert("inside areFirstThreeLettersAlphanumeric")
  //                 errors.fullname = "registration056";
  //                 totalError++;
  //             } else {
  //                 const allowedSpecialCharacters = /^[a-zA-Z !`-]+$/;
  //                 const isValidName = values.fullname && allowedSpecialCharacters.test(values.fullname);
  //                 // console.log("isValidName : ",isValidName)
  //                 if(!isValidName){
  //                     // alert("inside isValidName")
  //                     errors.fullname = "registration056";
  //                     totalError++;
  //                 } else {
  //                     if (!nameReg.test(values.fullname)) {
  //                         alert("inside here")
  //                         errors.fullname = "registration056";
  //                         values.fullname = "";
  //                     }
  //                 }
  //             }
  //         }
  //     }

  //     if (!passReg.test(values.password)) {
  //         errors.password = "registration030";
  //         totalError++;

  //     }
  //     if (values.password != values.confirmpassword) {
  //         errors.confirmpassword = "registration032";
  //         totalError++;

  //     }
  //     if (values.password.length < 8) {
  //         errors.password = "registration027";
  //         totalError++;

  //     }
  //     if (!values.password) {
  //         errors.password = "Login005";
  //         totalError++;

  //     }
  //     if (!values.confirmpassword) {
  //         errors.confirmpassword = "registration029";
  //         totalError++;

  //     }

  //     errorCount = totalError;

  //     return errors;
  // }
  const fullNameHandler = (e) => {
    // // console.log("e.target.value : ", e.target.value)
    if (e.target.value == "") {
      setFullNameVal("");
      setIsBtn(true)
    } else {
      setIsBtn(false)
      let inputValue = e.target.value;
      var nameReg = /^[A-Za-z !`-]+$/;
      if (nameReg.test(e.target.value)) {
        // // console.log("valid name");
        const firstThreeLetters = e.target.value && e.target.value.slice(0, 3);
        // console.log("firstThreeLetters : ", firstThreeLetters);
        const areFirstThreeLettersNotSpaces = firstThreeLetters && !firstThreeLetters.includes(' ');
        // console.log("areFirstThreeLettersNotSpaces in update : ", areFirstThreeLettersNotSpaces);
        if (!areFirstThreeLettersNotSpaces) {
          // // console.log("first three leter cant include space")
        } else {
          const firstLetter = e.target.value && e.target.value.slice(0, 1);
          let regexForSpecialCharacter = /^[a-zA-Z0-9]+$/;
          const areFirstThreeLettersAlphanumeric = firstLetter && regexForSpecialCharacter.test(firstLetter);
          // console.log("areFirstLetterAlphanumeric : ", areFirstThreeLettersAlphanumeric);
          if (!areFirstThreeLettersAlphanumeric) {
            // alert("first leter cant be special character")
          } else {
            setFullNameVal(inputValue);
          }
        }
      } else {
        // // console.log("invalid name")
      }
    }
  }
  const genderHandler = (e) => {
    // // console.log("e.target.value : ", e.target.value);
    if (e.target.value == "") {
      setIsBtn(true)
    }
    var index = e.nativeEvent.target.selectedIndex;
    if (e.nativeEvent.target[index].text == "Select your gender") {
      setSelectStyle("form-select form-dropdown input-bg select-change-placeholder")
    } else {
      setSelectStyle("form-select form-dropdown input-bg select-change-value")
    }
    setIsBtn(false)
    setGenderVal(e.target.value)

  }
  const showUploadPopup = () => {
    setIsUploadPopup(true)
  }
  const isShowHandler = (type) => {
    // // console.log("type : ", type)
    setIshow(type)
  }
  const submitProfileDetails = async (e) => {
    e.preventDefault();
    const newName = fullNameVal.trim()
    setFullNameVal(newName);
    // // console.log("submitProfileDetails called");
    // const profileErrorState = await validateEmailUpdate(emailFormValues)
    // // console.log("profileErrorState : ", profileErrorState);
    // setEmailFormErrors(emailErrorState);
    // if (Object.keys(emailErrorState).length > 0) {
    //   // console.log("solve errors of emailErrorState")
    // } else {

    // }
    setIsBtn(true)
    const bodyForProfile = {
      UserId: userId,
      FullName: newName,
      Gender: genderVal
    }
    const resAfterProfileUpdate = await axios.post(variables.API_URL + "api/UpdateUserProfile", bodyForProfile);
    // // console.log("resAfterProfileUpdate : ", resAfterProfileUpdate);
    if (resAfterProfileUpdate.data.isSuccess) {
      setTitlePrompt("UPDATED");
      setSuccessMess(`<h5>${resAfterProfileUpdate.data.returnMessage}</h5>`)
      setIsPopUpShow(true);
      onCloseIsUploadPopup("successfull")
    } else {
      // // console.log("error while updating profile");
      setIsBtn(false);
    }
  }

  const removePhoto = async () => {
    // console.log("calling remove photo");
    const body = {
      UserId: userId,
      ProfilePic: userProfileData?.profilePic
    }
    // console.log("body for remove photot : ", body);
    const resAfterRemovePhoto = await axios.post(variables.API_URL + "api/RemoveProfilePicture", body);
    // console.log("resAfterRemovePhoto : ", resAfterRemovePhoto);
    if (resAfterRemovePhoto.data.isSuccess) {
      setTitlePrompt("UPDATED");
      setSuccessMess(`<h5>${resAfterRemovePhoto.data.returnMessage}</h5>`)
      setIsPopUpShow(true);
      onCloseIsUploadPopup("successfull")
    } else {
      // console.log("error occurs in remove profile photo")
    }
  }


  return (
    <>
      {
        (isUploadPopup) &&
        <UploadPopup show={isUploadPopup} onClose={onCloseIsUploadPopup} />
      }
      {isPopUpShow &&
        <TestPopup show={true} txt={text == "2" ? prompt2 : successMess} title={titlePrompt} onOk={(e) => setIsPopUpShow(false)} onClose={(e) => setIsPopUpShow(false)} type="success" />
      }
      <div className="col-lg-9 col-sm-12 col-xs-12 content-cards m-0">
        <div className="profile-heading primary-radius">

          <SessionHeading iconType="myProfile" type="profile" name="Profile Details" />
          {/* <div className=" session-heading bg-body border-bottom">
            <div className="d-flex">
              <img src="../../Assets/Linqskills_Icons/profile-details.svg" className=""></img>
              <h5 className="mb-0 mt-1 ms-2 ps-1 ">Profile Details</h5>
            </div>
          </div> */}
          <div class="col-md-12 p-4 profile-details">
            <div class="row">


              <div class="profile-image text-center">
                {
                  userProfileImage?.length > 0 ? <img src={`${variables.AWSPath}${userProfileImage}`} className=""></img> : <img src="../../Assets/Linqskills_Icons/Profile-Large.svg" className=""></img>
                }
              </div>
            </div>

            <div class="row ">
              <div class="col-md-6 m-auto text-center">
                <div class="upload-btns">
                  {userProfileImage?.length > 0 ?
                    <button onClick={removePhoto} class="secondary-btn m-auto">Remove Photo </button> : ""}
                  <button onClick={showUploadPopup} class="primary-btn m-auto">
                    <img src="../../Assets/Linqskills_Icons/uploadfile-btn.svg" class=""></img>
                    Upload Photo </button>

                </div>
              </div>

              <div class="col-md-8 p-3 text-center m-auto">
                <h6 class="mb-1">
                  Choose your profile photo and then click on “Upload Photo” to submit.
                </h6>
                <h6>
                  Format Supported JPG/PNG/GIF.Size not more than 2MB
                </h6>
              </div>

            </div>

            <div class="col-md-12 personal-details">

              <h4>Personal Details</h4>

              <form>
                <div class="parent-form p-details pb-2">
                  <div class="labelWrap col-md-6 col-sm-12">
                    <input name="username" type="text" placeholder="Enter login ID or phone number or email" autocomplete="off" class="form-inputOne input-bg disable-state" value={userProfileData?.userName}></input>
                    <label>Login ID/Username<img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                    </label>
                  </div>
                  <div class="labelWrap col-md-6 col-sm-12">
                    <input name="username" type="text" placeholder="Enter login ID or phone number or email" autocomplete="off" class="form-inputOne input-bg disable-state" value="Participant/Student"></input>
                    <label>Role<img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                    </label>
                  </div>
                </div>
              </form>

              <form>
                <div class="parent-form p-details">
                  <div class="labelWrap col-md-6 col-sm-12">
                    <input name="username" type="text" placeholder="Full Name" autocomplete="off" class="form-inputOne input-bg" onChange={fullNameHandler} value={fullNameVal}></input>
                    <label>Full Name<img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                    </label>
                  </div>
                  <div class="labelWrap col-md-6 col-sm-12">
                    <label class="dropdown-label trn">Gender<img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                    </label>
                    <img src="../../Assets/Linqskills_Icons/arrow_down_ios_24px.svg" class="dropdown-arrow"></img>
                    <select name="countryid" value={genderVal} onChange={genderHandler} class={selectStyle} aria-label="Default select example">
                      <option value="" disabled>Select your gender</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                      <option value="O">Others</option>
                    </select>
                  </div>
                </div>
              </form>
              <div class="col-md-12 text-center p-3 mt-2">
                <button onClick={submitProfileDetails} disabled={isBtn || fullNameVal.length < 3 ? true : false} className={isBtn || fullNameVal.length < 3 ? "disabled-btn" : "primary-btn"}>Submit</button>

              </div>


            </div>


          </div>
        </div>
      </div>





    </>


  )

}


