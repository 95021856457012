import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  index: 'CO',
  filterVal : "",
  nodeData : [],
  rgsaResourceData: [],
  nextPrevData : [],
  onlineLec: [],
  openVideo : false,
  isdisposeCalled : false,
  isNextPrevCalled : false,
  isFilterRetained : false,
  nodeIdForAssesmentGetdata : "",
  dataForCertificate : {},
  certificateFrom : "",
  isBlockCourseIndexAPi : false,
  isNextPrevUpdated : true,
  isUserLoggedIn : true,  
  accordianNo: 0,
  callingGetTopicsList : true,
  isContentNodeVal : true,
  timmerRuning : false,
  guidedLearningStatus : "",
  isDevice : "finding",
  isMobileNo : "",
  videoIndex : "-1",
  activeStage1List : [],
  activeStage4List : [],
  programCourses : [],
  isProgram : false,
};



const courseindexSlice = createSlice({
    name:"courseindex",
    initialState,
    reducers:{
        updateIndex : (state,action)=>{
            // alert("updating index");
            state.index = action.payload
        },
        updateVideoIndex : (state,action)=>{
            // alert("updating index");
            state.videoIndex = action.payload
        },
        updateIsMobile : (state,action)=>{
            // alert("updating index");
            state.isMobileNo = action.payload
        },
        updateFilterVal : (state,action)=>{
            // // console.log("val in reducer updateFilterVal action.payload : ",action.payload);
            state.filterVal = action.payload
        },
        updateNodeVal : (state,action)=>{
            // console.log("working for reset nodeVal");
            state.nodeData = action.payload
        },
        updateRgsaResourceData : (state,action)=>{
            // console.log("working for reset updateRgsaResourceData");
            state.rgsaResourceData = action.payload
        },
        updateNextPrevData : (state,action)=>{
            state.nextPrevData = action.payload
        },
        
        updateOnlineLec : (state,action)=>{
            state.onlineLec = action.payload
        },
        updateVideo : (state,action)=>{
            // // console.log("action performing updating state of open video in reducer : ",action);
            state.openVideo = action.payload
        },
        callingDispose : (state,action)=>{
            // // console.log("calling callingDispose : ",action)
            state.isdisposeCalled = action.payload
        },
        callingNextPrev : (state,action)=>{
            // console.log("calling callingNextPrev : ",action)
            state.isNextPrevCalled = action.payload
        },
        updateFilterRetained : (state,action)=>{
            // // console.log("calling updateFilterRetained : ",action)
            state.isFilterRetained = action.payload
        },
        updateNodeIdForAssGetData : (state,action)=>{
            // // console.log("calling updateFilterRetained : ",action)
            state.nodeIdForAssesmentGetdata = action.payload
        },
        updateDataForCertificate : (state,action)=>{
            // // console.log("calling dataForCertificate : ",action)
            state.dataForCertificate = action.payload
        },
        updateCertificateFrom : (state,action)=>{
            // // console.log("val in reducer updateCertificateFrom action.payload : ",action.payload);
            state.certificateFrom = action.payload
        },
        updateIsBlockCourseIndexAPi : (state,action)=>{
            // // console.log("val in reducer updateIsBlockCourseIndexAPi action.payload : ",action.payload);
            state.isBlockCourseIndexAPi = action.payload
        },
        updateNextPrevUpdated : (state,action)=>{
            // // console.log("calling updateNextPrevUpdated : ",action)
            state.isNextPrevUpdated = action.payload
        },
        updateIsUserLoggedIn : (state,action)=>{
            // console.log("calling updateIsUserLoggedIn : ",action)
            state.isUserLoggedIn = action.payload
        },
        updateAccordianNo : (state,action)=>{
            // // console.log("action performing updating state of open video in reducer : ",action);
            state.accordianNo = action.payload
        },
        updateCallingGetTopicsList : (state,action)=>{
            // // console.log("calling updateNextPrevUpdated : ",action)
            state.callingGetTopicsList = action.payload
        },
        updateIsContentNodeVal : (state,action)=>{
            // // console.log("calling updateNextPrevUpdated : ",action)
            state.isContentNodeVal = action.payload
        },
        updateTimmerRunning : (state,action)=>{
            // // console.log("calling updateNextPrevUpdated : ",action)
            state.timmerRuning = action.payload
        },
        updateIsDevice : (state,action)=>{
            // // console.log("calling updateNextPrevUpdated : ",action)
            state.isDevice = action.payload
        },
        updateActiveStage1List : (state,action)=>{
            // // console.log("calling updateNextPrevUpdated : ",action)
            state.activeStage1List = action.payload
        },
        updateActiveStage4List : (state,action)=>{
            // // console.log("calling updateNextPrevUpdated : ",action)
            state.activeStage4List = action.payload
        },
        updateProgramCourses : (state,action)=>{
            // // console.log("calling updateNextPrevUpdated : ",action)
            state.programCourses = action.payload
        },
        updateIsProgram : (state,action)=>{
            // // console.log("calling updateNextPrevUpdated : ",action)
            state.isProgram = action.payload
        },
    }
})

export const {updateIndex,updateVideoIndex,updateIsMobile,updateFilterVal,updateNodeVal,updateRgsaResourceData,updateNextPrevData,updateVideo,callingDispose,callingNextPrev,updateFilterRetained,updateNodeIdForAssGetData,updateDataForCertificate,updateCertificateFrom,updateIsBlockCourseIndexAPi,updateNextPrevUpdated,updateIsUserLoggedIn,updateAccordianNo,updateCallingGetTopicsList,updateIsContentNodeVal,updateOnlineLec,updateTimmerRunning,updateIsDevice,updateActiveStage1List,updateActiveStage4List,updateProgramCourses,updateIsProgram} = courseindexSlice.actions;


export default courseindexSlice.reducer;