import React, { useEffect, useState } from "react";
import TrainerMessage from "./TrainerMessage";
// import ContinueProgress from "./ContinueProgress";
import ContentAccordion from "./ContentAccordion";
import NavOne from "../NavOne";
import Breadcrumb from "./Breadcrumb";
import Cookies from "universal-cookie";
import EligibilityCoursecard from "../EligibilityCheck/EligibilityCoursecard";
import { variables } from "../Shared/Variables";
import CourseIndexleftbox from "./CourseIndexleftbox";
import { updateChild, updateParent, updateExtended } from "../../features/breadcrumb/breadcrumbSlice";

import VcNotificationCard from "./VcNotificationCard";
import ContinueLearningCard from "./continueLearningCard";
import LanguagePopup from "../Shared/LanguagePopup";
import HamburgerIcons from "../Shared/HamburgerIcons";
import axios from "axios";
import ResourceLibraryIndex from "../ResourceLibrary/ResourceLibraryIndex";
import Certificate from "../Shared/Certificate";
import DownloadCertificate from "../MyCourses/DownloadCertificate";
import OnlineLecturesIndex from "../OnlineLectures/OnlineLecturesIndex";
import { useSelector, useDispatch } from "react-redux";
import { updateIndex, updateFilterVal, updateFilterRetained, updateAccordianNo } from "../../features/CourseIndex/courseIndexSlice";
import { isLanguageUpdated } from "../../features/Language/languageSlice";
import MyPerformance from "../Shared/MyPerformance"
import Translate from '../Shared/Translate';
import FilterOption from "../Shared/FilterOption";
import { useQuery } from "react-query";
import useScrollPosition from "../Shared/ScrollTracker";



function CourseIndex() {
  // useScrollPosition("CourseIndex")
  const dispatch = useDispatch();
  const [showMainDiv, setShowMainDiv] = useState(true);
  const cookies = new Cookies();
  const defaultLanguage = cookies.get('default-language')
  const isLangUpdate = useSelector(state => state.language.isLangUpdate);
  const index = useSelector(state => state.courseindex.index)
  // alert(index);
  const filterValue = useSelector(state => state.courseindex.filterVal);
  const accordianNo = useSelector(state => state.courseindex.accordianNo);
  const nodeValue = useSelector(state => state.courseindex.nodeData);
  const isDevice = useSelector(state => state.courseindex.isDevice);
  // // console.log("course index filterValue : ",filterValue);
  var Lan = localStorage.getItem("prefferedLanguage");
  Lan = Lan != undefined ? Lan.substring(0, 2) : "EN";
  const courseData = JSON.parse(localStorage.getItem("coursedata"));
  const userId = localStorage.getItem("login-userId")
  const [languageChange, setLanguageChange] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(index);
  const [vcNotifications, setVcNotifications] = useState();
  const [assessmentNotifications, setAssessmentNotifications] = useState();
  const [openLanguage, setopenLanguage] = useState(false);
  const [totalLanguages, setTotalLanguages] = useState([]);
  const [dataList, setDataList] = useState([])
  const [title, setTitle] = useState("Content");
  var course = JSON.parse(localStorage.getItem("coursedata"));
  const [filterVal, setFilterVal] = useState(filterValue);
  const [toggle, setToggle] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(accordianNo);
  const [subnodeForContLrn, setSubnodeForContLrn] = useState("");
  const [nodeForContLrn, setNodeForContLrn] = useState("");


  // // console.log("course from session storage : ",course);

  useEffect(() => {
    if (nodeValue.length > 0) {
      const subnodeArray = nodeValue.filter((item) => {
        return item.completionStatus == "Open" && item.nodeClassification == "Session"
      })
      const subnode = subnodeArray[0];
      if (subnode) {
        const nodeArray = nodeValue.filter((item) => {
          return item.nodeClassification == "Module" && item.nodeId == subnode.parentNodeId
        })
        let node = nodeArray[0];
        // console.log("subnode val : ", subnode);
        setSubnodeForContLrn(subnode);
        setNodeForContLrn(node);
      }

    }
  }, [nodeValue])

  const getVcNotificationData = async () => {
    // console.log("calling getVcNotificationData")
    const body = {
      UserId: userId,
      BatchId: course.batchId,
      TimeZone: variables.TimeZone
    }
    // // console.log("body for getVcNotificationData : ", body)
    const resAfterFetchVcData = await axios.post(variables.API_URL + 'api/GetTraineeTodaysWebinarDetails', body);
    // // console.log("resAfterFetchVcData", resAfterFetchVcData);
    if (resAfterFetchVcData?.data?.isSuccess) {
      if (resAfterFetchVcData?.data?.dataList != null) {
        localStorage.setItem("meeting", JSON.stringify(resAfterFetchVcData?.data?.dataList));
      } else {
        localStorage.removeItem("meeting");
      }
      setVcNotifications(resAfterFetchVcData?.data?.dataList);
      // setVcNotifications(null);
    }
  }

  let language = localStorage.getItem("prefferedLanguage");
  if (isLangUpdate) {
    getVcNotificationData();
    dispatch(isLanguageUpdated(false));
  }







  // // console.log("vcvcvc : ", vcNotifications)


  function closeLanguage() {
    // console.log("language popup closing")
    setopenLanguage(!openLanguage);
  }

  const totalLanguagesf = (data) => {
    // console.log("lang for popup : ", data);
    if (data.length < 1) {
      // console.log("set english, hindi as option");
      setTotalLanguages(data)
    } else {
      setTotalLanguages(data)
    }
  }

  const setData = (data) => {

    setDataList(data)
  }
  useEffect(() => {
    // console.log("open lang popup true")
    setopenLanguage(true);
    //totalLanguagesf();
    dispatch(updateExtended("false"));
    dispatch(updateParent('My Courses'))
    dispatch(updateChild(course.courseName))
    setShowMainDiv(true);
    getVcNotificationData();
  }, [])

  useEffect(() => {
    setToggle(!toggle);
  }, [filterValue, index])

  const DisplayHamburger = () => {
    setShowMainDiv(false);
  }

  const HandleHamburgerClick = (data) => {
    setShowMainDiv(data);
  }

  const CloseHamburger = () => {
    // alert("CloseHamburger");
    setShowMainDiv(true);

    // showMainDiv = true;
    //TestAlert();
  }

  // const TestAlert =()=>{
  //   alert("showMainDiv: "+ showMainDiv);
  // }
  // // console.log(totalLanguages.length +" total languages")
  const handleMenuItemSelect = (menuItem) => {
    // alert('menu')

    dispatch(updateIndex(menuItem));
    dispatch(updateFilterRetained(true));

    // // console.log("calling updateFilterVal() for reseting filter value all sessions")
    // dispatch(updateFilterVal(""));
    setSelectedMenuItem(menuItem);
    CloseHamburger()
  };


  const renderSelectedComponent = () => {

    switch (selectedMenuItem) {
      case 'CO':
        /* display content accordion */
        return <ContentAccordion filterVal={filterValue} setVcNotifications={setVcNotifications} ontotalLanguages={totalLanguagesf} activeAccordion={activeAccordion} setActiveAccordion={setActiveAccordion} setData={setData} />;

      case 'SS':
        /* display content accordion */
        return <ContentAccordion filterVal={filterValue} setVcNotifications={setVcNotifications} ontotalLanguages={totalLanguagesf} activeAccordion={activeAccordion} setActiveAccordion={setActiveAccordion} setData={setData} />;

      case 'AA':
        /* display content accordion */
        return <ContentAccordion filterVal={filterValue} setVcNotifications={setVcNotifications} ontotalLanguages={totalLanguagesf} activeAccordion={activeAccordion} setActiveAccordion={setActiveAccordion} setData={setData} />;

      case 'RL':
        /* resource library */
        return <ResourceLibraryIndex />;

      case 'CT':
        /* certificate pass props as necessary */
        return <Certificate courseIndex={true} />

      case 'OL':
        /* certificate pass props as necessary */
        return <OnlineLecturesIndex batchId={course.batchId} />
      case 'PR':
        return <MyPerformance />;

      // ... add cases for other menu items ...
      default:
        return null;
    }
  };


  return (
    <div id="mainDiv">
      <div className="container-fluid nav-wrapper bg-default border-bottom p-0 ">
        <div className="container-fluid p-0">
          <NavOne></NavOne>
        </div>
      </div>

      <div class="container-fluid body-wrapper bg-default p-0">
        <div class="container">
          {
            isDevice == "mobile" ?
              <Breadcrumb redirectLink="courseIndexMiddlePage" hamburgerClassSow={showMainDiv} /> :
              <Breadcrumb redirectLink="mycourses" hamburgerClassSow={showMainDiv} />
          }
        </div>

        <div class="container cont-padding">

          <HamburgerIcons dontShow="true" showHamburgerIcon={showMainDiv} hamburgerParentCallBack={HandleHamburgerClick} />
          {
            courseData?.isFormDetailsMandate == false ?
              <>
                {(language === null || language === "" || language === undefined) && (
                  <LanguagePopup
                    closeEvent={closeLanguage}
                    okEvent={closeLanguage}
                    headerKey="Login014"
                    availableLanguages={totalLanguages}
                    data={dataList}
                  />
                )}
              </> : ""
          }
          <div className="course-indexcontent mb-3 ps-2 pe-2">
            <CourseIndexleftbox sectorName={course.sectorName}
              courseName={course.courseName} title={title} setTitle={setTitle}
              description={course.description}
              courseIcon={variables.AWSPath + variables.AWSFolder + course.icon}
              showHamburger={DisplayHamburger} hideHamburger={CloseHamburger} showHamburgerIcon={showMainDiv}
              onMenuItemSelect={handleMenuItemSelect}
              selectedMenuItem={selectedMenuItem} />
            <div className={`col-lg-9 col-sm-12 col-xs-12 content-cards ${showMainDiv ? "sas m-0" : "d-none"}`}>

              {vcNotifications != null ?
                <VcNotificationCard iconVal={"../../Assets/Linqskills_Icons/goToTopic.svg"} cardTitle={"Online Lecture Schduled"} vcNotifications={vcNotifications}></VcNotificationCard> : ""
              }
              
              {/* {
                index == "CO" || "SS" || "AA" ? <FilterOption lan={Lan} totalLanguages={totalLanguages} activeAccordion={activeAccordion} setActiveAccordion={setActiveAccordion} filterVal={filterValue} setFilterVal={setFilterVal} /> : ""
              } */}
              <FilterOption lan={Lan} totalLanguages={totalLanguages} activeAccordion={activeAccordion} setActiveAccordion={setActiveAccordion} filterVal={filterValue} setFilterVal={setFilterVal} />
            
              {renderSelectedComponent()}

            </div>
          </div>
        </div>
      </div>
    </div>
  );

}
export default CourseIndex;


// {vcNotifications != null ?
//   <VcNotificationCard iconVal={"../../Assets/Linqskills_Icons/goToTopic.svg"} cardTitle={"Online Lecture Schduled"} vcNotifications={vcNotifications}></VcNotificationCard> : ""}
//   {vcNotifications != null ?
//   <VcNotificationCard iconVal={"../../Assets/Linqskills_Icons/assessment-purple.svg"}  cardTitle={"Assessment Due"}  vcNotifications={vcNotifications}></VcNotificationCard> : ""
// }


{/*                 <ContentAccordion setVcNotifications={setVcNotifications} ontotalLanguages={totalLanguagesf} setData={setData}></ContentAccordion>

 */}


{/* <TrainerMessage pageName="CourseContent" text="Before you begin, watch the video on how to view course content" title="coursecontent001" ></TrainerMessage> */ }
{/* {
                subnodeForContLrn ? 
                <> 
                {
                  vcNotifications != null && subnodeForContLrn.deliveryMode == 1 ? 
                   "" : <ContinueLearningCard subnode={subnodeForContLrn} node={nodeForContLrn} />
                }
                </>
                : ""
              } */}


{/*  <EligibilityCoursecard
                sectorName={course.sectorName}
                courseName={course.courseName}
                description={course.description}
                courseIcon={
                  variables.AWSPath + variables.AWSFolder + course.icon
                }
              /> */}