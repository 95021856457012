import { useEffect, useState } from "react";
import { variables } from "./Variables";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CryptoJS from "crypto-js";
import videojs from "video.js";
import CustumLoader from "./CustumLoader";


export default function ShowAnswers(props) {
  const [visibleQuestion, setVisibleQuestion] = useState(null);
  const [assessmentDetails, setAssessmentDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [SubmittedAssessment, setSubmittedAssessment] = useState(null);
  const [questionsFetched, setQuestionsFetched] = useState(false);
  const [questionOptions, setQuestionOptions] = useState({});
  const [openVideo, setOpenVideo] = useState(false);
  const [newHTML, setNewHTML] = useState('');
  const [videourl,setVideoUrl] = useState('')

  const langArray = [
    { value: "1", label: "English" },
    { value: "2", label: "Hindi" },
    { value: "3", label: "Tamil" },
    { value: "4", label: "Bengali" },
    { value: "5", label: "Telugu" },
    { value: "6", label: "Odiya" },
  ];

  var LangaugeId = langArray
    .filter((data) =>
      data.label.includes(localStorage.getItem("prefferedLanguage"))
    )
    .map((val) => val.value);

  const userId = localStorage.getItem("login-userId");
  const coursedata = JSON.parse(localStorage.getItem("coursedata"));

  function decrypt(encryptedData) {
    var key = CryptoJS.enc.Utf8.parse("8080808080808080");
    var iv = CryptoJS.enc.Utf8.parse("8080808080808080");
    try {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv });
        const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
//// console.log("decryptedText : " + decryptedText);
        return decryptedText;
    } catch (error) {
        console.error("Decryption failed:", error);
        return null;
    }
}

  const toggleQuestionVisibility = (questionIndex, questionID,questionheading) => {
    const decryptedQuestionHeading = decrypt(questionheading)
    // console.log({decryptedQuestionHeading})
    setOpenVideo(false)
    const newVisibleQuestion =
      visibleQuestion === questionIndex ? null : questionIndex;
    setVisibleQuestion(newVisibleQuestion);

    if (newVisibleQuestion !== null && !questionOptions[questionID]) {
      GetAssessmentQuestionsOption(questionID);
    }
  };

  const fetchAssessmentDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${variables.API_URL}api/GetSubmittedAssessmentDetail?userID=${parseInt(
          userId
        )}&batchID=${coursedata.batchId}&nodeID=${props.nodeId}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setAssessmentDetails(data.data);
    } catch (error) {
      console.error("Error fetching assessment details:", error);
    } finally {
      setLoading(false);
    }
  };

  const GetSubmittedAssessmentQuestions = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        `${
          variables.API_URL
        }api/GetSubmittedAssessmentQuestions?userID=${parseInt(
          userId
        )}&testID=${assessmentDetails.testid}&languageID=${LangaugeId}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      setSubmittedAssessment(data);
      setQuestionsFetched(true);
      // console.log("submitted assessment" + JSON.stringify(data));
    } catch (error) {
      console.error("Error fetching assessment details:", error);
    } finally {
      setLoading(false);
    }
  };

  const GetAssessmentQuestionsOption = async (questionID) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${
          variables.API_URL
        }api/GetAssessmentQuestionsOption?questionID=${parseInt(
          questionID
        )}&languageID=${LangaugeId}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setQuestionOptions(data);
    } catch (error) {
      console.error("Error fetching assessment details:", error);
    } finally {
      setLoading(false);
    }
  };

  const showAnswersClicked = () => {
    setShowAnswers(!showAnswers);
    if (!questionsFetched) {
      if (
        assessmentDetails.viewAssessmentFlag === "S1A1" ||
        assessmentDetails.viewAssessmentFlag === "S1A2"
      ) {
        setLoading(true);

        GetSubmittedAssessmentQuestions();
      }
    }
  };
  const valueGetByDangerouslySetInnerHTML = (e,decryptedQuestionHeading) => {
  // // console.log("decryptedQuestionHeading: " + decryptedQuestionHeading)
    const parser = new DOMParser();
    const doc = parser.parseFromString(decryptedQuestionHeading, 'text/html');

    const imgElement = doc.querySelector('img[videourl]');
    const extractedVideoUrl = imgElement?.getAttribute('videourl');
// console.log("extracted video url: " + extractedVideoUrl);
// console.log({imgElement})
   
     
      if (extractedVideoUrl) {
      const videoElement = document.createElement('video');
      videoElement.setAttribute('id', 'example_video_1');
      videoElement.setAttribute('width', '208');
      videoElement.setAttribute('src', extractedVideoUrl);
      videoElement.setAttribute('class', 'ega-video video-js vjs-default-skin vjs-big-play-centered');
      videoElement.setAttribute('controls', '');
      videoElement.setAttribute('preload', 'auto');
      videoElement.setAttribute('data-setup', '{"example_option":true}');

      imgElement.parentNode.replaceChild(videoElement, imgElement);
    }

    const updatedHtmlString = doc.body.innerHTML;
    // console.log("updatedHtmlString : ", updatedHtmlString);
    setVideoUrl(extractedVideoUrl);
      setNewHTML(doc.body.innerHTML);
      setOpenVideo(true);

   
  };


  const playVideo = async (srcLink) => {
     // console.log("inside play video")
    // // console.log("srcLink : ", srcLink)
    const videoJsPlayer = videojs("example_video_1", {
      autoplay: true,
      controls: true,
      responsive: true,
      sources: [
        {
          src: srcLink,
          type: "video/mp4",
        },
      ],
    });
  };

  useEffect(() => {
    if (openVideo) {
      playVideo(videourl);
    }
    // Cleanup function for the video.js player
    return () => {
      const player = videojs.getPlayer('example_video_1');
      if (player) {
        player.dispose();
      }
    };
  }, [ openVideo]);

  useEffect(() => {
    fetchAssessmentDetails();
  }, []);


  if (loading) {
    return (
      <div>
        {" "}
      {/*   <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
                       {loading &&      <CustumLoader message="Loading..." />}

      </div>
    );
  }

  return (
    <>
      {/*             <h6>New Component</h6>
       */}
      <div class="text-center show-answer">
        <div class="border-bottom pb-4">
          {/*              <h5>The test was submitted on<span class=""> 02 Oct 2022  |  2:30 PM</span></h5>
           */}
          {assessmentDetails?.viewAssessmentFlag != "S0A0" && (
            <h5 class="mt-2">
              Your score for this Assessment is
              <span class="">
                &nbsp;{assessmentDetails?.marksobtained} out of{" "}
                {assessmentDetails?.totalmarks}
              </span>
            </h5>
          )}
         { (assessmentDetails?.viewAssessmentFlag === "S1A1" || assessmentDetails?.viewAssessmentFlag === "S1A2") &&  <button class="primary-btn w-50" onClick={showAnswersClicked}>
            {showAnswers ? "Hide Answers" : "Show Answers"}
          </button>}
          {showAnswers &&
            assessmentDetails?.viewAssessmentFlag != "S0A0" &&
            assessmentDetails?.viewAssessmentFlag != "S1A0" && (
              <div class="mt-3 score-btns col-md-6 m-auto justify-content-around">
                <div class="d-flex btn-pt">
                  <button class="correct-btn">Correct</button>
                  <h5 class="ms-1 mb-0">
                    {SubmittedAssessment?.correctAnswerCount}
                  </h5>
                </div>

                <div class="d-flex btn-pt">
                  <button class="incorrect-btn">Incorrect</button>
                  <h5 class="ms-1 mb-0">
                    {SubmittedAssessment?.incorrectAnswerCount}
                  </h5>
                </div>
                <div class="d-flex btn-pt">
                  <button class="no-ans-btn">Not Answered</button>
                  <h5 class="ms-1 mb-0">
                    {SubmittedAssessment?.unansweredCount}
                  </h5>
                </div>
              </div>
            )}
        </div>

        {(assessmentDetails?.viewAssessmentFlag==="S1A1" || assessmentDetails?.viewAssessmentFlag==="S1A2") && showAnswers && (
          <div class="question-solved pt-4 pb-4">
            {SubmittedAssessment?.submittedAssessmentQuestionss.map(
              (question, index) => {
                const isAnswered = question.givenanswer !== null;
                const isCorrect = question.marksobtained > 0;
                const status = isAnswered
                  ? isCorrect
                    ? "Correct"
                    : "Incorrect"
                  : "Not Answered";
                const classsbtn = isAnswered
                  ? isCorrect
                    ? "correct-btn"
                    : "incorrect-btn"
                  : "no-ans-btn";
                const bgbtn = isAnswered
                  ? isCorrect
                    ? "right-ans"
                    : "wrong-ans"
                  : "default-ans";
                const icon = !isCorrect
                  ? "status-icon-cross"
                  : "status-icon-tick";
                const key = "8080808080808080";

                const decryptedQuestionHeading = decrypt(
                  question.questionheading
                );
                                const iss1a1 = assessmentDetails.viewAssessmentFlag==="S1A1";

                return (
                  <div class="mb-5">
                    <div class="score-btns d-flex justify-content-around col-md-8 m-auto">
                      <div class="col-md-6 d-flex justify-content-between ">
                     
                        <h5 class="text-start q-title display-desktop">Question {index + 1}</h5>
                        <h5 class="text-start q-title display-mobile">Q. {index + 1}</h5>
                        <button class={`${classsbtn} ms-3`}>{status}</button>
                      </div>

                      <div class="col-md-6 d-flex justify-content-evenly">
                        <h5 class="mt-1 d-flex">
                          <span class="q-title display-desktop me-2">Score</span> <span>{question?.marksobtained} of{" "}
                          {question?.marksperquestion}
                            </span>
                        </h5>

                        <img
                          src={
                            visibleQuestion === index
                              ? "../../Assets/Linqskills_Icons/circle-btn-up.svg"
                              : "../../Assets/Linqskills_Icons/circle-btn-down.svg"
                          }
                          class="cursor-pointer ms-2"
                          onClick={() =>
                            toggleQuestionVisibility(index, question.questionid,question.questionheading)
                          }
                        ></img>
                        {/* <img src="../../Assets/Linqskills_Icons/circle-btn-down.svg" class="cursor-pointer"></img> */}
                      </div>
                    </div>

                    {visibleQuestion === index && (
                      <div class="Collapse-Contents mb-3 p-3">
                        <div class="ms-4 border-bottom mb-3 pb-3">
                        <h5 class="mt-3 text-start video-width" onClick={(e)=>valueGetByDangerouslySetInnerHTML(e,decryptedQuestionHeading)} dangerouslySetInnerHTML={{ __html:openVideo?newHTML: decryptedQuestionHeading }} ></h5>
                          </div>
                          
            
                        {questionOptions.map((option, index) => {
                          const isgivenanswer =
                            question.givenanswer === option.answer;

                          return (
                            <div
                              className={`pt-0 p-3 pb-0  ${
                                iss1a1 && option.correctanswerflag === 1
                                  ? "right-ans"
                                  : isgivenanswer
                                  ? bgbtn
                                  : "default-ans"
                              }`}
                            >
                              <div class="form-check p-3 d-flex pb-2 pe-0">
                                <div>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    checked={isgivenanswer}
                                    disabled
                                  ></input>
                                </div>
{/*                                 <span>{String.fromCharCode(97 + index)}. </span>
 */}                               
 <div class="col">
   <label
                                  class="form-check-label float-start"
                                  for="flexCheckDefault"
                                  dangerouslySetInnerHTML={{
                                    __html: decrypt(option.answer),
                                  }}
                                ></label>
                                </div>
                                {(isgivenanswer ||
                                  (iss1a1 &&
                                    option.correctanswerflag === 1)) && (
                                  <div>
                                    <img
                                      src={`../../Assets/Linqskills_Icons/${
                                        iss1a1 && option.correctanswerflag === 1
                                          ? "status-icon-tick"
                                          : icon
                                      }.svg`}
                                      class="cursor-pointer"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}

                        {/*  <div class="p-3 right-ans">
                          <div class="form-check p-3 d-flex">
                            <div>
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                              ></input>
                            </div>{" "}
                            <span>a.</span>
                            <label
                              class="form-check-label float-start"
                              for="flexCheckDefault"
                            >
                              Personalized Learning: ChatGPT can provide
                              personalized learning experiences to students by
                              adapting to their individual needs, pace, and
                              learning styles.
                            </label>
                            <div>
                              <img
                                src="../../Assets/Linqskills_Icons/status-icon-tick.svg"
                                class="cursor-pointer"
                              ></img>
                            </div>
                          </div>
                        </div>

                        <div class="p-3 default-ans">
                          <div class="form-check p-3 d-flex">
                            <div>
                              {" "}
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                              ></input>
                            </div>{" "}
                            <span>a.</span>
                            <label
                              class="form-check-label float-start"
                              for="flexCheckDefault"
                            >
                              Personalized Learning: ChatGPT can provide
                              personalized learning experiences to students by
                              adapting to their individual needs, pace, and
                              learning styles.
                            </label>
                            <div>
                              <img
                                src="../../Assets/Linqskills_Icons/status-icon-tick.svg"
                                class="cursor-pointer"
                              ></img>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    )}
                  </div>
                );
              }
            )}
            {/*   <div class="mb-4">
              <div class="score-btns d-flex justify-content-around mb-4 col-md-8 m-auto">
                <div class="col-md-6 d-flex">
                  <h5>Question 1</h5>
                  <button class="incorrect-btn ms-3">Incorrect</button>
                </div>

                <div class="col-md-6 d-flex ps-5">
                  <h5 class="mt-1 me-5">Score 1 of 1</h5>
                  <img
                    src="../../Assets/Linqskills_Icons/circle-btn-down.svg"
                    class="cursor-pointer"
                  ></img>
                  <img src="../../Assets/Linqskills_Icons/circle-btn-down.svg" class="cursor-pointer"></img> 
                </div>
              </div>
                <div class="Collapse-Content">
                  <p>Collapse Content</p>
                </div> 
            </div> */}

           {/*  <div class="mb-4">
              <div class="score-btns d-flex justify-content-around mb-4 col-md-8 m-auto">
                <div class="col-md-6 d-flex">
                  <h5>Question 3</h5>
                  <button class="no-ans-btn ms-3">Not Answered</button>
                </div>

                <div class="col-md-6 d-flex ps-5">
                  <h5 class="mt-1 me-5">Score 1 of 1</h5>
                  <img
                    src="../../Assets/Linqskills_Icons/circle-btn-up.svg"
                    class="cursor-pointer"
                  ></img>
                  <img
                    src="../../Assets/Linqskills_Icons/circle-btn-down.svg"
                    class="cursor-pointer"
                  ></img>
                </div>
              </div>
              <div class="Collapse-Contents">
                <h5>Question</h5>
                <div class="p-3 wrong-ans">
                  <div class="form-check p-3 d-flex">
                    <div>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      ></input>
                    </div>
                    <span>a.</span>
                    <label
                      class="form-check-label float-start"
                      for="flexCheckDefault"
                    >
                      Personalized Learning: ChatGPT can provide personalized
                      learning experiences to students by adapting to their
                      individual needs, pace, and learning styles.
                    </label>
                    <div>
                      <img
                        src="../../Assets/Linqskills_Icons/status-icon-cross.svg"
                        class="cursor-pointer"
                      ></img>
                    </div>
                  </div>
                </div>

                <div class="p-3 right-ans">
                  <div class="form-check p-3 d-flex">
                    <div>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      ></input>
                    </div>{" "}
                    <span>a.</span>
                    <label
                      class="form-check-label float-start"
                      for="flexCheckDefault"
                    >
                      Personalized Learning: ChatGPT can provide personalized
                      learning experiences to students by adapting to their
                      individual needs, pace, and learning styles.
                    </label>
                    <div>
                      <img
                        src="../../Assets/Linqskills_Icons/status-icon-tick.svg"
                        class="cursor-pointer"
                      ></img>
                    </div>
                  </div>
                </div>

                <div class="p-3 default-ans">
                  <div class="form-check p-3 d-flex">
                    <div>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      ></input>
                    </div>{" "}
                    <span>a.</span>
                    <label
                      class="form-check-label float-start"
                      for="flexCheckDefault"
                    >
                      Personalized Learning: ChatGPT can provide personalized
                      learning experiences to students by adapting to their
                      individual needs, pace, and learning styles.
                    </label>
                    <div>
                      <img
                        src="../../Assets/Linqskills_Icons/status-icon-tick.svg"
                        class="cursor-pointer"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        )}
      </div>
    </>
  );
}
