

import React, { useState, useEffect } from "react";
import NavOne from '../../components/NavOne.js';
import Breadcrumb from '../CourseIndex/Breadcrumb';
import QuestionBox from './QuestionBox';
import TestPopup from "../Popups/TestPopup";
import { variables } from '../Shared/Variables';
import CryptoJS from "crypto-js";
import { Navigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";

import { updateChild, updateExtended, updateParent,updateggChild } from "../../features/breadcrumb/breadcrumbSlice";


export default function QuizQuestion() {
   // var props = JSON.parse(localStorage.getItem("TestData"));
   const greatgrandchildNode = useSelector(state=>state.breadcrumb.greatgrandchildNode);
   
//    // console.log("greatgrandchildNode in quizQues : ",greatgrandchildNode);
    const [openEGAList, setOpenEGAList] = useState(false);
    const [callUpdateAnsFunc ,setCallUpdateAnsFunc] = useState(false)
    const [quizQuestions, setquizQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [totalAttemptedQuestion, setTotalAttemptedQuestion] = useState([]);
    const [userAnsOptionNo, setUserAnsOptionNo] = useState(0);
    const [userAns, setUserAns] = useState("");

    var props = JSON.parse(localStorage.getItem("TestData1")); 
    // // console.log("props from session for sending data in props : ",props);
    const [timeRemaining, setTimeRemaining] = useState(null);    
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [timeLeft, setTimeLeft] = useState(props.duration);
    const [abortTest, setAbortTest] = useState(false);
    const [openTopicContent, setOpenTopicContent] = useState(false);
    var Aborttxt = "<h5>You have attempted to abort the test. Do you wish to leave the test?</h5>"
    const handleNavigate = () => {
        setAbortTest(true)    
    }
    
    const settingSetOpenEGAList = () =>{
        // // console.log("settingSetOpenEGAList called");
        // // console.log("calling updateAnsData func for abort the test");
        updateQuestionTime();
        setOpenEGAList(true)
    }
    const settingSetOpenTopicContent = () =>{
        // // console.log("settingSetOpenEGAList called");
        // // console.log("calling updateAnsData func for abort the test");
        updateQuestionTime();
        setOpenTopicContent(true)
    }

const updateQuestionTime=()=>{
    if (userAns != "") {
        updateAnsData();
    }else{
        var timeSpent = timeLeft - timeRemaining;
        const QuestionId = quizQuestions[currentQuestionIndex].QuestionId;
        fetch(variables.API_URL + 'api/UpdateQuestionTime',
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                "userId": props.UserId,
                "testId": props.TestId,
                "questionID": QuestionId,              
                "timeValue": timeSpent,         
            })
        })
        .then(response => response.json())
        .then(result => {

        });
    }

}

    const updateAnsData = () => {
        // console.log("updateAnsDatagfhfgfhgfghfgfgfgfgcalling;")
        const QuestionId = quizQuestions[currentQuestionIndex].QuestionId;
        var AnsMarks = 0;
        if (userAns != "") {

            if (totalAttemptedQuestion.indexOf(QuestionId) === -1) { totalAttemptedQuestion.push(QuestionId); }
            if (userAnsOptionNo == quizQuestions[currentQuestionIndex].correctAnswer) {
                AnsMarks = quizQuestions[currentQuestionIndex].MarksPerQuestion;
            }
            else {
                AnsMarks = 0;
            }
        }
        var timeSpent = timeLeft - timeRemaining;
        // console.log("timeRemaining : ",timeRemaining);
        // console.log("timeSpent : ",timeSpent);
        if (quizQuestions[currentQuestionIndex].QuestionType == 6 && quizQuestions[currentQuestionIndex].FileFormatId > 0) {
            const objFilter = filesToUpload.filter((item) => {
                return item.queId == quizQuestions[currentQuestionIndex].QuestionId
            })
            // console.log("objFilter : ", objFilter);
            if (objFilter[0].ansStr != "" && objFilter[0].ansStr != undefined) {
                // console.log("objFilter.ansStr is present : ", objFilter[0].ansStr)
                UpdateAnswer(QuestionId, objFilter[0].ansStr, userAnsOptionNo, AnsMarks, quizQuestions[currentQuestionIndex].QuesSectionNo, quizQuestions[currentQuestionIndex].QuestionType, timeSpent);
            }
        } else {
            // console.log("in else updateAnsData")
            UpdateAnswer(QuestionId, userAns, userAnsOptionNo, AnsMarks, quizQuestions[currentQuestionIndex].QuesSectionNo, quizQuestions[currentQuestionIndex].QuestionType, timeSpent);
            
        }
    };

    function UpdateAnswer(QuestionId, Answer, OptionNo, Marks, SectionNo, QuestionType, timeSpent) {
        // // console.log("UpdateAnswer called : ", Answer);
        // // console.log("props in UpdateAnswer in quiz que : ",props);
        if (Answer != "") {
            var AnswerEncry = CryptoJS.AES.encrypt(Answer, "0101001").toString();
            fetch(variables.API_URL + 'api/UpdateEGAAnswer',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({

                        "userId": props.UserId,
                        "testId": props.TestId,
                        "sectionNo": SectionNo,
                        "questionID": QuestionId,
                        "questionNo": OptionNo,
                        "questionType": QuestionType,
                        "marks": Marks,
                        "time": timeSpent,
                        "answerText": AnswerEncry,
                        "optionNo": OptionNo,
                        "languageId": props.LanguageId

                    })
                })
                .then(response => response.json())
                .then(result => {
                    setUserAns("");

                });
        }
    }
    // const dispatch = useDispatch();


    // useEffect(()=>{
    //     dispatch(updateExtended("false"))
    //     dispatch(updateParent('My Courses'))
    //     dispatch(updateChild('Eligibility Check'))
    //    },[])

    if (openEGAList) {
        
        return <Navigate to="/eligibility" />;
    }
    else if (openTopicContent) {
        return <Navigate to={`/TopicDetails/${props.ParentNodeName}/${props.ParentNodeId}/${props.BatchId}/${props.DeliveryMode}/${props.ExamMode}`} />;

    }
    else {
        return (

            <>

                <NavOne mobileView={true}></NavOne>

                <div class="container-fluid body-w rapper bg-default">
                    <div class="container p-0">

                    <Breadcrumb handleNavigate={handleNavigate} isTest={1} />
                    </div>
                    <div class="container">


                        <div class=" pb-4">
                            <div className="col-lg-9 col-sm-12 col-xs-12 content-cards bg-body box-one p-0">
                                <div className="">
                                    <div className="session-heading bg-body border-bottom">
                                        <div className="d-flex">
                                            <div class="pink-box">
                                            <img src="../../Assets/Linqskills_Icons/assessment-multicolorRed.png" className=""></img>
                                            </div>
                                            <div class="heading-one">
                                            <h5 className="mb-0 text-truncate">{greatgrandchildNode}</h5>

                                            </div>
                                      
                                        </div>
                                        <div className="session-details d-flex pt-1 ps-1">
                                            <div class="f-flex me-2">
                                                <div>
                                                    <img src="../../Assets/Linqskills_Icons/Marks-grey.svg" className="pe-1"></img>
                                                </div>
                                                <div>

                                                    <span className="h7 self-align-center">
                                                        {props.totalMarks} marks</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <QuestionBox 
                                 quizQuestions={quizQuestions} 
                                 setquizQuestions={setquizQuestions} 
                                 currentQuestionIndex={currentQuestionIndex} 
                                 setCurrentQuestionIndex={setCurrentQuestionIndex} 
                                 totalAttemptedQuestion={totalAttemptedQuestion} 
                                 setTotalAttemptedQuestion={setTotalAttemptedQuestion}  
                                 userAnsOptionNo={userAnsOptionNo} 
                                 setUserAnsOptionNo={setUserAnsOptionNo} 
                                 timeLeft={timeLeft} 
                                 setTimeLeft={setTimeLeft}
                                 userAns={userAns} 
                                 setUserAns={setUserAns}
                                 timeRemaining={timeRemaining} 
                                 setTimeRemaining={setTimeRemaining}
                                 filesToUpload={filesToUpload} 
                                 setFilesToUpload={setFilesToUpload}
                                 updateAnsData={updateAnsData}
                                 updateQuestionTime={updateQuestionTime}
                                />
                                
                                {abortTest && !props.IsAssessment && <TestPopup show={true} txt={Aborttxt} onOk={settingSetOpenEGAList} onClose={(e) => setAbortTest(false)} title="Abort Test" />}
                                {abortTest && props.IsAssessment && <TestPopup show={true} txt={Aborttxt} onOk={settingSetOpenTopicContent} onClose={(e) => setAbortTest(false)} title="Abort Test" />}

                            </div>
                        </div>


                    </div>

                </div>

         
        </>


    )
    }

}


