import { useNavigate } from "react-router";
import Cookies from "universal-cookie"

export default function Footer() {

    return (

        <>
      
                                <div class="conatiner-fluid main-footer mobilefooter ">
                                    <div class="container d-flex p-0">
                                    <div className="col-lg-6 col-sm-12 poweredby">
                                    <span>Powered by <span class="span-bold">LinQ Skills</span></span>
                                    <span class="ps-3 footertext">Developed by  <span class="span-bold">Schoolnet</span></span>
                                        </div>
                                        <div className="col-lg-6 text-right footerdetails display-desktop p-0"> 
                                        <span class="">All rights reserved. 2022</span>
                                       
                                        </div>
                                     </div>
 
                                    </div>
            
        </>
    )
}

                                  
{/* <div className="container d-flex login-footer mobilefooter">
                                   
<div className="col-6 col-sm-12 ps-3 poweredby">
    <span>f <span className="span-bold">
      sss</span></span>
    <span className="ps-3 footertext">
    sdsd <span className="span-bold">
        dsdsss</span></span>
</div>
<div className="col-6 text-right pe-3 footerdetails display-desktop">
    <span className="ps-3">
    sdsd</span>
</div>

</div> */}