

import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';



//import PopupOne from '../Shared/PopupOne';

export default function PendingReminder(props) {
    return (
        <>
            <div class={props.externalExam?"reminder mt-2":"reminder-pending mt-2"}>
                {/* <img src="../../Assets/Linqskills_Icons/reminder-pending.svg" class="" /> */}
                <img src={props.externalExam?"../../Assets/Linqskills_Icons/reminder-clock.svg":"../../Assets/Linqskills_Icons/pending-clock.svg"} class="" />
                <span className="self-align-center">{props.txtValue}</span>
            </div>
        </>
    )

}


