var currentDate = new Date();

// for uat


// export const variables = {
//   //  API_URL: "http://localhost:5251/",
//   API_URL: "https://betauatocelotegateway.linqskills.in/",
//   AWSFolder: "UAT/LH",
//   AWSPath: "https://linqskills.s3.ap-south-1.amazonaws.com/",
//   TimeZone: -(currentDate.getTimezoneOffset()),
//   CertificateFolderPath: "../../Assets/CandidateCertificate",
//   CourseCardDefaultImg: "../../Assets/Linqskills_Icons/placeholder-one.svg",
//   CertificateDefaultImg: "../../Assets/Linqskills_Icons/certificate-default.svg",
//   sdkKey: "iUiCMBUGQlSWKeJk49TQnw",
//   sdkSecret: "50ZE1p68loFjYdgYT5tY81T9QKYLqPSP",
//   accessKeyId: "AKIAZ3NHXLRFOEV5HBNW",
//   secretAccessKey: '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',
//   region: 'ap-south-1',
//   bucketName: "linqskills",
//   certificateFilePath: 'UAT/LH/Data/Certificate/',
//   downloadCertificate : "https://betauatlms.linqskills.in/"
// }




// for live

export const variables = {
  // API_URL: "http://localhost:5251/",
  //API_URL: "https://betauatocelotegateway.linqskills.in/",
  API_URL: "https://v2lmsocelotegatewayapi.linqskills.in/",
  AWSFolder: "LH",
  AWSPath: "https://linqskillslive.s3.ap-south-1.amazonaws.com/",
  TimeZone: -(currentDate.getTimezoneOffset()),
  CertificateFolderPath: "../../Assets/CandidateCertificate",
  CourseCardDefaultImg: "../../Assets/Linqskills_Icons/placeholder-one.svg",
  CertificateDefaultImg: "../../Assets/Linqskills_Icons/certificate-default.svg",
  sdkKey: "iUiCMBUGQlSWKeJk49TQnw",
  sdkSecret: "50ZE1p68loFjYdgYT5tY81T9QKYLqPSP",
  accessKeyId: "AKIAZ3NHXLRFOEV5HBNW",
  secretAccessKey: '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',
  region: 'ap-south-1',
  bucketName: "linqskillslive",
  certificateFilePath: 'LH/Data/Certificate/',
  downloadCertificate : "https://linqskills.in/"
}



// testing server for live


// export const variables = {
//   // API_URL: "http://localhost:5251/",
//   //API_URL: "https://betauatocelotegateway.linqskills.in/",
//   API_URL: "https://v3LMSOceloteGatewayapi.linqskills.in/",
//   AWSFolder: "LH",
//   AWSPath: "https://linqskillslive.s3.ap-south-1.amazonaws.com/",
//   TimeZone: -(currentDate.getTimezoneOffset()),
//   CertificateFolderPath: "../../Assets/CandidateCertificate",
//   CourseCardDefaultImg: "../../Assets/Linqskills_Icons/placeholder-one.svg",
//   CertificateDefaultImg: "../../Assets/Linqskills_Icons/certificate-default.svg",
//   sdkKey: "iUiCMBUGQlSWKeJk49TQnw",
//   sdkSecret: "50ZE1p68loFjYdgYT5tY81T9QKYLqPSP",
//   accessKeyId: "AKIAZ3NHXLRFOEV5HBNW",
//   secretAccessKey: '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',
//   region: 'ap-south-1',
//   bucketName: "linqskillslive",
//   certificateFilePath: 'LH/Data/Certificate/',
//   downloadCertificate : "https://linqskills.in/"
// }


