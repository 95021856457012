import React, { useState, useEffect } from "react";
import SessionHeading from "../EligibilityCheck/SessionHeading";
import CompletedTest from './CompletedTest'
import PdfComponent from "./pdfComponent";
import { variables } from "../Shared/Variables";
import downloadPdfFromS3 from "./downloadFileBtn";

export default function Certificate(props) {
    const certificateData = JSON.parse(localStorage.getItem("certificateData"));
    // console.log('certificateData : ', certificateData)
    // var path = variables.AWSPath+variables.AWSFolder+"/"+props.certificatePDFFileName
    // console.log("final path :", variables.AWSPath + variables.AWSFolder + "/Data/Certificate/" + certificateData.certificate);
    const [dataCertificate, setDataCertificate] = useState("");
    const userId = localStorage.getItem('login-userId');
    var course = JSON.parse(localStorage.getItem("coursedata"));
    var currentDate = new Date();
    var targetDate = new Date(certificateData.certificationDate);
    function isFutureDate() {
        if (certificateData.releaseCertificatePostCertificateDate) {
            if (targetDate > currentDate) {
                console.log("The provided date is in the future.");
                return true
            } else if (targetDate < currentDate) {
                console.log("The provided date is in the past.");
                return false
            } else {
                console.log("The provided date is the same as the current date.");
                return false
            }
        } else {
            return false
        }

    }

    useEffect(() => {
        if (props.courseIndex) {
            // getDataOfCertificateForCourse()
        }
        // // console.log("isFutureDate(certificateData.certificationDate) : ",isFutureDate("2023-12-25"));
    }, []);

    const isCheckingFunc = () => {
        if (certificateData.markedCompleted == 1) {
            return true
        } else {
            if (certificateData.courseCompleted == 1) {
                return true
            } else {
                return false
            }
        }
    }


    const [scriptLoaded, setScriptLoaded] = useState(false);
    const loadScript = () => {
        if (!scriptLoaded) {
            const script = document.createElement('script');
            script.src = `${variables.downloadCertificate}shoeb.js?id=${certificateData?.certificate}`; // Adjust the URL and ID as needed
            script.async = true;
            script.onload = () => {
                setScriptLoaded(true);
                // console.log('Script loaded successfully');
            };
            document.body.appendChild(script);
        } else {
            // console.log('Script already loaded');
        }
    };

    return (
        <>
            {
                props.courseIndex ?
                    <>
                        <div class="content-cards p-0 bg-body box-one m-0">

                            <SessionHeading name="Course Certificate" />
                            {!certificateData.certificate || !isCheckingFunc() || isFutureDate() || !certificateData.isCertificateToShow ?
                                <>
                                    {
                                        certificateData.IsAssessmentGraded == true ? <>new condition</> :
                                            <>
                                                <CompletedTest dontShowNavBtns={true} isShowGoToIndexBtn={false} Message={certificateData.courseCompleted == 0 && certificateData.batchCompleted == 0 && certificateData.markedCompleted == 0 ? "Course is not yet completed" : certificateData.markedCompleted == 1 ? "certificate will be released on" : !certificateData.isCertificateToShow ? "The certificate has not yet been released" : certificateData.releaseCertificatePostCertificateDate && isFutureDate() ? `The certificate will be released on` : certificateData.releaseCertificatePostCertificateDate && !isFutureDate() ?
                                                    "The certificate has already been released" : ""}
                                                    testCompletedOn={!isCheckingFunc() ? "" :
                                                        !certificateData.isCertificateToShow ? "" : certificateData.releaseCertificatePostCertificateDate && isFutureDate() ? certificateData.certificationDate : certificateData.certificationDate}
                                                    ImgSrc="../../Assets/Linqskills_Icons/completedImg.svg" />
                                            </>
                                    }
                                </>
                                :
                                <>
                                    <div class="border-bottom pt-2 pb-2 download-certificate">
                                        <div class="certificatebg m-auto col-12 text-center">
                                            {/* <img src={props.certificateImgFileName} class="w-auto" /> */}
                                            {
                                                certificateData?.certificate != "" && certificateData.certificate != undefined ?
                                                    <PdfComponent url={variables.AWSPath + variables.certificateFilePath + certificateData?.certificate} /> : ""
                                            }
                                        </div>

                                    </div>
                                    <div class="container">
                                        <div class="d-flex certificate-action p-3">
                                            {/* certificate-btn */}
                                            <div class="col order-md-last order-sm-first text-center">
                                                {
                                                    certificateData.certificate != "" && certificateData.certificate != undefined ?
                                                        <div>
                                                            <button onClick={loadScript} class="primary-btn text-uppercase">
                                                                {/* <img src="../../Assets/Linqskills_icons/download-icon.svg" class="" /> */}
                                                                Download
                                                            </button>
                                                        </div> : ""
                                                }
                                            </div>
                                            {/* 
                                            <div class="col p-0 me-3">
                                                <span class="">The details of competencies gained on completion of training can be viewed here on the</span>
                                                <span class="hyperlink-performance">  course performance page</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </> :
                    <>
                        {/* col-lg-9 col-sm-12 col-xs-12  */}
                        <div class="content-cards p-0 bg-body box-one m-0">

                            <SessionHeading name="Course Certificate" />
                            {!certificateData.certificate || !isCheckingFunc() || isFutureDate() || !certificateData.isCertificateToShow ?
                                <>
                                    {
                                        certificateData.IsAssessmentGraded == true ? <>new condition</> :
                                            <>
                                                <CompletedTest dontShowNavBtns={true} isShowGoToIndexBtn={false} Message={certificateData.courseCompleted == 0 && certificateData.batchCompleted == 0 && certificateData.markedCompleted == 0 ? "Course is not yet completed" : certificateData.markedCompleted == 1 ? "certificate will be released on" : !certificateData.isCertificateToShow ? "The certificate has not yet been released" : certificateData.releaseCertificatePostCertificateDate && isFutureDate() ? `The certificate will be released on` : certificateData.releaseCertificatePostCertificateDate && !isFutureDate() ?
                                                    "The certificate has already been released" : ""}
                                                    testCompletedOn={!isCheckingFunc() ? "" :
                                                        !certificateData.isCertificateToShow ? "" : certificateData.releaseCertificatePostCertificateDate && isFutureDate() ? certificateData.certificationDate : certificateData.certificationDate}
                                                    ImgSrc="../../Assets/Linqskills_Icons/completedImg.svg" />
                                            </>
                                    }
                                </>
                                :
                                <>
                                    <div class="border-bottom pt-2 pb-2 download-certificate">
                                        <div class="certificatebg m-auto col-12 text-center">
                                            {/* <img src={props.certificateImgFileName} class="w-auto" /> */}
                                            {
                                                certificateData.certificate != "" && certificateData.certificate != undefined ?
                                                    <PdfComponent url={variables.AWSPath + variables.certificateFilePath + certificateData?.certificate} /> : ""
                                            }
                                        </div>

                                    </div>
                                    <div class="container">
                                        <div class="row certificate-action p-3">
                                            <div class="col-md-4 order-md-last order-sm-first certificate-btn">
                                                {
                                                    certificateData.certificate != "" && certificateData.certificate != undefined ?
                                                        <div>
                                                            <button onClick={loadScript} class="primary-btn text-uppercase">
                                                                {/* <img src="../../Assets/Linqskills_icons/download-icon.svg" class="" /> */}
                                                                Download
                                                            </button>
                                                        </div> : ""
                                                }
                                            </div>

                                            {/* <div class="col-md-8 p-0">
                                                <span class="">The details of competencies gained on completion of training can be viewed here on the</span>
                                                <span class="hyperlink-performance">  course performance page</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </>
            }
        </>


    )
}


{/* <>
    <CompletedTest dontShowNavBtns={true} isShowGoToIndexBtn={false} Message={certificateData.courseCompleted == 0 && certificateData.batchCompleted == 0 && certificateData.markedCompleted == 0 ? "Course is not yet completed" : certificateData.releaseCertificatePostCertificateDate && isFutureDate() ? "Certificate available after release date" : "ask trainer to release certificate"}
        testCompletedOn={certificateData.courseCompleted == 0 ? "" : certificateData.releaseCertificatePostCertificateDate && isFutureDate() ? certificateData.certificationDate : "03 Oct 2022"}
        ImgSrc="../../Assets/Linqskills_Icons/completedImg.svg" />
</> */}

// {
//     (certificateData.certificate != "") &&
//     (<>
//         <a href={certificateData.certificate} rel="noreferrer" download>
//             <button class="primary-btn text-uppercase">
//                 <img src="../../Assets/Linqskills_icons/download-icon.svg" class="" />
//                 Download
//             </button>
//         </a>
//     </>)
// }

{/* <div class="certificatebg m-auto col-7 text-center">
                            <img src="../../Assets/Linqskills_Icons/Certificatebg.svg" class="w-auto" />
                        </div> */}



// const handleDownload = () => {
//     // Replace 'example.pdf' with the actual file name and path if needed
//     const pdfUrl = props.certificatePDFFileName;

//     // Create a hidden anchor element
//     const anchor = document.createElement('a');
//     anchor.style.display = 'none';
//     anchor.href = pdfUrl;
//     anchor.download = 'downloaded_file.pdf';

//     // Append the anchor to the body
//     document.body.appendChild(anchor);

//     // Trigger a click on the anchor element
//     anchor.click();

//     // Remove the anchor from the body
//     document.body.removeChild(anchor);
//   };
// const getDataOfCertificateForCourse = () => {
//     const bodyForCertificate = {
//         userId: userId,
//         batchId: course.batchId
//     }
//     const resFromGetCertificate = {
//         "isSuccess": true, "returnMessage": "success", "data": [{ "batchId": 4516, "userId": 0, "startDate": null, "endDate": null, "enrollmentDate": null, "isActive": false, "certificate": "Data/Certificate/certificate_151_65762.pdf", "certificateno": null, "downloadDate": null, "isCertificateReleased": false, "certificateGrade": null, "createdOn": "0001-01-01T00:00:00", "id": 0, "markedCompleted": false, "studentAttendancePercentage": null }], "dataList": null, "userDataList": null
//     }

//     if(resFromGetCertificate.isSuccess){
//         // // console.log("data fetched from getDataForBatchCertificate");
//         setDataCertificate(resFromGetCertificate.data[0]);
//     }
// }