import TitleHeader from './TitleHeaderRgsa'
import PdfComponent from "../../components/Shared/pdfComponent";
import { variables } from "../../components/Shared/Variables";
import Translate from '../../components/Shared/Translate';
import CompletedTestRgsa from './completeRgsa';
import FooterRgsa from "../rgsaLogin/FooterRgsa";
import React, { useState, useEffect } from "react";

const CertificateRgsa = () => {
  const certificateData = JSON.parse(localStorage.getItem("coursedata"));
  var course = JSON.parse(localStorage.getItem("coursedata"));
  var currentDate = new Date();
  var targetDate = new Date(certificateData[0].certificationDate);
  function isFutureDate() {
    if (certificateData[0].releaseCertificatePostCertificateDate) {
      if (targetDate > currentDate) {
        // console.log("The provided date is in the future.");
        return true
      } else if (targetDate < currentDate) {
        // console.log("The provided date is in the past.");
        return false
      } else {
        // console.log("The provided date is the same as the current date.");
        return false
      }
    } else {
      return false
    }
  }

  const isCheckingFunc = () => {
    if (certificateData[0].markedCompleted == 1) {
      return true
    } else {
      if (certificateData[0].courseCompleted == 1) {
        return true
      } else {
        return false
      }
    }
  }

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const loadScript = () => {
    if (!scriptLoaded) {
      const script = document.createElement('script');
      script.src = `https://betauatlms.linqskills.in/shoeb.js?id=${certificateData[0]?.certificate}`; // Adjust the URL and ID as needed
      script.async = true;
      script.onload = () => {
        setScriptLoaded(true);
        // console.log('Script loaded successfully');
      };
      document.body.appendChild(script);
    } else {
      // console.log('Script already loaded');
    }
  };
  // console.log("certificateData : ", certificateData);
  return (
    <>
            <TitleHeader url='/RgsaMyCourses' title="સરતિફિકેટ"></TitleHeader>

      <div class="certificate-bg pt-6em">

      
        {!certificateData[0].certificate || !isCheckingFunc() || isFutureDate() || !certificateData[0].isCertificateToShow ?
          <>
            <CompletedTestRgsa dontShowNavBtns={true} isShowGoToIndexBtn={false} Message=""
              testCompletedOn={!isCheckingFunc() ? "" :
                !certificateData[0].isCertificateToShow ? "" :
                  certificateData[0].releaseCertificatePostCertificateDate && isFutureDate() ? certificateData[0].certificationDate
                    : certificateData[0].certificationDate}
              ImgSrc="../../Assets/Linqskills_Icons/Certificate-illustration.svg" />
          </>
          :
          <>
            <div class="border-bottom bg-white pt-5 rgsa-border-radius download-certificate">
              <div class="certificatebg m-auto col-12 text-center">
                {/* <img src={props.certificateImgFileName} class="w-auto" /> */}
                {
                  certificateData[0].certificate != "" && certificateData[0].certificate != undefined ?
                    <PdfComponent url={variables.AWSPath + variables.certificateFilePath + certificateData[0]?.certificate} /> : ""
                }
              </div>
              <div class="container">
              <div class="row certificate-action p-3">
                <div class="col-md-4 order-md-last order-sm-first certificate-btn">
                  {
                    certificateData[0].certificate != "" && certificateData[0].certificate != undefined ?
                      <div>
                        <button onClick={loadScript} class="rgs-primarybtn text-uppercase">
                          {/* <img src="../../Assets/Linqskills_icons/download-icon.svg" class="" /> */}
                          <Translate textKey="certificate003" /><img src="../../Assets/Linqskills_icons/download-rgsa.svg" class="float-end mt-4p"></img>
                        </button>
                      </div> : ""
                  }
                </div>
              </div>

            </div>
            </div>
          
          </>
        }
        {/* <PdfComponent url={variables.AWSPath + variables.certificateFilePath + "certificate_151_67028.pdf"} /> */}
        {/* {
        coursedata?.certificate == "" ?
          <PdfComponent url={variables.AWSPath + variables.certificateFilePath + "certificate_151_67028.pdf"} /> : ""
      } */}
      </div>
      <FooterRgsa> </FooterRgsa>
    </>
  )

}

export default CertificateRgsa