import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import VcNotificationCard from "./VcNotificationCard";
import ContinueLearningCard from "./continueLearningCard";
import { variables } from "../Shared/Variables";
import axios from "axios";

import CourseIndexMiddlebox from "./CourseIndexMiddlebox";
import NavOne from "../NavOne";
import Breadcrumb from "./Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { updateIndex, updateFilterVal, updateFilterRetained, updateAccordianNo } from "../../features/CourseIndex/courseIndexSlice";
const CourseIndexMiddlePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const index = useSelector(state => state.courseindex.index)
  const [vcNotifications, setVcNotifications] = useState();
  const [showMainDiv, setShowMainDiv] = useState(true);
  const [title, setTitle] = useState("Content");
  const [selectedMenuItem, setSelectedMenuItem] = useState(index);
  var course = JSON.parse(localStorage.getItem("coursedata"));
  const userId = localStorage.getItem("login-userId")

  const getVcNotificationData = async () => {
    // console.log("calling getVcNotificationData")
    const body = {
      UserId: userId,
      BatchId: course.batchId,
      TimeZone: variables.TimeZone
    }
    // // console.log("body for getVcNotificationData : ", body)
    const resAfterFetchVcData = await axios.post(variables.API_URL + 'api/GetTraineeTodaysWebinarDetails', body);
    // // console.log("resAfterFetchVcData", resAfterFetchVcData);
    if (resAfterFetchVcData?.data?.isSuccess) {
      if (resAfterFetchVcData?.data?.dataList != null) {
        localStorage.setItem("meeting", JSON.stringify(resAfterFetchVcData?.data?.dataList));
      } else {
        localStorage.removeItem("meeting");
      }
      setVcNotifications(resAfterFetchVcData?.data?.dataList);
      // setVcNotifications(null);
    }
  }
  const handleMenuItemSelect = async (menuItem,filterval) => {
    // alert('menu')
    // console.log("working here lllll : ", menuItem,filterval)
    
    await dispatch(updateIndex(menuItem));
    await dispatch(updateFilterVal(filterval));
    await dispatch(updateFilterRetained(true));
    gotocourse();
  };
  
  const gotocourse = () => {
    
    navigate("/CourseIndex")
  }

  
  useEffect(() => {
    getVcNotificationData();
  }, [])


  return (
    <div id="mainDiv">
      <div className="container-fluid nav-wrapper bg-default border-bottom p-0 ">
        <div className="container-fluid p-0">
          <NavOne></NavOne>
        </div>
      </div>

      <div class="container-fluid body-wrapper bg-default p-0">
        <div class="container">
          <Breadcrumb redirectLink="mycourses" hamburgerClassSow={showMainDiv} />
        </div>
      {/*  {vcNotifications != null ?
        <VcNotificationCard iconVal={"../../Assets/Linqskills_Icons/goToTopic.svg"} cardTitle={"Online Lecture Schduled"} vcNotifications={vcNotifications}></VcNotificationCard> : ""
     } */}
      <CourseIndexMiddlebox
      courseName={course.courseName} title={title} setTitle={setTitle}
      description={course.description}
      courseIcon={variables.AWSPath + variables.AWSFolder + course.icon}
      
      onMenuItemSelect={handleMenuItemSelect}
      selectedMenuItem={selectedMenuItem} />
      </div>
    </div>
  )
}


export default CourseIndexMiddlePage