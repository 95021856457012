import React, { Component, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { variables } from "../Shared/Variables";
import {
  updateChild,
  updateParent,
  updategrandChild,
  updateggChild,
  updateExtended,
} from "../../features/breadcrumb/breadcrumbSlice";
import {
  updateFilterVal,
  updateNodeVal,
  updateFilterRetained,
  updateIsBlockCourseIndexAPi,
  updateAccordianNo,
  updateIsContentNodeVal
} from "../../features/CourseIndex/courseIndexSlice";
import { useDispatch, useSelector } from "react-redux";
import { useErrorBoundary } from "react-error-boundary";

import dateFormat from "dateformat";
import Translate from '../Shared/Translate';
import TestPopup from "../Popups/TestPopup";
import { TimerFormat } from "../generalFunctions/timeFormat";
import Cookies from "universal-cookie";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import useScrollPosition from "../Shared/ScrollTracker";
import { apiCallingForCourseStarted } from "../generalFunctions/apiCalling.js";
import CustumLoader from "../Shared/CustumLoader.js";

export default function ContentAccordion({
  ontotalLanguages,
  setData,
  setVcNotifications,
  title,
  filterVal,
  OpenSessionCount,
  activeAccordion,
  setActiveAccordion
}) {
  useScrollPosition("ContentAccordion")
  const isFilterRetained = useSelector(state => state.courseindex.isFilterRetained);
  const isUserLoggedIn = useSelector(state => state.courseindex.isUserLoggedIn);
  const isBlockCourseIndexAPi = useSelector(state => state.courseindex.isBlockCourseIndexAPi);
  // // console.log("isFilterRetained in contentAccoredian : ",isFilterRetained)
  const nodeValue = useSelector(state => state.courseindex.nodeData);
  const isContentNodeVal = useSelector(state => state.courseindex.isContentNodeVal);
  // // console.log("nodeValue nodeValue :: ", nodeValue);
  const [nodedata, setNodeData] = useState(nodeValue);
  const [courseNodeId, setcourseNodeId] = useState(0);
  var coursedata = JSON.parse(localStorage.getItem("coursedata"));
  const [courseLevel, setcourseLevel] = useState(coursedata?.courseLevel);
  const [batchID, setBatchID] = useState(4);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [toggle, setToggle] = useState(false);
  const userId = localStorage.getItem("login-userId")
  const courseData = JSON.parse(localStorage.getItem("coursedata"));

  // // console.log("accordion in main : ",activeAccordion);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const prefferedLanguage = localStorage.getItem("prefferedLanguage");
  const defaultLanguage = cookies.get("default-language");
  const language = localStorage.getItem("prefferedLanguage");
  const { showBoundary } = useErrorBoundary();
  const [dataList, setDataList] = useState([]);
  const [sublevel, setSublevel] = useState([]);



  var popuptxt =
    "<h5>We are adding more exiciting content soon. Stay tuned!!!</h5>";

  const maxArray = () => {
    let arr = [];
    let nodeSessions = nodedata.filter(nd => nd.nodeClassification === "Session")

    nodedata.map((data, ind) => {
      const array = data.contentLanguagesName.split(",");
      arr.push(array);
    });

    // // console.log("arr in maxArray : ", arr);

    let arr2 = [...new Set(arr.flat(2))];
    let final = arr2.filter((lan) => lan != "");
    // // console.log("final in maxArray : ", final);
    ontotalLanguages(final);
  };


  useEffect(() => {
    if (isContentNodeVal) {
      dispatch(updateIsContentNodeVal(false));
      getcourseIndex();
      setToggle(toggle);
    }
  }, [nodeValue, isContentNodeVal])
  useEffect(() => {
    // if(isBlockCourseIndexAPi){
    //   // // console.log("previous data has to be setup");
    //   setNodeData(nodeValue);
    // } else {
    //   dispatch(updateIsBlockCourseIndexAPi(false));
    // }
    if (isUserLoggedIn) {
      console.log("calling getcourseIndex")
      getcourseIndex();
    }
  }, [isFilterRetained]);
  useEffect(() => {
    maxArray();
  }, [nodedata]);

  // // console.log("filterVal : ",filterVal);
  useEffect(() => {
    if (nodedata.length > 0) {
      if (courseLevel == 3) {
        // // console.log("checking for course level 3 : ", filterVal);
        if (filterVal == "") {
          let tempdataList = nodedata.filter(
            (node) => node.nodeClassification === "Session"
          );
          // // console.log("tempdataList : ", tempdataList);
          setDataList(tempdataList);
        } else {
          const sublevel = nodedata.filter((subnode) => subnode.deliveryMode == filterVal);
          // console.log("sublevel after filter : ", sublevel);
          // setSublevel(sublevel);
          setDataList(sublevel);
        }
      } else {
        // // console.log("Filter Val change check filter func : ", filterVal);
        // // console.log("nodedata prev val : ", nodedata);
        
        const sublevel = nodedata.filter((subnode) => subnode.deliveryMode == filterVal);
        // console.log("sublevel after filter : ", sublevel);
        // alert("here")
        setSublevel(sublevel);
        
        // setDataList(tempFilterData);
      }
    } else {
      // alert("no node data")
      // getcourseIndex();
    }
  }, [filterVal, isFilterRetained])

  const nextPrevData = async () => {
    const nodeValFromLocalStorage = JSON.parse(localStorage.getItem("nodeVal") || "[]");
    const allTopics = nodeValFromLocalStorage.filter((it) => {
      return it.deliverymodename != ""
    })
    // // console.log("allTopics : ",allTopics);
    let nodeIds = "";
    const nodeIdsArr = allTopics.map((i) => {
      return i.nodeId
    })
    nodeIds = nodeIdsArr.join(",");
    let bodyForNextPrev = {
      "userId": userId,
      "batchId": courseData.batchId,
      "nodeids": nodeIds
    }
    // // console.log("body for next prev : ",bodyForNextPrev);
    let resAfterFetchingNextPrev = await axios.post(variables.API_URL + `api/GetUserSessionv1`, bodyForNextPrev);
    // console.log("resAfterFetchingNextPrev : ", resAfterFetchingNextPrev);
    if (resAfterFetchingNextPrev?.data?.isSuccess) {
      // for (let i = 0; i < resAfterFetchingNextPrev.data.userDataList.length; i++) {
      //   if(i < resAfterFetchingNextPrev.data.userDataList.length-1){
      //     if(resAfterFetchingNextPrev.data.userDataList[i].sessionID == resAfterFetchingNextPrev.data.userDataList[i+1].sessionID) {
      //       // console.log(`same session id : at index ${i} `,resAfterFetchingNextPrev.data.userDataList[i].sessionID)
      //     } else {
      //       // console.log(`different session are here at index ${i}`)
      //     }
      //   }
      //   // resAfterFetchingNextPrev.data.userDataList[i].topicStatus = "Locked";
      // }
      localStorage.setItem("nextPrevData", JSON.stringify(resAfterFetchingNextPrev));
      setIsLoader(false);
    }
  }

  const getcourseIndex = async () => {


    setcourseLevel(coursedata.courseLevel);
    setcourseNodeId(coursedata.courseNodeId);
    setBatchID(coursedata.batchId);
    var userId = localStorage.getItem("login-userId");
    var coursedataInApi = await JSON.parse(localStorage.getItem("coursedata"));
    // alert(coursedataInApi?.courseLevel);
    if (nodeValue.length > 0) {
      // console.log("inside nodeValue.length > 0 : ", nodeValue)
      // alert("re arranging value in co after nodeval updated");
      setNodeData(nodeValue);
      setData(nodeValue);
      if (coursedataInApi?.courseLevel == 3) {
        // console.log("inside course level 3 : ", courseLevel);
        if (filterVal != "") {
          const sublevel = nodeValue.filter((subnode) => subnode.deliveryMode == filterVal);
          // console.log("sublevel after filter in api resp : ", sublevel);
          setDataList(sublevel);
        } else {
          let tempdataList = nodeValue.filter(
            (node) => node.nodeClassification === "Session"
          );
          // console.log("tempdataList : ", tempdataList);
          setDataList(tempdataList);
        }
      } else {
        const sublevel = nodeValue.filter((subnode) => subnode.deliveryMode == filterVal);
        // console.log("sublevel after filter : ", sublevel);
        setSublevel(sublevel);
      }
    } else {
      console.log("calling api/CourseIndex")
      setIsLoader(true);
      fetch(variables.API_URL + "api/CourseIndex", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CourseNodeId: coursedata.courseNodeId,
          UserId: userId,
          BatchId: coursedata.batchId,
        }),
      })
        .then((response) => response.json())
        .then(async (data) => {
          // console.log("course api result : ", data.dataList)
          if (data.isSuccess) {
            setNodeData(data.dataList);
            // setVcNotifications([1])
            setData(data.dataList);
            if (coursedataInApi?.courseLevel == 3) {
              if (filterVal != "") {
                const sublevel = data.dataList.filter((subnode) => subnode.deliveryMode == filterVal);
                // console.log("sublevel after filter in api resp : ", sublevel);
                setDataList(sublevel);
              } else {
                let tempdataList = data.dataList.filter(
                  (node) => node.nodeClassification === "Session"
                );
                // // console.log("tempdataList : ", tempdataList);
                setDataList(tempdataList);
              }
            } else {
              const sublevel = data.dataList.filter((subnode) => subnode.deliveryMode == filterVal);
              // console.log("sublevel after filter : ", sublevel);
              setSublevel(sublevel);
            }
            
            dispatch(
              updateNodeVal(
                data.dataList
              )
            );
            localStorage.setItem("nodeVal", JSON.stringify(data?.dataList));
            dispatch(updateFilterRetained(false));
            nextPrevData();
            var isAnyTopicCompleted = data.dataList.filter((it) => {
              return it.completionStatus == "Completed" && it.nodeClassification == "Session"
            })
            if (isAnyTopicCompleted.length > 0) {
              // alert("at least topic is complete")
              apiCallingForCourseStarted(userId, coursedata.batchId);
            }
          } else {
            setIsLoader(false);
            throw new Error('Data is not successful');
          }

        }).catch((error) => {
          showBoundary(error);

          // // console.log("error in api/CourseIndex : ", error);
          setIsLoader(false);
        })
    }


  };

  // // console.log("nodeData final : ", nodedata);
  const SessionRedirectionLink = (
    deliveryMode,
    nodeName,
    nodeID,
    batchID,
    examMode, completionStatus
  ) => {
    // alert("nodeID: "+nodeID);
    // // console.log("nodeName: "+ nodeName+", nodeID: "+nodeID+", batchID: "+batchID+", deliverMode: "+deliveryMode);
    // // console.log("completionStatus in content accordian : ", completionStatus)
    var link = "";
    switch (deliveryMode) {
      case 1: {
        if (completionStatus == "Completed") {
          // // console.log("link to complete")
          link = `/VirtualSession/2`;
          break;
        } else {
          // // console.log("normal vc link")
          link = `/VirtualSession/${nodeID}`;
          break;
        }
      }
      case 2: {
        link = `/TopicDetails/${nodeName}/${nodeID}/${batchID}/${deliveryMode}/${examMode}`;
        // localStorage.setItem("sessionnodename", nodeName);
        // localStorage.setItem("sessionnodeid", nodeID);
        // localStorage.setItem("sessionbatchid", batchID);
        break;
      }
      case 3: {
        link = `/VirtualSession`;
        break;
      }

      // this case below for assesment
      case 4: {
        link = `/TopicDetails/${nodeName}/${nodeID}/${batchID}/${deliveryMode}/${examMode}`;
        break;
      }
      case 5: {
        link = `/VirtualSession`;
        break;
      }
      case 6: {
        link = `/Feedback`;
        break;
      }
    }

    /*  if(deliveryMode == 2) {
    
    }
    else {
      link = `/courseIndex/vc/${nodeName}`;
    } */

    //var link = deliveryMode == 2 ? `/CourseIndex/TopicDetails/${nodeName}/${nodeID}/${batchID}` : ``;
    return link;
  };

  const setCourseDetailsInSession = (
    deliveryMode,
    nodeName,
    nodeID,
    batchID
  ) => {
    //debugger;
    localStorage.setItem("sessionnodename", nodeName);
    localStorage.setItem("sessionnodeid", nodeID);
    localStorage.setItem("sessionbatchid", batchID);
  };

  const firstLevelData = nodedata.filter(
    (node) => node.parentNodeId == courseNodeId
  );

  /* counting number of open sessions for my performance page */

  const openSessions = () => {
    const openVCCount = dataList.filter(
      (subnode) =>
        subnode.completionStatus === "Open" &&
        subnode.deliverymodename === "Virtual Class"
    ).length;

    const openSSCount = dataList.filter(
      (subnode) =>
        subnode.completionStatus === "Open" &&
        subnode.deliverymodename === "Self Study"
    ).length;
    const assessmentCount = dataList.filter(
      (subnode) =>
        subnode.completionStatus === "Open" &&
        subnode.deliverymodename === "Assessment"
    ).length;

    const assignmentCount = dataList.filter(
      (subnode) =>
        subnode.completionStatus === "Open" &&
        subnode.deliverymodename === "Assignment"
    ).length;
    const openSubnodeCount = dataList.filter(
      (subnode) => subnode.completionStatus === "Open"
    ).length;


    const OpenSessionCounts = {
      VirtualClass: openVCCount,
      SelfStudy: openSSCount,
      Assessment: assessmentCount,
      Assignment: assignmentCount,
      AllSessions: openSubnodeCount
    }
    localStorage.setItem("openSessions", JSON.stringify(OpenSessionCounts))
  };

  useEffect(() => {
    openSessions();
  }, [nodedata, courseLevel]);


  const updateBreadCrumb = (node, subnode) => {
    /* save node names and subnode names in session storage */
    // console.log("updateBreadCrumb node value : ", node);
    // console.log("updateBreadCrumb subnode value : ", subnode);
    localStorage.setItem("nodeData", JSON.stringify(node));
    localStorage.setItem("subNodeData", JSON.stringify(subnode));
    let arr = [];
    // // console.log("updateContentBreadCrumb called : ", subnode);
    arr.push(subnode);
    // // console.log("arr in session in content accordian : ", arr);
    localStorage.setItem("meeting", JSON.stringify(arr));
    if (subnode.deliveryMode == 1) {
      localStorage.setItem("topicDetails", JSON.stringify(arr));
    }
    dispatch(
      updateChild(
        node[`nodeTitle_${language}`] ||
        subnode[`nodeTitle_${prefferedLanguage}`] ||
        node.nodeName
      )
    );
    dispatch(
      updategrandChild(
        subnode[`nodeTitle_${language}`] ||
        subnode[`nodeTitle_${prefferedLanguage}`] ||
        subnode.nodeName
      )
    );
    if (
      subnode.deliveryMode === 3 ||
      // (subnode.deliveryMode ===4 && subnode.examMode==="Practical") ||
      // (subnode.deliveryMode ===4 && subnode.examMode==="Practical") ||
      subnode.deliveryMode === 5 ||
      subnode.deliveryMode === 6
    ) {
      setShowPopup(true);
    } else
      // // console.log("subnode.completionStatus", subnode.completionStatus);
      navigate(
        SessionRedirectionLink(
          subnode.deliveryMode,
          subnode.nodeName,
          subnode.nodeId,
          batchID,
          subnode.examMode,
          subnode.completionStatus,
        )
      );
    //dispatch(updateParent(coursedata.courseName))
  };

  const FilteredUpdateBreadCrumb = (subnode) => {
    /* save node names and subnode names in session storage */
    // // console.log("updateBreadCrumb node value : ",node);
    // // console.log("updateBreadCrumb subnode value : ", subnode);
    localStorage.setItem("nodeData", JSON.stringify(subnode));
    localStorage.setItem("subNodeData", JSON.stringify(subnode));
    let arr = [];
    // // console.log("updateContentBreadCrumb called : ", subnode);
    arr.push(subnode);
    // // console.log("arr in session : ", arr);
    localStorage.setItem("meeting", JSON.stringify(arr));
    dispatch(
      updateChild(
        subnode[`nodeTitle_${language}`] ||
        subnode[`nodeTitle_${prefferedLanguage}`] ||
        subnode.nodeName
      )
    );
    dispatch(
      updategrandChild(
        subnode[`nodeTitle_${language}`] ||
        subnode[`nodeTitle_${prefferedLanguage}`] ||
        subnode.nodeName
      )
    );
    if (
      subnode.deliveryMode === 3 ||
      // (subnode.deliveryMode ===4 && subnode.examMode==="Practical") ||
      // (subnode.deliveryMode ===4 && subnode.examMode==="Practical") ||
      subnode.deliveryMode === 5 ||
      subnode.deliveryMode === 6
    ) {
      setShowPopup(true);
    } else
      // // console.log("subnode.completionStatus", subnode.completionStatus);
      navigate(
        SessionRedirectionLink(
          subnode.deliveryMode,
          subnode.nodeName,
          subnode.nodeId,
          batchID,
          subnode.examMode,
          subnode.completionStatus,
        )
      );
    //dispatch(updateParent(coursedata.courseName))
  };

  const updateContentBreadCrumb = (subnode) => {
    // console.table("subnode : " + JSON.stringify(subnode))
    localStorage.setItem("subNodeData", JSON.stringify(subnode))
    let arr = [];
    // // console.log("updateContentBreadCrumb called : ", subnode);
    arr.push(subnode);
    // console.log("arr in session : ", arr);
    localStorage.setItem("meeting", JSON.stringify(arr));
    dispatch(updateChild("Content"));
    dispatch(updategrandChild(subnode.nodeName));
    if (
      subnode.deliveryMode === 3 ||
      // subnode.deliveryMode === 4 ||
      subnode.deliveryMode === 5 ||
      subnode.deliveryMode === 6
    ) {
      setShowPopup(true);
    } else
      navigate(
        SessionRedirectionLink(
          subnode.deliveryMode,
          subnode.nodeName,
          subnode.nodeId,
          batchID,
          subnode.examMode,
          subnode.completionStatus
        )
      );
  };

  // let dataList = nodedata.filter(
  //   (node) => node.nodeClassification === "Session"
  // );
  // // console.log("dataList : ", dataList);

  // // console.log("dataList : ", dataList);

  const filterActiveAcordian = () => {
    if (activeAccordion == 1) {
      setActiveAccordion(0);
    } else {
      setActiveAccordion(1);
    }
    // // console.log("accordion : " + activeAccordion);
  }

  const handleAccordianNo = (index) => {
    setActiveAccordion(index);
    // console.log("accordion : " + index);
    dispatch(updateAccordianNo(index));
  }



  // console.log("courseLevel final : ", courseLevel);
  if (courseLevel == 3) {
    return (
      <div>
       {/*  <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoader}

        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
                       {isLoader &&      <CustumLoader message="Loading..." />}

        <div className="accordion mb-4 p-0" id="accordionExample">
          <div class="accordion-item box-shadow">
            {showPopup && (
              <TestPopup
                show={true}
                txt={popuptxt}
                title="Coming Soon"
                onOk={(e) => setShowPopup(false)}
                onClose={(e) => setShowPopup(false)}
                type="prompt"
              />
            )}
            <div class="accordion-header topic-header s" id="headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <div >
                  {
                    filterVal == '4' ? <img src="../../Assets/Linqskills_Icons/assessment_topic_icon.svg" className="me-2"></img> : filterVal == '2' ? <img src="../../Assets/Linqskills_Icons/self_study_topic_icon.svg" className="me-2"></img> : filterVal == '1' ? <img src="../../Assets/Linqskills_Icons/virtual_class_topic_icon.svg" className="me-2"></img> : <img src="../../Assets/Linqskills_Icons/content-multicolorRed.svg" class="me-2 pink-box2 Mt-4px"></img>
                  }
                </div>
                <h5 class="m-0 ml-2 text-truncate">
                  {" "}
                  {
                    filterVal == '4' ? <Translate textKey="coursecontent006" /> : filterVal == '2' ? <Translate textKey="coursecontent004" /> : filterVal == '1' ? <Translate textKey="courseindex004" /> : <Translate textKey="courseindex003" />
                  }
                </h5>
                {/* <h5 class="m-0 text-truncate">Content</h5> */}
              </button>
            </div>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="accordionExample"
            >
              <div class="accordion-body border-top ">
                {dataList?.length > 0 ?
                  <>
                    {dataList.map((subnode, index) => {
                      // console.log("subnode in course level 3 : ", subnode);
                      let isSessionStarts = "true"
                      if (subnode.sessionReleaseStartDate != "1900-01-01T00:00:00" || subnode.sessionReleaseStartDate != null) {
                        // console.log("at index value : ", index)
                        // Convert the string to a Date object
                        const endDate = new Date(subnode.sessionReleaseStartDate);
                        // Get the current date and time
                        const currentDate = new Date();
                        // Compare the two dates
                        if (endDate > currentDate) {
                          // console.log("The session release date is in the future.");
                          isSessionStarts = "false"
                        } else {
                          // console.log("The session release date is right now!");
                        }
                      } else {
                        // console.log("session relaese date is not set yet");
                      }
                      // console.log("isSessionStarts in course level 3 : ", isSessionStarts);
                      // // console.log("subnode content : ", subnode)
                      return (
                        <div class="row" key={index}>
                          <div class="col-md-12 col-lg-12 d-flex pb-4 justify-content-start">
                            <div class="col-1 left-contentIcon ">
                              {subnode.completionStatus === "Open" && isSessionStarts == "true" && (
                                <img
                                  src="../../Assets/Linqskills_Icons/sessionstatus-open.svg"
                                  class="pe-2"
                                ></img>
                              )}
                              {subnode.completionStatus === "Open" && isSessionStarts == "false" && (
                                <img
                                  src="../../Assets/Linqskills_Icons/sessionstatus-locked.svg"
                                  class="pe-2"
                                ></img>
                              )}
                              {subnode.completionStatus === "Completed" && (
                                <img
                                  src="../../Assets/Linqskills_Icons/sessionstatus-completed.svg"
                                  class="pe-2"
                                ></img>
                              )}
                              {subnode.completionStatus === "Locked" && (
                                <img
                                  src="../../Assets/Linqskills_Icons/sessionstatus-locked.svg"
                                  class="pe-2"
                                ></img>
                              )}

                              {subnode.completionStatus === "Submitted" && (
                                <img
                                  src="../../Assets/Linqskills_Icons/sessionstatus-submitted.svg"
                                  class="pe-2"
                                ></img>
                              )}
                            </div>

                            <div class="col-lg-9 sas col-sm-10 ps-2 mt-6p">
                              <h5 class="bold-500 mb-1">
                                {subnode[`nodeTitle_${language}`] ||
                                  subnode[`nodeTitle_${prefferedLanguage}`] ||
                                  subnode.nodeName}{" "}
                              </h5>
                              {subnode.completionStatus === "" ? (
                                <></>
                              ) : (
                                <div class="col-lg-12 session-details">
                                  {subnode.deliverymodename === "Virtual Class" && (
                                    <>
                                      {" "}
                                      <div class="me-2 d-flex">
                                        <div>
                                          <img
                                            src="../../Assets/Linqskills_Icons/virtual-multicolorgreen.svg"
                                            class="pe-1"
                                          ></img>
                                        </div>
                                        <div>
                                          <span class="h7 align-self-center">
                                            {subnode.completionStatus === "Open" &&
                                              TimerFormat(subnode.duration)}
                                            {subnode.completionStatus ===
                                              "Locked" && TimerFormat(subnode.duration)}
                                            {subnode.completionStatus ===
                                              "Completed" && "Completed"}
                                          </span>
                                        </div>
                                      </div>
                                      {subnode.completionStatus === "Completed" && (
                                        <div class="me-2 d-flex">
                                          <div>
                                            <img
                                              src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                              class="pe-1"
                                            ></img>
                                          </div>
                                          <div>
                                            <span class="h7 align-self-center">
                                              {dateFormat(
                                                subnode.completedOn,
                                                "dd mmm yyyy  |  h:MM TT"
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                  {subnode.deliverymodename === "Self Study" && (
                                    <div class="me-2 d-flex">
                                      {
                                        isSessionStarts == "true" ?
                                          <>
                                            <div>
                                              <img
                                                src="../../Assets/Linqskills_Icons/interactivegallery-multicolor.svg"
                                                class="pe-1"
                                              ></img>
                                            </div>
                                          </> :
                                          <>
                                            <div>
                                              <img
                                                src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                                class="pe-1"
                                              ></img>
                                            </div>
                                          </>
                                      }

                                      <div>
                                        <span class="h7 align-self-center">
                                          {
                                            isSessionStarts == "true" ?
                                              <>
                                                {subnode.completionStatus === "Open" &&
                                                  TimerFormat(subnode.duration)}
                                                {subnode.completionStatus === "Locked" &&
                                                  TimerFormat(subnode.duration)}
                                              </> :
                                              <>
                                                {
                                                  (dateFormat(subnode.sessionReleaseStartDate, "mmm dd yyyy  |  h:MM TT"))
                                                }
                                              </>
                                          }

                                          {subnode.completionStatus ===
                                            "Completed" && "Completed"}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {subnode.deliverymodename === "Self Study" &&
                                    subnode.completionStatus === "Completed" && (
                                      <div class="me-2 d-flex">
                                        <div>
                                          <img
                                            src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                            class="pe-1"
                                          ></img>
                                        </div>
                                        <div class="">
                                          <span class="h7 align-self-center">
                                            {dateFormat(
                                              subnode.completedOn,
                                              "dd mmm yyyy  |  h:MM TT"
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  {(subnode.deliverymodename === "Assignment" ||
                                    subnode.deliverymodename === "Assessment") && (
                                      <>
                                        {" "}
                                        {
                                          isSessionStarts == "false" ?
                                            <>
                                              <div class="me-2 d-flex">
                                                {(subnode.completionStatus ===
                                                  "Completed" ||
                                                  subnode.completionStatus ===
                                                  "Submitted" ||
                                                  subnode.completionStatus ===
                                                  "Pending") ?
                                                  <>
                                                    <div>
                                                      <img
                                                        src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                                        class="pe-1"
                                                      ></img>
                                                    </div>
                                                  </> : ""
                                                }
                                                <div>
                                                  <span class="h7 align-self-center">
                                                    {(subnode.completionStatus ===
                                                      "Completed" ||
                                                      subnode.completionStatus ===
                                                      "Submitted" ||
                                                      subnode.completionStatus ===
                                                      "Pending") &&
                                                      dateFormat(
                                                        subnode.completedOn,
                                                        "dd mmm yyyy  |  h:MM TT"
                                                      )}

                                                    {(subnode.completionStatus ===
                                                      "Locked" ||
                                                      subnode.completionStatus ===
                                                      "Open") &&
                                                      subnode.sessionReleaseStartDate != "1900-01-01T00:00:00"
                                                      && subnode.sessionReleaseStartDate != null ?
                                                      <>
                                                        <div>
                                                          <img
                                                            src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                                            class="pe-1"
                                                          ></img>
                                                        </div>
                                                        {
                                                          (dateFormat(subnode.sessionReleaseStartDate, "mmm dd yyyy  |  h:MM TT"))
                                                        }
                                                      </> : ""
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </> :
                                            <>
                                              <div class="me-2 d-flex">
                                                <div>
                                                  {subnode.deliverymodename ===
                                                    "Assignment" && (
                                                      <img
                                                        src="../../Assets/Linqskills_Icons/assignment-multicolorgreen.svg"
                                                        class="pe-1"
                                                      ></img>
                                                    )}
                                                  {subnode.deliverymodename ===
                                                    "Assessment" && (
                                                      <img
                                                        src="../../Assets/Linqskills_Icons/assessment-multicolorgreen.svg"
                                                        class="pe-1 Mt-4px"
                                                      ></img>
                                                    )}
                                                </div>
                                                <div>
                                                  <span class="h7 align-self-center">
                                                    {(subnode.completionStatus === "Open" ||
                                                      subnode.completionStatus ===
                                                      "Locked") &&
                                                      TimerFormat(subnode.duration)}
                                                    {subnode.completionStatus ===
                                                      "Pending" && "Pending"}
                                                    {subnode.completionStatus ===
                                                      "Completed" && "Completed"}
                                                    {subnode.completionStatus ===
                                                      "Submitted" && "Submitted"}
                                                  </span>
                                                </div>
                                              </div>
                                              {
                                                (subnode.deliverymodename === "Assignment" ||
                                                  subnode.deliverymodename ===
                                                  "Assessment") &&
                                                (subnode.completionStatus === "Open" ||
                                                  subnode.completionStatus ===
                                                  "Locked") && (
                                                  <div class="me-2 d-flex">
                                                    <div>
                                                      <img
                                                        src="../../Assets/Linqskills_Icons/marks-multicolorgreen.svg"
                                                        class=""
                                                      ></img>
                                                    </div>
                                                    <div>
                                                      <span class="h7 align-self-center ms-1">
                                                        {subnode.totalMarks} marks
                                                      </span>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            </>
                                        }
                                      </>
                                    )}
                                </div>
                              )}
                            </div>
                            {(subnode.completionStatus === "Open" && isSessionStarts == "true" ||
                              subnode.completionStatus === "Completed" ||
                              subnode.completionStatus === "Submitted" ||
                              subnode.completionStatus === "Pending" ||
                              subnode.completionStatus === "") && (
                                <div
                                  class="col float-end"
                                  onClick={() => updateContentBreadCrumb(subnode)}
                                >
                                     {/* Do not delete this commented part it is required for next sprint */}
                                     {/* <div class="d-flex">
                                            <div>
                                            <img
                                            src="../../Assets/Linqskills_Icons/interactive-multicolorgreen.svg"
                                            class="float-end cursor-pointer"
                                          ></img>
                                              </div>
                                  
                                          <h5 class="display-desktop mb-0">View Content</h5>
                                            </div> */} 
                                            <div class="">
                                    <img
                                    src="../../Assets/Linqskills_Icons/forward-actionblue.svg"
                                    class="float-end cursor-pointer"
                                  ></img>

                                      </div>
                             
                                  </div>
                           
                             
                                
                              )}
                          </div>
                        </div>
                      );
                    })}
                  </>
                  :
                  <div class="row pb-3 ps-2"  >
                    {
                      nodeValue.length > 0 ?
                        <>
                          {
                            filterVal == "" ?
                              <p class="bold-500">The index page is loading</p> :
                              <p class="bold-500">There are no {filterVal == 1 ? "online lectures" : filterVal == 4 ? "assessment sessions" : filterVal == 2 ? "self-study sessions" : "lectures"} scheduled for this course</p>
                          }
                        </> :
                        <p class="bold-500">The index page is loading</p>
                    }

                  </div>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (courseLevel == 4 || courseLevel == 5) {
    // // console.log("firstLevelData : ", firstLevelData)

    return (
      <>
       {/*  <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoader}

        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
                       {isLoader &&      <CustumLoader message="Loading..." />}

        {filterVal != "" ?
          <>
            <div class=" accordion" id="accordionExample">
              <div class="bg-white box-shadow">
                <div class="accordion-header topic-header" id="headingOne" onClick={() => { filterActiveAcordian() }}>
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <div>
                      {/* <p class="number-badge mb-0">5</p> */}
                      {
                        filterVal == '4' ? <img src="../../Assets/Linqskills_Icons/assessment_topic_icon.svg" className=""></img> : filterVal == '2' ? <img src="../../Assets/Linqskills_Icons/self_study_topic_icon.svg" className="me-1"></img> : filterVal == '1' ? <img src="../../Assets/Linqskills_Icons/virtual_class_topic_icon.svg" className="me-1"></img> : ""
                      }

                    </div>

                    {" "}
                    <h5 class="m-0 ml-2 text-truncate">
                      {" "}
                      {
                    filterVal == '4' ? <Translate textKey="coursecontent006" /> : filterVal == '2' ? <Translate textKey="coursecontent004" /> : filterVal == '1' ? <Translate textKey="courseindex004" /> : <Translate textKey="courseindex003" />
                  }
                    </h5>
                  </button>
                </div>
              </div>
              <div>

                <div
                  class={`accordion-collapse mT-1 collapse ${activeAccordion != 0 ? 'show' : ''}`}
                  aria-labelledby="headingOne"
                  data-bs-parent="accordionExample"
                >
                  {showPopup && (
                    <TestPopup
                      show={true}
                      txt={popuptxt}
                      title="Coming Soon"
                      onOk={(e) => setShowPopup(false)}
                      onClose={(e) => setShowPopup(false)}
                      type="prompt"
                    />
                  )}
                  <div class="accordion-body border-top">
                    {
                      sublevel.length > 0 ?
                        <>
                          {sublevel.map((subnode, index) => {
                            // // console.log("subnode at inisde filter content : ", subnode)
                            return (
                              <div class="row" key={index}>
                                <div class="col-md-12 col-lg-12 d-flex pb-4 justify-content-start">
                                  <div class="col-1 left-contentIcon ">
                                    {subnode.completionStatus === "Open" && (
                                      <img
                                        src="../../Assets/Linqskills_Icons/sessionstatus-open.svg"
                                        class="pe-2"
                                      ></img>
                                    )}
                                    {subnode.completionStatus === "Completed" && (
                                      <img
                                        src="../../Assets/Linqskills_Icons/sessionstatus-completed.svg"
                                        class="pe-2"
                                      ></img>
                                    )}
                                    {subnode.completionStatus === "Locked" && (
                                      <img
                                        src="../../Assets/Linqskills_Icons/sessionstatus-locked.svg"
                                        class="pe-2"
                                      ></img>
                                    )}

                                    {subnode.completionStatus === "Submitted" && (
                                      <img
                                        src="../../Assets/Linqskills_Icons/sessionstatus-submitted.svg"
                                        class="pe-2"
                                      ></img>
                                    )}
                                  </div>

                                  <div class="col-lg-9 col-sm-10 ps-2 mt-6p">
                                    <h5 class="bold-500 mb-1">
                                      {subnode[`nodeTitle_${language}`] ||
                                        subnode[
                                        `nodeTitle_${prefferedLanguage}`
                                        ] ||
                                        subnode.nodeName}
                                    </h5>
                                    {subnode.completionStatus === "" ? (
                                      <></>
                                    ) : (
                                      <div class="col-lg-12 session-details">
                                        {subnode.deliverymodename ===
                                          "Virtual Class" && (
                                            <>
                                              {" "}
                                              <div class="me-2 d-flex">
                                                <div>
                                                  <img
                                                    src="../../Assets/Linqskills_Icons/virtual-multicolorgreen.svg"
                                                    class="pe-1"
                                                  ></img>
                                                </div>
                                                <div>
                                                  <span class="h7 align-self-center">
                                                    {subnode.completionStatus ===
                                                      "Open" && TimerFormat(subnode.duration)}
                                                    {subnode.completionStatus ===
                                                      "Locked" && TimerFormat(subnode.duration)}
                                                    {subnode.completionStatus ===
                                                      "Completed" && "Completed"}
                                                  </span>
                                                </div>
                                              </div>
                                              {subnode.completionStatus ===
                                                "Completed" && (
                                                  <div class="me-2 d-flex">
                                                    <div>
                                                      <img
                                                        src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                                        class="pe-1"
                                                      ></img>
                                                    </div>
                                                    <div>
                                                      <span class="h7 align-self-center">
                                                        {dateFormat(
                                                          subnode.completedOn,
                                                          "dd mmm yyyy  |  h:MM TT"
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                              {subnode.completionStatus ===
                                                "Open" && (
                                                  <div class="me-2 d-flex">
                                                    {subnode.sessionStart != null ?
                                                      <>
                                                        <div>
                                                          <img
                                                            src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                                            class="pe-1"
                                                          ></img>
                                                        </div>
                                                        <div>
                                                          <span class="h7 align-self-center">
                                                            {dateFormat(
                                                              subnode.sessionStart,
                                                              "dd mmm yyyy  |  h:MM TT"
                                                            )}
                                                          </span>
                                                        </div>
                                                      </> : ""}
                                                  </div>
                                                )}
                                            </>
                                          )}
                                        {subnode.deliverymodename ===
                                          "Self Study" && (
                                            <div class="me-2 d-flex">
                                              <div>
                                                <img
                                                  src="../../Assets/Linqskills_Icons/interactivegallery-multicolor.svg"
                                                  class="pe-1"
                                                ></img>
                                              </div>
                                              <div>
                                                <span class="h7 align-self-center">
                                                  {subnode.completionStatus ===
                                                    "Open" && TimerFormat(subnode.duration)}
                                                  {subnode.completionStatus ===
                                                    "Locked" && TimerFormat(subnode.duration)}
                                                  {subnode.completionStatus ===
                                                    "Completed" && "Completed"}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        {subnode.deliverymodename ===
                                          "Self Study" &&
                                          subnode.completionStatus ===
                                          "Completed" && (
                                            <div class="me-2 d-flex">
                                              <div>
                                                <img
                                                  src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                                  class="pe-1"
                                                ></img>
                                              </div>
                                              <div>
                                                <span class="h7 align-self-center">
                                                  {dateFormat(
                                                    subnode.completedOn,
                                                    "dd mmm yyyy  |  h:MM TT"
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          )}

                                        {(subnode.deliverymodename ===
                                          "Assignment" ||
                                          subnode.deliverymodename ===
                                          "Assessment") && (
                                            <>
                                              {" "}
                                              <div class="me-2 d-flex">
                                                <div>
                                                  {subnode.deliverymodename ===
                                                    "Assignment" && (
                                                      <img
                                                        src="../../Assets/Linqskills_Icons/assignment-multicolorgreen.svg"
                                                        class="pe-1"
                                                      ></img>
                                                    )}
                                                  {subnode.deliverymodename ===
                                                    "Assessment" && (
                                                      <img
                                                        src="../../Assets/Linqskills_Icons/assessment-multicolorgreen.svg"
                                                        class="pe-1 Mt-4px"
                                                      ></img>
                                                    )}
                                                </div>
                                                <div>
                                                  <span class="h7 align-self-center">
                                                    {(subnode.completionStatus ===
                                                      "Open" ||
                                                      subnode.completionStatus ===
                                                      "Locked") &&
                                                      TimerFormat(subnode.duration)}
                                                    {subnode.completionStatus ===
                                                      "Pending" && "Pending"}
                                                    {subnode.completionStatus ===
                                                      "Completed" && "Completed"}
                                                    {subnode.completionStatus ===
                                                      "Submitted" && "Submitted"}
                                                  </span>
                                                </div>
                                              </div>
                                              {(subnode.deliverymodename ===
                                                "Assignment" ||
                                                subnode.deliverymodename ===
                                                "Assessment") &&
                                                (subnode.completionStatus ===
                                                  "Open" ||
                                                  subnode.completionStatus ===
                                                  "Locked") && (
                                                  <div class="me-2 d-flex">
                                                    <div>
                                                      <img
                                                        src="../../Assets/Linqskills_Icons/marks-multicolorgreen.svg"
                                                        class=""
                                                      ></img>
                                                    </div>
                                                    <div>
                                                      <span class="h7 align-self-center ms-1">
                                                        {subnode.totalMarks} marks
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                              <div class="me-2 d-flex">
                                                {(subnode.completionStatus ===
                                                  "Completed" ||
                                                  subnode.completionStatus ===
                                                  "Submitted" ||
                                                  subnode.completionStatus ===
                                                  "Pending") ?
                                                  <>
                                                    <div>
                                                      <img
                                                        src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                                        class="pe-1"
                                                      ></img>
                                                    </div>
                                                  </> : ""
                                                }

                                                <div>
                                                  <span class="h7 align-self-center">
                                                    {(subnode.completionStatus ===
                                                      "Completed" ||
                                                      subnode.completionStatus ===
                                                      "Submitted" ||
                                                      subnode.completionStatus ===
                                                      "Pending") &&
                                                      dateFormat(
                                                        subnode.completedOn,
                                                        "dd mmm yyyy  |  h:MM TT"
                                                      )
                                                    }

                                                    {(subnode.completionStatus ===
                                                      "Locked" ||
                                                      subnode.completionStatus ===
                                                      "Open") &&
                                                      subnode.sessionReleaseStartDate != "1900-01-01T00:00:00"
                                                      && subnode.sessionReleaseStartDate != null ?
                                                      <>
                                                        <div>
                                                          <img
                                                            src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                                            class="pe-1"
                                                          ></img>
                                                        </div>
                                                        {(dateFormat(subnode.sessionReleaseStartDate, "mmm dd yyyy  |  h:MM TT")
                                                          + " to " + dateFormat(subnode.sessionReleaseEndDate, "mmm dd yyyy  |  h:MM TT")
                                                        )}
                                                      </> : ""
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                  {(subnode.completionStatus === "Open" ||
                                    subnode.completionStatus === "Completed" ||
                                    subnode.completionStatus === "Submitted" ||
                                    subnode.completionStatus === "Pending" ||
                                    subnode.completionStatus === "") && (
                                      <div
                                        class="col float-end"
                                        onClick={() =>
                                          FilteredUpdateBreadCrumb(subnode)
                                        }
                                      >
                                         {/* Do not delete this commented part it is required for next sprint */}
                                   {/* <div class="d-flex">
                                            <div>
                                            <img
                                            src="../../Assets/Linqskills_Icons/interactive-multicolorgreen.svg"
                                            class="float-end cursor-pointer"
                                          ></img>
                                              </div>
                                  
                                          <h5 class="display-desktop mb-0">View Content</h5>
                                            </div> */}
                                            <div class="">

                                        <img
                                          src="../../Assets/Linqskills_Icons/forward-actionblue.svg"
                                          class="float-end cursor-pointer"
                                        ></img>
                                        </div>
                                          </div>
                                     
                                    )}
                                </div>
                              </div>
                            );
                          })}
                        </> :
                        <div class="row pb-3 ps-2"  >
                          <p class="bold-500">There are no {filterVal == 1 ? "online lectures" : filterVal == 4 ? "asessments" : "self study"} scheduled for this course</p>
                        </div>
                    }
                  </div>
                </div>
              </div>


            </div>
          </>
          :
          <>
            <div class="row accordion" id="accordionExample">
              {firstLevelData.map((node, index) => {
                const sublevel = nodedata.filter(
                  (subnode) => subnode.parentNodeId == node.nodeId
                );
                // // console.log("sublevel ar 1152 : ", sublevel);
                const collapseOne = "collapseOne" + index;
                const collapseOneParent = "#collapseOne" + index;
                return (
                  <div>
                    <div
                      className="accordion mb-4 p-0"
                      id="accordionExample"
                      key={index}
                    >
                      <div class="accordion-item box-shadow">

                        <div class="accordion-header topic-header" id="headingOne" onClick={() => handleAccordianNo(index)}>
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={collapseOneParent}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <div>
                              <p class="number-badge mb-0">{index + 1}</p>
                            </div>

                            {" "}
                            <h5 class="m-0 text-truncate">
                              {" "}
                              {node[`nodeTitle_${language}`] ||
                                node[`nodeTitle_${prefferedLanguage}`] ||
                                node.nodeName}{" "}
                            </h5>
                          </button>
                        </div>

                        <div
                          id={collapseOne}
                          class={`accordion-collapse collapse ${index == activeAccordion ? 'show' : ''}`}
                          aria-labelledby="headingOne"
                          data-bs-parent="accordionExample"

                        >
                          {showPopup && (
                            <TestPopup
                              show={true}
                              txt={popuptxt}
                              title="Coming Soon"
                              onOk={(e) => setShowPopup(false)}
                              onClose={(e) => setShowPopup(false)}
                              type="prompt"
                            />
                          )}
                          <div class="accordion-body border-top">
                            {sublevel.map((subnode, index1) => {
                              // // console.log("subnode at content here : ", subnode)
                              let isSessionStarts = "true"
                              if (subnode.sessionReleaseStartDate != "1900-01-01T00:00:00" || subnode.sessionReleaseStartDate != null) {
                                // // console.log("at index value : ", index1)
                                // Convert the string to a Date object
                                const endDate = new Date(subnode.sessionReleaseStartDate);
                                // Get the current date and time
                                const currentDate = new Date();
                                // Compare the two dates
                                if (endDate > currentDate) {
                                  // // console.log("The session release date is in the future.");
                                  isSessionStarts = "false"
                                } else {
                                  // // console.log("The session release date is right now!");
                                }
                              } else {
                                // // console.log("session relaese date is not set yet");
                              }

                              return (
                                <div class="row" key={index}>
                                  <div class="col-md-12 col-lg-12 d-flex pb-4 justify-content-start">
                                    <div class="col-1 left-contentIcon ">
                                      {subnode.completionStatus === "Open" && isSessionStarts == "true" && (
                                        <img
                                          src="../../Assets/Linqskills_Icons/sessionstatus-open.svg"
                                          class="pe-2"
                                        ></img>
                                      )}
                                      {subnode.completionStatus === "Open" && isSessionStarts == "false" && (
                                        <img
                                          src="../../Assets/Linqskills_Icons/sessionstatus-locked.svg"
                                          class="pe-2"
                                        ></img>
                                      )}
                                      {subnode.completionStatus === "Completed" && (
                                        <img
                                          src="../../Assets/Linqskills_Icons/sessionstatus-completed.svg"
                                          class="pe-2"
                                        ></img>
                                      )}
                                      {subnode.completionStatus === "Locked" && (
                                        <img
                                          src="../../Assets/Linqskills_Icons/sessionstatus-locked.svg"
                                          class="pe-2"
                                        ></img>
                                      )}

                                      {subnode.completionStatus === "Submitted" && (
                                        <img
                                          src="../../Assets/Linqskills_Icons/sessionstatus-submitted.svg"
                                          class="pe-2"
                                        ></img>
                                      )}
                                    </div>

                                    <div class="col-lg-9 col-sm-10 ps-2 mt-6p">
                                      <h5 class="bold-500 mb-1">
                                        {subnode[`nodeTitle_${language}`] ||
                                          subnode[
                                          `nodeTitle_${prefferedLanguage}`
                                          ] ||
                                          subnode.nodeName}
                                      </h5>
                                      {subnode.completionStatus === "" ? (
                                        <></>
                                      ) : (
                                        <div class="col-lg-12 session-details">
                                          {subnode.deliverymodename ===
                                            "Virtual Class" && (
                                              <>
                                                {" "}
                                                <div class="me-2 d-flex">
                                                  <div>
                                                    <img
                                                      src="../../Assets/Linqskills_Icons/virtual-multicolorgreen.svg"
                                                      class="pe-1"
                                                    ></img>
                                                  </div>
                                                  <div>
                                                    <span class="h7 align-self-center">
                                                      {subnode.completionStatus ===
                                                        "Open" && TimerFormat(subnode.duration)}
                                                      {subnode.completionStatus ===
                                                        "Locked" && TimerFormat(subnode.duration)}
                                                      {subnode.completionStatus ===
                                                        "Completed" && "Completed"}
                                                    </span>
                                                  </div>
                                                </div>
                                                {subnode.completionStatus ===
                                                  "Completed" && (
                                                    <div class="me-2 d-flex">
                                                      <div>
                                                        <img
                                                          src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                                          class="pe-1"
                                                        ></img>
                                                      </div>
                                                      <div>
                                                        <span class="h7 align-self-center">
                                                          {dateFormat(
                                                            subnode.completedOn,
                                                            "dd mmm yyyy  |  h:MM TT"
                                                          )}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                                {subnode.completionStatus ===
                                                  "Open" && (
                                                    <div class="me-2 d-flex">
                                                      {subnode.sessionStart != null ?
                                                        <>
                                                          <div>
                                                            <img
                                                              src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                                              class="pe-1"
                                                            ></img>
                                                          </div>
                                                          <div>
                                                            <span class="h7 align-self-center">
                                                              {dateFormat(
                                                                subnode.sessionStart,
                                                                "dd mmm yyyy  |  h:MM TT"
                                                              )}
                                                            </span>
                                                          </div>
                                                        </> : ""}
                                                    </div>
                                                  )}
                                              </>
                                            )}
                                          {subnode.deliverymodename ===
                                            "Self Study" && (
                                              <div class="me-2 d-flex">
                                                <div>
                                                  <img
                                                    src="../../Assets/Linqskills_Icons/interactivegallery-multicolor.svg"
                                                    class="pe-1"
                                                  ></img>
                                                </div>
                                                <div>
                                                  <span class="h7 align-self-center">
                                                    {subnode.completionStatus ===
                                                      "Open" && TimerFormat(subnode.duration)}
                                                    {subnode.completionStatus ===
                                                      "Locked" && TimerFormat(subnode.duration)}
                                                    {subnode.completionStatus ===
                                                      "Completed" && "Completed"}
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          {subnode.deliverymodename ===
                                            "Self Study" &&
                                            subnode.completionStatus ===
                                            "Completed" && (
                                              <div class="me-2 d-flex">
                                                <div>
                                                  <img
                                                    src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                                    class="pe-1"
                                                  ></img>
                                                </div>
                                                <div>
                                                  <span class="h7 align-self-center">
                                                    {dateFormat(
                                                      subnode.completedOn,
                                                      "dd mmm yyyy  |  h:MM TT"
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            )}

                                          {(subnode.deliverymodename ===
                                            "Assignment" ||
                                            subnode.deliverymodename ===
                                            "Assessment") && (
                                              <>
                                                {" "}
                                                {
                                                  isSessionStarts == "false" ?
                                                    <>
                                                      <div class="me-2 d-flex">
                                                        {(subnode.completionStatus ===
                                                          "Completed" ||
                                                          subnode.completionStatus ===
                                                          "Submitted" ||
                                                          subnode.completionStatus ===
                                                          "Pending") ?
                                                          <>
                                                            <div>
                                                              <img
                                                                src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                                                class="pe-1"
                                                              ></img>
                                                            </div>
                                                          </> : ""
                                                        }

                                                        <div>
                                                          <span class="h7 align-self-center">
                                                            {(subnode.completionStatus ===
                                                              "Completed" ||
                                                              subnode.completionStatus ===
                                                              "Submitted" ||
                                                              subnode.completionStatus ===
                                                              "Pending") &&
                                                              dateFormat(
                                                                subnode.completedOn,
                                                                "dd mmm yyyy  |  h:MM TT"
                                                              )
                                                            }

                                                            {(subnode.completionStatus ===
                                                              "Locked" ||
                                                              subnode.completionStatus ===
                                                              "Open") &&
                                                              subnode.sessionReleaseStartDate != "1900-01-01T00:00:00"
                                                              && subnode.sessionReleaseStartDate != null ?
                                                              <>
                                                                <div>
                                                                  <img
                                                                    src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                                                    class="pe-1"
                                                                  ></img>
                                                                </div>
                                                                {(dateFormat(subnode.sessionReleaseStartDate, "mmm dd yyyy  |  h:MM TT")
                                                                )}
                                                              </> : ""
                                                            }
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </> :
                                                    <>
                                                      <div class="me-2 d-flex">
                                                        <div>
                                                          {subnode.deliverymodename ===
                                                            "Assignment" && (
                                                              <img
                                                                src="../../Assets/Linqskills_Icons/assignment-multicolorgreen.svg"
                                                                class="pe-1"
                                                              ></img>
                                                            )}
                                                          {subnode.deliverymodename ===
                                                            "Assessment" && (
                                                              <img
                                                                src="../../Assets/Linqskills_Icons/assessment-multicolorgreen.svg"
                                                                class="pe-1 Mt-4px"
                                                              ></img>
                                                            )}
                                                        </div>
                                                        <div>
                                                          <span class="h7 align-self-center">
                                                            {(subnode.completionStatus ===
                                                              "Open" ||
                                                              subnode.completionStatus ===
                                                              "Locked") &&
                                                              TimerFormat(subnode.duration)}
                                                            {subnode.completionStatus ===
                                                              "Pending" && "Pending"}
                                                            {subnode.completionStatus ===
                                                              "Completed" && "Completed"}
                                                            {subnode.completionStatus ===
                                                              "Submitted" && "Submitted"}
                                                          </span>
                                                        </div>
                                                      </div>
                                                      {
                                                        (subnode.deliverymodename ===
                                                          "Assignment" ||
                                                          subnode.deliverymodename ===
                                                          "Assessment") &&
                                                        (subnode.completionStatus ===
                                                          "Open" ||
                                                          subnode.completionStatus ===
                                                          "Locked") && (
                                                          <div class="me-2 d-flex">
                                                            <div>
                                                              <img
                                                                src="../../Assets/Linqskills_Icons/marks-multicolorgreen.svg"
                                                                class=""
                                                              ></img>
                                                            </div>
                                                            <div>
                                                              <span class="h7 align-self-center ms-1">
                                                                {subnode.totalMarks} marks
                                                              </span>
                                                            </div>
                                                          </div>
                                                        )
                                                      }
                                                    </>
                                                }
                                              </>
                                            )}
                                        </div>
                                      )}
                                    </div>
                                    {(subnode.completionStatus === "Open" && isSessionStarts == "true" ||
                                      subnode.completionStatus === "Completed" ||
                                      subnode.completionStatus === "Submitted" ||
                                      subnode.completionStatus === "Pending" ||
                                      subnode.completionStatus === "") && (
                                        <div
                                          class="col float-end"
                                          onClick={() =>
                                            updateBreadCrumb(node, subnode)
                                          }
                                        >
                                           {/* Do not delete this commented part it is required for next sprint */}
                                          {/* <div class="d-flex">
                                            <div>
                                            <img
                                            src="../../Assets/Linqskills_Icons/interactive-multicolorgreen.svg"
                                            class="float-end cursor-pointer"
                                          ></img>
                                              </div>
                                  
                                          <h5 class="display-desktop mb-0">View Content</h5>
                                            </div> */}
                                            <div class="">
                                          <img
                                            src="../../Assets/Linqskills_Icons/forward-actionblue.svg"
                                            class="float-end cursor-pointer"
                                          ></img>
                                            </div>
                                      
                                        </div>
                                      )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        }

      </>
    );
  }





  // const intervalId = setInterval(() => {
  //   // // console.log("Get the current time inside set interval")
  //   function isToday(date) {
  //     const today = new Date();
  //     // 👇️ Today's date
  //     // // console.log(today);
  //     if (today.toDateString() === date.toDateString()) {
  //       return true;
  //     }
  //     return false;
  //   }
  //   // // console.log("isToday(new Date('2023-08-11') : ", isToday(new Date(meetingDataArr[0])));

  //   if (isToday(new Date(meetingDataArr[0]))) {
  //     const convertTime12to24 = (time12h) => {
  //       const [time, modifier] = time12h.split(' ');

  //       let [hours, minutes] = time.split(':');

  //       if (hours === '12') {
  //         hours = '00';
  //       }

  //       if (modifier === 'PM') {
  //         hours = parseInt(hours, 10) + 12;
  //       }

  //       return `${hours}:${minutes}`;
  //     }


  //     // // console.log("convertTime12to24('meetingDataArr[1]') : ", convertTime12to24(`${meetingDataArr[1]} ${meetingDataArr[2]}`));
  //     let meetingTimeIn24hrFormat = convertTime12to24(`${meetingDataArr[1]} ${meetingDataArr[2]}`)
  //     let targetTime = new Date(); // Create a new date object to avoid modifying the current time
  //     targetTime.setMinutes(targetTime.getMinutes() - 5);
  //     targetTime = targetTime.toLocaleString('en-US', { hour12: false })
  //     // // console.log("targetTime before : ", targetTime);

  //     const targetTimeArr = targetTime.split(" ");
  //     // // console.log("targetTimeArr line 103 : meetingDataArr[1] :", meetingDataArr[1], targetTimeArr[1]);

  //     function strToMins(t) {
  //       var s = t.split(":");
  //       return Number(s[0]) * 60 + Number(s[1]);
  //     }

  //     function minsToStr(t) {
  //       return Math.trunc(t / 60) + ':' + ('00' + t % 60).slice(-2);
  //     }
  //     var result = minsToStr(strToMins(meetingDataArr[1]) - strToMins(targetTimeArr[1]));
  //     // // console.log("result", result);
  //     if (meetingDataArr[1] < targetTimeArr[1]) {
  //       // console.log("inside meetingDataArr[1] < targetTimeArr[1]")
  //       if (result <= "0:59") {
  //         // console.log("result <= 0:59 in condition");
  //         // Enable the button if the current time is equal to or after the target time
  //         // setIsButtonEnabled(true);
  //         clearInterval(intervalId);
  //       } else {
  //         // console.log("opposite result <= 0:59 in condition");
  //         // Disable the button if the current time is before the target time
  //         // setIsButtonEnabled(false);
  //       }
  //     } else {
  //       // console.log("outside meetingDataArr[1] < targetTimeArr[1]")
  //       if (result <= "0:05") {
  //         // console.log("result <= 0:05 in condition");
  //         // Enable the button if the current time is equal to or after the target time
  //         // setIsButtonEnabled(true);
  //         clearInterval(intervalId);
  //       } else {
  //         // console.log("oppsite result <= 0:05 in condition");
  //         // Disable the button if the current time is before the target time
  //         // setIsButtonEnabled(false);
  //       }
  //     }
  //   }
  // }, 4000);

  // comments

  {/*<h5 class="accordion-header " id="headingOne">*/ }
  {/*    <button class="accordion-button accordion-btn primary-radius" type="button" data-bs-toggle="collapse" data-bs-target={collapseOneParent} aria-expanded="true" aria-controls="collapseOne">*/ }
  {/*        <p class="badge bg-secondary mb-0 me-3 cirlce-badge1">{(index + 1)}</p>*/ }
  {/*        <h5 class="mb-0">{node.nodeName}</h5>*/ }
  {/*    </button>*/ }
  {/*</h5>*/ }

  {/*<div class="pink-box me-2"><img src="../../Assets/Linqskills_Icons/assessment-multicolorRed.png" class="me-1"></img></div>*/ }

  //<div class="row">
  //    <div class="col-md-12 col-lg-12 d-flex pb-4">
  //        <div class="col-lg-1 left-contentIcon">
  //            <img src="../../Assets/Linqskills_Icons/Ongoing content_24.svg" class="pe-2"></img>
  //        </div>
  //        <div class="col-lg-11 col-sm-10">
  //            <h5 class="bold-500">Session 2 : What did you understand?</h5>
  //            <h6>
  //                <img src="../../Assets/Linqskills_Icons/Watch in progress_16.svg" class="pe-2"></img>
  //                2 min - Watch
  //            </h6>
  //        </div>
  //        <div class="col flex-shrink-1">
  //            <img src="../../Assets/Linqskills_Icons/Forword Arrow_32.svg" class="float-end"></img>
  //        </div>
  //    </div>
  //</div>

  //<div class="row">
  //    <div class="col-md-12 col-lg-12 d-flex pb-4">
  //        <div class="col-lg-1 left-contentIcon">
  //            <img src="../../Assets/Linqskills_Icons/Not Available Content_24.svg" class="pe-2"></img>
  //        </div>
  //        <div class="col-lg-11 col-sm-10">
  //            <h5 class="bold-500">Session 3 : Handling Sales Objection</h5>
  //            <h6>
  //                <img src="../../Assets/Linqskills_Icons/Read_16.svg" class="pe-2"></img>
  //                30 min - Read
  //            </h6>
  //        </div>
  //        <div class="col flex-shrink-1">
  //            <img src="../../Assets/Linqskills_Icons/Forword Arrow_32.svg" class="float-end"></img>
  //        </div>

  //    </div>
  //</div>

  //<div class="row">
  //    <div class="col-md-12 col-lg-12 d-flex pb-4">
  //        <div class="col-lg-1 left-contentIcon">
  //            <img src="../../Assets/Linqskills_Icons/Available content_24.svg" class="pe-2"></img>
  //        </div>

  //        <div class="col-lg-11 col-sm-10">
  //            <h5 class="bold-500">Session 4 : Value Proposition Matters </h5>

  //            <h6>
  //                <img src="../../Assets/Linqskills_Icons/Listen Selected_16.svg" class="pe-2"></img>
  //                30 min - Listen
  //            </h6>
  //        </div>
  //        <div class="col flex-md-shrink-1">
  //            <img src="../../Assets/Linqskills_Icons/Forword Arrow_32.svg" class="float-end"></img>

  //        </div>
  //    </div>

  //</div>
  //<div class="accordion-item box-shadow mb-3">
  //    <h2 class="accordion-header" id="headingTwo">
  //        <button class="accordion-button accordion-btn collapsed primary-radius" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
  //            <p class="badge bg-secondary mb-0 me-2"> 1.2</p>

  //            <h5 class="mb-0">Negotiation of sales</h5>
  //        </button>
  //    </h2>
  //    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
  //        <div class="accordion-body">
  //            <div class="row">
  //                <div class="col-md-12 col-lg-12 d-flex pb-4">
  //                    <div class="col-1 left-contentIcon">
  //                        <img src="../../Assets/Linqskills_Icons/Not Available Content_24.svg" class="pe-2"></img>
  //                    </div>

  //                    <div class="col w-100">
  //                        <h5 class="bold-500">Negotiation Time Line and Closing</h5>

  //                        <h6>
  //                            <img src="../../Assets/Linqskills_Icons/Watch in progress_16.svg" class="pe-2"></img>
  //                                    6 min - Live
  //                                </h6>
  //                    </div>
  //                    <div class="col flex-md-shrink-1">
  //                        <img src="../../Assets/Linqskills_Icons/Forword Arrow_32.svg" class="float-end"></img>

  //                    </div>
  //                </div>
  //            </div>

  //            <div class="row">
  //                <div class="col-md-12 col-lg-12 d-flex pb-4">
  //                    <div class="col-lg-1 left-contentIcon">
  //                        <img src="../../Assets/Linqskills_Icons/Not Available Content_24.svg" class="pe-2"></img>
  //                    </div>

  //                    <div class="col-lg-11 col-sm-10">
  //                        <h5 class="bold-500">Practical Session</h5>

  //                        <h6>
  //                            <img src="../../Assets/Linqskills_Icons/Read_16.svg"></img>
  //                                                30 min - Read
  //                                </h6>
  //                    </div>
  //                    <div class="col flex-shrink-1">
  //                        <img src="../../Assets/Linqskills_Icons/Forword Arrow_32.svg" class="float-end"></img>

  //                    </div>
  //                </div>
  //            </div>

  //            <div class="row">

  //                <div class="col-md-12 col-lg-12 d-flex pb-4">
  //                    <div class="col-lg-1 left-contentIcon">
  //                        <img src="../../Assets/Linqskills_Icons/locked content_24.svg" class="pe-2"></img>

  //                    </div>

  //                    <div class="col-lg-11 col-sm-10">
  //                        <h5 class="bold-500">Assessment</h5>

  //                        <h6>
  //                            <img src="../../Assets/Linqskills_Icons/Assessment_16.svg"></img>
  //                                                            30 Min
  //                                </h6>
  //                    </div>
  //                    <div class="col flex-shrink-1">
  //                        <img src="../../Assets/Linqskills_Icons/Forword Arrow_32.svg" class="float-end"></img>

  //                    </div>
  //                </div>
  //            </div>
  //        </div>
  //    </div>
  //</div>

  //<div className="accordion-item box-shadow p-3 ps-3 pe-3 mt-3">
  //    <h5 class="mb-0"> <img src="../../Assets/Linqskills_Icons/locked content_24.svg" class="pe-2"></img>
  //       Feedback</h5>
  //</div>

  //<div className="accordion-item box-shadow p-3 ps-3 pe-3 mt-3">
  //    <h5 class="mb-0">Assessment Obj & Sub</h5>
  //</div>

  //<div className="accordion-item box-shadow p-3 ps-3 pe-3 mt-3 mb-3">
  //    <h5 class="mb-0">Final Exams</h5>
  //</div>
}
