
import React, { useState, useEffect } from "react";

import { variables } from '../Shared/Variables';
import TextField from '../Shared/TextField';
import Translate from '../Shared/Translate';
import ActionBtnText from '../Shared/ActionBtnText';
import Cookies from 'universal-cookie';
import PrimaryButton from '../Shared/Primarybtn';
import { data } from "jquery";
import axios from "axios";
import { generateSessionToken, validateSessionToken } from 'session-id-token';
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { updateIndex, updateIsUserLoggedIn, updateNodeVal, callingDispose, updateVideo, updateIsDevice, updateIsMobile, updateVideoIndex, updateActiveStage1List, updateActiveStage4List, updateIsProgram, updateProgramCourses } from "../../features/CourseIndex/courseIndexSlice";

const LoginForm = ({ setauthenticated, setForgotPassword, setShowRegistration }) => {
    const isMobileNo = useSelector(state => state.courseindex.isMobileNo);

    const initialValues = { username: "", password: "", selectedUserId: 0, roleTypeAdmin: "", accValAdmin: "" }
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [usernames, setusernames] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isMultiUser, setMultiUser] = useState(false);
    const [disabledClass, setdisabledClass] = useState("disabled-btn");
    const [disabled, setdisabled] = useState(true);
    const [ipAddress, setIPAddress] = useState('')
    const [accVal, setAccVal] = useState("");
    const [isAccount, setIsAccount] = useState(false);
    const [isAdminLogin, setIsAdminLogin] = useState(false);
    const [rolesArr, setRolesArr] = useState([]);
    const cookies = new Cookies();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Define your mobile screen width here
    useEffect(() => {
        const handleWindowSizeChange = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        cookies.set("language", "English");
        localStorage.setItem("prefferedLanguage", "English")
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    // console.log("isMobile isMobile : ", isMobile);

    const [selectStyle, setSelectStyle] = useState("form-select form-dropdown input-bg select-change-placeholder");

    const dispatch = useDispatch();



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value })
        if (e.target.value != "") {
            setdisabledClass("");
            setdisabled(false)
        }
        else {
            setdisabledClass("disabled-btn");
            setdisabled(true)

        }


    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log("handleSubmit called : ", isAdminLogin)
        if (isAdminLogin) {
            // console.log("working on admin login")
            setFormErrors({})
            setFormErrors(validateAdminLogin(formValues))
            setIsSubmit(true)
        } else {
            setFormErrors({})
            setFormErrors(validate(formValues))
            setIsSubmit(true)
        }
    };
    //const chkRememberMe = (e) => {
    //    //cookies.set('rememberMe', e.target.value);
    //    //setrememberMe(e.target.value);

    //};


    const setLanguage = (languageId) => {
        switch (languageId) {
            case 1:
                return 'English';
            case 2:
                return 'Hindi';
            case 3:
                return 'Tamil';
            case 4:
                return 'Bengali';
            case 5:
                return 'Telugu';
            case 6:
                return 'Odiya';
            default:
                return '';
        }
    }

    const logEveryoneOut = async () => {
        //// console.log("logged everyionev out")
        try {
            let url = variables.API_URL + 'api/LogEveryoneOut'
            const data = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    userId: localStorage.getItem('login-userId')

                })
            })

            const res = await data.json();


            // console.log(res, "logged everyionev out")
        } catch (error) {
            // console.log(error)
        }

    }

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    function generateString() {
        let result = ' ';
        const charactersLength = characters.length;
        for (let i = 0; i < 16; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }


    const createSession = async () => {
        // console.log("session created")
        try {
            let url = variables.API_URL + 'api/InserUserLoginDetails'
            const sessionToken = generateString();

            /*  const SIGNING_KEY = 'lInQsKiLlS';
            const sessionToken = generateSessionToken(SIGNING_KEY); 
            // console.log(sessionToken,"session")   */

            const data = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    userId: localStorage.getItem('login-userId'),
                    sessionID: sessionToken,
                    ipAddress: ipAddress,
                    deviceInfo: isMobile ? "Mobile" : "Desktop"

                })
            })

            const res = await data.json();
            if (res.isSuccess) {
                const expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() + 1);
                cookies.set('sessionId', sessionToken, { path: '/', expires: expirationDate })
            }
            // console.log(res, "insert details")

        } catch (error) {
            // console.log(error)
        }

    }

    useEffect(() => {
        if (isMobileNo != "") {
            setFormValues({ ...formValues, ["username"]: isMobileNo })
        }
    }, [isMobileNo]);

    useEffect(() => {
        // // console.log("formValues in use", formValues)
        if (formValues.username?.length > 0) {
            setdisabled(false);
        }
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            submitLogin();
        }
        formErrors.username = "";
        formErrors.password = "";
    }, [formErrors]);





    // const getGeoLocation = async () => {
    //     await fetch("https://geolocation-db.com/json/62d8d690-e7b8-11ed-8c81-cbb990727ab5")
    //     .then(response => response.json() )
    //     .then( data => // console.log("data of geo location : ", data))
    // }
    const validate = (values) => {
        const errors = {}
        if (!values.username) {
            errors.username = "Login003";//"Username is required";
        }
        if (!values.password) {
            errors.password = "Login005";//"Password is required";
        }
        return errors;
    }
    const validateAdminLogin = (values) => {
        const errors = {}
        if (!values.username) {
            errors.username = "Login003";//"Username is required";
        }
        if (!values.password) {
            errors.password = "Login005";//"Password is required";
        }
        if (!values.password) {
            errors.password = "Login005";//"Password is required";
        }
        if (!values.password) {
            errors.password = "Login005";//"Password is required";
        }

        return errors;
    }




    async function submitLogin() {
        // getGeoLocation();
        const errors = {}

        await fetch(variables.API_URL + 'api/LoginUser', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                UserName: formValues.username,
                Password: formValues.password,
                /*  sessionID: sessionToken,
                 ipAddress: ipAddress,
                 Device: isMobile ? "Mobile" : "Desktop",
                 LoginErrMsg:"" */
            })

        })
            .then(res => res.json())
            .then(async (result) => {
                // // console.log("result",result)
                if (result.isSuccess == true) {
                    localStorage.clear();
                    localStorage.setItem("JWTToken", result.jwtToken)
                    if (result.dataList.length > 1) {
                        setMultiUser(true);
                        setusernames(result.dataList);
                    } else {
                        if (result.dataList[0].userId != 0) {
                            // console.log("result.dataList[0] in login : ", result.dataList[0]);
                            var loggedInUser = result.dataList[0].userId;
                            var loggedInUserName = result.dataList[0].userName;
                            var loggedFirstName = result.dataList[0].firstName;
                            var prefferedLanguage = result.dataList[0].userPreferredLanguageId;
                            // var prefferedLanguage = 0;
                            localStorage.setItem("login-userId", loggedInUser);
                            localStorage.setItem("login-userName", loggedInUserName);
                            localStorage.setItem("login-firstName", loggedFirstName);
                            // temp line below need to remove
                            // localStorage.setItem("courseIdRegisterFor" , 147);
                            localStorage.setItem("prefferedLanguage", setLanguage(prefferedLanguage));
                            cookies.set('language', setLanguage(prefferedLanguage))
                            if (isMobile) {
                                localStorage.setItem("isDevice", "mobile");
                                dispatch(updateIsDevice('mobile'));
                            } else {
                                localStorage.setItem("isDevice", "desktop");
                                dispatch(updateIsDevice('desktop'));
                            }
                            //localStorage.setItem("userSelectedTestLan",prefferedLanguage)
                            dispatch(updateIndex('CO'));
                            dispatch(updateIsMobile(""));
                            dispatch(updateNodeVal([]));
                            dispatch(updateActiveStage1List([]));
                            dispatch(updateActiveStage4List([]));
                            dispatch(callingDispose(false));
                            dispatch(updateVideo(false));
                            dispatch(updateIsUserLoggedIn(true));
                            dispatch(updateVideoIndex("-1"));
                            dispatch(updateProgramCourses([]));
                            dispatch(updateIsProgram(false));
                            //cookies.set('username', result.dataList[0].userName);
                            // cookies.set('password', result.dataList[0].password);
                            createSession().then(() => {

                                setauthenticated(loggedInUser);
                            })
                            logEveryoneOut().then(() => {

                            })
                        } else {
                            // alert("User is wrong");
                            errors.password = "Login011";//"Incorrect password";
                            errors.username = "Login010";//"Could not find the Login ID";
                            setFormErrors(errors)
                        }
                    }
                } else {
                    if (result.returnMessage == "Invalid Password")
                        errors.password = "Login011";//"Incorrect password";
                    else
                        errors.username = "Login010";//"Could not find the Login ID";

                    setFormErrors(errors)
                }
            }, (error) => {
            })
    }



    function multiuserLogin() {
        var loggedInUser = formValues.selectedUserId;
        //update logn details call api
        //localStorage.clear();
        localStorage.setItem("login-userId", loggedInUser)

        const resultFromUsernames = usernames.filter((item) => {
            return item.userId == loggedInUser
        })
        console.log("resultFromUsernames : ", resultFromUsernames);

        var loggedInUser = resultFromUsernames[0].userId;
        var loggedInUserName = resultFromUsernames[0].userName;
        var loggedFirstName = resultFromUsernames[0].firstName;
        var prefferedLanguage = resultFromUsernames[0].userPreferredLanguageId;
        localStorage.setItem("login-userName", loggedInUserName);
        localStorage.setItem("login-firstName", loggedFirstName);
        // temp line below need to remove
        // localStorage.setItem("courseIdRegisterFor" , 147);
        localStorage.setItem("prefferedLanguage", setLanguage(prefferedLanguage));
        cookies.set('language', setLanguage(prefferedLanguage))
        if (isMobile) {
            localStorage.setItem("isDevice", "mobile");
            dispatch(updateIsDevice('mobile'));
        } else {
            localStorage.setItem("isDevice", "desktop");
            dispatch(updateIsDevice('desktop'));
        }
        //localStorage.setItem("userSelectedTestLan",prefferedLanguage)
        dispatch(updateIndex('CO'));
        dispatch(updateIsMobile(""));
        dispatch(updateNodeVal([]));
        dispatch(updateActiveStage1List([]));
        dispatch(updateActiveStage4List([]));
        dispatch(callingDispose(false));
        dispatch(updateVideo(false));
        dispatch(updateIsUserLoggedIn(true));
        dispatch(updateVideoIndex("-1"));

        createSession().then(() => {
            setauthenticated(loggedInUser);
        })
        // logEveryoneOut().then(() => {

        // })
    }
    function onChangeValue(event) {
        // // console.log("working : ",event.target.value)
        formValues.selectedUserId = event.target.value;
    }
    function closeModal() {
        setMultiUser(false);

    }

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIPAddress(data.ip))
            .catch(error => console.log(error))
    }, []);

    const roleHandler = (e) => {
        // console.log("roleHandler : ", e.target.value)

        setFormValues({ ...formValues, roleTypeAdmin: e.target.value })
        if (e.target.value == "81") {
            setIsAccount(false);
        } else {
            setIsAccount(true);
        }
    }

    // console.log("formValues : ", formValues)
    // useEffect(() => {
    //     fethAllRoles();
    // }, [])

    // const fethAllRoles = async () => {
    //     // console.log("fethAllRoles called");
    //     const resAfterFetchingRoles = await axios.get(variables.API_URL + 'api/GetRoles');
    //     // console.log("resAfterFetchingRoles : ", resAfterFetchingRoles);
    //     if (resAfterFetchingRoles.data.isSuccess) {
    //         setRolesArr(resAfterFetchingRoles.data.dataList);
    //     }
    //     // https://betauatocelotegateway.linqskills.in/api/GetRoles
    // }

    const adminLoginHandler = () => {
        // console.log("adminLoginHandler : ");
        setIsAdminLogin(!isAdminLogin);
    }


    const downloadDummy = () => {
        // download();
    }

    return (
        <>


            <div className="row mt-5 heading-text ">
                <h3 className="text-center ps-1 pe-1 hin-h3"><Translate textKey="Login001" /></h3>
            </div>

            <div id="frmRegisterEGA" className="padding-new ">
                <div className="d-block mt-3 padding-desk" id="">
                    <div id="dvMobileNo" className="">
                        <form className="col-md-8 col-xl-8 m-auto" onSubmit={handleSubmit}>
                            {isAdminLogin ?
                                <>
                                    <TextField type="text"
                                        onchange={handleChange} name="username"
                                        value={formValues.username}
                                        classNm="change-one" label="Login ID"
                                        infoKey=""
                                        textKey="Login002"
                                        errorKey={formErrors.username}
                                        placeholderkey="Login003"
                                    />

                                    <TextField type="password"
                                        onchange={handleChange} name="password"
                                        value={formValues.password}
                                        classNm="change-one" label="Password"
                                        infoKey=""
                                        textKey="Login004"
                                        errorKey={formErrors.password}
                                        placeholderkey="Login005"
                                    />
                                    <div className="labelWrap">
                                        <label style={{ display: 'none' }}>
                                            <Translate textKey="Select Role" />
                                        </label>
                                        <label class="dropdown-label"><Translate textKey="Select Role" /><img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class=""></img></label>
                                        <img src="../../Assets/Linqskills_Icons/arrow_down_ios_24px.svg" class="dropdown-arrow"></img>
                                        <select class={selectStyle}
                                            onChange={roleHandler}
                                            name="stateid"
                                            aria-label="Default select example">
                                            <option value="" disabled selected >Select Role</option>
                                            {rolesArr.map((rolesItem) => {
                                                return (
                                                    <>
                                                        <option value={rolesItem.roleId}>{rolesItem.roleName}</option>
                                                    </>
                                                )
                                            })}
                                        </select>
                                    </div>

                                    {
                                        isAccount ?
                                            <>
                                                <div className="labelWrap">
                                                    <label style={{ display: 'none' }}>
                                                        <Translate textKey="Select Role" />
                                                    </label>
                                                    <label class="dropdown-label"><Translate textKey="Select Account" /><img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class=""></img></label>
                                                    <img src="../../Assets/Linqskills_Icons/arrow_down_ios_24px.svg" class="dropdown-arrow"></img>
                                                    <select class={selectStyle}
                                                        onChange={roleHandler}
                                                        name="stateid"
                                                        aria-label="Default select example">
                                                        <option value="" disabled selected >Select Account</option>
                                                        {rolesArr.map((rolesItem) => {
                                                            return (
                                                                <>
                                                                    <option value={rolesItem.roleId}>{rolesItem.roleName}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </> : ""
                                    }
                                </> :
                                <>
                                    <TextField type="text"
                                        onchange={handleChange} name="username"
                                        value={formValues.username}
                                        classNm="change-one" label="Login ID"
                                        infoKey=""
                                        textKey="Login002"
                                        errorKey={formErrors.username}
                                        placeholderkey="Login003"
                                    />

                                    <TextField type="password"
                                        onchange={handleChange} name="password"
                                        value={formValues.password}
                                        classNm="change-one" label="Password"
                                        infoKey=""
                                        textKey="Login004"
                                        errorKey={formErrors.password}
                                        placeholderkey="Login005"

                                    />
                                </>
                            }
                            <div className="next-prev-padding d-flex btnMT">
                                <PrimaryButton type="submit" disabled={disabled} disabledClass={disabledClass} textKey="Login007" />

                            </div>
                            <ActionBtnText textKey="Login008" onClick={() => setForgotPassword(true)} />

                            {/* <ActionBtnText textKey={isAdminLogin ? "Candidate Login" : "Admin Login"} onClick={() => adminLoginHandler()} /> */}
                            {/*<div className="next-prev-padding d-flex btnMT">*/}
                            {/*    <button type="button" id="btnSubmit" onClick={() => setShowRegistration(true)} className="continue-popup btn secondary-btn w-100"><Translate textKey="Login009" /></button>*/}
                            {/*</div>*/}
                        </form>
                    </div>
                </div>
            </div>
            {
                isMultiUser ?
                    <>
                        <div className="row">
                            <div className="popup-one p-3 text-lg-left">
                                <img src="../../Assets/Linqskills_Icons/popup-close.svg" className="cursor-pointer float-end close-icon" onClick={closeModal}></img>
                                <div className="pb-3">
                                    <div class="d-flex mb-3 p-0">
                                        <div>
                                            <img src="../../Assets/Linqskills_Icons/select-user.svg" className="pe-2 mt-1"></img>
                                        </div>
                                        <div>
                                            <h6 className="mb-0 mt-2">

                                                <Translate textKey="Login012" />
                                            </h6>
                                        </div>
                                    </div>

                                    <p className="h7 m-0 p-0">

                                        <Translate textKey="userprofile021" />
                                    </p>

                                </div>
                                {usernames.map((user) => (
                                    <div className="form-check mb-3" key={user.userId}  >

                                        <label className="form-check-label cursor-pointer" for={user.userId}>
                                            <input className="form-check-input cursor-pointer" onChange={onChangeValue} type="radio" name="MultiUserId" value={user.userId} id={user.userId}></input>
                                            {user.userName}
                                        </label>
                                    </div>
                                ))}
                                <div className="next-prev-padding d-flex mt-4">
                                    <PrimaryButton type="button" onClick={multiuserLogin} disabled={disabled} textKey="Login007" />

                                </div>
                            </div>
                        </div>

                        <section className="modal overlay">
                        </section>
                    </>
                    : null
            }
        </>
    )
}
export default LoginForm;