import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  parentNode: '',
  childNode:'',
  extendedBreadCrumb: "false",
  grandchildNode: '',
  greatgrandchildNode: '',
  currenNodeIdAt : "",
  isProgramFromRedux : false,
  programCourses : [],
  logoutProfile : true,
};

const breadcrumbSlice = createSlice({
    name:"breadcrumb",
    initialState,
    reducers:{
        updateParent : (state,action)=>{
            state.parentNode = action.payload
        },
        updateChild : (state,action)=>{
            state.childNode = action.payload
        },
        updateExtended : (state,action)=>{
            state.extendedBreadCrumb = action.payload
        },
        updategrandChild : (state,action)=>{
            // // console.log("updating grand child at : ",action);
            state.grandchildNode = action.payload
        },
        updateggChild : (state,action)=>{
            // // console.log("updating gg child : ",action);
            state.greatgrandchildNode = action.payload
        },
        updateCurrentNodeIdAt : (state,action)=>{
            // // console.log("updating current node at : ",action);
            state.currenNodeIdAt = action.payload
        },
        updatIsProgramFromRedux : (state,action)=>{
            // // console.log("updating gg child : ",action);
            state.isProgramFromRedux = action.payload
        },
        updateProgramCourses : (state,action)=>{
            // // console.log("calling updateNextPrevUpdated : ",action)
            state.programCourses = action.payload
        },
        updateLogoutProfile: (state,action)=>{
            //console.log("logout : ",state.logoutProfile)
            state.logoutProfile = action.payload
        }
    }
})

export const { updateChild,updateParent,updateExtended,updateggChild,updategrandChild,updateCurrentNodeIdAt,updatIsProgramFromRedux,updateProgramCourses,updateLogoutProfile} = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;