import React,{useState,useEffect,useRef} from "react";
import CompletedTest from './CompletedTest';
import TopicsList from "../Shared/TopicsList";
import { variables } from '../Shared/Variables';
import NavOne from "../NavOne";
import VideoPlayer from "../Shared/VideoPlayer";
import moment from "moment";
import Breadcrumb from "../CourseIndex/Breadcrumb";
import SessionHeading from "../EligibilityCheck/SessionHeading";
import { useNavigate } from 'react-router-dom';
import { SSRProvider } from "react-bootstrap";


export default function VcComplete(){
    
   const webinarDetails= JSON.parse(localStorage.getItem('webinardetails'));
   var course = JSON.parse(localStorage.getItem('coursedata'));
//    var topicData= JSON.parse(localStorage.getItem('topicDetails'));
   var CompletedOn = new Date();
   const shouldLog = useRef(true);
   let [data, setData] = useState({});
  
   const meetingData = localStorage.getItem("meeting");
   const parsedMeetingData = JSON.parse(meetingData);
   // console.log("meetingData in vc complete : ", parsedMeetingData);

 const getSessionRecording=() => {
    let meetingNumber =parseFloat(webinarDetails[0].webinarMeetingId);
  
 fetch(variables.API_URL + 'api/GetSesionRecording/' + meetingNumber,
 {
     method: 'GET',
     headers: {
         'Authorization': 'Bearer ' + localStorage.getItem("JWTToken")
     },
 })
 .then(response => {
     if (response.statusText == "Unauthorized") {
         this.setState({ isUnauthorizedUser: true });
         
     } else
         return response.json();
         
       
 })
 .then(res => {
     if (res.isSuccess) { 
        data = res.data;  
        setData(data);
   }
 });

 }

 const setRedirect=() => {
     let nodeId = parsedMeetingData[0].nodeId;
     // console.log("nodeId val : ")
      if(nodeId != undefined){
        if(nodeId == 0){
            let strVar = window.location.origin +`/#/VirtualSession/1`
            window.location.href = strVar;
            const endFlag= localStorage.getItem('endSession')
            if(endFlag==='true')
            {
                localStorage.setItem("endSession", 'false');   
                window.location.reload();
            }
        } else {
            let strVar = window.location.origin +`/#/VirtualSession/${nodeId}`
            window.location.href = strVar;
            const endFlag= localStorage.getItem('endSession')
            if(endFlag==='true')
            {
                localStorage.setItem("endSession", 'false');   
                window.location.reload();
            }
        }
    }
 }

     useEffect(() => {    
        if (shouldLog.current) {
            shouldLog.current = false;
            setRedirect()
            getSessionRecording()
            //alert('Refresh the Page to End the Session.')
        }       
     }, [data,meetingData])



    return(
        <>        
        
        <NavOne />
        <div class="container-fluid body-wrapper bg-default">
        <div class="container">
         <Breadcrumb redirectLink="CourseIndex" /> 
       <div class="row pb-4">
       {/* <TopicsList sessionType="meeting" sessionName ={course.courseName} mainTitle={course.courseName} subTitle ={course.courseName} topicsListData={topicData}/> */}
       <div className="col-lg-9 col-sm-12 col-xs-12 content-cards bg-body box-one m-0">
                   <div className="">
                   <SessionHeading name={webinarDetails[0].topic} startTime={webinarDetails[0].startTime} completedOn={webinarDetails[0].completedOn} meetingStatus={webinarDetails[0].meetingStatus} duration={webinarDetails[0].duration + " mins"}  startDateTime = {webinarDetails[0].sessionStart}  deliveryMode="1"></SessionHeading>                           
           {

            (data) ?
            <VideoPlayer src={data} />
            :
          <CompletedTest dontShowNavBtns={false} isShowGoToIndexBtn={true} testCompletedOn={ moment(CompletedOn).add('day').format("DD MMM YYYY | hh:mm A")}  Message="The video recording will be made available after" ImgSrc="../../Assets/Linqskills_Icons/test-submited.svg"/>
          } 

  
    </div>
    
    </div>
    </div>
    </div>
    </div>
    </>
    )
}


