
import React, { useState, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
//import { Redirect } from "react-router-dom";
//import TrainerMessage from '../../components/CourseIndex/TrainerMessage.js';
//import Translate from '../Shared/Translate';
//import Cookies from 'universal-cookie';
//import PopupOne from '../Shared/PopupOne';
// import TestSubmitImg from './TestSubmitImg';
import CompletedTest from "../Shared/CompletedTest";
import { variables } from '../Shared/Variables';
import LanguagePopup from '../Shared/LanguagePopup';
import TopicList from "../VirtualSession/TopicList";
import Cookies from 'universal-cookie';
import moment from "moment";
import { useDispatch,useSelector } from "react-redux";
import { updateChild, updateExtended, updateParent,updateggChild } from "../../features/breadcrumb/breadcrumbSlice";

//import AssessmentInstructions from './AssessmentInstructions';



export default function InstructionsContent(props) {
    const dispatch = useDispatch();
    const [instructionData, setInstructionData] = useState([]);
    const [startTest, setStartTest] = useState(false);
    const [instructions, setInstructions] = useState("");
    const shouldLog = useRef(true);
    const [noOfLanguage, setNoOfLanguage] = useState(0);
    const [openLanguage, setopenLanguage] = useState(false);
    const cookies = new Cookies();
    const [testCompleted, setTestCompleted] = useState(props.TestStatus);
    const [testLanguage, setTestLanguage] = useState(localStorage.getItem("userSelectedTestLan"));
    const langArray = [
        { value: '1', label: 'EN' },
        { value: '2', label: 'HI' },
        { value: '3', label: 'TA' },
        { value: '4', label: 'BN' },
        { value: '5', label: 'TE' },
        { value: '6', label: 'OR' },

    ];
    const [Lan, setLan] = useState(langArray.filter(data => data.value.includes(testLanguage)).map(val => (val.label)));
    // console.log("Lan " +testLanguage)

    function closeLanguage() {
        // // console.log("closeLanguage called")
        setopenLanguage(false)
    }
    function okLanguage() {
        // alert("ooo")

        getInstructions("okLanguage");

        setopenLanguage(false);
        setLan(langArray.filter(data => data.value.includes(parseInt(testLanguage))).map(val => (val.label)))
    }


    // this useEffect is only used for initail render of InstructionsContent page 
    useEffect(() => {
        // // console.log("working in useEffect")
        if (shouldLog.current) {
            shouldLog.current = false;
            getInstructions("useEffect");
        }
    }, []);


    function replaceAll(str, find, replace) {
        var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return str.replace(new RegExp(escapedFind, 'g'), replace);
    }
    const getInstructions = (callingFrom) => {

        fetch(variables.API_URL + 'api/GetEGAInstructions', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: props.UserId,
                testId: props.TestId,
                projectId: props.ProjectId,
                courseId: props.CourseId,
                courseNodeId: props.CourseNodeId,
                languageId: parseInt(testLanguage) == 0 ? 1 : parseInt(testLanguage),
                lessionPlanId: props.LessionPlanId

            })
        })
            .then(response => response.json())
            .then(result => {
                // // console.log("result.data.testLanguages",result.data.testLanguages)
                setNoOfLanguage(result.data.testLanguages.split(",").length)
                if (result.data.testLanguages.split(",").length > 1 && callingFrom == "useEffect") {
                    // // console.log("more then 1 language : ",result.data.testLanguages.split(",").length );
                    if (parseInt(localStorage.getItem("userSelectedTestLan")) === 0) {
                        setopenLanguage(true);
                    }
                } else {
                    // // console.log("only 1 language");
                    //localStorage.setItem("userSelectedTestLan", 1);
                    localStorage.setItem("userSelectedTestLan", parseInt(testLanguage));

                }
                setInstructionData(result.data);
                setInstructions(result.data.instructions != null ? (result.data.instructions).replaceAll("<div>", "").replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("</div>", "") : "");
            });



    };

    const handleStartTest = () => {
        const data = {
            UserId: props.UserId,
            TestId: props.TestId,
            ProjectId: props.ProjectId,
            CourseId: props.CourseId,
            status: props.TestStatus,
            templateId: props.templateId,
            totalMarks: instructionData.totalMarks,
            sequenceType: 1,
            AddOnCourseId: 0,
            duration: props.duration,
            LanguageId: parseInt(testLanguage),
            LessionPlanId: props.LessionPlanId,
            testName: props.TestName,
            IsAssessment: false,
            ParentNodeId: 0,
            BatchId: 0,

        };



        localStorage.setItem("TestData1", null);
        localStorage.setItem("TestData1", JSON.stringify(data));
        setStartTest(true);

    };
    // // console.log("instructionData.testLanguageIds : ",instructionData.testLanguages);

    // // console.log("openLanguage : ",openLanguage)
    if (startTest) {
        dispatch(updateggChild(props.TestName))
        return <Navigate to="/quizquestion" />;
        // UserId={props.UserId} TestId={props.TestId} ProjectId={props.ProjectId} CourseId={props.CourseId}
        //                        status={props.TestStatus} templateId={props.templateId} totalMarks={instructionData.totalMarks} sequenceType={1} AddOnCourseId={0} duration={props.duration} LanguageId={props.LanguageId} LessionPlanId={props.LessionPlanId}                                   
    } else { 

        // console.log("props.TestLanguageIds :: ",props.TestLanguageIds);
        // console.log("props.CompletedOn :: ",props.CompletedOn);
        // console.log("props in instruction content : ",props);
        return (
            <div className="col-lg-9 col-sm-12 col-xs-12 content-cards bg-body box-one  p-0 mb-3">

                {

                    (testCompleted === "C" || testCompleted === "E") ?
                        <div className="">
                            <div className="session-heading bg-body border-bottom">
                                <div className="d-flex">
                                    <div class="pink-box">

                                        <img src="../../Assets/Linqskills_Icons/assessment-multicolorRed.png" className=""></img>
                                    </div>
                                    <div class="heading-one">
                                        <h5 className="mb-0 text-truncate">{props.TestName}</h5>
                                    </div>

                                </div>
                                <div className="session-details d-flex pt-2 ps-1">
                                    <div class="f-flex me-2">
                                        <div>
                                            <img src="../../Assets/Linqskills_Icons/Marks-grey.svg" className="pe-1"></img>
                                        </div>
                                        <div>

                                            <span className="h7 self-align-center">
                                                {`${moment(props.CompletedOn).format("DD MMM YYYY")}  |  ${moment(props.CompletedOn).format("hh:mm A")}`}

                                                </span>
                                        </div>
                                    </div>


                                </div>

                            </div>
                            <CompletedTest isEGA={true} dontShowNavBtns={true} isShowGoToIndexBtn={false} isDateTime={true} testCompletedOn={props.CompletedOn} Message="The test was submitted" ImgSrc="../../Assets/Linqskills_Icons/test-submited.svg" />

                        </div>
                        :


                        <div className="">{instructionData.testLanguageIds}
                            {
                                (openLanguage) &&
                                <LanguagePopup closeEvent={closeLanguage} okEvent={okLanguage}
                                    header="Select the preferred language to take up the test"
                                    title="Select Medium of Instruction"
                                    ContentLanguages={props.TestLanguageIds}
                                    setTestLanguage={setTestLanguage}
                                    type="center"
                                    show={openLanguage}
                                />
                            }
                            <div className="session-heading bg-body border-bottom">
                                {noOfLanguage > 1 ?
                                    <div className="language-select float-end ss text-uppercase" onClick={() => setopenLanguage(true)}>
                                        <img src="../../Assets/Linqskills_Icons/Language_16.svg" className="pe-1 mb-1px"></img><span class="align-self-center">
                                            {Lan}
                                        </span>
                                    </div> : ""}
                                <div className="d-flex">
                                    <div class="pink-box">

                                        <img src="../../Assets/Linqskills_Icons/assessment-multicolorRed.png" className=""></img>
                                    </div>
                                    <div class="heading-one">
                                        <h5 className="mb-0 text-truncate">{props.TestName}</h5>
                                    </div>


                                </div>

                                <div className="session-details d-flex pt-2 ps-1">
                                    <div class="d-flex me-2">
                                        <div>
                                            <img src="../../Assets/Linqskills_Icons/Marks-grey.svg" className="me-1"></img>
                                        </div>
                                        <div>
                                            <span className="h7 self-align-center">
                                                {instructionData.totalMarks} marks</span>
                                        </div>
                                    </div>

                                    <div class="d-flex me-2">
                                        <div>
                                            <img src="../../Assets/Linqskills_Icons/Language_16.svg" className="me-1"></img>
                                        </div>
                                        <div>
                                            <span className="h7 self-align-center">
                                                {instructionData.testLanguages}</span>
                                        </div>
                                    </div>


                                </div>



                            </div>
                            <div className="assessment-instructions border-bottom">
                                <h6>Time limit to complete : <span className="b-text">{props.duration / 60} Min.</span></h6>
                                <h6>No of questions to attempt : <span className="b-text">{instructionData.totalQuestions}</span></h6>
                                <h6>Negative marking :  <span className="b-text">{instructionData.negativeMarks}</span></h6>
                                <h6>Attempts allowed :  <span className="b-text">{instructionData.reAttemptsNo}</span></h6>

                                <p className="instruction-info" dangerouslySetInnerHTML={{ __html: instructions }}></p>
                            </div>
                            <div className="row text-center pt-3 pb-3 click-start">
                                <h6>Click on the “Start” button to proceed</h6>
                                <button className="primary-btn m-auto text-uppercase" onClick={handleStartTest}>Start</button>
                            </div>
                        </div>
                }

            </div>
        )
    }

}


