import axios from 'axios';
import React, { useEffect, useState } from 'react';
import dateFormat from "dateformat";
import Cookies from "universal-cookie";
import { variables } from "../Shared/Variables";
import {
    updateChild,
    updategrandChild,
} from "../../features/breadcrumb/breadcrumbSlice";
import { useDispatch,useSelector} from "react-redux";
import { useNavigate } from "react-router";
import moment from "moment";
import { TimerFormat } from "../generalFunctions/timeFormat";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import VcAccordian from './vcAccordian';
import {updateOnlineLec} from '../../features/CourseIndex/courseIndexSlice'
import CustumLoader from '../Shared/CustumLoader';


const OnlineLecturesIndex = ({ title, batchId }) => {
    const cookies = new Cookies();
    const userId = localStorage.getItem("login-userId")
    const onlineLec = useSelector(state => state.courseindex.onlineLec);
    const [onlineVcList, setOnlineVcList] = useState([]);
    const [pastOnlineVcList, setPastOnlineVcList] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [acc2forPastVc, setAcc2forPastVc] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        getOnlineVcList();
    }, [])
    const getOnlineVcList = async () => {
        // // console.log("getOnlineVcList calling")
        if(onlineLec.length > 0){
            let orignalArray = onlineLec;
            let upcomingVc = orignalArray.filter((item, idx)=> {
                return item.meetingStatus == "upcoming";
            });
            let pastVc = orignalArray.filter((item, idx)=> {
                return item.meetingStatus == "past";
            });
            let reversedArray = [...upcomingVc].reverse();
            // console.log("upcomingVc : ",reversedArray);
            // console.log("pastVc : ",pastVc)
            setOnlineVcList(reversedArray);
            setPastOnlineVcList(pastVc);
        } else {
            setIsLoader(true);
            const body = {
                UserId: userId,
                BatchId: batchId,
                TimeZone: variables.TimeZone
            }
            const resAfterVcList = await axios.post(variables.API_URL + 'api/GetTraineeOnlineSessions', body);
            // console.log("resAfterVcList : ", resAfterVcList);
            if (resAfterVcList.data.isSuccess && resAfterVcList.data.dataList != null) {
                dispatch(
                  updateOnlineLec(
                    resAfterVcList.data.dataList
                  )
                );
                localStorage.setItem("onlineLec", JSON.stringify(resAfterVcList.data.dataList));
                // setOnlineVcList(resAfterVcList.data.dataList);
                let orignalArray = resAfterVcList.data.dataList;
                let upcomingVc = orignalArray.filter((item, idx)=> {
                    return item.meetingStatus == "upcoming";
                });
                let pastVc = orignalArray.filter((item, idx)=> {
                    return item.meetingStatus == "past";
                });
                let reversedArray = [...upcomingVc].reverse();
                // console.log("upcomingVc : ",reversedArray);
                // console.log("pastVc : ",pastVc)
                setOnlineVcList(reversedArray);
                setPastOnlineVcList(pastVc);
            }
            setIsLoader(false);
        }
    }
    // console.log("onlineVcList : ", onlineVcList);

    const updateContentBreadCrumb = (subnode, meetingStatus) => {
        let arr = [];
        // // console.log("updateContentBreadCrumb called : ", subnode);
        arr.push(subnode);
        localStorage.setItem("meeting", JSON.stringify(arr));
        dispatch(updateChild("Online Lectures"));
        dispatch(updategrandChild(subnode.topic));
        if (meetingStatus == "upcoming" && subnode.nodeId == 0) {
            navigate(`/VirtualSession/1`);
        } else if (subnode.nodeId != 0 && meetingStatus == "upcoming") {
            navigate(`/VirtualSession/${subnode.nodeId}`);
        } else {
            navigate(`/VirtualSession/2`);
        }
    };
    return (
        <div>
           {/*  <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoader}

            >
                <CircularProgress color="inherit" />
            </Backdrop> */}
                           {isLoader &&      <CustumLoader message="Loading..." />}

            {
              onlineVcList.length < 1 && pastOnlineVcList.length < 1 ? <VcAccordian vcAccordianName="Online Lectures" vcList={[]} updateContentBreadCrumb={updateContentBreadCrumb} acc2forPastVc={true} /> : 
              <>
              {/* {
                onlineVcList.length > 0 ?  : "" 
              } */}
              <VcAccordian vcAccordianName="Upcoming Online Lectures" vcList={onlineVcList} updateContentBreadCrumb={updateContentBreadCrumb} acc2forPastVc={true} />
              {
                pastOnlineVcList.length > 0 ? <VcAccordian vcAccordianName="Completed Online Lectures" vcList={pastOnlineVcList} updateContentBreadCrumb={updateContentBreadCrumb} setAcc2forPastVc={setAcc2forPastVc} acc2forPastVc={acc2forPastVc} /> : ""
              }
              </>
            } 
            
        </div>
    );
}

export default OnlineLecturesIndex;
