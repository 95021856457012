import { useNavigate } from "react-router";
import Cookies from "universal-cookie";
import { Modal } from "react-bootstrap";
import Translate from "../Shared/Translate";
//ABORT TEST
//   var txt = "<h5>You have attempted to abort the test. Do you wish to leave the test?</h5>"

//Incomplete TEST
//   var txt = "<h5>You have not completed all the questions in the test.</h5><h5> Do you still wish to End the test and submit for final score? </h5>"

//End Test
// var txt = "<h5>The test will now be submitted for final score. Do you wish to proceed? </h5>"

export default function TestPopup(props) {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="couponAppliedpop"
        backdrop={false}
      >
        <Modal.Body>
          <>
            <div class="popup-center text-lg-left select-medium">
              <img
                src="../../Assets/Linqskills_Icons/popup-close.svg"
                class="cursor-pointer float-end cross-icon2 "
                onClick={props.onClose}
              ></img>
              <div class="row">
                {props.title && (
                  <div class="d-flex mb-3 p-0">
                    <h6 class="m-0">
                      <img
                        src="../../Assets/Linqskills_Icons/popup-info.svg"
                        class=""
                      ></img>
                      {props.title}
                    </h6>
                  </div>
                )}
                <div class="d-flex mb-3 popup-bodytxt">
                  {/* <h5 class="m-0" id="testname">
                    {txt}   </h5> */}
                  <div dangerouslySetInnerHTML={{ __html: props.txt }} />
                </div>
              </div>

              {props.type == "prompt" ? (
                <div class="d-flex float-end mt-3 popups-btns">
                  <button class="pe-3 primary-btn w-128p  cursor-pointer" onClick={props.onOk}>
                  <Translate textKey="registration59" />
                  </button>
                </div>
              ) : props.type == "success" ? (
                ""
              ) : (
                <div class="d-flex float-end mt-3 popups-btns">
                     <button class="cursor-pointer secondary-btn me-3 w-128p" onClick={props.onClose}>
                     <Translate textKey="registration016" />
                  </button>
                  <button class="pe-3 primary-btn w-128p  cursor-pointer" onClick={props.onOk}>
                  <Translate textKey="registration59" />
                  </button>
                 
                </div>
              )}
            </div>
          </>
        </Modal.Body>
      </Modal>

      <section class="modal overlay"></section>
    </>
  );
}
