import React, { useEffect, useState, useRef, useMemo } from "react";
import LanguagePopup from '../Shared/LanguagePopup';
import Cookies from "universal-cookie";
import { variables } from '../Shared/Variables';
import ViewTutorialPopUp from "../CourseIndex/viewContentPopUp";
import SessionHeading from "../EligibilityCheck/SessionHeading";
import { Navigate, json, useNavigate, useParams } from "react-router-dom";
import videojs from "video.js";
import { Link, useLocation } from "react-router-dom";
import NavigationBtns from "./NavigationBtns";
import { useSelector, useDispatch } from "react-redux";
import { updateVideo, callingDispose, updateNextPrevUpdated, updateNodeVal, updateCallingGetTopicsList, updateIsContentNodeVal, updateTimmerRunning, updateVideoIndex } from "../../features/CourseIndex/courseIndexSlice";
import axios from "axios";
import { apiCallingForCourseStarted, ApiCallingForAuto } from "../generalFunctions/apiCalling.js";
// import ShowAnswers from "./ShowAnswers";
// import "video.js/dist/video-js.css";

const TopicContentData = ({ htmlStringArray, openVideoForSelfStudyTopicContent }) => {
    // // console.log("timeRemaining in timer : ",timeRemaining,time)

    return (
        <>
            {
                htmlStringArray.map((it, ind) => {
                    console.log("it in map for htmlStringArray : ", htmlStringArray.length, it, ind);
                    return (
                        <>
                            <div class="col-md-12 p-4 self-studyImg">
                                <>
                                    <div onClick={(e) => openVideoForSelfStudyTopicContent(e, ind)} dangerouslySetInnerHTML={{
                                        __html: it
                                    }} />
                                </>
                            </div>
                        </>
                    )
                })
            }
        </>
    );
};

const arePropsEqual = (prevProps, nextProps) => {
    // Custom comparison logic
    // console.log("prevProps in arePropsEqual : ",prevProps);
    // console.log("nextProps in arePropsEqual : ",nextProps);
    if (prevProps.topicContent.nodeID !== nextProps.topicContent.nodeID) {
        // console.log("inside prevProps.topicContent.nodeID !== nextProps.topicContent.nodeID returning false")
        return false;
    } else {
        return true; // Always return true to prevent re-rendering
    }
    // if(prevProps.topicsListData.length == nextProps.topicsListData.length){

    // }
};

const TopicContent = React.memo((props) => {
    let openVideoF = useSelector(state => state.courseindex.openVideo)
    let isdisposeCalled = useSelector(state => state.courseindex.isdisposeCalled);
    let timmerRuning = useSelector(state => state.courseindex.timmerRuning);
    let videoIndex = useSelector(state => state.courseindex.videoIndex);

    // console.log("isdisposeCalled kkkkk : ",isdisposeCalled);
    var nodeValue = useSelector(state => state.courseindex.nodeData);
    // console.log("openVideoF in useEffect : ", openVideoF);
    let playVideo = async (srcLink) => {
        // console.log("inside play video")
        console.log("srcLink srcLink srcLink : ", srcLink)
        const videoJsPlayer = videojs('example_video_1', {
            autoplay: true,
            controls: true,
            responsive: true,
            // fluid: true,
            sources: [{
                src: srcLink,
                type: 'video/mp4',
            }],
            disablePictureInPicture: true,
            playbackRates: [0.5, 1, 1.5, 2],
            controlBar: {
                skipButtons: {
                    forward: 10,
                    backward: 10
                }
            },
        });
    }
    const [videoUrl, setVideoUrl] = useState("");
    var coursedata = JSON.parse(localStorage.getItem("coursedata"));
    const dispatch = useDispatch();
    // alert("props.topicContent.nodeID : "+ props.topicContent.nodeID);
    const shouldLog = useRef(true);
    const timer = useRef(null);
    const [timerCount, setTimer] = useState(0)
    const cookies = new Cookies();
    const [languagePopupClicked, setLanguagePopupClicked] = useState(false);
    const [topicUpdated, setTopicUpdated] = useState(0);
    let nodeID = props.topicContent.nodeID;
    let sessionId = props.topicContent.sessionID;
    const totalTimeSpentByCandidate = props.currentTopicContent.timeSpentInTopic;
    var timeSpentCnt = totalTimeSpentByCandidate;
    // var finalTimeSpentCnt = timeSpentCnt;
    const [isShowModal, setIsShowModal] = useState(false);
    const [extensionType, setExtensionType] = useState("");
    const [linkUrl, setLinkUrl] = useState("");
    const [startTest, setStartTest] = useState(false);
    const langArray = [
        { value: '1', label: 'English' },
        { value: '2', label: 'Hindi' },
        { value: '3', label: 'Tamil' },
        { value: '4', label: 'Bengali' },
        { value: '5', label: 'Telugu' },
        { value: '6', label: 'Odiya' },
    ];

    const [htmlStringToDangerouslySet, setHtmlStringToDangerouslySet] = useState("");
    const [htmlStringArray, setHtmlStringArray] = useState([]);
    const [htmlStringArrayDuplicate, setHtmlStringArrayDuplicat] = useState([]);

    const [toggle, setToggle] = useState(false);
    const [openVideo, setOpenVideo] = useState(false);


    const navigate = useNavigate();
    const { name, id, batchId, deliveryMode, examMode } = useParams();  // Retrieve current route params
  
   

    useEffect(() => {
        if (shouldLog.current) {
            shouldLog.current = false;
        }
        document.addEventListener('click', aClick);
    }, []);
    // // console.log("props.showMainDiv : ", props.showMainDiv);
    useEffect(() => {
        // // console.log("props.showMainDiv : ", props.showMainDiv);
    }, [props.showMainDiv])

    const defaultLanguage = cookies.get('default-language')
    // const defaultLanguage = localStorage.getItem("prefferedLanguage");
    const Languages = props.topicContent?.contentLanguages?.split(",")
    const availableLanguages = Languages.filter((lan) => (lan != ""));
    localStorage.setItem("topicDetails", JSON.stringify(props.topicContent))

    // if (topicUpdated == 1) {
    //     var el = document.getElementById("hdnTopicUpdated");
    //     el.click();
    //     StartTimer();
    // }

    const aClick = (event) => {

        if (event.target.className === 'aCls') {
            // // console.log("111111111");
            /* clicking on link text will open pdf in popup */
            if ((event.target.getAttribute("fullUrl") == undefined) || (event.target.getAttribute("fullUrl") == "") || (event.target.getAttribute("fullUrl") == null)) {
                var hrefAttrVal = event.target.getAttribute("href");
                event.target.setAttribute("fullUrl", hrefAttrVal);
            }

            event.target.setAttribute("href", "javascript:void(0);");
            var hrefVal = event.target.getAttribute("fullUrl");
            var hrefStringVal = hrefVal.toString();
            setLinkUrl(hrefStringVal);
            var extension = hrefStringVal.split('.').pop();
            setExtensionType(extension);
            DisplayContentInModal();
        }
        else if (event.target.parentNode?.className === "aCls") {
            /* clicking on image will open pdf in popup */
            // // console.log("2222222222");
            event.preventDefault();
            let href = event.target.parentNode.href
            setLinkUrl(href)
            let extension = href.split('.').pop();
            setExtensionType(extension);
localStorage.setItem("href", href)
            DisplayContentInModal();
        }
        else if (event.target.hasAttribute('videourl')) {
            // // console.log("3333333333");
            /* iclicking on image will open video in popup */
            let url = event.target.getAttribute('videourl')
            setLinkUrl(url)
            let extension = url.split('.').pop();
            setExtensionType(extension);
            // console.log("extension aCls: ", extension);
            if (extension == "mp4") {
                // console.log("dont display popup");
            } else {
                DisplayContentInModal();
            }
        }
    };

    const DisplayContentInModal = () => {

        setIsShowModal(true);
    }

    const CloseModalPopup = () => {
        setIsShowModal(false);
        disposeVideoJs()
    }

    const StartTimer = (comingFrom) => {
        if (props.currentTopicContent.sessionDeliveryMode === 2 && props.currentTopicContent.timeSpentInTopic < 30) {
            var timeSpentCnt = totalTimeSpentByCandidate;
            cookies.set("nodeTimeSpentCnt", timeSpentCnt);
            // Increase the time by 1 every second
            // console.log("timmerRuning in startTimer() : ",timmerRuning);
            if (timmerRuning) {
                timer.current = setInterval(() => {
                    timeSpentCnt = ++timeSpentCnt;

                    cookies.set("nodeTimeSpentCnt", timeSpentCnt);
                    // console.log(timeSpentCnt);
                    setTimer((timerCount) => timerCount + 1)
                    // When the time spent reaches 30s, unlock the immediate next topic 

                    if (timeSpentCnt == 30) {
                        UpdateTopicStatus();
                        clearInterval(timer.current);
                        setTimer(0);
                    }
                    // else if(comingFrom == "goToIndexPage") {
                    //     // console.log("doing clear interval calling from gotoindexpage");
                    //     clearInterval(timer);
                    // }
                }, 1000);
            }
            // // console.log("props in topic content : ", props);
            // Cleanup the interval on component unmount or re-render
            return () => {
                clearInterval(timer.current);
            };
        }
    }

    // // console.log("props: " + JSON.stringify(props.topicContent))

    const UpdateTopicStatus = () => {
        // alert("calling UpdateTopicStatus");
        var userID = localStorage.getItem("login-userId");
        userID = encodeURIComponent(userID);
        // // console.log("cookies.get(nodeTimeSpentCnt) : ", cookies.get("nodeTimeSpentCnt"))
        // console.log("props.topicsListData in UpdateTopicStatus : ",props.topicsListData)
        if (props.topicContent.sessionDeliveryMode == 2) {
            fetch(variables.API_URL + 'api/UpdateTopicStatus',
                {
                    method: 'Post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        UserID: userID,
                        NodeID: nodeID,
                        BatchID: props.batchId,
                        TimeSpent: cookies.get("nodeTimeSpentCnt") //timeSpentCnt
                        // TimeSpent: "30"
                    }),
                })
                .then(response => {
                    if (response.statusText == "Unauthorized") {
                        this.setState({ isUnauthorizedUser: true });
                    }
                    else
                        return response.json();
                })
                .then(data => {
                    // console.log("result after api/UpdateTopicStatus : ", data);
                    if (data.isSuccess) {
                        const isAllTopicCompleted = props.topicsListData.filter((it) => {
                            return it.topicStatus == "Open";
                        })
                        // console.log("isAllTopicCompleted : ", isAllTopicCompleted);
                        if (isAllTopicCompleted.length < 2) {
                            // console.log("topics are completed, last topic is running")
                            UpdateSessionStatus();
                        } else {
                            // console.log("topics are still not completed");
                            let nextPrevDataIn = JSON.parse(localStorage.getItem("nextPrevData"));

                            for (let i = 0; i < nextPrevDataIn.data.userDataList.length; i++) {
                                if (nextPrevDataIn.data.userDataList[i].nodeID == nodeID && nextPrevDataIn.data.userDataList[i].sessionID == sessionId) {

                                    // console.log("changing current node topic status");
                                    nextPrevDataIn.data.userDataList[i].topicStatus = "Completed"
                                    if (nextPrevDataIn.data.userDataList[i + 1].topicStatus == "Locked") {
                                        // // console.log("opening next session in self study");
                                        // alert("opening next session in self study");
                                        nextPrevDataIn.data.userDataList[i + 1].topicStatus = "Open"
                                    }
                                }
                                // if (nextPrevData.data.userDataList[i].topicStatus == "Locked") {
                                //     nextPrevData.data.userDataList[i].topicStatus = "Open"
                                //     return false
                                // }
                            }

                            dispatch(updateNextPrevUpdated(true));
                            dispatch(updateCallingGetTopicsList(true));
                            localStorage.setItem("nextPrevData", JSON.stringify(nextPrevDataIn));
                        }
                    }
                    // if (data.isSuccess) {
                    //     props.topicContent.timeSpentInTopic = timeSpentCnt;
                    //     if (!props.isUpdateTopicTimeSpent) {
                    //         var el = document.getElementById("hdnTopicUpdated");
                    //         el.click();
                    //         shouldLog.current = true;
                    //     }
                    //     else {
                    //         shouldLog.current = false;
                    //     }
                    // }
                });
        }
    }

    const UpdateSessionStatus = async () => {

        let nextPrevData = await JSON.parse(localStorage.getItem("nextPrevData"));
        let nodeVal = JSON.parse(localStorage.getItem("nodeVal"));
        let coursedata = JSON.parse(localStorage.getItem("coursedata"));
        // console.log("nodeVal : ",nodeVal);
        var userID = localStorage.getItem("login-userId");
        userID = encodeURIComponent(userID);

        // // console.log("cookies.get(nodeTimeSpentCnt) : ", cookies.get("nodeTimeSpentCnt"))
        // console.log("props.topicContent in UpdateSessionStatus : ",props.topicContent);
        if (props.topicContent.sessionDeliveryMode == 2) {
            fetch(variables.API_URL + 'api/UpdateTopicStatus',
                {
                    method: 'Post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        UserID: userID,
                        NodeID: sessionId,
                        BatchID: props.batchId,
                        TimeSpent: cookies.get("nodeTimeSpentCnt") //timeSpentCnt
                        // TimeSpent: "30"
                    }),
                })
                .then(response => {
                    if (response.statusText == "Unauthorized") {
                        this.setState({ isUnauthorizedUser: true });
                    }
                    else
                        return response.json();
                })
                .then(data => {
                    // console.log("result after api in UpdateSessionStatus: ", data);
                    // // console.log("nextPrevData?.data.userDataList : ",nextPrevData?.data.userDataList);
                    for (let i = 0; i < nextPrevData?.data.userDataList.length; i++) {
                        if (nextPrevData.data.userDataList[i]?.nodeID == nodeID && nextPrevData.data.userDataList[i].sessionID == sessionId) {
                            // // console.log("changing current node topic status");
                            nextPrevData.data.userDataList[i].topicStatus = "Completed"
                            if (nextPrevData.data.userDataList[i + 1]?.topicStatus == "Locked") {
                                // console.log("opening next session in self study");
                                // alert("opening next session in self study");`1   
                                nextPrevData.data.userDataList[i + 1].topicStatus = "Open"
                            }
                            for (let k = 0; k < nodeVal.length; k++) {
                                // // console.log("inside 270 line ; ",nodeValue[k].sessionID + " ;" + nextPrevData.data.userDataList[i].sessionID + " ;" + nodeValue[k].nodeClassification)
                                if (nodeVal[k].sessionID == nextPrevData.data.userDataList[i].sessionID && nodeVal[k].nodeClassification == "Session") {
                                    nodeVal[k].completionStatus = "Completed"

                                    if (nodeVal[k + 1]?.completionStatus == "Locked" && nodeVal[k + 1]?.nodeClassification == "Session" && nodeVal[k + 1]?.deliveryMode != 1) {
                                        // alert("opening next seesion");
                                        nodeVal[k + 1].completionStatus = "Open"
                                    } else if (nodeVal[k + 2]?.completionStatus == "Locked" && nodeVal[k + 2]?.nodeClassification == "Session" && nodeVal[k + 2]?.deliveryMode != 1) {
                                        // alert("opening after module seesion");
                                        if (coursedata.courseLevel != 3 && nodeVal[k + 1]?.nodeClassification != "Session") {
                                            nodeVal[k + 2].completionStatus = "Open"
                                        } else {
                                            // // console.log("course level is 3 thats why didnt changes status to open");
                                        }
                                    }
                                    // // console.log("nodeVal after updating : ", nodeVal);
                                    dispatch(updateNodeVal(nodeVal));
                                    dispatch(updateIsContentNodeVal(true));
                                    localStorage.setItem("nodeVal", JSON.stringify(nodeVal));
                                }
                            }
                        }
                        // if (nextPrevData.data.userDataList[i].topicStatus == "Locked") {
                        //     nextPrevData.data.userDataList[i].topicStatus = "Open"
                        //     return false
                        // }
                    }
                    dispatch(updateNextPrevUpdated(true));
                    localStorage.setItem("nextPrevData", JSON.stringify(nextPrevData));
                    apiCallingForCourseStarted(userID, props.batchId);
                    if (data.coursestatus == "True" && data.generateCertificate == "Auto") {
                        ApiCallingForAuto(coursedata);
                    }
                    // if (data.isSuccess) {
                    //     props.topicContent.timeSpentInTopic = timeSpentCnt;
                    //     if (!props.isUpdateTopicTimeSpent) {
                    //         var el = document.getElementById("hdnTopicUpdated");
                    //         el.click();
                    //         shouldLog.current = true;
                    //     }
                    //     else {
                    //         shouldLog.current = false;
                    //     }
                    // }
                });
        }
    }

    useEffect(() => {
        // // console.log("checking for all topics completed or not");
        if (isdisposeCalled) {
            disposeVideoJs();
            dispatch(callingDispose(false));
        }
        const isAllTopicCompleted = props.topicsListData.filter((it) => {
            return it.topicStatus == "Open";
        })
        // console.log("isAllTopicCompleted : ", isAllTopicCompleted);
        if (isAllTopicCompleted.length < 1) {
            // console.log("topics are completed")
            // console.log("props.topicContent : ", props.topicContent);
            UpdateSessionStatus();
        } else {
            // console.log("topics are still not completed");
        }
    }, [])

    useEffect(() => {
        // // console.log("to check if back btn clicked from breadcrum is this working");
    }, [props.isBackBtnClicked])

    const disposeVideoJs = () => {
        if (openVideoF) {
            // // console.log("disposing video in next")
            var oldPlayer = document.getElementById('example_video_1');
            videojs(oldPlayer).dispose();
            setOpenVideo(false)
            dispatch(updateVideo(false));
            setHtmlStringArray(htmlStringArrayDuplicate);
            return true
        } else {
            return false
        }
    }

    if (isdisposeCalled) {
        disposeVideoJs();
        dispatch(callingDispose(false));
    }

    // useEffect(() => {
    //     // // console.log("useEffect for disposing the video js player")
    //     alert("working here in use effect props.isTopicChanged, props :", openVideoF);
    //     if (openVideoF) {
    //         // // console.log("disposing video in next")
    //         var oldPlayer = document.getElementById('example_video_1');
    //         videojs(oldPlayer).dispose();
    //     }
    //     setOpenVideo(false)
    //     dispatch(updateVideo(false));
    //     setIsindexChanged("-1")
    //     // clearInterval(timer);

    // }, [props.isTopicChanged, props])

    const goToIndexPage = () => {
        // console.log("timer.current : ", timer.current);
        clearInterval(timer.current);
        setTimer(0);
        disposeVideoJs()
        const isAllTopicCompleted = props.topicsListData.filter((it) => {
            return it.topicStatus == "Open";
        })
        // console.log("isAllTopicCompleted : ", isAllTopicCompleted);
        if (isAllTopicCompleted.length < 1) {
            // console.log("topics are completed")

            UpdateSessionStatus();
        } else {
            // console.log("topics are still not completed");
        }
    }


    // if (props.isUpdateTopicTimeSpent) {
    //     // alert("isUpdateTopicTimeSpent");
    //     if (shouldLog.current) {
    //         shouldLog.current = false;

    //         UpdateTopicStatus();            
    //         // cookies.set("nodeTimeSpentCnt", timeSpentCnt);
    //     }
    // }

    function closeLanguage() {
        setLanguagePopupClicked(false);
        StartTimer("normal");
    }

    function okLanguage() {
        setLanguagePopupClicked(false);
        StartTimer("normal");
    }

    const setTopicDuartion = () => {
        var durationInString = "";

        if (props.topicContent.topicDurationInMins < 60)
            durationInString = props.topicContent.topicDurationInMins + " min";
        else {
            const minutes = props.topicContent.topicDurationInMins % 60;
            const hours = Math.floor(props.topicContent.topicDurationInMins / 60);
            durationInString = hours + " hr " + minutes + " min";
        }

        return durationInString;
    }

    const DisplayLanguagePopup = () => {
        setLanguagePopupClicked(true);
    }


    //// console.log("props.topicContent = " + props.topicContent)


    const functionForContentLanguages = (conLang) => {
        let obj = []
        var conDef = conLang;
        conDef = conDef.indexOf("amazonaws.com") < 0 ? conDef.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conDef;
        conDef = conDef.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");
        // // console.log("conDef : ", conDef);
        const parser = new DOMParser();
        const doc = parser.parseFromString(conDef, 'text/html');
        const pTags = doc.querySelectorAll('p');
        const numberOfPTags = pTags.length;
        // // console.log("numberOfPTags : ", numberOfPTags);
        // // console.log("ptags arr before : ", pTags);

        for (let i = 0; i < pTags.length; i++) {
            // // console.log(`pTags ${i} : `, pTags[i].outerHTML);
            if (i + 1 == pTags.length) {
                // // console.log("last ele push as it is")
                obj.push(pTags[i].outerHTML);
            } else {
                if (JSON.stringify(pTags[i].innerHTML) == JSON.stringify(pTags[i + 1].outerHTML)) {
                    // // console.log(`element is same dont push it i = ${i}  i+1 = ${i + 1}`);
                    // // console.log(`pTags ${i} inner in same : `, pTags[i].innerHTML);
                    // // console.log(`pTags ${i + 1} outer in same : `, pTags[i + 1].outerHTML);
                } else {
                    // // console.log(`different ele push it i = ${i}  i+1 = ${i + 1}`);
                    // // console.log(`pTags ${i} inner in diff : `, pTags[i].innerHTML);
                    // // console.log(`pTags ${i + 1} outer in diff : `, pTags[i + 1].outerHTML);
                    obj.push(pTags[i].outerHTML);
                }
            }
        }
        // console.log("obj after : ", obj);
        return obj
    }

    useEffect(() => {
        // console.log("props.topicContent for checking timer : ", props.topicContent);
        // console.log("props.currentTopicContent for checking timer : ", props.currentTopicContent);
        if (props?.currentTopicContent?.topicStatus === "Open") {
            dispatch(updateTimmerRunning(true));
            StartTimer("normal");
        } else {
            dispatch(updateTimmerRunning(false));
        }
        // // console.log("cookies.get(language) : ", cookies.get("language"))
        let valOfContentLanguage = props.topicContent.contentLanguages.split(",");
        // // console.log("valOfContentLanguage : ", valOfContentLanguage.length);
        if (valOfContentLanguage.length > 1) {
            if (localStorage.getItem("prefferedLanguage") == "Hindi" && props.topicContent.contentInHindi != "") {

                const obj = functionForContentLanguages(props.topicContent.contentInHindi);
                setHtmlStringArray(obj);
                setHtmlStringArrayDuplicat(obj);
                // setHtmlStringToDangerouslySet(props.topicContent.contentInHindi)

            } else if (localStorage.getItem("prefferedLanguage") == "English" && props.topicContent.contentInEnglish != "") {

                // console.log("hererere : ")
                const obj = functionForContentLanguages(props.topicContent.contentInEnglish);
                setHtmlStringArray(obj);
                setHtmlStringArrayDuplicat(obj);
                // setHtmlStringToDangerouslySet(props.topicContent.contentInEnglish)

            } else if (localStorage.getItem("prefferedLanguage") == "Tamil" && props.topicContent.contentInTamil != "") {

                const obj = functionForContentLanguages(props.topicContent.contentInTamil);
                setHtmlStringArray(obj);
                setHtmlStringArrayDuplicat(obj);
                // setHtmlStringToDangerouslySet(props.topicContent.contentInTamil)

            } else if (localStorage.getItem("prefferedLanguage") == "Bengali" && props.topicContent.contentInBengali != "") {

                const obj = functionForContentLanguages(props.topicContent.contentInBengali);
                setHtmlStringArray(obj);
                setHtmlStringArrayDuplicat(obj);
                // setHtmlStringToDangerouslySet(props.topicContent.contentInBengali)

            } else if (localStorage.getItem("prefferedLanguage") == "Telugu" && props.topicContent.contentInTelugu != "") {

                const obj = functionForContentLanguages(props.topicContent.contentInTelugu);
                setHtmlStringArray(obj);
                setHtmlStringArrayDuplicat(obj);
                // setHtmlStringToDangerouslySet(props.topicContent.contentInTelugu)

            } else if (localStorage.getItem("prefferedLanguage") == "Odiya" && props.topicContent.contentInOdiya != "") {

                const obj = functionForContentLanguages(props.topicContent.contentInOdiya);
                setHtmlStringArray(obj);
                setHtmlStringArrayDuplicat(obj);
                // setHtmlStringToDangerouslySet(props.topicContent.contentInOdiya)

            } else {

                const obj = functionForContentLanguages(props.topicContent.defaultContent);
                setHtmlStringArray(obj);
                setHtmlStringArrayDuplicat(obj);
                // setHtmlStringToDangerouslySet(props.topicContent.defaultContent)
            }
        } else {
            const obj = functionForContentLanguages(props.topicContent.defaultContent);
            setHtmlStringArray(obj);
            setHtmlStringArrayDuplicat(obj);
            setHtmlStringToDangerouslySet(props.topicContent.defaultContent)
        }
    }, [cookies.get("language"), props, props.topicsListData, timmerRuning, props.currentTopicContent])

    useEffect(() => {
        // // console.log("openVideoF in useEffect : ", openVideoF);
        if (openVideoF) {
            // // console.log("calling Play video after redux ")
            playVideo(videoUrl)
            // setTimeout(() => {
            // }, 100)
        }

        // if(!isdisposeCalled){
        // } else {
        //         disposeVideoJs();
        //         dispatch(callingDispose(false));
        // }

    }, [openVideoF]);

    const openVideoForSelfStudyTopicContent = async (e, ind) => {
        const el = e.target.closest("Img");
        // console.log("props.isIndexChanged and videoindex: ", videoIndex, ind);
        if (ind == videoIndex) {
            // console.log("clicked on same image");
        } else {

            dispatch(updateVideoIndex(ind));
            const waitingForDispose = await disposeVideoJs();
            if (waitingForDispose) {
                // console.log("waitingForDispose is done : ", openVideoF);
                // console.log("index of p: ", ind)
                // console.log("setting video url : ", htmlStringArrayDuplicate[ind]);
                const splitArr = htmlStringArrayDuplicate[ind].split("<p/>");
                // console.log("splitArr : ", splitArr[0]);
                const parser = new DOMParser();
                const doc = parser.parseFromString(splitArr[0], 'text/html');
                const pTags = doc.querySelectorAll('p,div,span,img')
                // console.log("sasasasa : ", pTags);
                let isImgTag = false;
                for (let a = 0; a < pTags.length; a++) {
                    let valInside = pTags[a].innerHTML;
                    let tagPresent = valInside.slice(0, 4);
                    // console.log(`tagPresent ${a} : `, tagPresent)
                    if (tagPresent == "<img") {
                        isImgTag = true
                    }
                }
                // // console.log("isImgTag : ", isImgTag)
                if (isImgTag) {
                    if (ind > 0 || ind == 0) {
                        if (el.getAttribute("videourl") != null) {
                            //     const splitArrMore = JSON.stringify(splitArr[0]).split("<p/>");
                            // // console.log("splitArrMore : ", splitArrMore);
                            const htmlString = splitArr[0];
                            // console.log("htmlString dddsdss", htmlString)
                            const indexOfStyle = htmlString.indexOf("style");
                            const indexOfvideoUrl = htmlString.indexOf("videourl");
                            const lengthOfHtmlString = htmlString.length;
                            // console.log(`indexOfStyle => ${indexOfStyle} ::: indexOfvideoUrl=>${indexOfvideoUrl} :: : lengthOfHtmlString=> ${lengthOfHtmlString}`);
                            let stringBeforeStyle = htmlString.slice(0, indexOfStyle - 1);
                            let stringAfterStyle = htmlString.slice(indexOfvideoUrl, lengthOfHtmlString);
                            const newHtmlString = stringBeforeStyle + stringAfterStyle;
                            // console.log("newHtmlString : ", newHtmlString)
                            // // console.log("el.getAttribute(fileid) : ", el.getAttribute("fileid"));
                            // // console.log("el.getAttribute(prop) : ", el.getAttribute("prop"));
                            // console.log("el.getAttribute(videourl) : ", el.getAttribute("videourl"));
                            setVideoUrl(el.getAttribute("videourl"));

                            if (el.getAttribute("videourl") == null) {
                                disposeVideoJs()
                            } else {
                                let updatedHtmlString = ''
                                if (el.getAttribute("fileid") == null && el.getAttribute("prop") == null) {
                                    updatedHtmlString = newHtmlString.replace(`<img src="${el.getAttribute("src")}"videourl="${el.getAttribute("videourl")}">`, `<video id="example_video_1" src=${el.getAttribute("videourl")} class="ega-video video-js vjs-default-skin vjs-big-play-centered"
                                    controls preload="auto" 
                                    data-setup='{"example_option":true}' >
                                    </video>`)
                                } else {
                                    updatedHtmlString = newHtmlString.replace(`<img fileid="${el.getAttribute("fileid")}" prop="${el.getAttribute("prop")}" src="${el.getAttribute("src")}"videourl="${el.getAttribute("videourl")}">`, `<video id="example_video_1" src=${el.getAttribute("videourl")} class="ega-video video-js vjs-default-skin vjs-big-play-centered"
                        controls preload="auto" 
                        data-setup='{"example_option":true}' >
                      </video>`)
                                }
                                const tempUpdated = updatedHtmlString;
                                // playVideo(currentQuestion.isVideoUrl);
                                // console.log("updatedHtmlString fffffffffffff : ", updatedHtmlString)
                                let updatedHtmlStringArr = [];
                                for (let a = 0; a < htmlStringArrayDuplicate.length; a++) {
                                    if (a == ind) {
                                        updatedHtmlStringArr.push(updatedHtmlString);
                                    } else {
                                        updatedHtmlStringArr.push(htmlStringArrayDuplicate[a]);
                                    }
                                }
                                // console.log("updatedHtmlStringArr : ", updatedHtmlStringArr);
                                setHtmlStringArray(updatedHtmlStringArr);
                                // setCurrentQuestion({ ...currentQuestion, question: updatedHtmlString });
                                setOpenVideo(true);
                                dispatch(updateVideo(true));
                            }
                        } else {
                            // // console.log("video player is open already")
                            // // console.log("its not video")
                            disposeVideoJs()
                        }
                    } else {
                        // // console.log("clicked on ques");
                    }
                } else {
                    // // console.log("iamge is not present in the tag");
                    // // console.log("video player is open already")
                    // // console.log("its not video")
                    disposeVideoJs()
                }
            } else {
                // // console.log("waitingForDispose is false");
                // // console.log("index of p: ", ind)
                // // console.log("setting video url : ", htmlStringArrayDuplicate[ind]);
                const splitArr = htmlStringArrayDuplicate[ind].split("<p/>");
                // console.log("splitArr : ", splitArr[0]);
                const parser = new DOMParser();
                const doc = parser.parseFromString(splitArr[0], 'text/html');
                const pTags = doc.querySelectorAll('p,div,span,img')
                // console.log("sasasasa : ", pTags);
                let isImgTag = false;
                for (let a = 0; a < pTags.length; a++) {
                    let valInside = pTags[a].innerHTML;
                    let tagPresent = valInside.slice(0, 4);
                    // console.log(`tagPresent ${a} : `, tagPresent)
                    if (tagPresent == "<img") {
                        isImgTag = true
                    }
                }
                // console.log("isImgTag : ", isImgTag)
                if (isImgTag) {
                    if (ind > 0 || ind == 0) {
                        if (el.getAttribute("videourl") != null) {
                            //     const splitArrMore = JSON.stringify(splitArr[0]).split("<p/>");
                            // // console.log("splitArrMore : ", splitArrMore);
                            const htmlString = splitArr[0];
                            // console.log("htmlString dddsdss", htmlString)
                            const indexOfStyle = htmlString.indexOf("style");
                            const indexOfvideoUrl = htmlString.indexOf("videourl");
                            const lengthOfHtmlString = htmlString.length;
                            // console.log(`indexOfStyle => ${indexOfStyle} ::: indexOfvideoUrl=>${indexOfvideoUrl} :: : lengthOfHtmlString=> ${lengthOfHtmlString}`);
                            let stringBeforeStyle = htmlString.slice(0, indexOfStyle - 1);
                            let stringAfterStyle = htmlString.slice(indexOfvideoUrl, lengthOfHtmlString);
                            const newHtmlString = stringBeforeStyle + stringAfterStyle;
                            console.log("newHtmlString : ", newHtmlString)
                            // // console.log("el.getAttribute(fileid) : ", el.getAttribute("fileid"));
                            // // console.log("el.getAttribute(prop) : ", el.getAttribute("prop"));
                            // console.log("el.getAttribute(videourl) : ", el.getAttribute("videourl"));
                            setVideoUrl(el.getAttribute("videourl"));

                            if (el.getAttribute("videourl") == null) {
                                disposeVideoJs()
                            } else {
                                let updatedHtmlString = ''
                                if (el.getAttribute("fileid") == null && el.getAttribute("prop") == null) {
                                    console.log("when field is null");
                                    updatedHtmlString = newHtmlString.replace(`<img src="${el.getAttribute("src")}"videourl="${el.getAttribute("videourl")}">`, `<video id="example_video_1" src=${el.getAttribute("videourl")} class="ega-video video-js vjs-default-skin vjs-big-play-centered"
                                    controls preload="auto" 
                                    data-setup='{"example_option":true}' >
                                    </video>`)
                                    
                                } else {
                                   
                                    console.log("field is there;;;;; : ", newHtmlString);
                                    updatedHtmlString = newHtmlString.replace(`<img fileid="${el.getAttribute("fileid")}" prop="${el.getAttribute("prop")}" src="${el.getAttribute("src")}"videourl="${el.getAttribute("videourl")}">`, `<video id="example_video_1" src=${el.getAttribute("videourl")} class="ega-video video-js vjs-default-skin vjs-big-play-centered"
                        controls preload="auto" 
                        data-setup='{"example_option":true}' >
                      </video>`)
                                }
                                console.log("updatedHtmlString : ",updatedHtmlString)
                                
                                const tempUpdated = updatedHtmlString;
                                // playVideo(currentQuestion.isVideoUrl);
                                // console.log("updatedHtmlString fffffffffffff : ", updatedHtmlString)
                                let updatedHtmlStringArr = [];
                                for (let a = 0; a < htmlStringArrayDuplicate.length; a++) {
                                    if (a == ind) {
                                        updatedHtmlStringArr.push(updatedHtmlString);
                                    } else {
                                        updatedHtmlStringArr.push(htmlStringArrayDuplicate[a]);
                                    }
                                }
                                console.log("updatedHtmlStringArr : ", updatedHtmlStringArr);
                                setHtmlStringArray(updatedHtmlStringArr);
                                // setCurrentQuestion({ ...currentQuestion, question: updatedHtmlString });
                                // setOpenVideo(true);
                                dispatch(updateVideo(true));
                            }
                        } else {
                            // // console.log("video player is open already")
                            // // console.log("its not video")
                            disposeVideoJs()
                        }
                    } else {
                        // // console.log("clicked on ques");
                    }
                } else {
                    // // console.log("iamge is not present in the tag");
                    // // console.log("video player is open already")
                    // // console.log("its not video")
                    disposeVideoJs()
                }
            }
        }

    }

    const topicContentData = useMemo(() => <TopicContentData htmlStringArray={htmlStringArray} openVideoForSelfStudyTopicContent={openVideoForSelfStudyTopicContent} />, [htmlStringArray]);

    // if (arePropsEqual(topicContentData)) {
    //     // Execute your function here
    //     // console.log("Props are equal. Executing your function...");
    //     // if (isdisposeCalled) {
    //     //     disposeVideoJs();
    //     //     dispatch(callingDispose(false));
    //     // }
    //     // Your function code goes here
    // }


    if (startTest) {
        return <Navigate to="/quizquestion" />;
    } else  if(isShowModal && extensionType==="pdf"){
        return <Navigate to={`/TopicDetails/${name}/${id}/${batchId}/${deliveryMode}/${examMode}/pdf`}/>;

    }  else{

        // // console.log( availableLanguages.length +" languages")
        // // console.log("props.topicContent : ", props);
        // // console.log("htmlStringArray : ", htmlStringArray);
        return (
            <>
                {
                    (languagePopupClicked) && props.topicContent.contentLanguages && availableLanguages.length > 1 &&
                    <LanguagePopup closeEvent={closeLanguage} okEvent={okLanguage}
                        header="Select the preferred language to view the content"
                        title="Select Medium of Instruction"
                        extendedBC={true} // updating the breadcrumb in topic content
                        availableLanguages={availableLanguages}
                    //type="center"
                    // ContentLanguages={props.ContentLanguagesID} 
                    //selectedLanguageId={props.DefaultLanguageId}
                    //setTestLanguage={props.DefaultLanguageName}
                    />
                }
                <div className={`${props.showMainDiv ? "sasss col-lg-9 col-sm-12 col-xs-12 content-cards m-0" : "d-none"} `}>
                    <div class="global-radius mb-3">
                        <div className="profile-heading bg-white">

                            {/* onChange={topicUpdated === 1 ? props.onTopicComplete : javascript:void(0);} */}
                            <input type="hidden" value={topicUpdated} id="hdnTopicUpdated" onClick={props.onTopicComplete} />

                            <SessionHeading name={props.topicContent[`nodeName_${localStorage.getItem("prefferedLanguage")}`] || props.topicContent[`nodeName_${localStorage.getItem("prefferedLanguage")}`] || props.topicContent.nodeName}
                                sessionName={props.topicContent.sessionName}
                                duration={props.topicContent.topicDurationInMins}
                                languagesAvailable={props.topicContent.contentLanguages} deliveryMode={props.topicContent.sessionDeliveryMode}
                                onLanguageClick={DisplayLanguagePopup}
                                languagesMoreThanOne={availableLanguages.length > 1}
                                iconType="selfStudy"
                            ></SessionHeading>

                            {htmlStringArray !== null ? topicContentData : ""}
                            {/* <ShowAnswers/> */}
                            {/* {
                                htmlStringArray.map((it, ind) => {
                                    // // console.log("it in map for htmlStringArray : ", it, ind);
                                    return (
                                        <>
                                            <div class="col-md-12 p-4 self-studyImg">
                                                <>
                                                    <div onClick={(e) => openVideoForSelfStudyTopicContent(e, ind)} dangerouslySetInnerHTML={{
                                                        __html: it
                                                    }} />
                                                </>
                                            </div>
                                        </>
                                    )
                                })
                            } */}


                            {/* <div onClick={goToIndexPage} class="col-md-12 text-center border-top pt-4 pb-3">
                                <div class="col-md-12 justify-content-center text-center border-top pt-4 pb-3">
                                <div >
                                <Link to={'../CourseIndex'} >
                                    <button class="secondary-btn ">
                                        Go To Index Page
                                    </button>
                                </Link>
                                </div>
                            </div> */}

                            <NavigationBtns setCurrentTopicContentData={props.setCurrentTopicContentData} isBackBtnClicked={props.isBackBtnClicked} topicsListData={props.topicsListData} setIsBackBtnClicked={props.setIsBackBtnClicked} setIndex={props.setIndex} index={props.index} onTopicClick={props.onTopicClick} />

                        </div>
                    </div>
                </div>



                {/* (((cookies.get("default-language") == undefined) || (cookies.get("default-language") != cookies.get("language"))) ? cookies.get("language") == "HI" ? props.topicContent.contentInHindi : cookies.get("language") == "EN" ? props.topicContent.contentInEnglish : 
                cookies.get("language") == "TA" ? props.topicContent.contentInTamil : cookies.get("language") == "BE" ? props.topicContent.contentInBengali :
                cookies.get("language") == "TE" ? props.topicContent.contentInTelugu : cookies.get("language") == "OD" ? props.topicContent.contentInOdiya
            :cookies.get("default-language") == cookies.get("language") ?  cookies.get("language") == "HI" ? props.topicContent.contentInHindi : cookies.get("language") == "EN" ? props.topicContent.contentInEnglish : 
                cookies.get("language") == "TA" ? props.topicContent.contentInTamil : cookies.get("language") == "BE" ? props.topicContent.contentInBengali :
                cookies.get("language") == "TE" ? props.topicContent.contentInTelugu : cookies.get("language") == "OD" ? props.topicContent.contentInOdiya
            ) */}

                {
                    (isShowModal) &&
                    <ViewTutorialPopUp show={isShowModal} disposeVideoJs={disposeVideoJs} onHide={CloseModalPopup} src={linkUrl} autoPlay={false} extensionType={extensionType} showHeading={false} />
                }
            </>
        )
    }
}, arePropsEqual)

export default TopicContent;