import React, { useEffect, useState } from 'react';
import { StrictMode } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import Loginpage from './components/Login/Loginpage';
import MyCourses from './components/MyCourses/MyCourses';
import CourseIndex from './components/CourseIndex/CourseIndex';
import Eligibility from './components/EligibilityCheck/Eligibility';
import AssignmentsSessions from './components/CourseAssignments/AssignmentsSessions';
import EGADetails from './components/EligibilityCheck/EGADetails';
import PageError from './components/Shared/PageError';
import RegisterPageError from './components/Shared/registerPageError';
import DownloadCertificate from './components/MyCourses/DownloadCertificate';
import TopicDetails from './components/CourseIndex/TopicDetails';
import { createRoot } from 'react-dom/client';
import VirtualSession from './components/Shared/VirtualSession';
import VcComplete from './components/Shared/VcComplete';
import QuizQuestion from './components/EligibilityCheck/QuizQuestion';
import PrivateRoute from './components/Shared/PrivateRoute';
import TrainingDelivery from './components/Admin/TrainingDelivery';
import UserProfile from './components/Profile/UserProfile';
import { Worker } from '@react-pdf-viewer/core';
import UploadEvaluation from './components/CourseIndex/UploadEvaluation';
import AdminList from './components/Admin/AdminList';
import BatchesList from './components/Admin/BatchesList';
import AdminVcSession from './components/Admin/AdminVcSession';
import HelpVideos from './components/Shared/HelpVideos';
import NotFound from './pageNotFound';
import WelcomeMenu from './components/Shared/WelcomeMenu';
import SummaryScore from './components/Shared/SummaryScore';
import PrivacyPolicy from './components/Shared/privacyPolicy';
import CourseIndexMiddlePage from './components/CourseIndex/courseIndexMiddlePage';
import LoginRgsa from './rgsaComponents/rgsaLogin/LoginRgsa';
import ProfileDetailsRgsa from './rgsaComponents/rgsaLogin/ProfileDetailsRgsa';
import IndexRgsa from './rgsaComponents/CourseIndexRgsa/IndexRgsa';
import { variables } from "./components/Shared/Variables";
import Cookies from 'universal-cookie';
import {
  updateFilterVal,
  updateNodeVal,
  updateFilterRetained,
  updateIsBlockCourseIndexAPi,
  updateAccordianNo,
  updateIsContentNodeVal
} from "./features/CourseIndex/courseIndexSlice";
import { updateIsProject, updateUrl } from "./features/project/projectSlice";
import { setComponentName } from "./features/pageError/pageErrorSlice";
import { ErrorBoundary } from "react-error-boundary";
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import $ from 'jquery';
import WelcomeRgsa from './rgsaComponents/rgsaLogin/WelcomeRgsa';
import TopicContentRgsa from './rgsaComponents/CourseIndexRgsa/TopicDetailsRgsa';
import CertificateRgsa from './rgsaComponents/CourseIndexRgsa/certificateRgsa';
import CustumLoader from './components/Shared/CustumLoader';
import RgsaQuiz from './rgsaComponents/CourseIndexRgsa/RgsaQuiz';
import AdobePdfViewer from './components/Shared/adobePdf';

const App = () => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const [isLoader, setIsLoader] = useState(true);
  const isProject = useSelector(state => state.project.isProject);
  const url = useSelector(state => state.project.url);
  const [isSubmit, setIsSubmit] = useState(true);

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    localStorage.clear();
    return true;
  };

  const handleBeforeUnload = () => {
    if (isSubmit) {
      const reportviewerObj = $("#reportviewer-container").data("boldReportViewer");
      if (reportviewerObj) {
        reportviewerObj.clearReportCache();
        reportviewerObj.destroy();
      }
    }
    setIsSubmit(true);
  };

  useEffect(() => {
    const urlsToEnableRightClick = [
      'localhost',
      'beta'
    ];

    const currentURL = window.location.href;
    if (currentURL === "http://localhost:3000/" || 
        currentURL === "https://betauatlms.linqskills.in/" || 
        currentURL === "https://linqskills.in/" || 
        currentURL === "https://betauatlms.linqskills.in/rgsa" || 
        currentURL === "https://betauatlms.linqskills.in/#/rgsa" || 
        currentURL === "https://betauatlms.linqskills.in/#rgsa") {
      dispatch(updateNodeVal([]));
      clearCacheData();
    }

    if (currentURL === "http://localhost:3000/rgsa" || 
        currentURL === "http://localhost:3000/rgsa#/" || 
        currentURL === "https://betauatlms.linqskills.in/rgsa" || 
        currentURL === "https://betauatlms.linqskills.in/#/rgsa" || 
        currentURL === "https://betauatlms.linqskills.in/#rgsa" || 
        currentURL.includes("http://localhost:3000/rgsa") || 
        currentURL.includes("https://betauatlms.linqskills.in/rgsa")) {
      dispatch(updateUrl("rgsa"));
      setIsLoader(false);
    } else {
      dispatch(updateUrl(""));
      setIsLoader(false);
    }

    const handleContextMenu = (event) => {
      const shouldEnableRightClick = urlsToEnableRightClick.some(keyword =>
        window.location.href.includes(keyword)
      );
      if (!shouldEnableRightClick) {
        event.preventDefault();
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);
    window.addEventListener('beforeunload', handleBeforeUnload);

    const handleStorageChange = (event) => {
      if (event.key === 'login-userId') {
          console.log('Auth key updated in another tab:', event.newValue);
          // Refresh the page or perform necessary updates
          window.location.reload();
      }
    };

    // Add event listener
    window.addEventListener('storage', handleStorageChange);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const logError = (error, info) => {
    if (typeof info !== 'object' || !info.componentStack) {
      console.error("Invalid error info:", info);
      return;
    }
    const componentStack = info.componentStack;
    if (typeof componentStack !== 'string') {
      console.error("Component stack is not a string:", componentStack);
      return;
    }
    const match = componentStack.match(/at\s+(.+?)\s+at\s+(.+)/);
    if (match) {
      const substring = match[1];
      console.error("Extracted string:", substring);
    } else {
      console.error("Unable to extract substring from componentStack:", componentStack);
    }
    dispatch(setComponentName(componentStack));
  };

  const handleSubmit = (event) => {
    setIsSubmit(false);
  };

  useEffect(() => {
    document.body.addEventListener('submit', handleSubmit);
    return () => {
      document.body.removeEventListener('submit', handleSubmit);
    };
  }, []);


 /*  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      console.log("You're closing the tab or session!")
      alert("You're closing the tab or session!");  // Show the alert when tab is closed
    };

    window.addEventListener('beforeunload', handleTabClose);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []); */

  return (
    <>
     {/*  <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
                     {isLoader &&      <CustumLoader message="Loading..." />}

      <Router>
        {url === "rgsa" ? (
          <Routes>
            <Route path="/" element={<LoginRgsa />} />
            <Route exact path="/RgsaMyCourses" element={<PrivateRoute><WelcomeRgsa /></PrivateRoute>} />
            <Route exact path="/RgsaCourseIndex/:moduleId" element={<PrivateRoute><IndexRgsa /></PrivateRoute>} />
            <Route exact path="/TopicDetailsRgsa/:name/:id/:batchId/:deliveryMode/:examMode" element={<PrivateRoute><TopicContentRgsa /></PrivateRoute>} />
            <Route exact path="/RgsaProfile" element={<PrivateRoute><ProfileDetailsRgsa /></PrivateRoute>} />
            <Route exact path="/RgsaCertificate" element={<PrivateRoute><CertificateRgsa /></PrivateRoute>} />
            <Route path="/QuizQuestion" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><RgsaQuiz /></ErrorBoundary></PrivateRoute>} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        ) : (
          <Routes>
            <Route exact path="/*" element={<Loginpage />} />
            <Route exact path="/" element={<Loginpage />} />
            <Route path="*" element={<NotFound />} />
            <Route exact path="/privacyPolicy" element={<PrivateRoute><PrivacyPolicy /></PrivateRoute>} />
            <Route exact path="/MyCourses" element={<PrivateRoute><MyCourses /></PrivateRoute>} />
            <Route path="/WelcomeMenu" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><WelcomeMenu /></ErrorBoundary></PrivateRoute>} />
            <Route path="/EgaScore/:AssessmentResultId" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><SummaryScore /></ErrorBoundary></PrivateRoute>} />
            <Route path="/courseIndexMiddlePage" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><CourseIndexMiddlePage /></ErrorBoundary></PrivateRoute>} />
            <Route path="/CourseIndex" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><CourseIndex /></ErrorBoundary></PrivateRoute>} />
            <Route path="/VirtualSession/:id" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><VirtualSession /></ErrorBoundary></PrivateRoute>} />
            <Route path="/VcComplete" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><VcComplete /></ErrorBoundary></PrivateRoute>} />
            <Route path="/UserProfile" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><UserProfile /></ErrorBoundary></PrivateRoute>} />
            <Route path="/Eligibility" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><Eligibility /></ErrorBoundary></PrivateRoute>} />
            <Route path="/EGADetails" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><EGADetails /></ErrorBoundary></PrivateRoute>} />
            <Route path="/AssignmentsSessions" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><AssignmentsSessions /></ErrorBoundary></PrivateRoute>} />
            <Route path="/DownloadCertificate" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><DownloadCertificate /></ErrorBoundary></PrivateRoute>} />
            <Route path="/UploadEvaluation" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><UploadEvaluation /></ErrorBoundary></PrivateRoute>} />
            <Route path="/TopicDetails/:name/:id/:batchId/:deliveryMode/:examMode" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><TopicDetails /></ErrorBoundary></PrivateRoute>} />
            <Route path="/TopicDetails/:name/:id/:batchId/:deliveryMode/:examMode/pdf" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><AdobePdfViewer /></ErrorBoundary></PrivateRoute>} />
            <Route path="/QuizQuestion" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><QuizQuestion /></ErrorBoundary></PrivateRoute>} />
            <Route path="/TrainingDelivery" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><TrainingDelivery /></ErrorBoundary></PrivateRoute>} />
            <Route path="/AdminList" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><AdminList /></ErrorBoundary></PrivateRoute>} />
            <Route path="/BatchesList" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><BatchesList /></ErrorBoundary></PrivateRoute>} />
            <Route path="/AdminVcSession" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><AdminVcSession /></ErrorBoundary></PrivateRoute>} />
            <Route path="/HelpVideos" element={<PrivateRoute><ErrorBoundary FallbackComponent={PageError} onError={logError}><HelpVideos /></ErrorBoundary></PrivateRoute>} />
          </Routes>
        )}
      </Router>
    </>
  );
};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </StrictMode>
);
