import React, { useState, useEffect } from "react";
import axios from "axios";
import FooterRgsa from "./FooterRgsa";
import HeaderMenuRgsa from "./HeaderMenuRgsa";
import { variables } from "../../components/Shared/Variables";
import { useNavigate } from "react-router";
import TextField from '../../components/Shared/TextField';

export default function ProfileDetailsRgsa() {
  const [username, setUsername] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const userId = localStorage.getItem("login-userId");

  const [profile, setProfile] = useState({
    name: ""
  });

  useEffect(() => {
    // Fetch existing username from sessionStorage
    const existingUsername = localStorage.getItem("login-firstName");
    if (existingUsername) {
      setUsername(existingUsername);
      setProfile({ ...profile, 'name': existingUsername })
    }
  }, []); // Empty dependency array ensures the effect runs only once on mount

  useEffect(() => {
    // Enable/disable button based on username length
    if (username.length > 3) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [username]);

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    // Only allow alphabetic characters
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Replace any character that is not a letter or space
    // console.log("filteredValue : ", filteredValue)
    // setUsername(filteredValue);
  };

  const hanldeProfile = (e) => {
    // const inputValue = e.target.value;
    const { name, value } = e.target;
    // console.log("profile val : ", value);
    if (value == "") {
      setProfile({ ...profile, [name]: value })
    } else {
      if (value.length > 2) {
        const nameRegex1 = /^[a-zA-Z -]+$/;
        if (nameRegex1.test(value)) {
          setProfile({ ...profile, [name]: value })
        }
      } else {
        // console.log("working here")
        const nameRegex2 = /^[a-zA-Z]+$/;
        if (nameRegex2.test(value)) {
          // console.log("nameRegex2.test(value) : ",nameRegex2.test(value));
          setProfile({ ...profile, [name]: value })
        } else {
          // console.log("working outside test : ",profile.name)
          setProfile({ ...profile, [name]: profile.name })
        }
      }
    }
  };
  const navigate = useNavigate();

  const updateRGSProfile = async (e) => {
    e.preventDefault();
    try {
      // Assuming userId is defined elsewhere in your component
      const response = await axios.post(
        variables.API_URL + "api/UpdateRGSAprofile",
        {
          userId: parseInt(userId),
          userName: profile.name,
          firstName: profile.name,
          lastName: "",
          email: "",
        }
      );
      if (response.data.isSuccess) {
        localStorage.setItem("login-firstName", profile.name)
        navigate('/RgsaMyCourses')
      }
      // If update successful, redirect the user
    } catch (error) {
      console.error("Error updating RGS profile:", error);
    }
  };

  return (
    <>
      <div class="rgsa-wrapper">
      <HeaderMenuRgsa></HeaderMenuRgsa>

      <div class="container-fluid pe-3 ps-3 rgsa-backgroundcolor">
        <div class="conatiner profile-rgsa">
          <div class="pb-2 ps-3">
            <span class="heading-one-rgsa">પ્રોફાઇલ સંપાદિત કરો</span>
          </div>
          <form class="rgsa-form pb-5 mt-3">
            <div class="form-floating mb-3">
              {/* <input
                type="email"
                class="m-0 form-control"
                id="floatingInput"
                placeholder="તમારું નામ દાખલ કરો"
                value={username}
                onChange={handleUsernameChange}
              ></input>
              <label for="floatingInput">તમારું નામ દાખલ કરો</label> */}

              <TextField type="text"
                onchange={hanldeProfile} name="name"
                value={profile.name}
                classNm="m-0 form-control" label="તમારું નામ"
                infoKey=""
                textKey="તમારું નામ"
                isMandate={false}
                placeholderkey="તમારું નામ દાખલ કરો"
              />
            </div>
            <div class="col-md-12">
              <button disabled={profile.name.length < 3 ? true : false} className={profile.name.length < 3 ? "rgs-disabledbtn rgs-primarybtn" : "rgs-primarybtn"} onClick={updateRGSProfile}>આગળ વધો</button>
            </div>
          </form>
        </div>
      </div>

      <FooterRgsa> </FooterRgsa>
      </div>
    </>
  );
}
