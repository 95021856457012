import React, { useEffect, useState, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TitleHeader from "./TitleHeaderRgsa";
import { variables } from "../../components/Shared/Variables";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import VideoTopic from "./VideoTopic";
import { callingNextPrev, updateNodeIdForAssGetData, updateCallingGetTopicsList } from "../../features/CourseIndex/courseIndexSlice";
import RgsaQuiz from "./RgsaQuiz";
import AssessmentTopicContent from "../../components/CourseIndex/AssessmentTopicContent";
import TopicContentRgsa from "./TopicContentRgsa";

const TopicDetailsRgsa = () => {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const [listOfTopics, setTopicsList] = useState([])

  const isNextPrevCalled = useSelector(state => state.courseindex.isNextPrevCalled);
  const callingGetTopicsList = useSelector(state => state.courseindex.callingGetTopicsList);
  const shouldLog = useRef(true);
  const { name, id, batchId, deliveryMode, examMode } = useParams();
  const nodeID = id;
  const batchID = batchId;
  const deliveryMODE = deliveryMode;
  var userID = localStorage.getItem("login-userId");
  var moduleId = localStorage.getItem("moduleId");
  var coursedata = JSON.parse(localStorage.getItem("coursedata"));
  // // console.log("deter" + nodeID + "dcd" + coursedata.batchID + "dcd" + userID)
  const [currentTopicContentData, setCurrentTopicContentData] = useState([]);
  const [topicContentData, setTopicContentData] = useState([]);
  const [updateTopicTimeSpent, setUpdateTopicTimeSpent] = useState(false);
  const [showMainDiv, setShowMainDiv] = useState(true);
  const [isTopicChanged, setIsTopicChanged] = useState(false);
  const [isDisplayTopicContent, setTopicContentDisplay] = useState(false);
  const [isBackBtnClicked, setIsBackBtnClicked] = useState(false);
  const [index, setIndex] = useState(0);

  const GetTopicsList = (nodeID, batchID, isDisplayContent) => {
    setIsLoader(true);
    
    fetch(variables.API_URL + "api/UserSelfStudy", {
      method: "Post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(examMode === 'pdf' ? {
        UserID: "70223",
        NodeID: "18898",
        BatchID: 6565
      } : {
        UserID: userID,
        NodeID: nodeID,
        BatchID: batchID,
      }),
    })
      .then((response) => {
        if (response.statusText == "Unauthorized") {
          this.setState({ isUnauthorizedUser: true });
        } else return response.json();
      })
      .then((data) => {
        // console.log("setTopicsList(data.dataList) : ", data);
        if (data.isSuccess) {
          // alert(data.dataList)
          setTopicsList(data.dataList);
          // console.log("data" + JSON.stringify(data.dataList))
          if (isDisplayContent) {
            var lists = data.dataList;
            lists.map((l) => {
              var conEng = l.contentInEnglish;

              conEng = conEng.indexOf("amazonaws.com") < 0 ? conEng.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conEng;

              conEng = conEng.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/")

              var conHin = l.contentInHindi;

              conHin = conHin.indexOf("amazonaws.com") < 0 ? conHin.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conHin;

              conHin = conHin.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");

              var conTam = l.contentInTamil;

              conTam = conTam.indexOf("amazonaws.com") < 0 ? conTam.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conTam;

              conTam = conTam.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");

              var conBen = l.contentInBengali;

              conBen = conBen.indexOf("amazonaws.com") < 0 ? conBen.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conBen;

              conBen = conBen.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");

              var conTel = l.contentInTelugu;

              conTel = conTel.indexOf("amazonaws.com") < 0 ? conTel.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conTel;

              conTel = conTel.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");

              var conOdi = l.contentInOdiya;

              conOdi = conOdi.indexOf("amazonaws.com") < 0 ? conOdi.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conOdi;

              conOdi = conOdi.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");


              const updatedItem = {

                ...l.contentInEnglish = conEng,

                ...l.contentInHindi = conHin,

                ...l.contentInTamil = conTam,

                ...l.contentInBengali = conBen,

                ...l.contentInTelugu = conTel,

                ...l.contentInOdiya = conOdi

              }
              return updatedItem;
            });
            // // console.log("working here in GetTopicsList : ",data.dataList )
            DisplayTopicContent(lists);
            // dispatch(updateNodeIdForAssGetData(lists[0].nodeID));
            // // console.log("lists[0].[`nodeName_${language}`] : ",lists[0][`nodeName_${language}`]);
            // dispatch(updategrandChild(lists[0][`nodeName_${language}`]));
            localStorage.setItem("subNodeData", JSON.stringify(data.dataList[0]));
          }
          setIsLoader(false)
        }
      }).catch((error) => {
        // console.log("error in topic details rgsa self study : ", error);
        setIsLoader(false)
      })
  };

  const DisplayTopicContent = async (dataList) => {
    // alert("DisplayTopicContent");
    // console.log("dataList in  DisplayTopicContent : ", dataList)
    let resAfterFetchingNextPrev = await JSON.parse(localStorage.getItem("nextPrevData"));
    if (dataList.length > 0) {
      if (dataList.length > 1) {
        var data = dataList.filter((topics) => topics.topicStatus == "Open");
        // console.log("data after filter open status : ", data)
        if (data.length >= 0) {
          // console.log("ppp : ", dataList[index].sessionDeliveryMode);
          if (dataList[index].sessionDeliveryMode == 2) {
            for (let i = 0; i < resAfterFetchingNextPrev?.data.userDataList.length; i++) {
              if (resAfterFetchingNextPrev?.data.userDataList[i].nodeID == dataList[index].nodeID) {
                // console.log("setTopicContentData : ", resAfterFetchingNextPrev?.data.userDataList[i]);
                setCurrentTopicContentData(resAfterFetchingNextPrev?.data.userDataList[i]);
                setTopicContentData(dataList[index]);
              }
            }
          } else {
            
          }
        }
        else {
          // console.log("aaa")
          setCurrentTopicContentData(dataList[0]);
          setTopicContentData(dataList[0]);

        }
      }
      else {
        // console.log("rrr when only topic available : ", dataList[0])
        setCurrentTopicContentData(dataList[0]);
        setTopicContentData(dataList[0]);
       // console.log(dataList[0].duration);

      }
      setTopicContentDisplay(true);
    }
  }

  const handleTopicStatusUpdate = () => {
    // alert("handleTopicStatusUpdate");
    setUpdateTopicTimeSpent(false);

    GetTopicsList(nodeID, batchID, false);
  }

  const handleTopicChange = (data, isChange) => {
    // // console.log("data in handleTopicChange : ",data);
    // // alert("handleTopicChange: "+ isChange);
    // setUpdateTopicTimeSpent(isChange);

    //// Invoke GetTopicsList() after 2secs, so that the setUpdateTopicTimeSpent is updated first and timespent of the current node gets updated
    //setTimeout(() => {  
    // // console.log(`function callled handleTopicChange from topic list ${data}  `)
    DisplayTopicContent(data);
    // // console.log("working after istopic changed calling GetTopicsList")
    // GetTopicsList(nodeName, nodeID, batchID, true);
    //}, 2000);
  }
  const nodeName = useSelector(state => state.breadcrumb.grandchildNode);


  useEffect(() => {
    // // console.log("working after istopic outside changed")
    if (shouldLog.current) {
      shouldLog.current = false;
      // // console.log("working after istopic inside changed")
      GetTopicsList(nodeID, batchID, true);
    }
  }, [updateTopicTimeSpent, isTopicChanged, index]);

  useEffect(() => {
    if (isNextPrevCalled) {
      GetTopicsList(nodeID, batchID, true);
      dispatch(callingNextPrev(false));
    }
    // console.log("callingGetTopicsList:", callingGetTopicsList);
    if (callingGetTopicsList) {
      dispatch(updateCallingGetTopicsList(false));
      // alert("calling GetTopicsList in topicList.js after updating nextPrev")
      GetTopicsList(nodeID, batchID, true);
      // // console.log("seeting toggle for updating ui");
      // setToggle(!toggle);
    }
  }, [isNextPrevCalled, callingGetTopicsList])

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div class="">
        <TitleHeader url={`/RgsaCourseIndex/${moduleId}`} title={listOfTopics?.length > 0 && listOfTopics[0]?.nodeName}></TitleHeader>
        {
          isDisplayTopicContent && deliveryMODE == 2 &&
          <VideoTopic currentTopicContent={currentTopicContentData} setCurrentTopicContentData={setCurrentTopicContentData} topicContent={topicContentData} topicsListData={listOfTopics} setIndex={setIndex} index={index} onTopicClick={handleTopicChange} batchId={batchID} onTopicComplete={handleTopicStatusUpdate}></VideoTopic>
        }
        {
          isDisplayTopicContent && deliveryMODE == 4 &&  <div class={`course-indexcontent  `}><TopicContentRgsa index={index} showMainDiv={showMainDiv} topicContent={topicContentData} topicsListData={listOfTopics} ParentNodeId={nodeID} ParentNodeName={nodeName} batchId={batchID}
          onTopicComplete={handleTopicStatusUpdate} isUpdateTopicTimeSpent={updateTopicTimeSpent} setIndex={setIndex} examMode='Theory'  /></div>
        }
      </div>
    </div>
  );
};

export default TopicDetailsRgsa;
