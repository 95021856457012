import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { variables } from "./Variables";

const PrivateRoute = ({ children }) => {

// // console.log("inside PrivateRoute")

  const cookies = new Cookies();
  const navigate = useNavigate();

  const getSessionDetails = async () => {
   // debugger;
    try {
      let url =
      variables.API_URL+"api/ISValidSession";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sessionId: cookies.get("sessionId"),
          userId: localStorage.getItem("login-userId"),
        }),
      });
 // Check status code
 if (response.status === 200) {
  const res = await response.json();
  return { valid: res.strResult, status: response.status };
} else {
  return { valid: false, status: response.status };
}
     
    } catch (error) {
      // console.log(error);
    }
  };
  
  getSessionDetails().then((res)=>{
    console.log(res.valid===false)

    if(res.valid===false)
    {
      localStorage.clear();
      navigate('/');
    }
  })
  return children
};

export default PrivateRoute;
