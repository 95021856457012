import React, { useState } from "react";
import moment from "moment";
import Cookies from "universal-cookie";
import { variables } from '../Shared/Variables';
import { TimerFormat } from "../generalFunctions/timeFormat";
export default function SessionHeading(props) {
    const cookies = new Cookies();
    const defaultLanguage = cookies.get('default-language')
    var timeZone = (variables.TimeZone)
    return (
        <>
            <div className="session-heading bg-body border-bottom">
                {
                    props.type && props.type == "profile" ?
                        <div className="d-flex">
                            <div class="pink-box">
                                {
                                    props.iconType == "myProfile" ? <img src="../../Assets/Linqskills_Icons/profile-multicolorRed.svg" ></img>
                                        : props.iconType == "Edit Password" ? <img src="../../Assets/Linqskills_Icons/password-multicolorRed.svg" ></img> : props.iconType == "Update Contact" ? <img src="../../Assets/Linqskills_Icons/mail-multicolorRed.svg" ></img> : props.iconType == "User Preferences" ? <img src="../../Assets/Linqskills_Icons/settings-multicolorRed.svg" ></img> : props.iconType == "Organisation" ? <img src="../../Assets/Linqskills_Icons/b-icons-24_Organisation-Red.svg" class="org-img" ></img> : <img src="../../Assets/Linqskills_Icons/assessment-multicolorRed.svg" ></img>
                                }
                            </div>
                            <div class="heading-one text-truncate">
                                <h5 className="mb-0 text-truncate">{props.name}</h5>
                            </div>
                        </div> :
                        <>
                            <div>
                                {
                                    (props.deliveryMode == 2 || props.deliveryMode == 4 || props.sessionName != undefined) && props.languagesMoreThanOne ?
                                        <div className="language-select float-end ss text-uppercase" onClick={props.onLanguageClick}>
                                            <img src="../../Assets/Linqskills_Icons/Language_16.svg" className="pe-1 mb-1px"></img><span class="align-self-center cursor-pointer">
                                                {localStorage.getItem("prefferedLanguage") != undefined ? localStorage.getItem("prefferedLanguage").substring(0, 2) : "EN"}
                                            </span>
                                        </div>
                                        : <></>
                                }
                                <div className="d-flex">
                                    {/* <div class="pink-box"> */}
                                        {
                                            props.iconType == "vcSession" ? <img src="../../Assets/Linqskills_Icons/virtual_class_topic_icon.svg" className="me-1"></img>
                                            : 
                                            props.iconType == "selfStudy" ? <img src="../../Assets/Linqskills_Icons/self_study_topic_icon.svg" className="me-1"></img> 
                                            : props.iconType == undefined ?
                                            <img src="../../Assets/Linqskills_Icons/assessment_topic_icon.svg" className=""></img> 
                                            :
                                            <img src="../../Assets/Linqskills_Icons/assessment_topic_icon.svg" className=""></img> 
                                        }
                                    {/* </div> */}
                                    <div class="heading-one text-truncate">
                                        <h5 className="mb-0 text-truncate">{props.name}</h5>
                                    </div>

                                </div>
                            </div>
                            <div className="session-details d-flex pt-2 ps-1">
                                {
                                    props.marks != undefined && props.marks != null && props.marks != "" ?
                                        <div class="d-flex me-3">
                                            <div>
                                                <img src="../../Assets/Linqskills_Icons/Marks-grey.svg" className=""></img>
                                            </div>
                                            <div>
                                                <span className="ms-2 h7 self-align-center">
                                                    23 marks </span>
                                            </div>
                                        </div>
                                        : <></>
                                }
                                {
                                    props.duration != undefined && props.duration != null && props.duration != "" ?
                                        <div class="d-flex me-3">
                                            <div>
                                                <img src="../../Assets/Linqskills_Icons/time-grey.svg" class=""></img>
                                            </div>
                                            <div>
                                                <span class="h7 align-self-center ms-2">{TimerFormat(props.duration)}</span>
                                            </div>
                                        </div>
                                        : <></>
                                }
                                {
                                    props.startDateTime != undefined && props.startDateTime != null && props.startDateTime != "" ?
                                        <div class="d-flex me-3">
                                            <div>
                                                <img src="../../Assets/Linqskills_Icons/calendartime-grey.svg" class="" alt=""></img>
                                            </div>
                                            <div>
                                                <span class="h7 align-self-center ms-2">
                                                {( props.meetingStatus == "past") 
    ? moment(props.completedOn).format("DD MMM YYYY | hh:mm A")
    : `${moment(props.startDateTime).format("DD MMM YYYY")} | ${props.startTime}`} 
                               </span>
                                            </div>

                                        </div>
                                        : <></>

                                }
                                {
                                    (props.deliveryMode == 2 || props.deliveryMode == 4 || props.sessionName != undefined) ?
                                        <div class="d-flex me-3">
                                            <div>
                                                <img src="../../Assets/Linqskills_Icons/Language_16.svg" className=""></img>
                                            </div>
                                            <div>
                                                <span className="h7 self-align-center ms-2">{props.languagesAvailable}</span>
                                            </div>
                                        </div>
                                        : <></>
                                }
                            </div>
                        </>
                }

            </div>
        </>
    )
}