

import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import Translate from '../../components/Shared/Translate';
import { useSelector } from "react-redux";
import Cookies from 'universal-cookie';
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
// import NavigationBtns from "../../components/Shared/NavigationBtns";
// import videojs from "video.js";
// import "video.js/dist/video-js.css";
// import VideoJS from "./VideoJS";
// import ShowAnswers from "./ShowAnswers";



//import PopupOne from '../Shared/PopupOne';

export default function CompletedTestRgsa(props) {
  // console.log("props in completed rgsa test : ", props);
  const cookies = new Cookies();
  const subNodeData = JSON.parse(localStorage.getItem("subNodeData"));
  const { examMode } = useParams();


  // console.log("subNodeData: " + JSON.stringify(subNodeData))
  const comingFrom = useSelector(state => state.courseindex.index);
  // // console.log("comingFrom get value redux : ",comingFrom)
  const GoToIndexPage = () => {

  }
  // const playerRef = React.useRef(null);
  // const videoJsOptions = {
  //     autoplay: props.autoPlay ? true : false,
  //     controls: true,
  //     responsive: true,
  //     fluid: true,
  //     sources: [
  //         {
  //             // src: "https://linqskills.s3.ap-south-1.amazonaws.com/UAT/LH/Data/ImageGallery/18738/videos/CourseContent_hin.mp4"
  //             src: props?.recordedVC,
  //             type: "video/mp4",
  //         },
  //     ],
  //     disablePictureInPicture: true,
  //     playbackRates: [0.5, 1, 1.5, 2],
  //     controlBar: {
  //         skipButtons: {
  //             forward: 10,
  //             backward: 10
  //         }
  //     },
  // };
  // // console.log(props.src, "video src");
  // const handlePlayerReady = (player) => {
  //     playerRef.current = player;

  //     // You can handle player events here, for example:
  //     player.on("waiting", () => {
  //         videojs.log("player is waiting");
  //     });

  //     player.on("dispose", () => {
  //         videojs.log("player will dispose");
  //     });
  // };
  useEffect(() => {
    const handleContextMenu = (e) => {
      // prevent the right-click menu from appearing
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    // clean up the event listener when
    // the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <div class="bottom-radius">
      {/* <div class="assessment-instructions border-bottom"></div> */}
      <div class="test-submitted mb-4 text-center">

        {
          props?.recordedVC && props?.recordedVC != "" ?
            <>
              {/* <div class="col-md-12 video-left p-4">
                <h5 class="mt-0 mb-3">Click the video to play the recording </h5>
                <div className="col-md-8 col-sm-12">
                  <VideoJS
                    oncontextmenu="return false;"
                    options={videoJsOptions}
                    onReady={handlePlayerReady}
                    onContextMenu={(event) => event.preventDefault()}
                  />
                </div>
              </div> */}
            </>
            :
            <>
              <img src={props.ImgSrc} class="m-auto mt-3"></img>
              <div class="p-3 pt-0">
                <h6 class="sasas">
                  <span class="bold-500 mb-2 line-height-adjustment"><Translate textKey="certificate002" /> </span><br></br>

                </h6>
              </div>
            </>
        }
      </div>
    </div>
  )
}

// {
//   props.testCompletedOn != undefined && props.testCompletedOn != "" ?
//   <>
//     {
//       props.isDateTime ?
//         `${moment(props.testCompletedOn).format("DD MMM YYYY")}  |  ${moment(props.testCompletedOn).format("hh:mm A")}`
//         :
//         moment(props.testCompletedOn).format("DD MMM YYYY | 07:00 A")
//     }
//   </>
//   :
//   ""
// }



// {comingFrom == "CO" && !props.dontShowNavBtns ?
//                     // <div class="col-md-12 text-center border-top pt-4 pb-3">
//                     //     <Link to={'../CourseIndex'} >
//                     //         <button class="secondary-btn" onClick={GoToIndexPage}>
//                     //             Go To Index Page
//                     //         </button>
//                     //     </Link>
//                     // </div>
//                     <NavigationBtns index={props.index} topicsListData={props.topicsListData} />
//                     : ""
//                 }

