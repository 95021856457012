import React, { Component, useEffect, useState } from "react";
//import Message from './components/message';
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { variables } from '../Shared/Variables.js';
import { useDispatch, useSelector } from "react-redux";
import {
  updateChild,
  updateParent,
  updategrandChild,
  updateggChild,
  updateExtended,
} from "../../features/breadcrumb/breadcrumbSlice";
import {
  callingNextPrev, updateFilterRetained, updateNodeIdForAssGetData, updateIsBlockCourseIndexAPi, updateNextPrevUpdated, callingDispose, updateVideoIndex
} from "../../features/CourseIndex/courseIndexSlice";


export default function NavigationBtns(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nodeValue = useSelector(state => state.courseindex.nodeData);
  const nodeValFromLocalStorage = JSON.parse(localStorage.getItem("nodeVal") || "[]");
  // // console.log("nodeValFromLocalStorage : ",nodeValFromLocalStorage);
  const currenNodeIdAt = useSelector(state => state.courseindex.currenNodeIdAt);
  const isNextPrevUpdated = useSelector(state => state.courseindex.isNextPrevUpdated);
  const callingGetTopicsList = useSelector(state => state.courseindex.callingGetTopicsList);
  const userId = localStorage.getItem("login-userId");
  const courseData = JSON.parse(localStorage.getItem("coursedata"));
  const meetingData = localStorage.getItem("meeting");
  const guidedLearningStatus = localStorage.getItem("guidedLearningStatus");
  // // console.log("meetingData : ", JSON.parse(meetingData));
  const topicDetails = localStorage.getItem("topicDetails");
  const [toggle, setToggle] = useState(false);
  // // console.log("props in navigation btns : ", props);
  var temp = props.topicsListData.findIndex(topics => topics.topicStatus == "Open")
  // // console.log("temp value : ", temp);
  // var indexNoInNavBtns = temp == -1 ? props.index : temp

  var indexNoInNavBtns = props.index;
  // // console.log("props in NavigationBtns :", props);
  let dataOfProps = props.topicsListData?.length > 0 ? props.topicsListData : JSON.parse(meetingData);
  // // console.log("dataOfProps in NavigationBtns : ", dataOfProps);
  let resAfterFetchingNextPrevfuncCalling = JSON.parse(localStorage.getItem("nextPrevData"));

  // // console.log("currenNodeIdAt : ", currenNodeIdAt);

  const [isPrev, setIsPrev] = useState();
  const [isNext, setIsNext] = useState();
  const [isCurrent, setIsCurrent] = useState();
  const [allTopics, setAllTopics] = useState([]);

  // // console.log("courseData from session : ",courseData);

  const nextPrevDataFunc = async () => {
    // // console.log("node value from reduc : ",nodeValue);
    // const allTopics = nodeValFromLocalStorage.filter((it) => {
    //   return it.deliverymodename != ""
    // })
    // // // console.log("allTopics : ",allTopics);
    // let nodeIds = "";
    // const nodeIdsArr = allTopics.map((i) => {
    //   return i.nodeId
    // })
    // nodeIds = nodeIdsArr.join(",");
    // // // console.log("nodeIds final : ",nodeIds)
    // let bodyForNextPrev = {
    //   "userId": userId,
    //   "batchId": courseData.batchId,
    //   "nodeids": nodeIds
    // }
    // // console.log("body for next prev : ",bodyForNextPrev);
    // let resAfterFetchingNextPrev = await axios.post(variables.API_URL + `api/GetUserSessionv1`, bodyForNextPrev);
    let resAfterFetchingNextPrev = JSON.parse(localStorage.getItem("nextPrevData"));
    // // console.log("resAfterFetchingNextPrev : ",resAfterFetchingNextPrev);
    if (resAfterFetchingNextPrev?.data.isSuccess) {
      // // console.log("indexNoInNavBtns : ", indexNoInNavBtns);
      // // console.log("resAfterFetchingNextPrev.data.userDataList : ", resAfterFetchingNextPrev.data.userDataList);
      // // console.log("final dataOfProps : ", dataOfProps[0].nodeId);

      for (let i = 0; i < resAfterFetchingNextPrev?.data.userDataList.length; i++) {

        if (props.topicsListData?.length > 0) {
          // console.log("indexNoInNavBtns : ", indexNoInNavBtns);
          if (indexNoInNavBtns < 1) {
            // // console.log(`inin  value if i = ${i}: ,${resAfterFetchingNextPrev.data.userDataList[i].nodeID}, ${dataOfProps[0].nodeID}`)
            if (resAfterFetchingNextPrev.data.userDataList[i].nodeID == dataOfProps[0].nodeID) {
              // // console.log("value of i : ",i);
              if (i > 0 && i < resAfterFetchingNextPrev.data.userDataList.length - 1) {
                // // console.log("setting both next prev : ", i);
                setIsNext(resAfterFetchingNextPrev.data.userDataList[i + 1]);
                setIsPrev(resAfterFetchingNextPrev.data.userDataList[i - 1]);
                setIsCurrent(resAfterFetchingNextPrev.data.userDataList[i])
              } else if (i == 0) {
                // // console.log("setting next only : ", i);
                setIsNext(resAfterFetchingNextPrev.data.userDataList[i + 1]);
                setIsPrev();
                setIsCurrent(resAfterFetchingNextPrev.data.userDataList[i])
              } else if (i == resAfterFetchingNextPrev.data.userDataList.length - 1) {
                // // console.log("setting prev only : ", i);
                setIsPrev(resAfterFetchingNextPrev.data.userDataList[i - 1]);
                setIsNext();
                setIsCurrent(resAfterFetchingNextPrev.data.userDataList[i])
              }
            }
          } else {
            // // console.log("value of i in setting is next if last obj : ",i,indexNoInNavBtns);
            if (indexNoInNavBtns == props.topicsListData.length - 1) {
              // // console.log("resAfterFetchingNextPrev.data.userDataList[i].nodeID : ",resAfterFetchingNextPrev.data.userDataList[i].nodeID);
              // // console.log("props.topicsListData[indexNoInNavBtns].nodeID : ",props.topicsListData[indexNoInNavBtns].nodeID)
              if (resAfterFetchingNextPrev.data.userDataList[i].nodeID == props.topicsListData[indexNoInNavBtns].nodeID) {
                // // console.log("inside resAfterFetchingNextPrev.data.userDataList[i].nodeID == props.topicsListData[indexNoInNavBtns] : ",i,indexNoInNavBtns)
                setIsNext(resAfterFetchingNextPrev.data.userDataList[i + 1]);
                setIsCurrent(resAfterFetchingNextPrev.data.userDataList[i]);
                setIsPrev(props.topicsListData[indexNoInNavBtns - 1]);
              }
            } else {
              for (let j = 0; j < resAfterFetchingNextPrev.data.userDataList.length; j++) {
                if (resAfterFetchingNextPrev.data.userDataList[j].nodeID == props.topicsListData[indexNoInNavBtns].nodeID) {
                  setIsNext(resAfterFetchingNextPrev.data.userDataList[j + 1]);
                  setIsCurrent(resAfterFetchingNextPrev.data.userDataList[j]);
                }
              }
              setIsPrev(props.topicsListData[indexNoInNavBtns - 1]);
            }
          }
        } else {
          // // console.log('yooooooooononon : ', resAfterFetchingNextPrev.data.userDataList[i].sessionID, dataOfProps[0])

          if (resAfterFetchingNextPrev.data.userDataList[i].sessionID == dataOfProps[0].sessionID) {
            if (i > 0 && i < resAfterFetchingNextPrev.data.userDataList.length - 1) {
              // // console.log("setting both next prev");
              setIsNext(resAfterFetchingNextPrev.data.userDataList[i + 1]);
              setIsPrev(resAfterFetchingNextPrev.data.userDataList[i - 1]);
              setIsCurrent(resAfterFetchingNextPrev.data.userDataList[i])
            } else if (i == 0) {
              // // console.log("setting next only");
              setIsNext(resAfterFetchingNextPrev.data.userDataList[i + 1]);
              setIsPrev();
              setIsCurrent(resAfterFetchingNextPrev.data.userDataList[i])
            } else if (i == resAfterFetchingNextPrev.data.userDataList.length - 1) {
              // // console.log("setting prev only")
              setIsPrev(resAfterFetchingNextPrev.data.userDataList[i - 1]);
              setIsNext();
              setIsCurrent(resAfterFetchingNextPrev.data.userDataList[i])
            }
          }
        }

      }
      setAllTopics(resAfterFetchingNextPrev.data.userDataList);
    } else {
      // // console.log("error or something went wrong in resAfterFetchingNextPrev");
    }
  }

  // // console.log("isPrev : ", isPrev)
  // // console.log("isNext : ", isNext)


  useEffect(() => {
    if (isNextPrevUpdated) {
      dispatch(updateNextPrevUpdated(false));
      // // console.log("seeting toggle for updating ui");
      setToggle(!toggle);
    }
    // // console.log("calling nextPrevDataFunc")
    nextPrevDataFunc();

    // nextPrevData();
  }, [dataOfProps, indexNoInNavBtns, props.index, isNextPrevUpdated])

  const goBackToIndexPage = async () => {
    if (props.setIsBackBtnClicked) {
      dispatch(callingDispose(true));
      dispatch(updateVideoIndex("-1"));
      await props.setIsBackBtnClicked(true);
    }
    dispatch(updateFilterRetained(true));
    dispatch(updateNextPrevUpdated(true));
    navigate(`../CourseIndex`);
  }

  const callingForNextOrPrev = async (subnode, from, currentNode) => {
    if (props.setIsBackBtnClicked) {
      await props.setIsBackBtnClicked(true);
    }
    // // console.log("subnode : " + JSON.stringify(subnode))
    localStorage.setItem("subNodeData", JSON.stringify(subnode))
    let arr = [];
    // // console.log("updateContentBreadCrumb called : ", subnode);
    arr.push(subnode);
    // console.log("arr in session in navigations btns : ", arr);
    localStorage.setItem("meeting", JSON.stringify(arr));
    dispatch(updateChild("Content"));
    // dispatch(updategrandChild(subnode.nodeName));
    if (subnode.sessionDeliveryMode === 1) {
      if (from == "next") {
        if (subnode.topicStatus == "Completed") {
          // console.log("link to complete")
          navigate(`/VirtualSession/2`);
        } else {
          // console.log("normal vc link")
          navigate(`/VirtualSession/${isNext.sessionID}`);
        }
      } else if (from == "prev") {
        if (subnode.topicStatus == "Completed") {
          // console.log("link to complete")
          navigate(`/VirtualSession/2`);
        } else {
          // console.log("normal vc link")
          navigate(`/VirtualSession/${isNext.sessionID}`);
        }
      }
    } else if (subnode.sessionDeliveryMode === 2) {
      // // console.log("")
      dispatch(callingNextPrev(true));
      if (from == "next") {
        navigate(`/TopicDetails/${subnode.sessionName}/${subnode.sessionID}/${courseData.batchId}/${subnode.sessionDeliveryMode}/Theory`);
      } else {
        navigate(`/TopicDetails/${subnode.sessionName}/${subnode.sessionID}/${courseData.batchId}/${subnode.sessionDeliveryMode}/Theory`);
      }
    } else if (subnode.sessionDeliveryMode === 4) {
      // // console.log("isNext Val : ", subnode);
      dispatch(callingNextPrev(true));
      if (from == "next") {
        dispatch(updateNodeIdForAssGetData(isNext.nodeID));
        navigate(`/TopicDetails/${subnode.sessionName}/${subnode.sessionID}/${courseData.batchId}/${subnode.sessionDeliveryMode}/${subnode.sessionExamMode}`);
      } else {
        dispatch(updateNodeIdForAssGetData(isPrev.nodeID));
        navigate(`/TopicDetails/${subnode.sessionName}/${subnode.sessionID}/${courseData.batchId}/${subnode.sessionDeliveryMode}/${subnode.sessionExamMode}`);
      }
    }
  }


  const updateContentBreadCrumb = async (subnode, from, currentNode) => {
    // console.log("props.topicsListData in updateContentBreadCrumb value : ", props.topicsListData.length);
    // console.log("topicDetails in naviagtion btns from session : ", JSON.parse(topicDetails).nodeID);
    dispatch(updateNextPrevUpdated(true));
    dispatch(callingDispose(true));
    dispatch(updateVideoIndex("-1"));
    if (props.topicsListData.length > 1) {
      let ind = 0;
      for (let k = 0; k < props.topicsListData.length; k++) {
        if (props.topicsListData[k].nodeID == JSON.parse(topicDetails).nodeID) {
          if (from == "next") {
            ind = k + 1
          } else {
            ind = k - 1
          }
        }
      }
      // // console.log("final val of ind : ", ind);
      // props.setIndex(ind);
      if (ind <= props.topicsListData.length - 1 && ind >= 0) {
        // // console.log("check for handle topic list function");
        handleTopicClickInNavigationBtns(props.topicsListData[ind].nodeID, ind, props.topicsListData[ind].sessionName, props.topicsListData[ind].nodeName, props.topicsListData[ind])
        // props.setIndex(ind);
        // // console.log("props.topicsListData.length - 1 : ",props.topicsListData.length - 1)
        if (ind == props.topicsListData.length - 1) {
          if (from == "next") {
            let tempIsNextInd = 0;
            for (let d = 0; d < allTopics.length; d++) {
              if (allTopics[d].nodeID == props.topicsListData[ind].nodeID) {
                tempIsNextInd = d + 1
              }
            }
            // // console.log("seting isNext : ", allTopics[tempIsNextInd]);
            setIsNext(allTopics[tempIsNextInd]);
            setIsCurrent(allTopics[tempIsNextInd - 1])
            props.setCurrentTopicContentData(allTopics[tempIsNextInd - 1])
            setIsPrev();
          }
        } else {
          // // console.log("set isNext to normal");
          setIsNext(props.topicsListData[ind + 1]);
          setIsCurrent(props.topicsListData[ind]);
          props.setCurrentTopicContentData(props.topicsListData[ind]);
          setIsPrev(props.topicsListData[ind - 1]);
        }

      } else {
        // // console.log("go for the next topic : ", allTopics);
        props.setIndex(0);
        let filterInd = 0;
        for (let a = 0; a < allTopics.length; a++) {
          if (allTopics[a].nodeID == JSON.parse(topicDetails).nodeID) {
            // // console.log("calling fiteredInd at : ", filterInd);
            if (from == "next") {
              // // console.log("is next : ", allTopics[a + 1]);
              setIsNext(allTopics[a + 1]);
              setIsCurrent(allTopics[a]);
              props.setCurrentTopicContentData(allTopics[a]);
              callingForNextOrPrev(allTopics[a + 1], from, currentNode);
            } else {
              setIsPrev(allTopics[a - 1])
              callingForNextOrPrev(allTopics[a - 1], from, currentNode);
            }
            filterInd = a
          }
          // if(from == "next"){
          //   let filteredVal = allTopics.filter((it)=>{
          //     return it.nodeID == JSON.parse(topicDetails).nodeID
          //   })
          //   // console.log("goto next from next in filtered : ",filteredVal)
          // } else {
          //   let filteredVal = allTopics.filter((it)=>{
          //     return it.nodeID == JSON.parse(topicDetails).nodeID
          //   })
          //   // console.log("goto next from prev in filtered : ",filteredVal)
          // }
        }

      }
    } else {
      callingForNextOrPrev(subnode, from, currentNode);
    };
  }


  // const SessionRedirectionLink = (
  //   deliveryMode,
  //   nodeName,
  //   nodeID,
  //   batchID,
  //   examMode, completionStatus
  // ) => {
  //   // alert("nodeID: "+nodeID);
  //   // console.log(`"nodeName :: ${nodeName} ,, nodeID: ${nodeID} ,, batchID :: ${batchID} ,, deliverMode :: ${deliveryMode} ,, examMode :: ${examMode} ,, completionStatus :: ${completionStatus}`);
  //   // console.log("calling SessionRedirectionLink ");
  //   var link = "";
  //   switch (deliveryMode) {
  //     case 1: {
  //       if (completionStatus == "Completed") {
  //         // console.log("link to complete")
  //         link = `/VirtualSession/2`;
  //         break;
  //       } else {
  //         // console.log("normal vc link")
  //         link = `/VirtualSession/${nodeID}`;
  //         break;
  //       }
  //     }
  //     case 2: {
  //       // link = `/TopicDetails/${nodeName}/${nodeID}/${batchID}/${deliveryMode}/${examMode}`;
  //       // localStorage.setItem("sessionnodename", nodeName);
  //       // localStorage.setItem("sessionnodeid", nodeID);
  //       // localStorage.setItem("sessionbatchid", batchID);
  //       navigate(`/TopicDetails/${isNext.nodeName}/${isNext.nodeID}/${courseData.batchId}/${isNext.sessionDeliveryMode}/Theory`);
  //       break;
  //     }
  //     case 3: {
  //       link = `/VirtualSession`;
  //       break;
  //     }

  //     // this case below for assesment
  //     case 4: {
  //       link = `/TopicDetails/${nodeName}/${nodeID}/${batchID}/${deliveryMode}/${examMode}`;
  //       break;
  //     }
  //     case 5: {
  //       link = `/VirtualSession`;
  //       break;
  //     }
  //     case 6: {
  //       link = `/Feedback`;
  //       break;
  //     }
  //   }

  //   /*  if(deliveryMode == 2) {

  //   }
  //   else {
  //     link = `/courseIndex/vc/${nodeName}`;
  //   } */

  //   //var link = deliveryMode == 2 ? `/CourseIndex/TopicDetails/${nodeName}/${nodeID}/${batchID}` : ``;
  //   return link;
  // };

  const handleNextPrev = (from) => {
    // console.log("handleNextPrev from : ", from);
    if (from == "next") {
      // SessionRedirectionLink(isNext.sessionDeliveryMode,isNext.nodeName,isNext.nodeID,courseData.batchId,"Theory",isNext.topicStatus)
      if (isNext.sessionDeliveryMode == 2) {
        // console.log("navigating to topic details")
        navigate(`/TopicDetails/${isNext.nodeName}/${isNext.nodeID}/${courseData.batchId}/${isNext.sessionDeliveryMode}/Theory`);
      }
    } else {

    }
  }

  const handleTopicClickInNavigationBtns = (nodeID, ind, vcTitleHeading, topicName, topics) => {
    // console.log(`inside topiclist handleTopicClick : ${nodeID} ;;;`)
    if (nodeID === "m") {
      props.setIndex("m")
      dispatch(updateggChild("Meeting Details"))
      props.onTopicClick(nodeID)
      props.sessionType === "meeting" && props.setVcTitleHeading("Meeting Details");
    } else {
      props.setIndex(ind)
      localStorage.setItem("topicDetails", JSON.stringify(topics))
      dispatch(updateggChild(topicName))
      props.sessionType === "meeting" && props.setVcTitleHeading(vcTitleHeading);
      var listData = props.topicsListData;
      var data = listData.filter((topics) => topics.nodeID == nodeID);
      if ((data[0].topicStatus != "Locked") && (data[0].sessionDeliveryMode == 2 || data[0].sessionDeliveryMode == 1)) {
        props.onTopicClick(data, true);
      }
    }

    if (props.isTopicChanged) {
      props.setIsTopicChanged(!props.isTopicChanged)
    }
  }

  const isCheckIsNextSessionStarts = (isCheck) => {
    // console.log("isCheck in func : ", isCheck);
    if (isCheck.topicStatus == "Locked") {
      return false
    } else {
      return false;
    }
  }

  const isDisabledNext = () => {
    if (isNext?.topicStatus === "Locked") {
      return true
    } else {
      if (isNext?.topicStatus === "Open") {
        if (guidedLearningStatus == "yes") {
          if (isCurrent?.sessionID == isNext?.sessionID) {
            if (isCurrent.topicStatus == "Completed") {
              const endDate = new Date(isNext?.sessionReleaseDate);
              // Get the current date and time
              const currentDate = new Date();
              // Compare the two dates
              if (endDate > currentDate) {
                // console.log("The session release date is in the future.");
                return true
              } else {
                // console.log("The session release date is right now!");
                return false
              }
            } else {
              return true
            }
          } else {
            const endDate = new Date(isNext?.sessionReleaseDate);
            // Get the current date and time
            const currentDate = new Date();
            // Compare the two dates
            if (endDate > currentDate) {
              // console.log("The session release date is in the future.");
              return true
            } else {
              // console.log("The session release date is right now!");
              return false
            }
          }
        } else {
          const endDate = new Date(isNext?.sessionReleaseDate);
          // Get the current date and time
          const currentDate = new Date();
          // Compare the two dates
          if (endDate > currentDate) {
            // console.log("The session release date is in the future.");
            return true
          } else {
            // console.log("The session release date is right now!");
            return false
          }
        }
      } else {
        return false
      }
    }
  };
  const isDisabledPrev = () => {
    if (isPrev?.topicStatus === "Locked") {
      return true
    } else {
      if (isPrev?.topicStatus === "Open") {
        const endDate = new Date(isPrev?.sessionReleaseDate);
        // Get the current date and time
        const currentDate = new Date();
        // Compare the two dates
        if (endDate > currentDate) {
          // console.log("The session release date is in the future.");
          return true
        } else {
          // console.log("The session release date is right now!");
          return false
        }
      } else {
        return false
      }
    }
  };



  // console.log("isNext : ", isNext);
  // // console.log("isPrev : ", isPrev);
  // console.log("isCurrent : ", isCurrent);
  return (
    <>
      {
        allTopics.length > 0 ?
          <>
            <div class="navigation-btns col-md-12 text-center border-top pt-4 pb-4 ps-3 pe-3 d-flex">
              <div class={isPrev ? "" : "invisible"}>
                <h6 class="display-desktop mb-2 line-clamp-two btntxt-height text-start truncate-width">{isCurrent?.sessionID == isPrev?.sessionID ? "Go to previous topic" : "Go to previous session"}</h6>
                <h6 class="display-mobile mb-3 mt-0">Previous </h6>
                <button onClick={() => updateContentBreadCrumb(isPrev, "prev", isCurrent)} disabled={isDisabledPrev()} class={isDisabledPrev() ? "disabled-btn d-flex" : "secondary-btn d-flex"}  >
                  <img src={isDisabledPrev() ? "../../Assets/Linqskills_Icons/locked-btn.svg" : "../../Assets/Linqskills_Icons/navleft-purple.svg"} class=""></img>
                  <span class="display-desktop mt-1 cursor-pointer">Previous</span>
                </button>
              </div>

              < div onClick={goBackToIndexPage} className='text-center m-auto'>
                {/* <Link to={'../CourseIndex'} > */}
                <h6 class="mb-2 btntxt-height display-desktop">Go To Content Index  </h6>
                <button class="secondary-btn d-flex display-desktop" >
                  <img src="../../Assets/Linqskills_Icons/content-purple.svg" class=""></img>

                  <span class="mt-1 cursor-pointer display-desktop">Content Index
                  </span>
                </button>
                <h6 class="display-mobile m-0 line-clamp-two btntxt-height text-center truncate-width">{props.topicsListData.length > 1 ? `${props.index + 1}/${props.topicsListData.length}` : "1/1"}</h6>
                <button class="secondary-btn d-flex display-mobile mt-3" >
                  <img src="../../Assets/Linqskills_Icons/content-purple.svg" class=""></img>
                </button>
                {/* </Link> */}
              </div>

              <div class={isNext ? "" : "invisible"}>
                <h6 class="display-desktop mb-2 line-clamp-two btntxt-height text-right truncate-width">{isCurrent?.sessionID == isNext?.sessionID ? "Go to next topic" : "Go to next session"}</h6>
                <h6 class="display-mobile mb-3 mt-0">Next </h6>
                <div>
                  <button onClick={() => updateContentBreadCrumb(isNext, "next", isCurrent)} disabled={isDisabledNext()} class={isDisabledNext() ? "float-end disabled-btn d-flex" : "secondary-btn d-flex float-end"}  >
                    <span class="display-desktop mt-1 cursor-pointer">Next</span>
                    <img src={isDisabledNext() ? "../../Assets/Linqskills_Icons/locked-btn.svg" : "../../Assets/Linqskills_Icons/navright-purple.svg"} class=""></img>
                  </button>
                </div>
              </div>
            </div>
          </> :
          <>
            <div class="skeleton-box d-flex justify-content-between">
              <button class=" d-flex skeleto-btn" >
                <span class="display-desktop mt-1"></span>
              </button>
              <button class="d-flex skeleto-btn" >
                <span class="display-desktop mt-1"></span>
              </button>
              <button class=" d-flex skeleto-btn" >
                <span class="display-desktop mt-1"></span>
              </button>
            </div>
          </>
      }
    </>
  )

}