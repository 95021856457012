import React, { useState, useEffect } from "react";
import NavOne from "../../components/NavOne.js";
import Breadcrumb from "../CourseIndex/Breadcrumb";
import TestPopup from "../Popups/TestPopup.js";
import axios from "axios";
import { variables } from "../Shared/Variables.js";
import SessionHeading from "../EligibilityCheck/SessionHeading.js";
import { Navigate } from "react-router-dom";

const UploadEvaluation = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setuploadedFiles] = useState([]);
  const [openTopicContent, setOpenTopicContent] = useState(false);
  const [isPopUpShow, setIsPopUpShow] = useState(false);

  const handleFileChange = (event) => {
    // console.log("Files selected:", event.target.files);
    const newFiles = Array.from(event.target.files);
    const totalFiles = selectedFiles.length + newFiles.length;

    if (totalFiles > 5) {
      // console.log("You can't add more than 5 files.");
      setIsPopUpShow(true);
    }
    else {
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
      // console.log("Files :", selectedFiles);
    }
  };

  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  var userId = localStorage.getItem("login-userId");
  const coursedata = JSON.parse(localStorage.getItem("coursedata"))
  const nodeData = JSON.parse(localStorage.getItem("nodeData"))
  const testData = JSON.parse(localStorage.getItem("TestData1"))

  // console.log("testData in updateevalation : ", testData);


  function EndTest() {
    fetch(variables.API_URL + 'api/EndTest',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({

          "userID": testData.UserId,
          "batchId": testData.BatchId,
          "CourseId": testData.CourseId,
          "projectId": testData.ProjectId,
          "testId": testData.TestId,
          "lessionplanid": testData.LessionPlanId,
          "isEGATest": testData.IsAssessment == true ? false : true,
          "sessionId": testData.ParentNodeId
        })
      })
      .then(response => response.json())
      .then(result => {
        if (result.isSuccess) {
          if (testData.IsAssessment) {
            setOpenTopicContent(true)
          }
          else {
            // setOpenEGAList(true)
          }
        }
      });
  }

  const handleUpload = () => {
    const formData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i]);
    }
    formData.append("BatchId", coursedata.batchId);
    formData.append("UserId", userId);
    formData.append("TopicNodeId", nodeData.nodeId);
    formData.append("IsCompleted", true);


    const apiEndpoint = variables.API_URL + "api/UploadActivity";

    fetch(apiEndpoint, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSelectedFiles([]);
        getUploadedFiles()
        // console.log("Files uploaded successfully!", data);
        EndTest();
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      });
  };

  const getUploadedFiles = async () => {
    try {
      let apiUrl = variables.API_URL + `api/GetActivityFiles/${userId}/${coursedata.batchId}/${nodeData.nodeId}`
      const response = await axios.get(apiUrl);

      const data = response.data;
      if (data.dataList != null) { setuploadedFiles(data.dataList); }
    } catch (error) {
      console.error('Error fetching uploaded files:', error);
    }
  }
  const removeFiles = async (responseId) => {
    try {
      let apiUrl = variables.API_URL + `api/RemoveActivityFile`
      const response = await axios.post(apiUrl, {
        ResponseId: responseId,
        UserId: userId
      });
      // console.log("response: " + JSON.stringify(response))
      getUploadedFiles();
    } catch (error) {
      console.error('Error removing  files:', error);
    }
  }

  useEffect(() => {
    getUploadedFiles();
  }, [])
  
  if (openTopicContent) {
    return <Navigate to={`/TopicDetails/${testData.ParentNodeName}/${testData.ParentNodeId}/${testData.BatchId}/${testData.DeliveryMode}/${testData.ExamMode}`} />;
  } else {
    return (
      <div>
        <NavOne mobileView={true}></NavOne>
  
        <div class="container-fluid body-w rapper bg-default">
          <div class="container p-0">
          <Breadcrumb redirectLink="CourseIndex" />
          </div>
          <div class="container">
         
            <div className={`col-lg-9 col-sm-12 m-0 col-xs-12 content-cards`}>
              <div class="global-radius mb-3">
                <div className="profile-heading bg-white mt-0">
                  <SessionHeading name={nodeData.nodeName} />
                  <div className="upload-files col-md-9">
                    {isPopUpShow && (
                      <TestPopup
                        show={true}
                        txt={"Cant upload more than 5 files at a time"}
                        title="Warning"
                        onOk={(e) => setIsPopUpShow(false)}
                        onClose={(e) => setIsPopUpShow(false)}
                        type="prompt"
                      />
                    )}
                    {uploadedFiles.length > 0 && <div>
                      {uploadedFiles.map((item, index) => {
                        // // console.log("item.size in kb : ", item)
                        const splitedItem = item.attachment.split(".");
                        // // console.log("splitedItem : ", splitedItem);
                        let extensionName = splitedItem[splitedItem.length - 1];
                        let truncFileName = splitedItem[0];
                        // // console.log("truncFileName : ", truncFileName, extensionName);
                        return (
                          <>
                            <div key={index} class="d-flex col-lg-12 pb-3">
                              <div class="d-flex col-lg-8">
                                <img
                                  src="../../Assets/Linqskills_Icons/sessionstatus-submitted.svg"
                                  class="pe-2"
                                ></img>
                                <img
                                  src="../../Assets/Linqskills_Icons/upload-img.svg"
                                  class="pe-2"
                                ></img>
                                <div class="mt-2 d-flex">
                                  <h6 class="line-clamp-one">{truncFileName.split('/').pop()}</h6>
                                  <h6>.{extensionName}</h6>
                                </div>
  
                                {/* <h6 class="mt-2">{`${truncFileName}.${extensionName}`}</h6> */}
                              </div>
  
                              <div class="col-lg-4 d-flex float-end properties">
  
                                <img
                                  src="../../Assets/Linqskills_Icons/close-small.svg"
                                  onClick={() => removeFiles(item.responseId)}
                                /*                                 onClick={() => removeImage(item)}
                                 */ class=""
                                ></img>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>}
                    <div class="row pb-3">
                      <h6>
                        Choose a file and then click on “Upload Files” to submit for
                        evaluation{" "}
                      </h6>
                    </div>
                    <div class="col-md-12">
                      <div class="file-input-wrapper col-md-3 ">
                        <input
                          type="file"
                          multiple
                          onChange={handleFileChange}
                          id="file-input"
                          class="hidden"
                        />{" "}
                        <label for="file-input" class="secondary-btn btn-change me-3">
                          Gallery
                        </label>{" "}
                      </div>
  
                      {/*   <div class="file-input-wrapper col-md-3 ms-2">
                  <input
                    type="file"
                    disabled={true}
                    id="file-input"
                    class="hidden"
                  />{" "}
                  <label for="" class="disabled-btn btn-change">
                    Take Photo
                  </label>{" "}
                </div> */}
  
                      <div class="col-lg-5 col-sm-12 pt-3 pb-3 text-center">
                        <img
                          src="../../Assets/Linqskills_Icons/upload-default.svg"
                          className="w-auto"
                        ></img>
                      </div>
                      <div class="col-lg-5 col-sm-12 text-center">
                        <h5 class="mb-2">
                          Formats allowed: PDF, CSV, XLSX, XLS, DOC, DOCX, PPT, PPTX,
                          TXT
                        </h5>
                        <h5>Upload file of any size</h5>
                      </div>
                      <div class="col-lg-9 col-sm-12 pt-4 pb-4">
                        <>
                          {selectedFiles.map((item, index) => {
                            // // console.log("item.size in kb : ", item)
                            const splitedItem = item.name.split(".");
                            // // console.log("splitedItem : ", splitedItem);
                            let extensionName = splitedItem[splitedItem.length - 1];
                            let truncFileName = splitedItem[0];
                            // // console.log("truncFileName : ", truncFileName, extensionName);
                            return (
                              <>
                                <div key={index} class="d-flex col-lg-12 pb-3">
                                  <div class="d-flex col-lg-8">
                                    <img
                                      src="../../Assets/Linqskills_Icons/sessionstatus-completed.svg"
                                      class="pe-2"
                                    ></img>
                                    <img
                                      src="../../Assets/Linqskills_Icons/upload-img.svg"
                                      class="pe-2"
                                    ></img>
                                    <div class="mt-2 d-flex">
                                      <h6 class="line-clamp-one">{truncFileName}</h6>
                                      <h6>.{extensionName}</h6>
                                    </div>
  
                                    {/* <h6 class="mt-2">{`${truncFileName}.${extensionName}`}</h6> */}
                                  </div>
  
                                  <div class="col-lg-4 d-flex float-end properties">
                                    <h6 class="mt-2">
                                      {item.size / 1000 < 1000
                                        ? `${(item.size / 1000).toFixed(1)} KB`
                                        : `${(item.size / 1000000).toFixed(1)} MB`}{" "}
                                    </h6>
                                    <img
                                      src="../../Assets/Linqskills_Icons/close-small.svg"
                                      onClick={() => removeFile(index)}
                                /*                                 onClick={() => removeImage(item)}
                                 */ class=""
                                    ></img>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
  
                        <div class="col-lg-7 col-sm-12 pb-4">
                          <button
                            class={`${selectedFiles.length > 0
                                ? "primary-btn w-100"
                                : "disabled-btn w-100"
                              }`}
                            onClick={handleUpload}
                          >
                            <img
                              src="../../Assets/Linqskills_Icons/uploadfile-btn.svg"
                              class=""
                            ></img>
                            Upload Files
                          </button>
                        </div>
                      </div>
                      <style>{`
            .hidden { display: none; } .file-input-wrapper { position: relative; display: inline-block; } .custom-button { /* Add your preferred button styles here */ padding: 10px 15px; background-color: #007bff; color: #fff; cursor: pointer; } 
            `}</style>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  } 
};

export default UploadEvaluation;
