import { useNavigate } from "react-router";
import Cookies from "universal-cookie";
import { Modal } from "react-bootstrap";

export default function TestPopup(props) {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="couponAppliedpop"
        backdrop={false}
      >
        <Modal.Body>
          <>
            <div class="popup-center text-lg-left select-medium">
             {props.type==="success" && <img
                src="../../Assets/Linqskills_Icons/popup-close.svg"
                class="cursor-pointer float-end cross-icon2 "
                onClick={props.onClose}
              ></img>}
              <div class="row">
                {props.title && (
                  <div class="d-flex mb-3 p-0">
                    <h6 class="m-0">
                      <img
                    src={
                      props.type === "prompt"
                        ? "../../Assets/Linqskills_Icons/Warning-popup-btn.svg"
                        : "../../Assets/Linqskills_Icons/popup-info.svg"
                    } 
                        class={`${props.type === "prompt" && "ms-2 me-2"}`}
                      ></img>
                      {props.title}
                    </h6>
                  </div>
                )}
                <div class="d-flex mb-3 popup-bodytxt">
                  {/* <h5 class="m-0" id="testname">
                    {txt}   </h5> */}
                    <div>
                    <h5 dangerouslySetInnerHTML={{ __html: props.txt }} />
                    </div>
               
                </div>
              </div>

              {props.type === "prompt" ? (
                <div class="d-flex float-end mt-3 popups-btns">
                   <button class="cursor-pointer secondary-btn me-3 w-128p" onClick={props.onClose}>
                    Cancel
                  </button>
                  <button class="pe-3 primary-btn w-128p cursor-pointer" onClick={props.onOk}>
                   {props.okLabel}
                  </button>
                 
                </div>
              ) :  null}
            </div>
          </>
        </Modal.Body>
      </Modal>

      <section class="modal overlay"></section>
    </>
  );
}
